import React, { useEffect, useContext } from "react";
import firebase from "firebase";
import { SignupContext } from "../../utils/providers/signup";
import { db } from "../../utils/firebase";

function SignupClient() {
    const {
        firstName,
        lastName,
        email,
        password,
    } = useContext(SignupContext);

    useEffect(() => {

        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then((userAuth) => {
                const { uid } = userAuth.user;

                db.doc(`clients/${uid}`).set({
                    first_name: firstName,
                    last_name: lastName,
                    email,
                    type: "Client",
                    signed_up: firebase.firestore.FieldValue.serverTimestamp(),
                }).then(() => {
                    window.location.href = "/app/calendar";
                });
            }).catch((error) => {
                const { message } = error;
                console.log(message);
            });
    }, []);

    return ("Signing you up...");
}

export default SignupClient;