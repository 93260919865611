import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./utils/providers/auth";
import { MessagesProvider } from "./utils/providers/messages";
import { NotificationsProvider } from "./utils/providers/notifications";

/**
 * Styles
 */
import "./app.scss";

/**
 * Routes and components
 */
import Navigation from "./components/navigation/navigation";
import DefaultRouter from "./utils/routers/default";
import AppRouter from "./utils/routers/app";
import Messages from "./containers/messages/messages";
import Notifications from "./containers/notifications/notifications";

/**
 * App entry point
 */
function App() {
    /**
     * Get the current pathname of the window
     */
    const pathname = window.location.pathname;
    /**
     * Are we on the app-side of the platform?
     */
    if (pathname.startsWith("/app")) {
        /**
         * Return the application specific setup
         */
        return (
            <AuthProvider>
                <section className="app with-bg">
                    {/* React router dom for switching pages */}
                    <BrowserRouter>
                        {/* Messages & notifications context */}
                        <NotificationsProvider>
                            <MessagesProvider>
                                {/* Upper navigation */}
                                <Navigation />
                                <section className="app-page">
                                    {/* AppRouter component keeps URLs neat in seperate file */}
                                    <AppRouter />
                                </section>
                                {/* Messages modal */}
                                <Messages />
                                {/* Notifications modal */}
                                <Notifications />
                            </MessagesProvider>
                        </NotificationsProvider>
                    </BrowserRouter>
                </section>
            </AuthProvider>
        );
    } else if (pathname.startsWith("/signup")) {
        /**
         * If we are on the signup page
         */
        return (
            <AuthProvider>
                {/* React router dom for switching pages */}
                <BrowserRouter>
                    {/* DefaultRouter component keeps URLs neat in seperate file */}
                    <DefaultRouter />
                </BrowserRouter>
            </AuthProvider>
        );
    } else {
        /**
         * All other pages
         */
        return (
            <section className="website-body">
                {/* React router dom for switching pages */}
                <BrowserRouter>
                    {/* DefaultRouter component keeps URLs neat in seperate file */}
                    <DefaultRouter />
                </BrowserRouter>
            </section>
        );
    }
}

export default App;
