import React, { useContext } from "react";
import { AuthContext } from "../../utils/providers/auth";
import "./verification.scss";

/**
 * UI components
 */
import Button from "../../components/ui/button/button";

/**
 * Functional component to collect verification data for the user
 */
function Verification() {
    /**
     * Deconstruct the user object from the context
     */
    const { user } = useContext(AuthContext);

    return (
        <>
            <div className="page-title">
                <h1>Verification Required</h1>
                <p>We need some information from you to enable payouts. We work with Stripe to verifiy our users, proceed to their verification process with the button below.</p>
            </div>

            {/* Do we have a verification link to show */}
            {(user?.stripe.verification.verify_link) &&
                <Button
                    onClick={() => window.location = user?.stripe.verification.verify_link}
                    class="primary">
                    Continue Verification
                </Button>
            }

            {/* Is there no verification link available yet */}
            {(!user?.stripe.verification.verify_link) &&
                <p className="no-link-yet">Generating you a verification link, one moment please...</p>
            }
        </>
    );
}

export default Verification;