import React, { useContext, useEffect, useState } from "react";
import { EventContext } from "../../../../../utils/providers/event";
import { db, auth } from "../../../../../utils/firebase";
import { TrashAltIcon } from "../../../../../utils/svgs";

/**
 * UI components
 */
import Button from "../../../../ui/button/button";
import Select from "../../../../ui/inputs/select/new-select";
import Exercise from "./exercise/exercise";

/**
 * 
 */
function Workouts() {
    const [workouts, setWorkouts] = useState({});
    const [chosenWorkout, setChosenWorkout] = useState({});

    /**
     * Deconstruct variables from the add event context
     */
    const {
        setStage,
        workout, setWorkout,
    } = useContext(EventContext);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Pull all the workouts associated with this trainer from the database
         */
        db.collection("workouts")
            .where("created_by", "==", auth.currentUser.uid)
            .get().then((workoutDocs) => {
                /**
                 * Loop through each of the workouts found
                 */
                workoutDocs.forEach((workoutDoc) => {
                    /**
                     * Push the workout into the local state
                     */
                    setWorkouts((workouts) => ({
                        ...workouts,
                        [workoutDoc.id]: workoutDoc.data().title,
                    }));
                });
            });
    }, []);

    /**
     * When the workout object is updated
     */
    useEffect(() => {
        /**
         * Pull the details for the workout from the database
         */
        db.doc(`workouts/${workout.option}`).get().then((workoutDoc) => {
            /**
             * Push the data for this workout into the state
             */
            setChosenWorkout(workoutDoc.data());
        });
    }, [workout]);

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <Select
                    label="Choose a workout:"
                    placeholder="Choose from your workouts"
                    onSelect={(option) => setWorkout(option)}
                    options={workouts} />

                {(chosenWorkout?.title) &&
                    <div className="chosen-workout">
                        <h4>{chosenWorkout?.title}</h4>

                        {(chosenWorkout.tags?.length > 0) &&
                            <div className="meal-modal-column">
                                <div className="tags-list">
                                    {chosenWorkout.tags?.map((tag, index) => (
                                        <p className="list-item-tag" key={index}>
                                            {tag}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        }

                        {(chosenWorkout.exercises?.length > 0) &&
                            <div className="meal-modal-column chosen-workout-exercises">
                                <h2>Exercises Involved</h2>
                                {chosenWorkout.exercises.map((exercise, index) => (
                                    <>
                                        <p className="list-item" key={index}>
                                            {exercise.title}
                                        </p>
                                        {(exercise.sets.length > 0) &&
                                            <div className="exercise-set-list">
                                                <small>Sets:</small>
                                                <ol>
                                                    {exercise.sets.map((set, index) => (
                                                        <li>{index + 1}) {set.reps.value} {set.reps.unit} at {set.load.value}{set.load.unit} with {set.rest.value} {set.rest.unit} {set.rest.label}</li>
                                                    ))}
                                                </ol>
                                            </div>
                                        }
                                    </>
                                ))}
                            </div>
                        }

                        {chosenWorkout.exercises?.map((exercise) => (
                            <Exercise key={exercise} id={exercise} />
                        ))}
                    </div>
                }
            </div>

            <Button
                class="primary"
                onClick={() => setStage(3)} >
                Continue to Participants
            </Button>
        </div>
    );
}

export default Workouts;