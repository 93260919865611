import React, { useContext } from "react";
import { SignupContext } from "../../utils/providers/signup";

import Input from "../ui/inputs/input";
import Button from "../ui/button/button";

function ClientTerms(props) {
    const {
        setSignupStage,
        errors, setErrors,
        password, setPassword,
        confirmPassword, setConfirmPassword,
    } = useContext(SignupContext);

    const checkInputs = () => {
        let proceed = true;
        let inputErrors = {};

        setErrors({});

        if (!password || password.length <= 6) {
            inputErrors["password"] = "Please enter a valid password";
            proceed = false;
        }

        if (!confirmPassword || confirmPassword.length <= 6) {
            inputErrors["confirmPassword"] = "Please enter a valid password";
            proceed = false;
        }

        if (password !== confirmPassword) {
            inputErrors["password"] = "Passwords must match";
            inputErrors["confirmPassword"] = "Passwords must match";
            proceed = false;
        }

        if (proceed) {
            setSignupStage((signupStage) => signupStage + 1);
        } else {
            setErrors(inputErrors);
        }
    }

    return (
        <>
            <div className="input-block-section">
                <h2>Terms &amp; Conditions</h2>
                <p>By signing up to FitPortal you are agreeing to our Terms &amp; conditions in regards to how we process &amp; store data for the purpose of facilitating payments through our platform.</p>
                <div className="input-elements-grid cols-2">
                    <Input type="password"
                        value={password}
                        placeholder="Password:"
                        label="Password:"
                        wrapperClass={errors.password && 'invalid'}
                        note={errors.password}
                        onChange={(e) => setPassword(e.target.value)} />
                    <Input type="password"
                        value={confirmPassword}
                        placeholder="Confirm Password:"
                        label="Confirm Password:"
                        wrapperClass={errors.confirmPassword && 'invalid'}
                        note={errors.confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)} />
                </div>
            </div>

            <div className="signup-next-stage">
                <Button class="primary" onClick={() => checkInputs()}>Continue</Button>
            </div>
        </>
    );
}

export default ClientTerms;