import React, { useState, useEffect, useContext } from "react";
import { db, auth } from "../../../utils/firebase";
import { AlertsContext } from "../../../utils/providers/alerts";

/**
 * UI componenets
 */
import Button from "../../ui/button/button";
import Textarea from "../../ui/inputs/textarea";

/**
 * 
 */
function Notes(props) {
    const [clientName, setClientName] = useState("");
    const [trainerNotes, setTrainerNotes] = useState("");
    const [clientBio, setClientBio] = useState("");

    /**
     * Get the userID from the props
     */
    const { userID } = props;

    const { pushAlert } = useContext(AlertsContext);

    /**
     * On component load
     */
    useEffect(() => {
        db.doc(`clients/${userID}`).get().then((clientDoc) => {
            const { first_name, bio } = clientDoc.data();
            setClientName(first_name);
            setClientBio(bio);
        });
    }, []);

    /**
     * On component load
     */
    useEffect(() => {
        db.doc(`trainers/${auth.currentUser.uid}/clients/${userID}`).get().then((trainerDoc) => {
            const { notes } = trainerDoc.data();
            setTrainerNotes(notes);
        });
    }, []);

    /**
     * 
     */
    const saveTrainerNotes = async () => {
        await db.doc(`trainers/${auth.currentUser.uid}/clients/${userID}`).set({
            notes: trainerNotes,
        }, { merge: true });

        pushAlert({
            title: "Client notes updated",
        })
    }

    return (
        <>
            <Textarea
                label="Private notes:"
                placeholder={`e.g. Write some personal notes about ${clientName ? clientName : "this client"}`}
                wrapperClass="fw"
                value={trainerNotes}
                rows={5}
                onChange={(e) => setTrainerNotes(e.target.value)} />

            <Textarea
                label={`${clientName ? clientName + "'s " : ""}FitPortal Bio:`}
                placeholder={`e.g. When ${clientName ? clientName : "they"} leave${clientName ? "s" : ""} a bio, it'll show here`}
                wrapperClass="fw"
                readonly={true}
                value={clientBio}
                rows={5}
                onChange={(e) => setClientBio(e.target.value)} />

            <Button
                class="primary"
                disabled={!trainerNotes}
                onClick={() => saveTrainerNotes()}>
                Save Notes
            </Button>
        </>
    );
}

export default Notes;