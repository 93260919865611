import React, { useEffect, useState } from 'react';
import { ExerciseProvider } from '../../utils/providers/exercise';
import { db, auth } from "../../utils/firebase";

/**
 * UI components
 */
import Button from "../../components/ui/button/button";
import Modal from "../../components/modal/modal";
import AddExercise from '../../components/exercises/add/add';
import Exercise from '../../components/exercises/exercise';

/**
 * 
 */
function Exercises() {
    const [exerciseModal, setExerciseModal] = useState(false);
    const [exercises, setExercises] = useState([]);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup a listener on the exercises collection for the user
         */
        const unsubscribe = db.collection("exercises")
            .where("createdBy", "==", auth.currentUser.uid)
            .onSnapshot((exercisesSnap) => {
                /**
                 * Loop through each of the exercises found
                 */
                exercisesSnap.docChanges().forEach((change) => {
                    /**
                     * Added
                     */
                    if (change.type === "added") {
                        setExercises((exercise) => [
                            ...exercise,
                            {
                                id: change.doc.id,
                                ...change.doc.data(),
                            }
                        ]);
                    }
                    /**
                     * Updated
                     */
                    if (change.type === "modified") {
                        setExercises((exercise) => {
                            let updatedExercises = [...exercise];
                            for (let i in exercise) {
                                if (exercise[i].id === change.doc.id) {
                                    updatedExercises[i] = {
                                        id: change.doc.id,
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedExercises;
                        });
                    }
                    /**
                     * Removed
                     */
                    if (change.type === "removed") {
                        setExercises((exercise) => exercise.filter((exerciseItem) => exerciseItem.id !== change.doc.id));
                    }
                });
            });
        /**
         * 
         */
        return () => unsubscribe();
    }, []);

    return (
        <>
            <div className="page-title">
                <div className="page-title-flex with-margin">
                    <h1>Exercises</h1>
                    <Button class="primary" onClick={() => setExerciseModal(true)}>Create Exercise</Button>
                </div>
                <p>Create exercises and assign them to a workout program for you to use with your clients.</p>
            </div>

            <div className="meals-container">
                {exercises.map((exercise) => <Exercise key={exercise.id} id={exercise.id} />)}
            </div>

            {exerciseModal &&
                <Modal
                    title="Create exercise"
                    hide={() => setExerciseModal(false)}
                    className="with-nav no-pad">
                    <ExerciseProvider>
                        <AddExercise close={() => setExerciseModal(false)} />
                    </ExerciseProvider>
                </Modal>
            }
        </>
    );
}

export default Exercises;