import React, { useContext } from "react";
import firebase from "firebase";
import { GoalContext } from "../../../../utils/providers/goal";
import { db, auth } from "../../../../utils/firebase";

/**
 * UI components
 */
import Button from "../../../ui/button/button";

/**
 * 
 */
function Save(props) {
    /**
     * Deconstruct variables from the add workout context
     */
    const {
        title,
        target,
        unit,
        period,
    } = useContext(GoalContext);

    /**
     * Save the goal with the variables from the context
     */
    const saveGoal = () => {
        /**
         * Add the goal into the users subscollection database
         */
        db.collection(`clients/${auth.currentUser.uid}/goals`).add({
            title,
            target,
            period: period.option,
            created_by: auth.currentUser.uid,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        });
        /**
         * Hide the add goal modal through the props
         */
        props.close();
    }

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <h1>Your New "{title}" Goal</h1>

                <p className="workout-sets">
                    Targeting {target} {unit.value}, {period.value}
                </p>
            </div>

            <Button
                class="primary"
                onClick={() => saveGoal()}>
                Save Goal
            </Button>
        </div >
    );
}

export default Save;