/**
 * Styles
 */
import "./input.scss";

/**
 * Input functional component
 *
 * @type {const}
 * @param {object} props Component props passed down
 */
function Input(props) {
    return (
        <div className={["input-block", props.wrapperClass, props.error && "invalid"].join(" ")}>
            {/* Label */}
            {props.label && <label>{props.label}</label>}
            {/* Input field wrap */}
            <div className="inputfield-wrapper">
                {/* If there is an icon to show on the left of the input field */}
                {props.icon &&
                    <div className="inputfield-icon">
                        {props.icon}
                    </div>
                }
                {/* Inputfield */}
                <input
                    id={props.id}
                    type={props.type}
                    className={["inputfield", props.class, props.icon && "has-icon", props.value && "has-value"].join(" ")}
                    onChange={props.onChange}
                    required={props.required}
                    placeholder={props.placeholder}
                    readOnly={props.readonly}
                    disabled={props.disabled}
                    value={props.value}
                    onKeyDown={props.onKeyDown}
                    min={props.min}
                    pattern={props.type === "tel" ? "[0-9]*" : "false"}
                />
            </div>
            {/* Input note / description */}
            {props.note && <p>{props.note}</p>}
            {/* Input error */}
            {props.error && <p className="input-error">{props.error}</p>}
        </div>
    );
}

export default Input;
