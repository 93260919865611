import React, { useState, useEffect } from "react";
import { db, auth } from "../../../utils/firebase";
import firebase from "firebase";
import "./add.scss";

/**
 * UI components
 */
import Input from "../../ui/inputs/input";
import Button from "../../ui/button/button";
import Select from "../../ui/inputs/select/new-select";

/**
 * 
 */
function AddMetric(props) {
    const [title, setTitle] = useState("");
    const [trackingID, setTrackingID] = useState("");
    const [unit, setUnit] = useState({});
    const [direction, setDirection] = useState({});
    const [allowed, setAllowed] = useState(true);

    /**
     * When the title is updated
     */
    useEffect(() => {
        /**
         * Cleanse the title to leave just letters and spaces
         */
        const cleansed = title.replace(/[^A-Za-z\s]+/g, "");
        /**
         * Replace spaces with underscores
         */
        const underscores = cleansed.replace(/[\s]+/g, "_");
        /**
         * Parse the whole string into uppercase
         */
        const uppercase = underscores.toUpperCase()
        /**
         * Set an uppercase version of the string into the state
         */
        setTrackingID(uppercase);
        /**
         * If the title length is longer than 5
         */
        if (title.length > 5) {
            /**
             * Check to see each time it updates if there is a metric that already exists with 
             * the same trackingID as the one generated
             */
            db.collection(`clients/${auth.currentUser.uid}/metrics`)
                .where("tracking_id", "==", uppercase)
                .get().then((metricDocs) => {
                    console.log(metricDocs.size)
                    /**
                     * If the size of the metric docs is great than 0 (a document was found)
                     */
                    if (metricDocs.size > 0) {
                        /**
                         * Update the state to show that metric title not being available
                         */
                        setAllowed(false);
                    } else {
                        /**
                         * If no documents were found, this title is available
                         */
                        setAllowed(true);
                    }
                });
        }
    }, [title]);

    /**
     * Save the new metric into the users subcollection
     */
    const saveMetric = async () => {
        /**
         * Add the new metric into the users subcollection
         */
        await db.collection(`clients/${auth.currentUser.uid}/metrics`).add({
            title,
            tracking_id: trackingID,
            unit: unit.option,
            trend_direction: direction.option,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            createdBy: auth.currentUser.uid,
        });
        /**
         * Hide the modal from view
         */
        props.hide();
        /**
         * Clear the inputs
         */
        setTitle("");
        setUnit({});
        setDirection({});
    }

    return (
        <div className="track-new-metric-inputs">
            <Input
                type="text"
                label="Metric Title:"
                value={title}
                wrapperClass={["fw", (title.length > 5 && (allowed ? "valid" : "invalid"))].join(" ")}
                placeholder="e.g. Resting Heart Rate"
                onChange={(e) => setTitle(e.target.value)}
                error={!allowed && "You already have a metric setup with a similar title, please change the title and try again"}
                note={(trackingID && allowed) && `FitPortal will track this metric as ${trackingID}`} />

            <Select
                label="Unit of Measurement:"
                placeholder="Choose a unit:"
                value={unit.value}
                onSelect={(option) => setUnit(option)}
                options={{
                    percent: "Percent (%)",
                    kilograms: "Kilograms (kg)",
                    bpm: "Beats per minute (bpm)",
                }} />

            <Select
                label="Trend Direction:"
                placeholder="Choose a direction:"
                value={direction.value}
                onSelect={(option) => setDirection(option)}
                options={{
                    higher_better: "Higher is better",
                    lower_better: "Lower is better",
                }}
                note="e.g. In weight loss, the lower the number the better. But for muscle gain, the higher the number, the better." />

            <Button
                class="primary"
                onClick={() => saveMetric()}
                disabled={!(title.length > 5) || !allowed || !unit?.option || !direction?.option}>
                Start Tracking Metric
            </Button>
        </div>
    );
}

export default AddMetric;