import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { db, auth } from "../../../utils/firebase";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "./meals.scss";

/**
 * Instantiate the momentJS locale
 */
moment.locale("gb", {
    week: {
        dow: 1,
        doy: 1,
    },
});

/**
 * Using the moment localizer for the calendar
 */
const localizer = momentLocalizer(moment);

/**
 * Functional component to return the meal planner for the current user
 */
function Meals(props) {
    const [meals, setMeals] = useState([]);
    const [viewingMeal, setViewingMeal] = useState(false);

    /**
     * Deconstruct the start and end dates to pull the records from the database
     */
    const { start, end } = props;

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the start of the period in milliseconds
         */
        const periodStartMillis = moment().startOf("week").format("x");
        /**
         * Generare a firebase timestamp for the start of the period
         */
        const fromTimestamp = firebase.firestore.Timestamp.fromMillis(periodStartMillis);
        /**
         * Setup a listener on the users meals subcollection
         */
        const unsubscribe = db.collection(`clients/${auth.currentUser.uid}/meals`)
            .where("for_when", ">=", fromTimestamp)
            .onSnapshot((mealsSnap) => {
                /**
                 * Loop through the meals found in the database
                 */
                mealsSnap.docChanges().map(async (change) => {
                    /**
                     * Get the meal data from the document
                     */
                    const meal_data = change.doc.data();
                    /**
                     * Pull the meal details from the meals colleciton in the database
                     */
                    const meal_doc_data = await db.doc(`meals/${meal_data.meal}`)
                        .get().then((mealDoc) => {
                            /**
                             * If this meal document exists
                             */
                            if (mealDoc.exists) {
                                /**
                                 * Return the meal data
                                 */
                                return mealDoc.data();
                            } else {
                                return false;
                            }
                        });
                    /**
                     * If the meal document exists in the database
                     */
                    if (meal_doc_data) {
                        /**
                         * If the meal was added
                         */
                        if (change.type === "added") {
                            setMeals((meals) => [
                                ...meals,
                                {
                                    id: change.doc.id,
                                    title: meal_doc_data.name,
                                    start: moment(meal_data.for_when.seconds, "X").toDate(),
                                    end: moment(meal_data.for_when.seconds, "X").add(1, "hour").toDate(),
                                    ...change.doc.data(),
                                    ...meal_doc_data,
                                },
                            ]);
                        }
                        /**
                         * Meal updated
                         */
                        if (change.type === "modified") {
                            setMeals((meals) => {
                                let updatedMeals = [...meals];
                                for (let i in updatedMeals) {
                                    if (updatedMeals[i].id === change.doc.id) {
                                        updatedMeals[i] = {
                                            title: meal_doc_data.name,
                                            start: moment(meal_data.for_when.seconds, "X").toDate(),
                                            end: moment(meal_data.for_when.seconds, "X").add(1, "hour").toDate(),
                                            ...change.doc.data(),
                                            ...meal_doc_data,
                                        };
                                        break;
                                    }
                                }
                                return updatedMeals;
                            });
                        }
                    }
                    /**
                     * Meal removed
                     */
                    if (change.type === "removed") {
                        setMeals((meals) => meals.filter((mealElement) => mealElement.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);

    /**
     * When the meals array is updated 
     */
    useEffect(() => {
        /**
         * Get the length of the array
         */
        const mealCount = meals.length;
        /**
         * Push the length up the props to show in the page intro
         */
        props.setMeals(mealCount);
    }, [meals]);

    return (
        <div className="stat-card">
            <h2 className="stat-heading">Meal Planning</h2>
            <Calendar
                view="week"
                step={60 * 4}
                timeslots={1}
                toolbar={false}
                localizer={localizer}
                formats={{
                    eventTimeRangeFormat: () => {
                        return "";
                    }
                }}
                events={meals}
                startAccessor="start"
                endAccessor="end"
                style={{ height: "300px" }}
            />
        </div>
    );
}

export default Meals;
