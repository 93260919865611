import React, { useContext, useEffect, useState } from "react";
import { EventContext } from "../../../../../utils/providers/event";
import { db, auth } from "../../../../../utils/firebase";
import { TrashAltIcon } from "../../../../../utils/svgs";

/**
 * UI components
 */
import Button from "../../../../ui/button/button";
import Select from "../../../../ui/inputs/select/new-select";

/**
 * 
 */
function Participants() {
    const [clients, setClients] = useState({});
    const [localParticipants, setLocalParticipants] = useState([]);
    const [localParticipantValues, setLocalParticipantValues] = useState([]);

    /**
     * Deconstruct variables from the add event context
     */
    const {
        setStage,
        participants, setParticipants,
    } = useContext(EventContext);

    /**
     * When this page first loads in, pull the current participants from the context
     */
    useEffect(() => {
        setLocalParticipants(participants);
    }, []);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Pull the clients this client is associated with from their subcollection
         */
        db.collection(`trainers/${auth.currentUser.uid}/clients`).get().then((clientDocs) => {
            /**
             * Loop through the clients found
             */
            clientDocs.forEach((clientDoc) => {
                /**
                 * Get this clients data from the database
                 */
                db.doc(`clients/${clientDoc.id}`).get().then((client) => {
                    /**
                     * Does this user exist?
                     */
                    if (client.exists) {
                        /**
                         * Deconstruct the clients details
                         */
                        const { first_name, last_name, email } = client.data();
                        /**
                         * Push them onto the clients object
                         */
                        setClients((clients) => ({
                            ...clients,
                            [client.id]: (first_name && last_name) ? `${first_name} ${last_name}` : email,
                        }))
                    }
                });
            });
        });
    }, []);

    /**
     * when the local participants are updated
     */
    useEffect(() => {
        /**
         * Init a new array for holding just the values
         */
        let userValues = [];
        /**
         * Loop through each 
         */
        localParticipants.map((participant) => {
            /**
             * Push just the user name into the array
             */
            return userValues.push(participant.value);
        });
        /**
         * Then set the userValues into the state array to pass back into the select field
         */
        setLocalParticipantValues(userValues);
        /**
         * Push the local participant array into the context
         */
        setParticipants(localParticipants);
    }, [localParticipants]);

    /**
     * When a participant is chosen from the select box
     */
    const userChosen = (user) => {
        /**
         * Push the user into the local state
         */
        setLocalParticipants((localParticipants) => [...localParticipants, user]);
    }

    /**
     * Remove the user as a participant from the local array
     */
    const removeUser = (user) => {
        /**
         * Remove the user from the array
         */
        setLocalParticipants(localParticipants.filter((currentUser) => currentUser.option !== user.option));
    }

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <Select
                    label="Add users:"
                    placeholder="Choose a user whose involved"
                    alreadyChosen={localParticipantValues}
                    onSelect={(option) => userChosen(option)}
                    options={clients} />

                {localParticipants.map((user, index) => (
                    <p className="list-item" key={index}>
                        {user.value}
                        <span onClick={() => removeUser(user)}>
                            <TrashAltIcon />
                        </span>
                    </p>
                ))}
            </div>

            <Button
                class="primary"
                onClick={() => setStage(4)} >
                Continue to Goals
            </Button>
        </div>
    );
}

export default Participants;