import React from "react";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
import "./home.scss";

/**
 * Slick slider carosuels
 */
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/**
 * UI componenets
 */
import Navigation from "../navigation/navigation";
import { CalendarIcon, MealsIcon, WorkoutsIcon } from "../../../utils/svgs";

/**
 * Images
 */
import bannerImage from "../../../assets/images/website/homepage-banner.png";
import trainerCard from "../../../assets/images/website/trainer-card.png";

import image_finances from "../../../assets/images/website/manage-your-fiances.jpg";
import image_coaching from "../../../assets/images/website/one-to-one-coaching.jpg";
import image_goals from "../../../assets/images/website/set-your-goals.jpg";
import image_yourself from "../../../assets/images/website/showcase-yourself.png";
import image_nutrition from "../../../assets/images/website/track-your-nutrition.jpg";
import image_finance from "../../../assets/images/website/finance-management.jpg";
import image_workout_hub from "../../../assets/images/website/work-out-hub.jpg";
import image_showcase from "../../../assets/images/website/showcase-yourself.jpg";

/**
 * Functional component to return the homepage
 */
function Home() {

    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1250,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 12
                }
            },
        ]
    };

    return (
        <>
            <section className="homepage-banner" style={{ backgroundImage: `url(${bannerImage})` }}>
                <Navigation />

                <div className="banner-text">
                    <h2>Find A Trainer And Track Your Progress</h2>
                    <h1>Take Control Of Your Health &amp; Join Us</h1>
                    <a className="web-button" href="https://fitportal.uk/signup">Let's Get Started</a>
                </div>
            </section>

            <section className="intro-cards padding-block">
                <div className="max-width">
                    <div className="ic-grid">
                        <div className="ic-card">
                            <CalendarIcon />
                            <h3>Find &amp; Connect with Trainers</h3>
                            <p>Using our trainer marketplace</p>
                            <a className="web-button small" href="#trainer">SEE MORE</a>
                        </div>
                        <div className="ic-card">
                            <MealsIcon />
                            <h3>Track Your Nutrition</h3>
                            <p>With our macro tracking dashboard</p>
                            <a className="web-button small" href="#personal">SEE MORE</a>
                        </div>
                        <div className="ic-card">
                            <WorkoutsIcon />
                            <h3>Organise Workouts &amp; Meals</h3>
                            <p>Integrated to your favourite calendar</p>
                            <a className="web-button small" href="#personal">SEE MORE</a>
                        </div>
                    </div>

                    <div className="welcome-to-block" id="trainer">
                        <div className="flex-block">
                            <div className="text">
                                <div className="heading-block">
                                    <small>ABOUT US</small>
                                    <h4>Welcome to FitPortal</h4>
                                    <hr />
                                </div>
                                <p>FitPortal is an all in one innovative platform designed to make fitness simple.</p>
                                <p>Finding a personal trainer was never simpler. We have a trainer marketplace stacked with qualified online coaches who specialise in multiple areas. Whether you are at the beginning of your fitness journey or need support getting yourself back on track, our trainers are there to guide you to health!</p>
                                <NavLink className="web-button" to="/signup">Find A Trainer</NavLink>
                            </div>
                            <div className="single-image">
                                <div className="ic-image-wrapper">
                                    <img src={trainerCard} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="padding-block no-bottom-padding gray-bg" id="personal">
                <div className="heading-block is-center is-white">
                    <h4>Personal Portal</h4>
                    <hr />
                    <p>Fallen off track? Need help getting your fitness journey started? FitPortal is there to guide you back on the right path with the help of our outstanding personal trainers. Our platform is packed with useful tools designed to make managing your workouts easy.</p>
                </div>

                <div className="fitportal-features-grid">
                    <Slider {...sliderSettings}>
                        <div>
                            <div className="ff-card" style={{ backgroundImage: `url(${image_goals})` }}>
                                <div className="ff-card-title">
                                    <h5>Set your Goals</h5>
                                </div>

                                <div className="ff-card-more">
                                    <h5>Set your Goals</h5>
                                    <hr />
                                    <p>Goal setting has never been simpler! Both you and your trainer can set yourself targets to hit on your fitness journey. Add, edit, complete!</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="ff-card" style={{ backgroundImage: `url(${image_coaching})` }}>
                                <div className="ff-card-title">
                                    <h5>One to One Coaching</h5>
                                </div>

                                <div className="ff-card-more">
                                    <h5>One to One Coaching</h5>
                                    <hr />
                                    <p>Personal experience is at the heart of FitPortal. Build a one on one relationships with your trainer through our messaging service as well as having 24/7 support from the FitPortal Team.</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="ff-card" style={{ backgroundImage: `url(${image_nutrition})` }}>
                                <div className="ff-card-title">
                                    <h5>Track your Nutrition</h5>
                                </div>

                                <div className="ff-card-more">
                                    <h5>Track your Nutrition</h5>
                                    <hr />
                                    <p>Have you ever felt like managing your nutrition was hopeless? FitPortal is designed to make tracking your meals simple with our one of a kind nutritions portal. Add meals, macros and more as well as receiving exclusive recipies.</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="ff-card" style={{ backgroundImage: `url(${bannerImage})` }}>
                                <div className="ff-card-title">
                                    <h5>Track Your Metrics</h5>
                                </div>

                                <div className="ff-card-more">
                                    <h5>Track Your Metrics</h5>
                                    <hr />
                                    <p>Personalise your metric tracking by being able to add and amend metrics that are most important to your goals.</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="ff-card" style={{ backgroundImage: `url(${bannerImage})` }}>
                                <div className="ff-card-title">
                                    <h5>Real Time Messaging</h5>
                                </div>

                                <div className="ff-card-more">
                                    <h5>Real Time Messaging</h5>
                                    <hr />
                                    <p>With our integrated messaging system you can contact your trainer all in the same place as your personal portal.</p>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>

            <section className="padding-block no-bottom-padding gray-bg trainers-features">
                <div className="heading-block is-center is-white">
                    <h4>Trainer Portal</h4>
                    <hr />
                    <p>FitPortal is packed with multiple features made for fitness professionals to gain and manage clients. Our innovative marketplace showcases your business to hundreds of clients. We aim to assist fitness fanatics in doing what they do best.</p>
                </div>

                <div className="fitportal-features-grid">
                    <Slider {...sliderSettings}>
                        <div>
                            <div className="ff-card" style={{ backgroundImage: `url(${image_finance})` }}>
                                <div className="ff-card-title">
                                    <h5>Finance Management</h5>
                                </div>

                                <div className="ff-card-more">
                                    <h5>Finance Management</h5>
                                    <hr />
                                    <p>Fitportal is designed to manage your finances for you, Set up subscriptions, one off payments and direct debits at the click of A button, we take care of the rest.</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="ff-card" style={{ backgroundImage: `url(${image_showcase})` }}>
                                <div className="ff-card-title">
                                    <h5>Showcase Yourself</h5>
                                </div>

                                <div className="ff-card-more">
                                    <h5>Showcase Yourself</h5>
                                    <hr />
                                    <p>Our trainer marketplace is a one of a kind platform where you can set up your own personal display profile. Gaining clients has never been more simple.</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="ff-card" style={{ backgroundImage: `url(${image_workout_hub})` }}>
                                <div className="ff-card-title">
                                    <h5>Workout Hub</h5>
                                </div>

                                <div className="ff-card-more">
                                    <h5>Workout Hub</h5>
                                    <hr />
                                    <p>Whether you are a personal trainer or and online coach, we have a workouts portal that is designed for all fitness professionals. Write plans for your clients, send scheduled workouts and more.</p>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
        </>
    );
}

export default Home;