import React from 'react';
import './platform.scss';

/**
 * UI components
 */
import Navigation from "../navigation/navigation";
import Footer from '../footer/footer';

/**
 * Images
 */
import bannerImage from "../../../assets/images/website/homepage-banner.png";

/**
 * Platform page
 */
function Platform() {
    return (
        <>
            <section className="general-banner" style={{ backgroundImage: `url(${bannerImage})` }}>
                <Navigation />

                <div className="banner-text">
                    <h1>Meet FitPortal</h1>
                </div>
            </section>
        </>
    );
}

export default Platform;