import React, { useContext, useEffect, useState } from "react";
import { ProgramContext } from "../../../../utils/providers/program";
import { db, auth } from "../../../../utils/firebase";
import { TrashAltIcon } from "../../../../utils/svgs";

/**
 * UI components
 */
import Button from "../../../ui/button/button";
import Select from "../../../ui/inputs/select/new-select";

/**
 * Functional component to return the details panel for adding a new program
 */
function Workouts() {
    const [userWorkouts, setUserWorkouts] = useState({});
    const [localWorkouts, setLocalWorkouts] = useState([]);

    /**
     * Deconstruct variables from the add meal context
     */
    const {
        setStage,
        workouts, setWorkouts,
    } = useContext(ProgramContext);

    /**
     * On component load, get the workouts from the context
     */
    useEffect(() => {
        /**
         * Pull the current workouts and add them into the local state
         */
        setLocalWorkouts(workouts);
    }, []);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Pull the workouts for this trainer from the database
         */
        db.collection("workouts")
            .where("createdBy", "==", auth.currentUser.uid)
            .get().then((workoutDocs) => {
                /**
                 * Loop through the workout docs
                 */
                workoutDocs.forEach((workoutDoc) => {
                    /**
                     * Push it into the local state
                     */
                    setUserWorkouts((userWorkouts) => ({
                        ...userWorkouts,
                        [workoutDoc.id]: workoutDoc.data().title
                    }));
                });
            });
    }, []);

    /**
     * Listen for the local workouts list to be updated
     */
    useEffect(() => {
        /**
         * Push them into the context when there is an update
         */
        setWorkouts(localWorkouts);
    }, [localWorkouts]);

    /**
     * Add the workout
     */
    const addWorkout = (option) => {
        /**
         * Push the ingredient into the local state
         */
        setLocalWorkouts((localWorkouts) => [...localWorkouts, option]);
    }

    /**
     * 
     */
    const removeWorkout = (remove) => {
        /**
         * Remove the ingredient from the array
         */
        setLocalWorkouts(localWorkouts.filter((workout) => workout.value !== remove));
    }

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <Select
                    label="Workouts involved:"
                    placeholder="Choose your workouts"
                    note="You'll assign these to schedules later on"
                    onSelect={(option) => addWorkout(option)}
                    options={userWorkouts} />

                {workouts.map((workout, index) => (
                    <p className="list-item" key={index}>
                        {workout.value}
                        <span onClick={() => removeWorkout(workout.value)}>
                            <TrashAltIcon />
                        </span>
                    </p>
                ))}
            </div>

            <Button
                class="primary"
                onClick={() => setStage(3)}>
                Continue to Meals
            </Button>
        </div>
    );
}

export default Workouts;