import { useState } from "react";
import { loginRequest } from "../../utils/providers/auth";
import FitPortalLogo from "../../assets/svgs/fit-portal-logo.svg";
import './login.scss';

/**
 * UI Components
 */
import Input from '../../components/ui/inputs/input';
import Button from "../../components/ui/button/button-new";

/**
 * Functional component to return the login page UI
 */
function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({
        username: null,
        password: null,
        global: null,
    });
    const [loggingIn, setLoggingIn] = useState(false);

    /**
     * Check the inputs before sending off to  login request
     */
    const checkInputs = async () => {
        /**
         * Set a loading state to the button
         */
        setLoggingIn(true);
        /**
         * Reset any errors
         */
        setErrors({
            username: null,
            password: null,
            global: null
        });
        /**
         * Are the password and username present?
         */
        if (!password) {
            setErrors({ ...errors, password: 'Password is required' });
        }
        if (!username) {
            setErrors({ ...errors, username: 'Username is required' });
        }
        /**
         * If both are present, send off the request
         */
        if (username && password) {
            /**
             * Await the result of the login request
             */
            const loginResult = await loginRequest(username, password);
            /**
             * Was it a success?
             */
            if (loginResult?.authenticated) {
                /**
                 * Redirect the user to the calendar page
                 */
                window.location.href = "/app/calendar";
            } else {
                setLoggingIn(false);
                /**
                 * Show the relevant error
                 */
                switch (loginResult?.code) {
                    case "auth/wrong-password":
                        setErrors({
                            username: null,
                            password: "Password incorrect",
                            global: "Wrong credentials, please check and try again"
                        });
                        break;
                    case "auth/user-not-found":
                        setErrors({
                            username: "Username not found",
                            password: null,
                            global: "We can't find a user with that email"
                        });
                        break;
                    case "auth/too-many-requests":
                        setErrors({
                            username: null,
                            password: null,
                            global: "Too many wrong attempts, please try again in 5 minutes"
                        });
                        break;
                    default:
                        setErrors({
                            username: null,
                            password: null,
                            global: "There was an error, please try again"
                        });
                        break;
                }
            }
        }
    }

    return (
        <div className="login-wrapper">
            <div id="login-form">
                <img src={FitPortalLogo} alt="Platform Logo" />
                <Input type="email"
                    value={username}
                    placeholder="Email:"
                    label="Email:"
                    wrapperClass={errors.username && 'invalid'}
                    note={errors.username}
                    onChange={(e) => setUsername(e.target.value)} />
                <Input type="password"
                    value={password}
                    placeholder="Password:"
                    label="Password:"
                    wrapperClass={errors.password && 'invalid'}
                    note={errors.password}
                    onChange={(e) => setPassword(e.target.value)} />

                {/* Is there an error to display on the form */}
                {errors.global &&
                    <div className="global-form-error">
                        <p>{errors.global}</p>
                    </div>}

                <Button
                    label="Login"
                    loading={loggingIn}
                    loadingText="Logging in..."
                    onClick={() => checkInputs()} />
            </div>
        </div>
    );
}

export default Login;
