import React from "react";
import "./button-new.scss";

/**
 * Loading icon for when the button is processing
 */
import { LoadingIcon } from "../../../utils/svgs";

/**
 * Functional component for a button
 *
 * @param {object} props Props passed itno the button component
 * @returns HTML markup and functionality for button components
 */
function Button(props) {
    return (
        <button
            id={props.id}
            onClick={props.onClick}
            className={[
                "ui-button",
                props.className,
                props.type,
                props.loading && "is-loading",
                props.fullWidth && "is-full-width",
                props.small && "is-small",
                props.icon && "has-icon",
            ].join(" ")}
            disabled={props.disabled} >

            {/* Is the button currently in a loading state */}
            {props.loading ? (
                <>
                    {/* Show the loading text (or default button text if empty) and the svg animation */}
                    {props.loadingText || props.label}
                    <div className="ui-button-loading-icon">
                        <LoadingIcon />
                    </div>
                </>
            ) : (
                <>
                    {/* Button label */}
                    {props.label}
                    {/* Is there an icon to display */}
                    {props.icon && <div className={["ui-button-icon", !props.label && "no-label"].join(" ")}>{props.icon}</div>}
                </>
            )}
        </button>
    );
}

export default Button;
