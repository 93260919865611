import React, { useContext } from "react";
import { EditWorkoutContext } from "../../../utils/providers/workout-edit";
import "./edit.scss";

/**
 * Add workout stages
 */
import Details from "./stages/details";
import Exercises from "./stages/exercises";
import Save from "./stages/save";
import Tags from "./stages/tags";

/**
 * Functional component to return the form for adding new meals
 */
function EditWorkout(props) {
    /**
     * Deconstruct the stage vars from the context
     */
    const { stage, setStage, setWorkoutID } = useContext(EditWorkoutContext);

    /**
     * 
     */
    setWorkoutID(props.workoutID);

    /**
     * Check the stage the user is trying to navigate towards before continuing, only 
     * allowing them to move back to a stage that has previoulsy been filled out
     */
    const processNavClick = (newStage) => {
        setStage(newStage);
    }

    return (
        <div className="add-meal-modal">
            <div className="add-meal-modal-nav">
                <p
                    onClick={() => processNavClick(1)}
                    className="is-active">
                    Details
                </p>
                <p
                    onClick={() => processNavClick(2)}
                    className="is-active">
                    Exercises
                </p>
                <p
                    onClick={() => processNavClick(3)}
                    className="is-active">
                    Tags
                </p>
                <p
                    onClick={() => processNavClick(4)}
                    className="is-active">
                    Save changes
                </p>
            </div>

            <div className="add-meal-modal-inputs">
                {(stage === 1) && <Details />}
                {(stage === 2) && <Exercises />}
                {(stage === 3) && <Tags />}
                {(stage === 4) && <Save hide={() => props.hide()} />}
            </div>
        </div>
    );
}

export default EditWorkout;