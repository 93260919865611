import { useState, useEffect } from "react";
import { storage } from "../utils/firebase";

/**
 * Hook to handle the upload and streaming of progress
 */
function useStorage(file, prefix, path, meta = {}) {
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [url, setUrl] = useState("");

    /**
     * On component update
     */
    useEffect(() => {
        /**
         * If a file is present in the state
         */
        if (file) {
            /**
             * Get the storage reference
             */
            const storageRef = storage.ref(`${path.join('/')}/${prefix}_${file.name}`);
            /**
             * Setup the metadata if there is any
             */
            const metadata = { customMetadata: { ...meta } };
            /**
             * Upload the file to teh storage and hook into the updates
             */
            storageRef.put(file, metadata).on("state_changed", (snap) => {
                /**
                 * Calculate the progress as a percent
                 */
                const percentage = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
                /**
                 * Update the state
                 */
                setProgress(percentage);
            }, (err) => {
                /**
                 * Update the state with the error details if there was one
                 */
                setError(err);
            }, async () => {
                /**
                 * Get the download URL for the file (to store and use to show on the front end)
                 */
                const downloadUrl = await storageRef.getDownloadURL();
                /**
                 * Update the state with the URL
                 */
                setUrl(downloadUrl);
            });
        }
    }, [file]);
    /**
     * Return all 3 state values
     */
    return { progress, url, error };
};

export default useStorage;