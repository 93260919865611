import React, { useContext } from "react";
import { GoalContext } from "../../../../utils/providers/goal";

/**
 * UI components
 */
import Input from "../../../ui/inputs/input";
import Button from "../../../ui/button/button";
import Select from "../../../ui/inputs/select/new-select";

/**
 * 
 */
function Details() {
    /**
     * Deconstruct variables from the add goal context
     */
    const {
        setStage,
        title, setTitle,
        target, setTarget,
        unit, setUnit,
        period, setPeriod,
    } = useContext(GoalContext);

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs add-new-goal">
                <div className="input-group-column cols-2">
                    <Input
                        type="text"
                        label="Goal title:"
                        placeholder="e.g. Run 50km every week"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title} />

                    <Select
                        label="Period scope:"
                        placeholder="e.g. Weekly"
                        value={period.value}
                        onSelect={(option) => setPeriod(option)}
                        options={{
                            daily: "Daily",
                            weekly: "Weekly",
                            fortnightly: "Fortnightly",
                            monthly: "Monthly",
                            quaterly: "Quaterly",
                            yearly: "Yearly",
                        }} />
                </div>

                <div className="input-group-column cols-2">
                    <Input
                        type="number"
                        label="Goal target:"
                        placeholder="e.g. 50"
                        onChange={(e) => setTarget(e.target.value)}
                        value={target} />

                    <Select
                        label="Unit of measurement:"
                        placeholder="e.g. Kilometers"
                        value={unit.value}
                        onSelect={(option) => setUnit(option)}
                        options={{
                            miles: "Miles",
                            kilometers: "Kilometers",
                            kilograms: "kg",
                            reps: "reps",
                            workouts: "Workouts",
                        }} />
                </div>
            </div>

            <Button
                class="primary"
                onClick={() => setStage(2)}
                disabled={!title || !period.option || !target || !unit.option}>
                Continue to Save
            </Button>
        </div>
    );
}

export default Details;