import React, { useEffect, useState } from "react";
import { db, auth } from "../../../utils/firebase";

const moment = require("moment");

/**
 *
 */
function MessagePriceAccepted(props) {
    const [name, setName] = useState("");

    /**
     * Get the message data from the props
     */
    const { message_data } = props;

    /**
     * Get the starting from date if it's an on-going plan
     */
    const price_accepted_on = moment.unix(message_data.timestamp.seconds).format("Do [of] MMMM [at] HH:mm");

    /**
     * When the message component loads in, get the client details
     */
    useEffect(() => {
        /**
         * Get the first name of the client who accepted the price
         */
        db.doc(`clients/${message_data.user}`).get().then((clientDoc) => {
            setName(clientDoc.data().first_name);
        });
    }, []);

    return (
        <div className={["message-element global-message price-accepted"].join(" ")}>
            <p>{name} accepted the payment for £{(message_data.value)} on the {price_accepted_on}, awaiting payment</p>
        </div>
    );
}

export default MessagePriceAccepted;
