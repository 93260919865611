import React, { useEffect, useState, useRef } from "react";
import { db, auth } from "../../utils/firebase";
import firebase from "firebase";
import moment from "moment";
import "chartjs-adapter-moment";
import "./metric.scss";

/**
 * ChartJS and UI components
 */
import { Line } from "react-chartjs-2";
import {
    CaretDownIcon,
    CaretUpIcon,
    ChevronsLeftIcon,
    ChevronsRightIcon,
    PlusIcon
} from "../../utils/svgs";
import Button from "../ui/button/button";
import Modal from "../modal/modal";
import Record from "./record/record";

/**
 * 
 */
function Metric(props) {
    const [recordingModal, setRecordingModal] = useState(false);
    const [metricData, setMetricData] = useState([]);
    const [sortedMetricData, setSortedMetricData] = useState([]);
    const [metricValues, setMetricValues] = useState([]);
    const [metricDifference, setMetricDifference] = useState(0);
    const [metricDirection, setMetricDirection] = useState("");
    const [metricStarting, setMetricStarting] = useState(0);
    const [metricCurrent, setMetricCurrent] = useState(0);
    const [metricUnit, setMetricUnit] = useState("");
    const [reducedMetricValues, setReducedMetricValues] = useState([]);

    /**
     * Deconstruct the start and end dates to pull the records from alogn with metric details
     */
    const { start, end, metric } = props;

    /**
     * On component load & update of the start and end dates
     */
    useEffect(() => {
        /**
         * Clear the metric data
         */
        setMetricData([]);
        /**
         * Generate some timestamps for querying the metic data
         */
        const startTimestamp = firebase.firestore.Timestamp.fromMillis(start.format("x"));
        const endTimestamp = firebase.firestore.Timestamp.fromMillis(end.format("x"));
        /**
         * Setup a listener on the database to stream updated into the local state for this metric
         */
        const unsubscribe = db.collection(`clients/${auth.currentUser.uid}/metrics/${metric.id}/records`)
            .where("recorded_for", ">=", startTimestamp)
            .where("recorded_for", "<=", endTimestamp)
            .onSnapshot((records) => {
                /**
                 * Loop over the record changes passed down
                 */
                records.docChanges().forEach((change) => {
                    /**
                     * Metric record added
                     */
                    if (change.type === "added") {
                        setMetricData((metrics) => [
                            ...metrics,
                            {
                                id: change.doc.id,
                                ...change.doc.data()
                            }
                        ]);
                    }
                    /**
                     * Metric record updated
                     */
                    if (change.type === "modified") {
                        setMetricData((metrics) => {
                            let updateMetrics = [...metrics];
                            for (let i in metrics) {
                                if (metrics[i].id === change.doc.id) {
                                    updateMetrics[i] = {
                                        id: change.doc.id,
                                        ...change.doc.data()
                                    };
                                    break;
                                }
                            }
                            return updateMetrics;
                        });
                    }
                    /**
                     * Metric record removed
                     */
                    if (change.type === "removed") {
                        setMetricData((metrics) => metrics.filter((record) => record.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, [start, end]);

    /**
     * When the metricData array is updated, we want to sort it into date order
     */
    useEffect(() => {
        /**
         * Is there any metric data to sort?
         */
        if (metricData.length > 0) {
            /**
             * Sort the records by their created timestamp
             */
            const sortedData = [...metricData.sort((a, b) => {
                return a.recorded_for.seconds - b.recorded_for.seconds;
            })];
            /**
             * Push this new sorted copy into the local state
             */
            setSortedMetricData(sortedData);
        }
    }, [metricData]);

    /**
     * When the sorted array of metric data is updated
     */
    useEffect(() => {
        /**
         * Check to make sure there is some sorted data for us to use
         */
        if (sortedMetricData.length > 0) {
            /**
             * Set the starting value for the period to the first index
             */
            setMetricStarting(sortedMetricData[0].value);
            /**
             * Then set the current value to the most recent record
             */
            setMetricCurrent(sortedMetricData[sortedMetricData.length - 1].value);
            /**
             * Reset the metric values
             */
            setMetricValues([])
            /**
             * We then need to loop through the sorted values and start segregating them into and 
             * array, ordered by their dates
             */
            sortedMetricData.map((metricRecord) => {
                /**
                 * Build a unqiue string for the date this record was added to
                 */
                const recordDate = moment(metricRecord.recorded_for.seconds, "X").format("YYYY-MM-DD");
                /**
                 * Parse the metricValue through as an float
                 */
                const value = parseFloat(metricRecord.value).toFixed(2);
                /**
                 * Then we want to push this value to the "metricValues" array, this will overwrite 
                 * additional records, leaving just the most recent for that particular date. Might 
                 * change this in the future to accomodate for multiple records, making an average 
                 * instead
                 */
                setMetricValues((metricValues) => ([...metricValues, { x: `${recordDate}`, y: value }]));
            });
        }
    }, [sortedMetricData]);

    /**
     * When the metric unit is recognised
     */
    useEffect(() => {
        /**
         * Push the corresponding symbol to the state to show on the chart
         */
        switch (metric.unit) {
            case "percent":
                return setMetricUnit("%");
            case "kilograms":
                return setMetricUnit("kg");
            case "bpm":
                return setMetricUnit("bpm");
            default:
                return null;
        }
    }, [metric.unit]);

    /**
     * When either the starting or current values are updates
     */
    useEffect(() => {
        /**
         * Work out the difference
         */
        const difference = (metricCurrent - metricStarting).toFixed(2);
        /**
         * Set the difference into the state
         */
        setMetricDifference(difference);
        /**
         * Then we need to work out what direction the trend is heading in
         */
        if (metric.trend_direction === "lower_better") {
            /**
             * If the trend is the lower the number the better & the current value is 
             * less than the starting one
             */
            if (parseFloat(metricCurrent) < parseFloat(metricStarting)) {
                setMetricDirection("POSITIVE");
            } else {
                setMetricDirection("NEGATIVE");
            }
        } else if (metric.trend_direction === "higher_better") {
            /**
             * If the trend is the higher the number the better & the current value is 
             * less than the starting one
             */
            if (parseFloat(metricCurrent) < parseFloat(metricStarting)) {
                setMetricDirection("NEGATIVE");
            } else {
                setMetricDirection("POSITIVE");
            }
        }
    }, [metricStarting, metricCurrent]);

    /**
     * When the metric values have been updated
     */
    useEffect(() => {
        /**
         * Init a new object to store the values in
         */
        let sorted = {};
        /**
         * Loop through all the metric values that were added
         */
        metricValues.forEach((value) => {
            /**
             * If we already have a copy of it in the new object
             */
            if (sorted[`${value.x}`]) {
                /**
                 * Push the value onto the array
                 */
                sorted[`${value.x}`] = [...sorted[`${value.x}`], value.y];
            } else {
                /**
                 * Otherwise just set it as the first value
                 */
                sorted[`${value.x}`] = [value.y];
            }
        });
        /**
         * Init a new array to hold the reduced data
         */
        let reducedData = [];
        /**
         * Loop through each of the now sorted values
         */
        Object.entries(sorted).forEach((dataPoint) => {
            /**
             * Get the records array, holding the values to be averaged
             */
            const dateRecords = dataPoint[1];
            /**
             * Make a sum of all the records
             */
            const recordsSum = dateRecords.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
            /**
             * Then find the avaerage
             */
            const recordsAvg = parseFloat(recordsSum / dateRecords.length).toFixed(2);
            /**
             * Push this new reduced version of the metric records to our local array
             */
            reducedData = [...reducedData, { x: `${dataPoint[0]}`, y: recordsAvg }];
        });
        /**
         * Push the complete reduced array into the local state
         */
        setReducedMetricValues(reducedData);
    }, [metricValues]);

    /**
     * Setup a reference for the chart
     */
    const chartRef = useRef(null);

    /**
     * Establish some parameters for the chart
     */
    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                display: false,
            },
            x: {
                type: "time",
                time: {
                    unit: "day",
                    tooltipFormat: "DD/MM/YYYY",
                },
                suggestedMin: start,
                suggestedMax: end,
                display: true,
                grid: {
                    color: "#EDF0F4",
                    lineWidth: 2,
                    borderWidth: 0,
                },
                ticks: {
                    color: "#4E5C70",
                    font: {
                        family: "Poppins",
                        size: 12,
                        weight: 500,
                    },
                },
            },
        },
        borderColor: "#50BAC8",
        backgroundColor: "#50BAC8",
        pointRadius: 6,
        tension: 0.35,
    }

    return (
        <>
            <div className={["metric-card", (metricData.length === 0) && "no-data"].join(" ")}>
                {/* Metric heading and new record button */}
                <h2 className="stat-heading">
                    {metric.title}
                    <Button
                        class="primary small"
                        onClick={() => setRecordingModal(true)}>
                        <PlusIcon />
                    </Button>
                </h2>

                {/* Upper details to sit above the chart */}
                <div className="stat-details">
                    <div className="stat-numbers">
                        {/* Starting value for this metric */}
                        <div className="big-stat">
                            <p>
                                {metricStarting}<span>{metricUnit}</span>
                                <small>Starting</small>
                            </p>
                        </div>

                        {/* Is this a positive trend direction */}
                        {(metricDirection === "POSITIVE") &&
                            <div className="metrics-animation up">
                                <ChevronsRightIcon />
                                <p>
                                    {metric.trend_direction === "lower_better" && <CaretDownIcon />}
                                    {metric.trend_direction === "higher_better" && <CaretUpIcon />}
                                    {metricDifference}{metricUnit}
                                </p>
                                <ChevronsLeftIcon />
                            </div>
                        }

                        {/* Or a negative trend direction */}
                        {(metricDirection === "NEGATIVE") &&
                            <div className="metrics-animation down">
                                <ChevronsLeftIcon />
                                <p>
                                    {metric.trend_direction === "lower_better" && <CaretUpIcon />}
                                    {metric.trend_direction === "higher_better" && <CaretDownIcon />}
                                    {metricDifference}{metricUnit}
                                </p>
                                <ChevronsRightIcon />
                            </div>
                        }

                        {/* Current value for this metric */}
                        <div className="big-stat">
                            <p>
                                {metricCurrent}<span>{metricUnit}</span>
                                <small>Current</small>
                            </p>
                        </div>
                    </div>

                    {/* ChartJS line chart with the parameters and data from above */}
                    <div className="stat-chart small">
                        <Line
                            ref={chartRef}
                            width={475}
                            height={145}
                            data={{
                                datasets: [{ data: reducedMetricValues }]
                            }}
                            options={chartOptions} />
                    </div>
                </div>

                {/* Is there no data to show */}
                {(metricData.length === 0) &&
                    <p className="stat-no-data">We can't find any data for this metric, yet</p>
                }
            </div>

            {/* Adding a record modal for this metric */}
            {recordingModal &&
                <Modal
                    title="Record Data"
                    className="super-slim"
                    hide={() => setRecordingModal(false)}>
                    <Record
                        unit={metricUnit}
                        metricID={metric.id}
                        hide={() => setRecordingModal(false)} />
                </Modal>
            }
        </>
    );
}

export default Metric;