import React from 'react';
import './pricing.scss';

/**
 * UI components
 */
import Navigation from "../navigation/navigation";
import Footer from '../footer/footer';

/**
 * Images
 */
import bannerImage from "../../../assets/images/website/homepage-banner.png";

/**
 * Pricing page
 */
function Pricing() {
    return (
        <>
            <section className="general-banner" style={{ backgroundImage: `url(${bannerImage})` }}>
                <Navigation />

                <div className="banner-text">
                    <h1>Pricing</h1>
                </div>
            </section>
        </>
    );
}

export default Pricing;