import React, { useState, createContext } from "react";

/**
 * Setup a context for storing the "setup program" modal objects
 */
const ProgramContext = createContext();

/**
 * Creating the provider to wrap the "setup program" modal in for displaying
 */
function ProgramProvider({ children }) {
    const [stage, setStage] = useState(1);
    const [name, setName] = useState("");
    const [details, setDetails] = useState("");
    const [length, setLength] = useState("");
    const [lengthUnit, setLengthUnit] = useState("");
    const [workouts, setWorkouts] = useState([]);
    const [meals, setMeals] = useState([]);

    return (
        <ProgramContext.Provider value={{
            stage, setStage,
            name, setName,
            details, setDetails,
            length, setLength,
            lengthUnit, setLengthUnit,
            workouts, setWorkouts,
            meals, setMeals,
        }}>
            {children}
        </ProgramContext.Provider>
    );
};

export { ProgramContext, ProgramProvider };