import { useContext } from 'react';
import { AuthContext } from "../../utils/providers/auth";

/**
 * Styles
 */
import './calendar.scss';

/**
 * Dashboard types
 */
import ClientCalendar from './types/client';
import TrainerCalendar from './types/trainer';

/**
 * Component for the dashboard
 */
function Calendar() {
    /**
     * Get the user context
     */
    const { user } = useContext(AuthContext);
    /**
     * What dashboard do we need to load?
     */
    if (user?.is_client) {
        return <ClientCalendar />
    } else if (user?.is_trainer) {
        return <TrainerCalendar />
    } else {
        return 'loading...';
    }
}

export default Calendar;