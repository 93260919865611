import React from "react";
import firebase from "firebase";
import { auth, db } from "../../utils/firebase";

/**
 * Algolia
 */
import algoliasearch from "algoliasearch";
import { InstantSearch, Hits, connectSearchBox, ToggleRefinement } from "react-instantsearch-dom";

/**
 * UI components
 */
import Input from "../ui/inputs/input";
import Button from "../ui/button/button";

/**
 * Start a new conversation by searching through the trainers database for users matching either the 
 * name or email address the user has typed in
 */
function StartConversation() {
    /**
     * 
     */
    const findOrStartConversation = async (trainerID) => {
        /**
         * Perform a query on the database to try and find a conversation that already 
         * exists with both the current users ID and the trainers ID
         */
        const alreadyExists = await db.collection("conversations")
            .where("users", "array-contains", auth.currentUser.uid, trainerID)
            .get().then((conversationDocs) => {
                console.log(conversationDocs.size)
                /**
                 * Are there any conversations for this user?
                 */
                if (conversationDocs.size > 0) {
                    /**
                     * Set the initial result to false
                     */
                    let existingConversation = false;
                    /**
                     * Loop through the converstaion documents found
                     */
                    conversationDocs.forEach((conversationDoc) => {
                        /**
                         * Deconstruct the users from the document
                         */
                        const { users } = conversationDoc.data();
                        /**
                         * Is the size of the users array only 2?
                         */
                        if (users.length === 2) {
                            /**
                             * Does the current trainer ID exist in this array? This would 
                             * mean this conversation is private between the current user 
                             * and the trainer
                             */
                            if (users.includes(trainerID)) {
                                /**
                                 * If it is, then there is already a conversation between 
                                 * these two users setup
                                 */
                                existingConversation = true;
                            }
                        }
                    });
                    /**
                     * Return the result
                     */
                    return existingConversation;
                } else {
                    return false;
                }
            });
        /**
         * If the conversation between these two users does not already exist
         */
        if (!alreadyExists) {
            /**
             * Add the conversation to the database collection
             */
            const conversation = await db.collection("conversations").add({
                createdBy: auth.currentUser.uid,
                created: firebase.firestore.Timestamp.now(),
                users: [auth.currentUser.uid, trainerID],
                latest_message: firebase.firestore.FieldValue.serverTimestamp(),
            });
            /**
             * Push the conversation ID onto the two users
             */
            await db.doc(`users/${auth.currentUser.uid}/conversations/${conversation.id}`).set({
                active: true,
                muted: false,
                removed: false,
            });
            return db.doc(`users/${trainerID}/conversations/${conversation.id}`).set({
                active: true,
                muted: false,
                removed: false,
            });
        } else {
            /**
             * If it does already exist
             */

        }
    }

    /**
     * Algolia search API keys 
     */
    const searchClient = algoliasearch(
        "NY4FAU44RS",
        "f0f2b14a92657a9dee8d5867065e727c",
    );

    /**
     * Custom inputfield for the algolia search
     */
    const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
        <form noValidate action="" role="search">
            <Input
                type="search"
                placeholder="Enter a name or email..."
                value={currentRefinement}
                onChange={(e) => refine(e.currentTarget.value)} />
        </form>
    );

    /**
     * Connect the custom search box
     */
    const CustomSearchBox = connectSearchBox(SearchBox);

    return (
        <div className="start-conversation">
            <div className="start-conversation-search">
                <h1>Start a conversation</h1>
                <h2>Enter the name or email of the trainer you are trying to find and we'll see whose registered with us.</h2>
                {/* Input section */}
                <InstantSearch searchClient={searchClient} indexName="fitportal_users">
                    <div className="hide-algolia-refinements">
                        <ToggleRefinement
                            attribute="type"
                            label="Only trainers"
                            value="Trainer"
                            defaultRefinement={true}
                        />
                    </div>
                    {/* Inputfield to connect to algolia */}
                    <CustomSearchBox />
                    {/* Search results from algolia */}
                    <div className="start-conversation-results">
                        <Hits hitComponent={(trainer) => {
                            if (trainer.hit.is_trainer) {
                                return (
                                    <div className="filtered-trainer">
                                        <div className="filtered-trainer-image">
                                            <p>{trainer.hit.first_name.charAt(0)}{trainer.hit.last_name.charAt(0)}</p>
                                            {trainer.picture &&
                                                <img
                                                    src={trainer.picture}
                                                    alt={`${trainer.hit.first_name} ${trainer.hit.last_name}`} />
                                            }
                                        </div>
                                        <p>{trainer.hit.first_name} {trainer.hit.last_name} <span>{trainer.hit.email}</span></p>
                                        <Button
                                            class="primary"
                                            onClick={() => findOrStartConversation(trainer.hit.objectID)}>
                                            Message
                                        </Button>
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        }} />
                    </div>
                </InstantSearch>
            </div>
        </div>
    );
}

export default StartConversation;