import React, { useContext, useEffect, useState } from "react";
import firebase from "firebase";
import { EventContext } from "../../../../../utils/providers/event";
import { db, auth, arrayUnion } from "../../../../../utils/firebase";
import moment from "moment";

/**
 * UI components
 */
import Button from "../../../../ui/button/button";

/**
 * 
 */
function Save(props) {
    const [participantIDs, setParticipantIDs] = useState([auth.currentUser.uid]);

    /**
     * Deconstruct variables from the add event context
     */
    const {
        title,
        comments,
        startTime,
        endTime,
        isAllDay,
        contributionGoal,
        contributionValue,
    } = useContext(EventContext);

    /**
     * Generate some readable dates with moment
     */
    const allDayReadable = moment(startTime).format("DD/MM/YYYY");
    const startReadable = moment(startTime).format("DD/MM/YYYY HH:mma");
    const endReadable = moment(endTime).format("DD/MM/YYYY HH:mma");

    /**
     * Save the event with the variables from the context
     */
    const saveEvent = async () => {
        /**
         * Add the event into the database and return the document
         */
        const addedEvent = await db.collection("events").add({
            title,
            comments,
            all_day: isAllDay,
            users: arrayUnion(auth.currentUser.uid),
            starts: firebase.firestore.Timestamp.fromMillis(moment(startTime).format("x")),
            ends: firebase.firestore.Timestamp.fromMillis(moment(endTime).format("x")),
            created: firebase.firestore.FieldValue.serverTimestamp(),
            setup_by: auth.currentUser.uid,
        });
        /**
         * Is this event to be tied to a goal?
         */
        if (contributionGoal?.option) {
            const user = auth.currentUser.uid;
            const goalID = contributionGoal.option;
            /**
             * Then add a record to the goals subcollection, using the eventID as the recordID
             */
            db.doc(`clients/${user}/goals/${goalID}/records/${addedEvent.id}`)
                .set({
                    starts: firebase.firestore.Timestamp.fromMillis(moment(startTime).format("x")),
                    ends: firebase.firestore.Timestamp.fromMillis(moment(endTime).format("x")),
                    goal_contribution: contributionValue || 0,
                }, { merge: true });
        }
        /**
         * Hide the add event modal through the props
         */
        props.close();
    }

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <h1>{title}</h1>

                <small className="event-timespan">
                    {isAllDay &&
                        `Scheduled for all day on ${allDayReadable}`
                    }

                    {!isAllDay &&
                        `Scheduled between ${startReadable} and ${endReadable}`
                    }
                </small>

                {contributionGoal?.option &&
                    <p className="workout-sets">
                        When this event has passed, it'll contribute {contributionValue} to your {contributionGoal.value} goal
                    </p>
                }
            </div>

            <Button
                class="primary"
                onClick={() => saveEvent()}>
                Save Event
            </Button>
        </div >
    );
}

export default Save;