import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { SignupContext } from "../../utils/providers/signup";

/**
 * Styles
 */
import "./signup.scss";

/**
 * UI components
 */
import AccountType from "../../components/signup/account-type";
import PersonalDetails from "../../components/signup/personal-details";
import Address from "../../components/signup/address";
import Billing from "../../components/signup/billing";
import Terms from "../../components/signup/terms";
import SignupTrainer from "../../components/signup/signup-trainer";
import SlimPersonalDetails from "../../components/signup/personal-details-slim";
import ClientTerms from "../../components/signup/terms-client";
import SignupClient from "../../components/signup/signup-client";

/**
 * Manages the signup flow for the trainers or clients
 *
 * @returns HTML markup for the wrapper elements to the
 */
function Signup() {
    const [title, setTitle] = useState("Choose your account type");
    const [subTitle, setSubTitle] = useState("Let us know what type of account you'd like to open");

    const {
        accountType,
        setAccountType,
        signupStage,
    } = useContext(SignupContext);

    useEffect(() => {
        if (accountType === "TRAINER") {
            setTitle("Signup as a Trainer");
            setSubTitle("Great, we're excited to have you! We just need some details...");
        } else if (accountType === "CLIENT") {
            setTitle("Signup as a Client");
            setSubTitle("Great, we're excited to have you! We just need some details...");
        }
    }, [accountType]);

    return (
        <section className="signup-flow">
            <div className="signup-flow-left">
                <h2>Welcome to</h2>
                <h1>FitPortal</h1>
                <p className="copyright">&copy; FitPortal 2021. All Rights Reserved.</p>
            </div>
            <div className="signup-flow-window">
                <div className="title-tags">
                    <div className="signup-titles">
                        <h1>{title}</h1>
                        <p>{subTitle}</p>
                    </div>
                    <p className="need-help">
                        Need a hand? <NavLink to="/contact">Get help.</NavLink>
                    </p>
                </div>

                {/* If there is no account type present in the state */}
                {!accountType &&
                    <AccountType setAccountType={(type) => setAccountType(type)} />
                }

                {/* Trainer signup flow */}
                {(accountType === "TRAINER") &&
                    <>
                        {/* Personal details (name, email, phone etc) */}
                        {(signupStage === 0) && <PersonalDetails />}

                        {/* Address details */}
                        {(signupStage === 1) && <Address />}

                        {/* Bank details and photo ID */}
                        {(signupStage === 2) && <Billing />}

                        {/* Terms and conditions & password */}
                        {(signupStage === 3) && <Terms />}

                        {/* Signing them up */}
                        {(signupStage === 4) && <SignupTrainer />}
                    </>
                }

                {/* Client signup flow */}
                {(accountType === "CLIENT") &&
                    <>
                        {/* Personal details (name, email, phone etc) */}
                        {(signupStage === 0) && <SlimPersonalDetails />}

                        {/* Terms and conditions & password */}
                        {(signupStage === 1) && <ClientTerms />}

                        {/* Signing them up */}
                        {(signupStage === 2) && <SignupClient />}
                    </>
                }
            </div>
        </section>
    );
}

export default Signup;
