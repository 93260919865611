import React, { useState, useContext } from "react";
import { WorkoutContext } from "../../../../utils/providers/workout";
import { db } from "../../../../utils/firebase";

import Button from "../../../ui/button/button-new";

export default function Save(props) {
    const [saving, setSaving] = useState(false);

    /**
     * Deconstruct the workoutID from the context
     */
    const {
        workoutID,
        title,
        summary,
        exercises_order,
        tags,
    } = useContext(WorkoutContext);

    /**
     * Save the workout in the databse by flagging the "is_draft" field to false
     */
    const saveWorkout = async () => {
        /**
         * Show a saving spinner
         */
        setSaving(true);
        /**
         * Mark the workout as no longer a draft
         */
        await db.doc(`workouts/${workoutID}`).set({
            is_draft: false,
        }, { merge: true });
        /**
         * Close the modal
         */
        props.close();
    }

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <h1>{title}</h1>
                <p>{summary}</p>

                <div className="tags-list summary">
                    {tags.map((tag, index) => (
                        <p className="list-item-tag" key={index}>
                            {tag}
                        </p>
                    ))}
                </div>
            </div>

            <Button
                label="Save workout"
                loading={saving}
                loadingText="Saving..."
                disabled={saving}
                onClick={() => saveWorkout()} />
        </div >
    );
}