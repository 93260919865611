function NavigationAction(props) {
    return (
        <div
            className={[
                'navigation-profile-action',
                !props.onClick ? 'no-pointer' : '',
                props.className,
                props.showDot ? "has-unread" : "",
            ].join(' ')}
            onClick={() => props.onClick()}>
            {props.icon}
        </div>
    );
}

export default NavigationAction;