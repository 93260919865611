import React, { useState, createContext } from "react";

/**
 * Setup a context for storing the "add workout" modal objects
 */
const EventContext = createContext();

/**
 * Creating the provider to wrap the add workout modal in for displaying
 */
function EventProvider({ children }) {
    const [stage, setStage] = useState(1);
    const [title, setTitle] = useState("");
    const [comments, setComments] = useState("");
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [isAllDay, setIsAllDay] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [contributionGoal, setContributionGoal] = useState("");
    const [contributionValue, setContributionValue] = useState("");
    const [clientGoals, setClientGoals] = useState([]);
    const [workout, setWorkout] = useState("");

    return (
        <EventContext.Provider value={{
            stage, setStage,
            title, setTitle,
            comments, setComments,
            startTime, setStartTime,
            endTime, setEndTime,
            isAllDay, setIsAllDay,
            participants, setParticipants,
            contributionGoal, setContributionGoal,
            contributionValue, setContributionValue,
            clientGoals, setClientGoals,
            workout, setWorkout,
        }}>
            {children}
        </EventContext.Provider>
    );
};

export { EventContext, EventProvider };