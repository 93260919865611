import React, { useContext, useEffect, useState } from "react";
import { MealContext } from "../../../../utils/providers/meal";
import { PlusIcon, SaveIcon, TrashAltIcon } from "../../../../utils/svgs";

/**
 * UI components
 */
import Input from "../../../ui/inputs/input";
import Button from "../../../ui/button/button";

function Ingredients(props) {
    const [addingIngred, setAddingIngred] = useState(false);
    const [ingredName, setIngredName] = useState("");
    const [ingredUnitMeasurement, setIngredUnitMeasurement] = useState("");
    const [ingredMeasurement, setIngredMeasurement] = useState("");
    const [localIngredients, setLocalIngredients] = useState([]);

    /**
     * Deconstruct variables from the add meal context
     */
    const {
        setStage,
        ingredients, setIngredients,
    } = useContext(MealContext);

    /**
     * When this page first loads in, pull the current ingredients from the context
     */
    useEffect(() => {
        setLocalIngredients(ingredients);
    }, []);

    /**
     * Save the new ingredient into the local state
     */
    const saveIngredient = (addingAnother) => {
        /**
         * Do we have all 3 fields filled out
         */
        if (ingredName && ingredUnitMeasurement && ingredMeasurement) {
            /**
             * Push the ingredient into the local state
             */
            setLocalIngredients((localIngredients) => [...localIngredients, {
                name: ingredName,
                unit: ingredUnitMeasurement,
                measurement: ingredMeasurement,
            }]);
            /**
             * Reset the fields
             */
            setIngredName("");
            setIngredUnitMeasurement("");
            setIngredMeasurement("");
            /**
             * If we are not adding another one
             */
            if (!addingAnother) {
                /**
                 * Hide the adding form
                 */
                setAddingIngred(false);
            }
        }
    }

    /**
     * Remove an ingredient from the local array via the name passed in
     */
    const removeIngredient = (name) => {
        /**
         * Remove the ingredient from the array
         */
        setLocalIngredients(localIngredients.filter(ingred => ingred.name !== name));
    }

    /**
     * Listen for the local ingrdients to be updated
     */
    useEffect(() => {
        /**
         * Push them into the context when there is an update
         */
        setIngredients(localIngredients);
    }, [localIngredients]);

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                {!addingIngred &&
                    <div className="add-list-item">
                        <p onClick={() => setAddingIngred(true)}><PlusIcon /> Add Ingredient</p>
                    </div>
                }

                {addingIngred &&
                    <div className="add-ingredient">
                        <Input
                            type="text"
                            label="Ingredient:"
                            placeholder="e.g. Salted butter"
                            onChange={(e) => setIngredName(e.target.value)}
                            value={ingredName} />

                        <div className="input-group-column cols-2 weighted">
                            <Input
                                type="text"
                                label="Unit of Measurement:"
                                placeholder="e.g. g"
                                onChange={(e) => setIngredUnitMeasurement(e.target.value)}
                                value={ingredUnitMeasurement} />

                            <Input
                                type="text"
                                label="Measurement:"
                                placeholder="e.g. 150"
                                onChange={(e) => setIngredMeasurement(e.target.value)}
                                value={ingredMeasurement} />
                        </div>

                        <div className="save-list-item">
                            <p onClick={() => saveIngredient(false)}><SaveIcon /> Save</p>
                            <p onClick={() => saveIngredient(true)}><SaveIcon /> Save &amp; Add Another</p>
                            <p onClick={() => setAddingIngred(false)} className="red">Cancel</p>
                        </div>
                    </div>
                }

                {!addingIngred &&
                    <>
                        {ingredients.map((ingredient, index) => (
                            <p className="list-item" key={index}>
                                {ingredient.measurement}{ingredient.unit} {ingredient.name}
                                <span onClick={() => removeIngredient(ingredient.name)}>
                                    <TrashAltIcon />
                                </span>
                            </p>
                        ))}
                    </>
                }
            </div>

            <Button
                class="primary"
                onClick={() => setStage(3)}
                disabled={ingredients.length === 0}>
                Continue to Instructions
            </Button>
        </div>
    );
}

export default Ingredients;