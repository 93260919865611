import React, { useEffect, useState } from "react";
import { db } from "../../../utils/firebase";
import "./view.scss";

import Modal from "../../modal/modal";
import Button from "../../ui/button/button-new";
import Exercise from "./exercise/exercise";

/**
 * Functional component to return the 
 */
function ViewWorkout(props) {
    const [loading, setLoading] = useState(true);
    const [workout, setWorkout] = useState({});
    const [exercises, setExercises] = useState([]);

    const { workoutID } = props;

    useEffect(() => {
        db.doc(`workouts/${workoutID}`).get().then((workoutDoc) => {
            if (workoutDoc.exists) {
                setWorkout({ ...workoutDoc.data(), });
                setLoading(false);
            }
        });
    }, [workoutID]);

    useEffect(() => {
        db.collection(`workouts/${workoutID}/exercises`)
            .orderBy("created")
            .get().then(async (exerciseDocs) => {
                exerciseDocs.forEach((exercise) => {
                    setExercises((exercises) => [...exercises, { id: exercise.id, ...exercise.data() }])
                })
            });
    }, [workoutID]);

    if (loading) {
        return null;
    } else {
        return (
            <Modal
                title={workout.title}
                className="center viewing-workout"
                hide={() => props.hide()} >

                {workout.summary &&
                    <p className="workout-summary">
                        {workout.summary}
                    </p>
                }

                {(exercises?.length > 0) &&
                    <div className="meal-modal-column">
                        <h2>Exercises Involved</h2>
                        {exercises.map((exercise, index) => (
                            <table className="exercise-table-layout">
                                <Exercise
                                    key={exercise.id}
                                    id={exercise.id}
                                    title={exercise.title}
                                    workoutID={workoutID} />
                            </table>
                        ))}
                    </div>
                }
            </Modal>
        );
    }
}

export default ViewWorkout;