import React, { useEffect, useState } from "react";
import { fun } from "../../../utils/firebase";
import { NavLink, useLocation } from "react-router-dom";
import "./footer.scss";

/**
 * UI components
 */
import Button from "../../../components/ui/button/button";
import { Facebook, Instagram, LinkedIn } from "../../../utils/svgs";

/**
 * Images
 */
import image_contact from "../../../assets/images/website/contact-block.jpg";

/**
 * Functional component to return the footer for the website
 */
function Footer() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);

    const { pathname } = useLocation();

    /**
     * When the stage udpates, scroll to the top of the page
     */
    useEffect(() => {
        /**
         * Scroll back tot he top of the page
         */
        setTimeout(() => {
            /**
             * Get the wrapper div that has the overflow on it
             */
            const root_window = document.getElementById("root");
            /**
             * Scroll to the top
             */
            root_window.scrollTo({
                top: 0,
                left: 0,
            });
        }, 0);
    }, [pathname]);

    /**
     * Send the email from the form via a cloud function
     */
    const sendMail = async () => {
        setSending(true);
        const sendMailFunction = fun.httpsCallable("sendEmailFromForm");
        await sendMailFunction({
            name,
            email,
            message,
        });
        setName("");
        setEmail("");
        setMessage("");
        setSending(false);
        setSent(true);
    }

    return (
        <>
            <section className={`website-contact gray-bg ${window.location.pathname === '/contact' ? 'no-top' : ''}`}>
                <div className="wc-form">
                    <div className="heading-block is-white">
                        <h4>Contact Us</h4>
                        <hr />
                    </div>
                    <div className="wc-form-wrapper">
                        <input type="text" placeholder="Name:" value={name} readOnly={sending} onChange={(e) => setName(e.target.value)} />
                        <input type="email" placeholder="Email:" value={email} readOnly={sending} onChange={(e) => setEmail(e.target.value)} />
                        <textarea placeholder="Write your message here..." value={message} readOnly={sending} onChange={(e) => setMessage(e.target.value)} rows="5" />
                        <Button class="primary" onClick={() => sendMail()} disabled={sending}>
                            {sending ? "Sending..." : "Submit"}
                        </Button>

                        {sent &&
                            <p className="message-sent">Your message has been sent, we'll be in touch shortly</p>
                        }
                    </div>
                    <div className="wc-form-details">
                        <div className="wc-form-contact">
                            <a href="mailto:info@fitportal.uk">info@fitportal.uk</a>
                        </div>
                        <div className="wc-form-socials">
                            <a href="https://www.facebook.com/fitportaluk" target="_blank" rel="noreferrer">
                                <Facebook />
                            </a>
                            <a href="https://www.instagram.com/fitportaluk/" target="_blank" rel="noreferrer">
                                <Instagram />
                            </a>
                            <a href="https://uk.linkedin.com/company/fitportal-ltd" target="_blank" rel="noreferrer">
                                <LinkedIn />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="image">
                    <img src={image_contact} alt="" />
                </div>
            </section>

            <section className="website-footer">
                <ul>
                    <NavLink to="/" activeClassName="is-active">Home</NavLink>
                    <NavLink to="/become-a-trainer" activeClassName="is-active">Become A Trainer</NavLink>
                    <NavLink to="/contact" activeClassName="is-active">Contact Us</NavLink>
                </ul>
            </section>

            <section className="website-copyright gray-bg">
                <p>&copy; FitPortal 2022. All Rights Reserved.</p>
            </section>
        </>
    );
}

export default Footer;