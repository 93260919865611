import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { SignupProvider } from "../providers/signup";

/**
 * Components
 */
import Home from "../../containers/website/home/home";
import Platform from "../../containers/website/platform/platform";
import Pricing from "../../containers/website/pricing/pricing";
import Trainer from "../../containers/website/trainer/trainer";
import Contact from "../../containers/website/contact/contact";
import Login from "../../containers/login/login";
import Signup from "../../containers/signup/signup";
import Error404 from "../../containers/error/404";
import Continue from "../../containers/website/continue/continue";
import PrivacyPolicy from "../../containers/website/privacy-policy/privacy-policy";
import TermsAndConditions from "../../containers/website/terms-and-conditions/terms-and-conditions";
import Navigation from "../../containers/website/navigation/navigation";
import Footer from "../../containers/website/footer/footer";

/**
 * Router for running the default pages through the
 */
function DefaultRouter() {
    const [windowSize, setWindowSize] = useState({
        x: window.innerWidth,
        y: window.innerHeight
    });

    /**
     * Get the current pathname of the window
     */
    const pathname = window.location.pathname;

    /**
     * Update the window size
     */
    const updateSize = () => {
        /**
         * Update the sizes in the local state
         */
        setWindowSize({
            x: window.innerWidth,
            y: window.innerHeight
        });
    }

    /**
     * On component load
     */
    useEffect(() => (window.onresize = updateSize), []);

    return (
        <>
            {/* Page route switcher */}
            <Switch>
                {/* General pages */}
                <Route path="/" exact component={Home} />
                <Route path="/platform" component={Platform} />
                <Route path="/pricing" component={Pricing} />
                <Route path="/become-a-trainer" component={Trainer} />
                <Route path="/contact" component={Contact} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/terms-and-conditions" component={TermsAndConditions} />

                {/* If the user is on a large enough screen */}
                {(windowSize.x > 1200) &&
                    <>
                        {/* Login route */}
                        <Route path="/login" component={Login} />

                        {/* Signup route, wrapped in a context to store progress */}
                        <SignupProvider>
                            <Route path="/signup" component={Signup} />
                        </SignupProvider>
                    </>
                }

                {/* If the screen size is too small */}
                {(windowSize.x < 1200) &&
                    <>
                        <Route path="/login" component={Continue} />
                        <Route path="/signup" component={Continue} />
                    </>
                }

                {/* Error pages */}
                <Route component={Error404} />
            </Switch>

            {/* Footer component */}
            {(!pathname.startsWith("/signup") && !pathname.startsWith("/login")) &&
                <Footer />
            }
        </>
    );
};

export default DefaultRouter;
