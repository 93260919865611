import React, { useState, useEffect } from "react";
import { db, auth } from "../../utils/firebase";
import moment from "moment";
import "./metrics.scss";

/**
 * UI components
 */
import Modal from '../../components/modal/modal';
import Button from "../../components/ui/button/button";
import Metric from "../../components/metrics/metric";
import AddMetric from "../../components/metrics/add/add";
import Select from "../../components/ui/inputs/select/new-select";

/**
 * 
 */
function Metrics() {
    const [newMetricModal, setNewMetricModal] = useState(false);
    const [metrics, setMetrics] = useState([]);
    const [period, setPeriod] = useState({
        option: "this_week",
        value: "This Week",
    });
    const [periodStart, setPeriodStart] = useState({});
    const [periodEnd, setPeriodEnd] = useState({});

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup a listener on this users metrics collection
         */
        const unsubscribe = db.collection(`clients/${auth.currentUser.uid}/metrics`)
            .onSnapshot((mectricDocs) => {
                /**
                 * Loop over the metrics passed down
                 */
                mectricDocs.docChanges().forEach((change) => {
                    /**
                     * Metric record added
                     */
                    if (change.type === "added") {
                        setMetrics((metrics) => [
                            ...metrics,
                            {
                                id: change.doc.id,
                                ...change.doc.data()
                            }
                        ]);
                    }
                    /**
                     * Metric record updated
                     */
                    if (change.type === "modified") {
                        setMetrics((metrics) => {
                            let updateMetrics = [...metrics];
                            for (let i in metrics) {
                                if (metrics[i].id === change.doc.id) {
                                    updateMetrics[i] = {
                                        id: change.doc.id,
                                        ...change.doc.data()
                                    };
                                    break;
                                }
                            }
                            return updateMetrics;
                        });
                    }
                    /**
                     * Metric record removed
                     */
                    if (change.type === "removed") {
                        setMetrics((metrics) => metrics.filter((record) => record.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);

    /**
     * When the period is updated
     */
    useEffect(() => {
        /**
         * Update the start and end dates accordingly
         */
        switch (period.option) {
            case "this_week":
                setPeriodStart(moment().startOf("week"));
                setPeriodEnd(moment().endOf("week"));
                break;
            case "this_month":
                setPeriodStart(moment().startOf("month"));
                setPeriodEnd(moment().endOf("month"));
                break;
            case "this_year":
                setPeriodStart(moment().startOf("year"));
                setPeriodEnd(moment().endOf("year"));
                break;
            default:
                return null;
        }
    }, [period]);

    return (
        <>
            <div className="page-title">
                <div className="page-title-flex with-margin">
                    <h1>My Metrics</h1>
                    <Button
                        class="primary"
                        onClick={() => setNewMetricModal(true)}>
                        Track New Metric
                    </Button>
                    <Select
                        placeholder="Time period:"
                        options={{
                            this_week: "This Week",
                            this_month: "This Month",
                            this_year: "This Year",
                        }}
                        value={period.value}
                        onSelect={(option) => setPeriod(option)} />
                </div>
                <p>Setup and track various metrics to help you stay on track and achieve your goals.</p>
            </div>

            {/* Print this users metric charts to the page */}
            <div className="metrics-grid">
                {metrics.map((metric) => (
                    <Metric
                        key={metric.id}
                        metric={metric}
                        start={periodStart}
                        end={periodEnd} />
                ))}
            </div>

            {/* Adding a new metric */}
            {newMetricModal &&
                <Modal
                    className="slim"
                    title="Track New Metric"
                    hide={() => setNewMetricModal(false)} >
                    <AddMetric hide={() => setNewMetricModal(false)} />
                </Modal>
            }
        </>
    );
}

export default Metrics;
