import React, { useState, useEffect } from "react";
import { db } from "../../../../utils/firebase";

export default function Exercise({ id, title, workoutID }) {
    const [sets, setSets] = useState([]);

    useEffect(() => {
        db.collection(`workouts/${workoutID}/exercises/${id}/sets`)
            .orderBy("created")
            .get().then((setDocs) => {
                console.log(setDocs.size)
                setDocs.forEach(async (setDoc) => {
                    console.log(setDoc.data())
                    const { target } = setDoc.data();

                    setSets((sets) => [...sets, { reps: target.reps, load: target.load, rest: target.rest }]);
                });
            });
    }, []);

    return (
        <>
            <tr className="exercise-header">
                <td>{title}</td>
                <td>{sets[0]?.reps.label}</td>
                <td>{sets[0]?.load.label}</td>
                <td>{sets[0]?.rest.label}</td>
            </tr>
            {sets.map((set, index) => (
                <tr>
                    <td>Set {index + 1}</td>
                    <td>{set.reps.value}</td>
                    <td>{set.load.value}<span className="metric-unit">{set.load.measurement}</span></td>
                    <td>{set.rest.value}<span className="metric-unit">{set.rest.measurement}</span></td>
                </tr>
            ))}
        </>
    );
}