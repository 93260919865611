import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "../../utils/providers/auth";
import { MessagesContext } from "../../utils/providers/messages";
import { NotificationsContext } from "../../utils/providers/notifications";
import { NotificationsIcon, MessagesIcon, SettingsIcon } from "../../utils/svgs";
import { logoutRequest } from "../../utils/providers/auth";

import FitPortalLogo from "../../assets/svgs/fit-portal-logo.svg";

/**
 * Styles
 */
import "./navigation.scss";

/**
 * UI Components
 */
import NavigationAction from "./actions/action";

/**
 * Navigation types
 */
import ClientNavigation from "./types/client";
import TrainerNavigation from "./types/trainer";

/**
 * Conditional logic for the default, client or trainer navigations
 *
 * @type {const}
 * @param {object} props
 * @returns Navigation for the relevant user that is/isn't logged in
 */
function Navigation() {
    /**
     * Get context functions & data
     */
    const { user } = useContext(AuthContext);
    const {
        messagesShowing,
        setMessagesShowing,
        hasUnread,
    } = useContext(MessagesContext);
    const { notificationsShowing, setNotificationsShowing } = useContext(NotificationsContext);

    /**
     * Get the path of the page from router
     */
    const { pathname } = useLocation();
    console.log(hasUnread);

    /**
     * Return the relevant navigation for where the user is
     */
    return (
        <nav>
            <div className="navigation-wrapper">
                <div className="navigation-profile">
                    <div className="navigation-profile-image">
                        {/* Does the user have a profile image saved */}
                        {user?.profilePicture ? (
                            <>
                                {/* Load the thumbnail version of the users profile picture */}
                                <img src={user.profilePicture?._256 ?? user.profilePicture.original} alt={`${user?.firstName}'s FitPortal Profile`} />
                            </>
                        ) : (
                            <>
                                {/* Show the initials of the user of the coloured background */}
                                <div className="navigation-profile-initials">
                                    {user?.first_name?.substring(0, 1)}
                                    {user?.last_name?.substring(0, 1)}
                                </div>
                            </>
                        )}
                    </div>
                    <div className="navigation-profile-extra">
                        <p className="profile-name">
                            {user?.first_name} {user?.last_name?.substring(0, 1)}.
                        </p>
                        <div className="profile-actions">
                            <NavigationAction
                                icon={<NotificationsIcon />}
                                onClick={() => setNotificationsShowing(true)}
                                className={notificationsShowing ? "active" : ""} />

                            <NavigationAction
                                icon={<MessagesIcon />}
                                onClick={() => setMessagesShowing(true)}
                                showDot={hasUnread}
                                className={messagesShowing ? "active" : ""} />

                            <NavLink to="/app/account" activeClassName="active">
                                <NavigationAction icon={<SettingsIcon />} />
                            </NavLink>
                        </div>
                    </div>
                </div>
                {/* Do we need to tshow the default or app navigation */}
                {pathname.startsWith("/app") && (
                    <>
                        {/* Client navigation */}
                        {user?.is_client && <ClientNavigation />}
                        {/* Trainer navigation */}
                        {user?.is_trainer && <TrainerNavigation />}
                    </>
                )}
                {/* Navigation copyright footer */}
                <div className="navigation-footer">
                    <div className="nf-name">
                        <img src={FitPortalLogo} alt="Fit Portal Logo" /> FitPortal.
                    </div>
                    <div className="nf-copyright">
                        Copyright 2022,
                        <br />
                        All Rights Reserved
                    </div>
                    <p className="navigation-logout" onClick={() => logoutRequest()}>
                        Logout
                    </p>
                </div>
            </div>
        </nav>
    );
}

export default Navigation;
