import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { AuthContext } from "../providers/auth";
import { AlertsProvider } from "../providers/alerts";

/**
 * Alerts provider & components
 */
import Alerts from "../../components/app/alerts/alerts";

/**
 * Components
 */
import Dashboard from "../../containers/dashboard/dashboard";
import Account from "../../containers/account/account";
import Workouts from "../../containers/workouts/workouts";
import Calendar from "../../containers/calendar/calendar";

import Clients from '../../containers/clients/clients';
import Meals from "../../containers/meals/meals";
import Programs from "../../containers/programs/programs";
import Exercises from "../../containers/exercises/exercises";
import Verification from '../../containers/verification/verification';

import Marketplace from '../../containers/marketplace/marketplace';
import Goals from '../../containers/goals/goals';
import Nutrition from '../../containers/nutrition/nutrition';
import Equipment from '../../containers/equipment/equipment';
import Metrics from '../../containers/metrics/metrics';
import Continue from "../../containers/website/continue/continue";
import ParQ from "../../containers/parq/parq";

/**
 * Router for running the application pages through
 *
 * @type {const}
 * @returns Application route switches for trainers and clients
 */
const AppRouter = () => {
    const [windowSize, setWindowSize] = useState({
        x: window.innerWidth,
        y: window.innerHeight
    });

    /**
     * Update the window size
     */
    const updateSize = () => {
        /**
         * Update the sizes in the local state
         */
        setWindowSize({
            x: window.innerWidth,
            y: window.innerHeight
        });
    }

    /**
     * On component load
     */
    useEffect(() => (window.onresize = updateSize), []);

    /**
     * Get the user context
     */
    const { user } = useContext(AuthContext);

    /**
     * Get the path of the page from router
     */
    const { path } = useRouteMatch();

    /**
     * Return route paths
     */
    return (
        <AlertsProvider>
            <Switch>
                {/* If the user is on a large enough screen */}
                {(windowSize.x > 1200) &&
                    <>
                        {/* Does the user need to verify their account? */}
                        {(user?.is_trainer && user?.stripe.verification.status !== "verified") &&
                            <Route path="*" component={Verification} />
                        }

                        {/* Trainer routes */}
                        {(user?.is_trainer && user?.stripe.verification.status === "verified") && (
                            <>
                                <Route path={`${path}app/dashboard`} component={Dashboard} />
                                <Route path={`${path}app/account`} component={Account} />
                                <Route path={`${path}app/workouts`} component={Workouts} />
                                <Route path={`${path}app/calendar`} component={Calendar} />
                                <Route path={`${path}app/clients`} component={Clients} />
                                <Route path={`${path}app/meals`} component={Meals} />
                                <Route path={`${path}app/programs`} component={Programs} />
                                <Route path={`${path}app/exercises`} component={Exercises} />
                            </>
                        )}

                        {/* If it is a client user wihtout a ParQ */}
                        {(user?.is_client && !user?.parq?.completed) && (
                            <Route path="*" component={ParQ} />
                        )}

                        {/* Client routes */}
                        {(user?.is_client && user?.parq?.completed) && (
                            <>
                                <Route path={`${path}app/dashboard`} component={Dashboard} />
                                <Route path={`${path}app/account`} component={Account} />
                                <Route path={`${path}app/workouts`} component={Workouts} />
                                <Route path={`${path}app/calendar`} component={Calendar} />
                                <Route path={`${path}app/marketplace`} component={Marketplace} />
                                <Route path={`${path}app/goals`} component={Goals} />
                                <Route path={`${path}app/nutrition`} component={Nutrition} />
                                <Route path={`${path}app/equipment`} component={Equipment} />
                                <Route path={`${path}app/metrics`} component={Metrics} />
                                <Route path={`${path}app/parq`} component={ParQ} />
                            </>
                        )}
                    </>
                }

                {/* If the screen size is too small */}
                {(windowSize.x < 1200) &&
                    <Route path="*" component={Continue} />
                }
            </Switch>

            {/* App alerts wrapper */}
            <div className="app-alerts-wrapper">
                <Alerts />
            </div>
        </AlertsProvider>
    );
};

export default AppRouter;
