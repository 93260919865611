import React from "react";
import ReactDOM from "react-dom";
import App from "./app";

/**
 * Styles
 */
import "./index.scss";

/**
 * Render the application to the DOM
 */
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);
