import React from "react";
import "./continue.scss";
import LogoDark from "../../../assets/images/fit-portal-logoDARK.png";

/**
 * UI components
 */
import Button from "../../../components/ui/button/button";
import { NavLink } from "react-router-dom";

/**
 * Functional component to return a message saying to conintue on a desktop
 */
function Continue() {
    return (
        <div className="continue-wrapper">
            <img src={LogoDark} alt="FitPortal" />
            <p>Please continue on a laptop or desktop device.</p>
            <small>Our mobile app is coming soon</small>
            <NavLink to="/">
                Go Back
            </NavLink>
        </div>
    );
}

export default Continue;