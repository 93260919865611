import React, { useContext } from "react";
import { SignupContext } from "../../utils/providers/signup";

import Input from "../ui/inputs/input";
import Button from "../ui/button/button";

function Address(props) {
    const {
        setSignupStage,
        errors, setErrors,
        firstLine, setFirstLine,
        secondLine, setSecondLine,
        city, setCity,
        county, setCounty,
        postcode, setPostcode,
    } = useContext(SignupContext);

    const checkInputs = () => {
        let proceed = true;
        let inputErrors = {};

        setErrors({});

        if (!firstLine || firstLine.length <= 3) {
            inputErrors["firstLine"] = "Please enter a valid first address line";
            proceed = false;
        }

        if (!city || city.length <= 3) {
            inputErrors["city"] = "Please enter a valid city";
            proceed = false;
        }

        if (!county || county.length <= 3) {
            inputErrors["county"] = "Please enter a valid county";
            proceed = false;
        }

        if (!postcode || postcode.length <= 6) {
            inputErrors["postcode"] = "Please enter a valid postcode";
            proceed = false;
        }

        if (proceed) {
            setSignupStage((signupStage) => signupStage + 1);
        } else {
            setErrors(inputErrors);
        }
    }

    return (
        <>
            <div className="input-block-section">
                <h2>Your Address</h2>
                <p>We need your address to validate who you are in a later stage, clients will also be able to see your city and county when searching on the marketplace.</p>
                <div className="input-elements-grid cols-2">
                    <Input type="text"
                        value={firstLine}
                        placeholder="First Line:"
                        label="First Line:"
                        wrapperClass={errors.firstLine && 'invalid'}
                        note={errors.firstLine}
                        onChange={(e) => setFirstLine(e.target.value)} />
                    <Input type="text"
                        value={secondLine}
                        placeholder="Second Line:"
                        label="Second Line:"
                        wrapperClass={errors.secondLine && 'invalid'}
                        note={errors.secondLine}
                        onChange={(e) => setSecondLine(e.target.value)} />
                    <Input type="text"
                        value={city}
                        placeholder="City:"
                        label="City:"
                        wrapperClass={[errors.city && 'invalid', "span-2"].join(" ")}
                        note={errors.city}
                        onChange={(e) => setCity(e.target.value)} />
                    <Input type="text"
                        value={county}
                        placeholder="County:"
                        label="County:"
                        wrapperClass={errors.county && 'invalid'}
                        note={errors.county}
                        onChange={(e) => setCounty(e.target.value)} />
                    <Input type="text"
                        value={postcode}
                        placeholder="Postcode:"
                        label="Postcode:"
                        wrapperClass={errors.postcode && 'invalid'}
                        note={errors.postcode}
                        onChange={(e) => setPostcode(e.target.value)} />
                </div>
            </div>

            <div className="signup-next-stage">
                <Button class="primary" onClick={() => checkInputs()}>Continue</Button>
            </div>
        </>
    );
}

export default Address;