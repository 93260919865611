import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase";
import { db, auth } from "../../../utils/firebase";
import "./setup-price.scss";

/**
 * UI component
 */
import Input from "../../ui/inputs/input";
import Checkbox from "../../ui/checkbox/checkbox";
import Select from "../../ui/inputs/select/new-select";
import Button from "../../ui/button/button";

/**
 * Functional component to print the setup price markup in the chat
 */
function SetupPrice(props) {
    const [amount, setAmount] = useState(null);
    const [fee, setFee] = useState(null);
    const [message, setMessage] = useState("");
    const [isReccuring, setIsReccuring] = useState(false);
    const [chargePeriod, setChargePeriod] = useState();
    const [errors, setErrors] = useState({});

    /**
     * Deconstruct the currently active chat from the props
     */
    const { activeChat } = props;

    /**
     * When the amount is changed
     */
    useEffect(() => {
        /**
         * Work out a 10% cut
         */
        const cut = (amount * 0.1);
        /**
         * Update the state
         */
        setFee(cut.toFixed(2));
    }, [amount]);

    /**
     * Listen for a click outside the price window
     */
    const ClickedOutside = (priceWindowRef) => {
        useEffect(() => {
            /**
             * Check for the click to be outside the price window
             */
            const checkForOutsideClick = (event) => {
                /**
                 * Is the click outside?
                 */
                if (priceWindowRef.current && !priceWindowRef.current.contains(event.target)) {
                    /**
                     * Hide the price window
                     */
                    props.hideSetupPrice();
                }
            }
            /**
             * Bind on the component load
             */
            document.addEventListener("mousedown", checkForOutsideClick)
            return () => {
                /**
                 * Unbind on component unload
                 */
                document.removeEventListener("mousedown", checkForOutsideClick);
            }
        }, [priceWindowRef]);
    }

    /**
     * Price window ref
     */
    const priceWindowRef = useRef(null);

    /**
     * Bind onto the click event
     */
    ClickedOutside(priceWindowRef);

    /**
     * Process the price card setup with a new message and a charge setup for cloud 
     * fucntions to pick up and send to stripe
     */
    const setupNewPayment = () => {
        /**
         * Make sure there is a valie amount and accompaning message
         */
        if ((amount > 0) && (message.length > 0)) {
            /**
             * Create a PRICE_OFFER chat message
             */
            db.collection(`conversations/${activeChat}/messages`).add({
                user: auth.currentUser.uid,
                message: message,
                price: amount,
                fee: fee,
                interval: isReccuring ? chargePeriod.option : "one_off",
                starting_on: firebase.firestore.FieldValue.serverTimestamp(),
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                type: "PRICE_OFFER",
            });
            /**
             * Update the latest_message timestamp for the conversation
             */
            db.doc(`conversations/${activeChat}`).set({
                latest_message: firebase.firestore.FieldValue.serverTimestamp(),
            }, { merge: true });
            /**
             * Hide the price window
             */
            props.hideSetupPrice();
        } else {
            /**
             * If they have not entered an amount
             */
            if (amount <= 0) {
                setErrors((errors) => ({ ...errors, amount: "Please enter an amount to charge" }));
            }
            /**
             * If there was no message left
             */
            if (message.length <= 0) {
                setErrors((errors) => ({ ...errors, message: "Please send a message with the price card" }));
            }
        }
    }

    return (
        <div ref={priceWindowRef} className="setup-price-window">
            <div className="price-card-setup-fields">
                <h1>Setup new payment</h1>
                <Input
                    type="number"
                    label="Amount"
                    icon="£"
                    value={amount}
                    placeholder="0"
                    error={errors.amount}
                    onChange={(e) => { e.target.value >= 0 && setAmount(e.target.value) }}
                    note={[
                        `FitPortal will take a £${fee} fee`,
                        (isReccuring && chargePeriod?.option) ? ` every ${chargePeriod?.option}` : "",
                    ].join(" ")} />

                <Checkbox
                    checked={isReccuring}
                    onClick={() => setIsReccuring(!isReccuring)}
                    label="Repeat payment (setup a subscription)" />

                {isReccuring &&
                    <Select
                        label="Charge how often"
                        placeholder="Choose time period"
                        options={{
                            day: "Daily",
                            week: "Weekly",
                            month: "Monthly",
                        }}
                        value={chargePeriod?.value}
                        onSelect={(option) => setChargePeriod(option)}
                        note="The billing period for this will be if/when they accept it" />
                }

                <Input
                    type="text"
                    label="Message"
                    value={message}
                    error={errors.message}
                    placeholder="Further details..."
                    onChange={(e) => setMessage(e.target.value)} />

                <p className="additional-information">If they accept the price, you'll be notified in the chat with them and we'll let you know when the payment has gone through</p>

                <Button
                    class="primary"
                    onClick={() => setupNewPayment()}>
                    Send Payment Card
                </Button>
            </div>
        </div>
    );
}

export default SetupPrice;