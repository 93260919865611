import React, { useState, useEffect } from "react";
import { GoalProvider } from "../../utils/providers/goal";
import { db, auth } from "../../utils/firebase";
import "./goals.scss";

/**
 * UI components
 */
import Button from "../../components/ui/button/button";
import Modal from "../../components/modal/modal";
import Goal from "../../components/goals/goal";
import AddGoal from "../../components/goals/add/add";

/**
 * Functional component to return the goals for this current user
 */
function Goals() {
    const [newGoalModal, setNewGoalModal] = useState(false);
    const [personalGoals, setPersonalGoals] = useState([]);
    const [trainerGoals, setTrainerGoals] = useState([]);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup a listener on this users "goals" subcollection
         */
        const unsubscribe = db.collection(`clients/${auth.currentUser.uid}/goals`)
            .onSnapshot((goalsSnap) => {
                /**
                 * Loop through the goals found
                 */
                goalsSnap.docChanges().forEach((change) => {
                    /**
                     * Deconstruct the "created_by" field from the data
                     */
                    const { created_by } = change.doc.data();

                    /**
                     * If the record was added
                     */
                    if (change.type === "added") {
                        /**
                         * Was this added by the current user
                         */
                        if (auth.currentUser.uid === created_by) {
                            setPersonalGoals((personalGoals) => [
                                ...personalGoals,
                                {
                                    id: change.doc.id,
                                    ...change.doc.data(),
                                }
                            ]);
                        } else {
                            setTrainerGoals((trainerGoals) => [
                                ...trainerGoals,
                                {
                                    id: change.doc.id,
                                    ...change.doc.data(),
                                }
                            ]);
                        }
                    }

                    if (change.type === "modified") {
                        /**
                         * Is this goal added by the current user
                         */
                        if (auth.currentUser.uid === created_by) {
                            setPersonalGoals((personalGoals) => {
                                let updatedPersonalGoals = [...personalGoals];
                                for (let i in personalGoals) {
                                    if (personalGoals[i].id === change.doc.id) {
                                        updatedPersonalGoals[i] = {
                                            id: change.doc.id,
                                            ...change.doc.data(),
                                        };
                                        break;
                                    }
                                }
                                return updatedPersonalGoals;
                            });
                        } else {
                            setTrainerGoals((trainerGoals) => {
                                let updatedTrainerGoals = [...trainerGoals];
                                for (let i in trainerGoals) {
                                    if (trainerGoals[i].id === change.doc.id) {
                                        updatedTrainerGoals[i] = {
                                            id: change.doc.id,
                                            ...change.doc.data(),
                                        };
                                        break;
                                    }
                                }
                                return updatedTrainerGoals;
                            });
                        }
                    }

                    if (change.type === "removed") {
                        /**
                         * Is the goal being removed originally setup by the current user
                         */
                        if (auth.currentUser.uid === created_by) {
                            setPersonalGoals((personalGoals) => {
                                personalGoals.filter((personalGoal) =>
                                    personalGoal.id !== change.doc.id
                                );
                            });
                        } else {
                            setTrainerGoals((trainerGoals) => {
                                trainerGoals.filter((trainerGoal) =>
                                    trainerGoal.id !== change.doc.id
                                );
                            });
                        }
                    }
                });
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);

    return (
        <>
            <div className="page-title">
                <div className="page-title-flex with-margin">
                    <h1>My Goals</h1>
                    <Button class="primary" onClick={() => setNewGoalModal(true)}>Setup Goal</Button>
                </div>
                <p>Set personal goals for yourself and receive goals set by your trainers.</p>
            </div>

            <div className="goals-wrapper">
                <div className="goal-circles-block">
                    <div className="circle-title">Personal Set Goals</div>
                    <div className="goal-circles">
                        {/* If there are no personal goals */}
                        {(personalGoals.length === 0) &&
                            <p className="no-goals-notice">You havn't set any goals up yet</p>
                        }

                        {/* User added goal discs */}
                        {personalGoals.map((personalGoal) =>
                            <Goal key={personalGoal.id} data={personalGoal} />
                        )}
                    </div>
                </div>
            </div>

            {/* Setting up a new goal modal */}
            {newGoalModal &&
                <Modal
                    title="Setup New Goal"
                    className="with-nav no-pad"
                    hide={() => setNewGoalModal(false)}>
                    <GoalProvider>
                        <AddGoal close={() => setNewGoalModal(false)} />
                    </GoalProvider>
                </Modal>
            }
        </>
    );
}

export default Goals;
