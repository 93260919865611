import React, { useEffect, useState } from "react";
import { db, auth, arrayUnion } from "../../../utils/firebase";
import firebase from "firebase";
import moment from "moment";
import "./assign.scss";

import Modal from "../../modal/modal";
import Select from "../../ui/inputs/select/new-select";
import DatePicker from "../../ui/inputs/datetime";
import Button from "../../ui/button/button-new";

function AssignWorkout(props) {
    const [clients, setClients] = useState({});
    const [client, setClient] = useState({});
    const [forWhen, setForWhen] = useState({});
    const [loading, setLoading] = useState(false);

    const assignToClient = async () => {
        setLoading(true);

        await db.collection("events").add({
            title: props.title,
            comments: "",
            all_day: false,
            users: arrayUnion(auth.currentUser.uid, client.option),
            workout: props.workoutID,
            starts: firebase.firestore.Timestamp.fromMillis(moment(forWhen).format("x")),
            ends: firebase.firestore.Timestamp.fromMillis(moment(forWhen).add(1, "hours").format("x")),
            created: firebase.firestore.FieldValue.serverTimestamp(),
            setup_by: auth.currentUser.uid,
        });

        props.hide();
    }

    useEffect(() => {
        db.collection(`trainers/${auth.currentUser.uid}/clients`)
            .get().then((clientDocs) => {
                clientDocs.forEach((clientDoc) => {
                    db.doc(`clients/${clientDoc.id}`).get().then((userDoc) => {
                        setClients((clients) => {
                            let updatedClients = { ...clients };

                            updatedClients[clientDoc.id] = `${userDoc.data().first_name} ${userDoc.data().last_name}`;

                            return updatedClients;
                        });
                    });
                });
            });
    }, []);

    return (
        <Modal
            title="Assign workout"
            hide={() => props.hide()}
            className="slim assign-workout-modal">

            <Select
                label="Assign to:"
                placeholder="Choose client"
                wrapperClass="fw"
                value={client.value}
                note="Choose a client who you'd like to assign this workout to"
                onSelect={(option) => setClient(option)}
                options={clients} />

            <DatePicker
                type="text"
                label="For when:"
                wrapperClass="fw"
                placeholder="Choose a date/time"
                note="Choose a date &amp; time for this workout to be setup for"
                value={forWhen}
                onChange={(date) => setForWhen(date)} />

            <Button
                label="Assign workout to client"
                loading={loading}
                loadingText="Adding workout..."
                onClick={() => assignToClient()} />
        </Modal>
    );
}

export default AssignWorkout;