import React from "react";

function ClientDashboard() {
    return (
        <>
            <div className="page-title">
                <h1>Dashboard</h1>
            </div>
        </>
    );
}

export default ClientDashboard;
