import React, { useContext, useEffect, useState } from "react";
import { EventContext } from "../../../../../utils/providers/event";
import { db, auth } from "../../../../../utils/firebase";

/**
 * UI components
 */
import Input from "../../../../ui/inputs/input";
import Button from "../../../../ui/button/button";
import Select from "../../../../ui/inputs/select/new-select";

/**
 * 
 */
function Goals(props) {
    const [goals, setGoals] = useState({});

    /**
     * Deconstruct variables from the add event context
     */
    const {
        setStage,
        contributionGoal, setContributionGoal,
        contributionValue, setContributionValue,
    } = useContext(EventContext);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup a listener on the users "goals" subcollection to assign goal to an event
         */
        const unsubscribe = db.collection(`clients/${auth.currentUser.uid}/goals`)
            .onSnapshot((goalSnaps) => {
                /**
                 * Loop through the goals found
                 */
                goalSnaps.docChanges().forEach((change) => {
                    if (change.type === "added" || change.type === "modified") {
                        setGoals((goals) => ({
                            ...goals,
                            [change.doc.id]: change.doc.data().title,
                        }));
                    }

                    if (change.type === "removed") {
                        setGoals((goals) => {
                            goals.filter((goal) =>
                                goal.id !== change.doc.id
                            );
                        });
                    }
                });
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);

    console.log(goals)

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <Select
                    label="Assign to goal:"
                    placeholder="Choose a goal"
                    onSelect={(option) => setContributionGoal(option)}
                    value={contributionGoal.value}
                    options={goals} />

                <Input
                    type="number"
                    value={contributionValue}
                    label="Contribution value:"
                    placeholder="e.g 10"
                    note={`How much should this event contribute to your ${contributionGoal.value} goal?`}
                    onChange={(e) => setContributionValue(e.target.value)} />
            </div>

            <Button
                class="primary"
                onClick={() => setStage(3)}
                disabled={contributionGoal.option && !contributionValue}>
                Continue to Save
            </Button>
        </div>
    );
}

export default Goals;