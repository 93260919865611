import { useContext } from 'react';
import { AuthContext } from "../../utils/providers/auth";

/**
 * Styles
 */
import './dashboard.scss';

/**
 * Dashboard types
 */
import ClientDashboard from './types/client';
import TrainerDashboard from './types/trainer';

/**
 * Component for the dashboard
 */
function Dashboard() {
    /**
     * Get the user context
     */
    const { user } = useContext(AuthContext);
    /**
     * What dashboard do we need to load?
     */
    if (user?.is_client) {
        return <ClientDashboard />
    } else if (user?.is_trainer) {
        return <TrainerDashboard />
    } else {
        return 'loading...';
    }
}

export default Dashboard;