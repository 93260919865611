import React, { useContext } from "react";
import { ProgramContext } from "../../../../utils/providers/program";

/**
 * UI components
 */
import Input from "../../../ui/inputs/input";
import Textarea from "../../../ui/inputs/textarea";
import Select from "../../../ui/inputs/select/new-select";
import Button from "../../../ui/button/button";

/**
 * Functional component to return the details panel for adding a new program
 */
function Details() {
    /**
     * Deconstruct variables from the add meal context
     */
    const {
        setStage,
        name, setName,
        details, setDetails,
        length, setLength,
        lengthUnit, setLengthUnit,
    } = useContext(ProgramContext);

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <Input
                    type="text"
                    label="Name:"
                    placeholder="e.g. 6 month weight loss"
                    onChange={(e) => setName(e.target.value)}
                    value={name} />

                <div className="input-group-column cols-2 weighted">
                    <Input
                        type="number"
                        label="Program length:"
                        placeholder="e.g. 6"
                        onChange={(e) => setLength(e.target.value)}
                        value={length} />

                    <Select
                        label="Length unit:"
                        placeholder="Choose interval"
                        onSelect={(option) => setLengthUnit(option)}
                        value={lengthUnit.value}
                        options={{
                            day: "Days",
                            week: "Weeks",
                            month: "Months",
                        }} />
                </div>

                <Textarea
                    type="text"
                    label="Program details:"
                    placeholder="e.g. This 6 month program is designed to..."
                    onChange={(e) => setDetails(e.target.value)}
                    value={details}
                    rows={4} />
            </div>

            <Button
                class="primary"
                onClick={() => setStage(2)}
                disabled={!name || !length || !lengthUnit.option}>
                Continue to Workouts
            </Button>
        </div>
    );
}

export default Details;