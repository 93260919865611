import React, { useState } from "react";
import { db, auth } from "../../utils/firebase";
import { DotsIcon, RightArrowIcon, TrashIcon } from "../../utils/svgs";

const moment = require("moment");

/**
 * Functional component for the notification element to appear along the left of the notifications modal
 *
 * @returns HTML markup and functionality for updating the notifications context
 */
function Notification(props) {
    const [editing, setEditing] = useState(false);

    /**
     * Deconstruct the notification data form props
     */
    const notification_data = props.data;

    /**
     * Get the date of the notifcation as a string
     */
    const readable_date = moment.unix(notification_data.created.seconds).format("DD/MM/YYYY [at] HH:mm:ss");

    /**
     * Update the state to reflect if this notification is being edited
     *
     * @type {const}
     */
    const setEditActive = () => {
        /**
         * Update the state
         */
        setEditing((active) => !active);
    };

    /**
     * Delete the notification from the users account
     *
     * @type {const}
     */
    const deleteNotification = () => {
        /**
         * Delete the notificaiton from the subcollection
         */
        db.doc(`users/${auth.currentUser.uid}/notifications/${props.id}`).delete();
        /**
         * Update the state
         */
        setEditing(false);
    };

    return (
        <div className={["notification-element", notification_data.unread ? "unread" : ""].join(" ")}>
            <div className={["notification-preview", editing ? "active" : ""].join(" ")}>
                <div className="notification-text" onClick={() => props.onClick()}>
                    <p className="notification-message">{notification_data.message}</p>
                    <p className="notification-timestamp">{readable_date}</p>
                </div>
                <div className="notification-edit" onClick={() => setEditActive()}>
                    {editing ? <RightArrowIcon /> : <DotsIcon />}
                </div>
            </div>
            <div className="notification-actions">
                <div className="notification-action-delete" onClick={() => deleteNotification()}>
                    <TrashIcon />
                </div>
            </div>
        </div>
    );
}

export default Notification;
