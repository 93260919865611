import React from "react";
import { NavLink } from "react-router-dom";
import {
    DashboardIcon,
    CalendarIcon,
    WorkoutsIcon,
    MealsIcon,
    ClientsIcon,
    ClipboardIcon,
} from "../../../utils/svgs";

/**
 * Trainer navigation links, used when the user type matches that of a trainer
 */
const TrainerNavigation = () => (
    <ul className="nav-list">
        <li className="nav-item">
            <NavLink to="/app/dashboard" activeClassName="active">
                <div className="nav-icon">
                    <DashboardIcon />
                </div>
                <span>Dashboard</span>
            </NavLink>
        </li>
        <li className="nav-item">
            <NavLink to="/app/clients" activeClassName="active">
                <div className="nav-icon">
                    <ClientsIcon />
                </div>
                <span>Clients</span>
            </NavLink>
        </li>
        <li className="nav-item">
            <NavLink to="/app/calendar" activeClassName="active">
                <div className="nav-icon">
                    <CalendarIcon />
                </div>
                <span>Calendar</span>
            </NavLink>
        </li>
        <li className="nav-item">
            <NavLink to="/app/workouts" activeClassName="active">
                <div className="nav-icon">
                    <WorkoutsIcon />
                </div>
                <span>Workouts</span>
            </NavLink>
        </li>
        <li className="nav-item">
            <NavLink to="/app/meals" activeClassName="active">
                <div className="nav-icon">
                    <MealsIcon />
                </div>
                <span>Meals</span>
            </NavLink>
        </li>
    </ul>
);

export default TrainerNavigation;