import { NavLink } from "react-router-dom";
import { logoutRequest } from "../../../utils/providers/auth";
import { DashboardIcon, CalendarIcon, MarketplaceIcon, WorkoutsIcon, GoalsIcon, NutritionIcon, EquipmentIcon, MetricsIcon } from "../../../utils/svgs";

/**
 * Client navigation links, used when the user type matches that of a client
 *
 * @type {const}
 * @param {object} props
 * @returns Client navigation link structure HMTL
 */
const ClientNavigation = () => {
    return (
        <ul className="nav-list">
            <li className="nav-item">
                <NavLink to="/app/calendar" activeClassName="active">
                    <div className="nav-icon">
                        <CalendarIcon />
                    </div>
                    <span>Calendar</span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/app/marketplace" activeClassName="active">
                    <div className="nav-icon">
                        <MarketplaceIcon />
                    </div>
                    <span>Marketplace</span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/app/workouts" activeClassName="active">
                    <div className="nav-icon">
                        <WorkoutsIcon />
                    </div>
                    <span>Workouts</span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/app/goals" activeClassName="active">
                    <div className="nav-icon">
                        <GoalsIcon />
                    </div>
                    <span>Goals</span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/app/nutrition" activeClassName="active">
                    <div className="nav-icon">
                        <NutritionIcon />
                    </div>
                    <span>Nutrition</span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/app/equipment" activeClassName="active">
                    <div className="nav-icon">
                        <EquipmentIcon />
                    </div>
                    <span>Equipment</span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/app/metrics" activeClassName="active">
                    <div className="nav-icon">
                        <MetricsIcon />
                    </div>
                    <span>Metrics</span>
                </NavLink>
            </li>
        </ul>
    );
};

export default ClientNavigation;
