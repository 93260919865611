import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import "./input.scss";

/**
 * Input functional component
 */
function DatePicker(props) {
    return (
        <div className={["input-block", props.wrapperClass, props.error && "invalid"].join(" ")}>
            {/* Label */}
            {props.label && <label>{props.label}</label>}
            {/* React datetime picker */}
            <Datetime
                inputProps={{ placeholder: props.placeholder, readOnly: true }}
                dateFormat="DD/MM/YYYY"
                className={[props.value && "has-value"]}
                timeFormat={!props.hideTimes}
                value={props.value}
                closeOnClickOutside={true}
                closeOnSelect={props.closeOnSelect}
                onChange={(date) => props.onChange(date)} />
            {/* Input note / description */}
            {props.note && <p>{props.note}</p>}
            {/* Input error */}
            {props.error && <p className="input-error">{props.error}</p>}
        </div>
    );
}

export default DatePicker;
