import { useContext } from 'react';
import { AuthContext } from "../../utils/providers/auth";

/**
 * Styles
 */
import './account.scss';

/**
 * Account types
 */
import ClientAccount from './types/client';
import TrainerAccount from './types/trainer';

/**
 * Component for the Accounts page
 */
function Account() {
    /**
     * Get the user context
     */
    const { user } = useContext(AuthContext);
    /**
     * What dashboard do we need to load?
     */
    if (user?.is_client) {
        return <ClientAccount />
    } else if (user?.is_trainer) {
        return <TrainerAccount />
    } else {
        return 'loading...';
    }
}

export default Account;