import React from 'react';
import './trainer.scss';

/**
 * UI components
 */
import Navigation from "../navigation/navigation";

/**
 * Images
 */
import image_trainer from "../../../assets/images/website/trainer-banner.jpg";

/**
 * Trainer page
 */
function Trainer() {
    return (
        <>
            <section className="general-banner" style={{ backgroundImage: `url(${image_trainer})` }}>
                <Navigation />

                <div className="banner-text">
                    <h1>Become a Trainer</h1>
                </div>
            </section>
        </>
    );
}

export default Trainer;