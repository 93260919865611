import React, { useState, useRef, useEffect } from "react";
import { db, auth } from "../../../utils/firebase";
import moment from "moment";

/**
 * ChartJS
 */
import { Bar } from "react-chartjs-2";

/**
 * Functional component to return the calendar for the current users nutrition
 */
function Calories() {
    const [targetCalories, setTargetCalories] = useState(0);
    const [targetCarbs, setTargetCarbs] = useState(0);
    const [targetFat, setTargetFat] = useState(0);
    const [targetProtein, setTargetProtein] = useState(0);
    const [meals, setMeals] = useState([]);
    const [calorieData, setCalorieData] = useState([]);
    const [carbData, setCarbData] = useState([]);
    const [fatData, setFatData] = useState([]);
    const [proteinData, setProteinData] = useState([]);
    const [todayCalories, setTodayCalories] = useState(0);
    const [todayCarbs, setTodayCarbs] = useState(0);
    const [todayFat, setTodayFat] = useState(0);
    const [todayProtein, setTodayProtein] = useState(0);

    /**
     * Get moment date objects for the start and end of the week
     */
    const rangeStart = moment().startOf("week");
    const rangeEnd = moment().endOf("week");

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the current users document from the database
         */
        db.doc(`clients/${auth.currentUser.uid}`).get().then((userDoc) => {
            /**
             * Deconstruct the targets from the document
             */
            const { nutrition_targets } = userDoc.data();
            /**
             * Were there any nutrition targets loaded in?
             */
            if (nutrition_targets) {
                /**
                 * Update the local state
                 */
                setTargetCalories(nutrition_targets.calories);
                setTargetCarbs(nutrition_targets.carbs);
                setTargetFat(nutrition_targets.fat);
                setTargetProtein(nutrition_targets.protein);
            }
        });
    }, []);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup a listener on the users "meals" collection to stream the values
         */
        const unsubscribe = db.collection(`clients/${auth.currentUser.uid}/meals`)
            .onSnapshot((mealsSnap) => {
                /**
                 * Loop through the meals found for this client
                 */
                mealsSnap.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        setMeals((meals) => [
                            ...meals,
                            { id: change.doc.id, ...change.doc.data() }
                        ]);
                    }

                    if (change.type === "modified") {
                        setMeals((meals) => {
                            let updatedMeals = [...meals];
                            for (let i in meals) {
                                if (meals[i].id === change.doc.id) {
                                    updatedMeals[i] = { ...change.doc.data() };
                                    break;
                                }
                            }
                            return updatedMeals;
                        });
                    }

                    if (change.type === "removed") {
                        setMeals((meals) => meals.filter((mealElement) => mealElement.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on component unmount
         */
        return () => unsubscribe();
    }, []);

    /**
     * When the meals object is updated
     */
    useEffect(() => {
        /**
         * Instantiate some new arrays for holding the nutrition data
         */
        let localCarbData = [];
        let localFatData = [];
        let localProteinData = [];
        /**
         * Instantiate some variables to hold todays values
         */
        let todaysCarbValue = 0;
        let todaysFatValue = 0;
        let todaysProteinValue = 0;
        /**
         * Loop through the meals added into the local state
         */
        meals.map((meal) => {
            /**
             * Get a string value of the date for these values
             */
            const mealDay = moment(meal.for_when.seconds, "X").format("YYYY-MM-DD");
            /**
             * Deconstruct the nutrtion data from the meal
             */
            const { nutrition } = meal;
            /**
             * Was there nutrition data found
             */
            if (nutrition) {
                /**
                 * Parse the input value from the meal as a float
                 */
                const addCarbs = parseFloat(nutrition.carbs || 0);
                /**
                 * Create a copy of the local data array
                 */
                const updatedCarbData = [...localCarbData];
                /**
                 * If there are no values showing for this date yet
                 */
                if (updatedCarbData.length === 0) {
                    /**
                     * Push the value as the first element for this date
                     */
                    updatedCarbData.push({ x: `${mealDay}`, y: addCarbs });
                } else {
                    /**
                     * If there are already values being held, loop through them
                     */
                    for (let i in updatedCarbData) {
                        /**
                         * If the current date already has a value
                         */
                        if ((updatedCarbData[i].x === mealDay) && (updatedCarbData[i].y !== 0)) {
                            /**
                             * Add the values for this meal into the array for this date
                             */
                            updatedCarbData[i].y = updatedCarbData[i].y + addCarbs;
                        } else {
                            /**
                             * If there are no values for this date yet, push it as the first
                             */
                            updatedCarbData.push({ x: `${mealDay}`, y: addCarbs });
                        }
                    }
                }
                /**
                 * Push the local state into the state
                 */
                localCarbData = [...updatedCarbData];
                /**
                 * Parse the input value from the meal as a float
                 */
                const addFat = parseFloat(nutrition.fat || 0);
                /**
                 * Create a copy of the local data array
                 */
                const updatedFatData = [...localFatData];
                /**
                 * If there are no values showing for this date yet
                 */
                if (updatedFatData.length === 0) {
                    /**
                     * Push the value as the first element for this date
                     */
                    updatedFatData.push({ x: `${mealDay}`, y: addFat });
                } else {
                    /**
                     * If there are already values being held, loop through them
                     */
                    for (let i in updatedFatData) {
                        /**
                         * If the current date already has a value
                         */
                        if ((updatedFatData[i].x === mealDay) && (updatedFatData[i].y !== 0)) {
                            /**
                             * Add the values for this meal into the array for this date
                             */
                            updatedFatData[i].y = updatedFatData[i].y + addFat;
                        } else {
                            /**
                             * If there are no values for this date yet, push it as the first
                             */
                            updatedFatData.push({ x: `${mealDay}`, y: addFat });
                        }
                    }
                }
                /**
                 * Push the local state into the state
                 */
                localFatData = [...updatedFatData];
                /**
                 * Parse the input value from the meal as a float
                 */
                const addProtein = parseFloat(nutrition.protein || 0);
                /**
                 * Create a copy of the local data array
                 */
                const updatedProteinData = [...localProteinData];
                /**
                 * If there are no values showing for this date yet
                 */
                if (updatedProteinData.length === 0) {
                    /**
                     * Push the value as the first element for this date
                     */
                    updatedProteinData.push({ x: `${mealDay}`, y: addProtein });
                } else {
                    /**
                     * If there are already values being held, loop through them
                     */
                    for (let i in updatedProteinData) {
                        /**
                         * If the current date already has a value
                         */
                        if ((updatedProteinData[i].x === mealDay) && (updatedProteinData[i].y !== 0)) {
                            /**
                             * Add the values for this meal into the array for this date
                             */
                            updatedProteinData[i].y = updatedProteinData[i].y + addProtein;
                        } else {
                            /**
                             * If there are no values for this date yet, push it as the first
                             */
                            updatedProteinData.push({ x: `${mealDay}`, y: addProtein });
                        }
                    }
                }
                /**
                 * Push the local state into the state
                 */
                localProteinData = [...updatedProteinData];
                /**
                 * If the date for this meal matches the date for today
                 */
                if (mealDay === moment().format("YYYY-MM-DD")) {
                    /**
                     * Add the values to the value for today
                     */
                    todaysCarbValue = todaysCarbValue + addCarbs;
                    todaysFatValue = todaysFatValue + addFat;
                    todaysProteinValue = todaysProteinValue + addProtein;
                }
            }
        });
        /**
         * Push the local data into the state for the charts
         */
        setCarbData(localCarbData);
        setFatData(localFatData);
        setProteinData(localProteinData);
        /**
         * Push the today values into the local state
         */
        setTodayCarbs(todaysCarbValue);
        setTodayFat(todaysFatValue);
        setTodayProtein(todaysProteinValue);
    }, [meals]);

    /**
     * Setup a reference to the chart object
     */
    const chartRef = useRef();

    /**
     * Initialise some parameters to pass into the chart
     */
    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                display: false,
            },
            x: {
                type: "time",
                time: {
                    unit: "day",
                    tooltipFormat: "DD/MM/YYYY",
                },
                display: true,
                suggestedMin: rangeStart,
                suggestedMax: rangeEnd,
                grid: {
                    color: "#EDF0F4",
                    lineWidth: 2,
                    borderWidth: 0,
                },
                ticks: {
                    color: "#4E5C70",
                    font: {
                        family: "Poppins",
                        size: 12,
                        weight: 500,
                    },
                },
            },
        },
    };

    const colors = {
        purple: "rgba(205, 133, 222, 1)",
        green: "rgba(80, 201, 104, 1)",
        blue: "rgba(80, 187, 201, 1)",
    };

    const chartData = {
        datasets: [
            {
                label: "Carbs (g)",
                data: carbData,
                backgroundColor: [colors.purple],
                borderColor: "#fff",
                borderWidth: 2,
                borderRadius: 5,
                barThickness: 18,
                spanGaps: true,
            },
            {
                label: "Fat (g)",
                data: fatData,
                backgroundColor: [colors.green],
                borderColor: "#fff",
                borderWidth: 2,
                borderRadius: 5,
                barThickness: 18,
                spanGaps: true,
            },
            {
                label: "Protein (g)",
                data: proteinData,
                backgroundColor: [colors.blue],
                borderColor: "#fff",
                borderWidth: 2,
                borderRadius: 5,
                barThickness: 18,
                spanGaps: true,
            }
        ],
    }

    return (
        <div className="stat-card">
            <h2 className="stat-heading">Daily Trends</h2>
            <div className="stat-details">
                <div className="stat-numbers">
                    <div className="big-stat">
                        <p>
                            <em>{todayCalories}/</em>{targetCalories}<span>cal</span>
                            <small>Calories target</small>
                        </p>
                    </div>
                    <div className="chart-legend">
                        <div className="legend-label purple">
                            <em>{todayCarbs}/</em>{targetCarbs}<span>g</span>
                            <small>Carbs target</small>
                        </div>
                        <div className="legend-label green">
                            <em>{todayFat}/</em>{targetFat}<span>g</span>
                            <small>Fat target</small>
                        </div>
                        <div className="legend-label blue">
                            <em>{todayProtein}/</em>{targetProtein}<span>g</span>
                            <small>Protein target</small>
                        </div>
                    </div>
                </div>
                <div className="stat-chart">
                    <Bar
                        ref={chartRef}
                        width={475}
                        height={240}
                        data={chartData}
                        options={chartOptions} />
                </div>
            </div>
        </div>
    );
}

export default Calories;