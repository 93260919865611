import React, { useState, createContext } from "react";

const SignupContext = createContext();

const SignupProvider = ({ children }) => {
    const [accountType, setAccountType] = useState("");
    const [signupStage, setSignupStage] = useState(0);
    const [errors, setErrors] = useState({});
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [dob, setDob] = useState("");
    const [firstLine, setFirstLine] = useState("");
    const [secondLine, setSecondLine] = useState("");
    const [city, setCity] = useState("");
    const [county, setCounty] = useState("");
    const [postcode, setPostcode] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [sortCode, setSortCode] = useState("");
    const [photoIDFront, setPhotoIDFront] = useState("");
    const [photoIDBack, setPhotoIDBack] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    return (
        <SignupContext.Provider
            value={{
                accountType, setAccountType,
                signupStage, setSignupStage,
                errors, setErrors,
                firstName, setFirstName,
                lastName, setLastName,
                email, setEmail,
                phone, setPhone,
                dob, setDob,
                firstLine, setFirstLine,
                secondLine, setSecondLine,
                city, setCity,
                county, setCounty,
                postcode, setPostcode,
                accountNumber, setAccountNumber,
                sortCode, setSortCode,
                photoIDFront, setPhotoIDFront,
                photoIDBack, setPhotoIDBack,
                password, setPassword,
                confirmPassword, setConfirmPassword,
            }}>
            {children}
        </SignupContext.Provider>
    );
};

export { SignupContext, SignupProvider };