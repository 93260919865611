import './button.scss';
import Spinner from "../../../assets/images/loading.svg";

/**
 * Button functional component
 * 
 * @type {const}
 * @param {object} props Component props passed down
 */
const Button = (props) => (
    <button
        name={props.name}
        id={props.id}
        disabled={props.disabled}
        className={['button', props.class].join(' ')}
        onClick={props.onClick}
        type={props.action}>
        {/* Button contents */}
        {props.children}
        {/* Show a spinner */}
        {props.spinner
            && <img src={Spinner} alt="Loading Spinner" />}

    </button>
);

export default Button;