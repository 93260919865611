import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./navigation.scss";

import Logo from "../../../assets/images/fit-portal-logoPNG.png";
import LogoDark from "../../../assets/images/fit-portal-logoDARK.png";

/**
 * Functional component to return the navigation for the website
 */
function Navigation() {
    const [navShowing, setNavShowing] = useState(false);

    /**
     * Listen for a click outside the navigation ref
     */
    const ClickedOutside = (navRef) => {
        useEffect(() => {
            /**
             * Check for the click to be outside the mobile navigation
             */
            const checkForOutsideClick = (event) => {
                /**
                 * Is the click outside?
                 */
                if (navRef.current && !navRef.current.contains(event.target)) {
                    /**
                     * Hide the navigation
                     */
                    setNavShowing(false);
                }
            }
            /**
             * Bind on the component load
             */
            document.addEventListener("mousedown", checkForOutsideClick)
            return () => {
                /**
                 * Unbind on component unload
                 */
                document.removeEventListener("mousedown", checkForOutsideClick);
            }
        }, [navRef]);
    }

    const navRef = useRef(null);

    /**
     * Bind onto the click event
     */
    ClickedOutside(navRef);

    return (
        <>
            <div className="website-navigation">
                <NavLink to="/">
                    <div className="wn-logo">
                        <img src={Logo} alt="FitPortal" />
                    </div>
                </NavLink>
                <div className="wn-links">
                    <ul>
                        <NavLink to="/" activeClassName="is-active">Home</NavLink>
                        <NavLink to="/become-a-trainer" activeClassName="is-active">Become A Trainer</NavLink>
                        <NavLink to="/contact" activeClassName="is-active">Contact Us</NavLink>
                    </ul>
                </div>
                <div className="wn-buttons">
                    <NavLink to="/login">Login</NavLink>
                    <NavLink to="/signup">Signup</NavLink>
                </div>
                <div className="wn-toggle" onClick={() => setNavShowing(true)}>
                    <span className="wn-toggle-bars"></span>
                </div>
            </div>

            <div className={["mobile-navigation", navShowing && "is-showing"].join(" ")}>
                <div className="mobile-navigation-inner" ref={navRef}>
                    <div className="wn-logo">
                        <img src={LogoDark} alt="FitPortal" />
                    </div>
                    <div className="wn-links">
                        <ul>
                            <NavLink to="/" activeClassName="is-active">Home</NavLink>
                            <NavLink to="/become-a-trainer" activeClassName="is-active">Become A Trainer</NavLink>
                            <NavLink to="/contact" activeClassName="is-active">Contact Us</NavLink>
                        </ul>
                    </div>
                    <div className="wn-buttons">
                        <NavLink to="/login">Login</NavLink>
                        <NavLink to="/signup">Signup</NavLink>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Navigation;