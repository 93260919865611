import React, { useContext } from "react";
import { AlertsContext } from "../../../utils/providers/alerts";
import "./alerts.scss";

/**
 * Alert component
 */
import Alert from "../../ui/toast/toast";

/**
 * Sits over the top of the application screen and hooks into the alerts context to pull through 
 * the alerts pushed into it's state. 
 * 
 * @returns HTML markup for the app alerts
 */
function Alerts() {
    /**
     * Get the alerts data from the context
     */
    const { alerts } = useContext(AlertsContext);
    /**
     * Are there any alerts to print onto the screen?
     */
    if (alerts.length > 0) {
        return (
            <div className="app-alerts-column">
                {/* Map over the alerts and insert them to the DOM */}
                {alerts.map((alert) =>
                    <Alert
                        key={alert.id}
                        id={alert.id}
                        type={alert.type}
                        title={alert.title}
                        body={alert.body} />
                )}
            </div>
        );
    } else {
        /**
         * And if there isn't
         */
        return false;
    }
}

export default Alerts;