import React, { useState, useEffect } from 'react';
import { db, auth, fun } from '../../../utils/firebase';
import firebase from "firebase";
import "./add-client.scss";

/**
 * UI Components
 */
import Input from '../../ui/inputs/input';
import Button from '../../ui/button/button';

/**
 * Functional component to return the form needed to add a new client to the trainer
 */
function AddClient(props) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);

    const [errors, setErrors] = useState({ email: "" });
    const [findingClient, setFindingClient] = useState(false);

    /**
     * When the email string is updated
     */
    useEffect(() => {
        /**
         * Check it against a RegEx for the formatting
         */
        const isEmail = email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        /**
         * Set the result of the check into the state
         */
        setValidEmail(isEmail);
    }, [email]);

    /**
     * Check to see if a user has a fitportal account with that email
     */
    const checkForUser = async () => {
        /**
         * Show a spinner
         */
        setFindingClient(true);
        /**
         * Run a database query for the user
         */
        const userData = await db.collection("clients")
            .where("email", "==", email)
            .get().then((userDocs) => {
                /**
                 * If there are documents found
                 */
                if (userDocs.size > 0) {
                    /**
                     * Return the data for the first one
                     */
                    return { ...userDocs.docs[0].data(), id: userDocs.docs[0].id };
                } else {
                    return false;
                }
            });
        /**
         * Do we need to assign the client to the trainer account?
         */
        if (userData) {
            /**
             * Assign them to the trainer
             */
            await assignClientToTrainer(userData.id);
        } else {
            /**
             * Start the process of calling a cloud function to setup a new auth account 
             * for this client email
             */
            await createNewUserAccount();
        }
        /**
         * Hide the button spinner
         */
        setFindingClient(false);
        /**
         * Clear the input
         */
        setEmail("");
        /**
         * Hide the new modal form
         */
        props.complete();
    }

    /**
     * Assign the client to the trainer account
     */
    const assignClientToTrainer = async (clientID) => {
        /**
         * Assign the client to the trainer
         */
        await db.doc(`trainers/${auth.currentUser.uid}/clients/${clientID}`).set({
            connected: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true });
        /**
         * Assign the trainer to the client
         */
        await db.doc(`clients/${clientID}/trainers/${auth.currentUser.uid}`).set({
            connected: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true });
    }

    /**
     * Create a new user auth account with an email passed in
     */
    const createNewUserAccount = async () => {
        /**
         * Make a reference to the cloud function for 
         */
        const createUser = fun.httpsCallable("createNewUserAuth");
        /**
         * Uppercase the first letter of the name and lowercase the rest
         */
        const upperCaseFirst = (name) => {
            const lowercase = name.toLowerCase();
            const uppercase = lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
            return uppercase;
        }
        /**
         * Sanitise the inputs before sending them off to set the account up
         */
        const client_email = email.toLowerCase();
        const client_first_name = upperCaseFirst(firstName);
        const client_last_name = upperCaseFirst(lastName);
        /**
         * Call the function with the client email and the ID of this trainer
         */
        await createUser({
            firstName: client_first_name,
            lastName: client_last_name,
            email: client_email,
            trainerID: auth.currentUser.uid,
        });
    }

    return (
        <div className="client-signup-modal">
            <Input
                type="text"
                value={firstName}
                placeholder="First name:"
                onChange={(e) => setFirstName(e.target.value)} />

            <Input
                type="text"
                value={lastName}
                placeholder="Last name:"
                onChange={(e) => setLastName(e.target.value)} />

            <Input type="email"
                value={email}
                placeholder="Email:"
                wrapperClass={errors.email && 'invalid'}
                note={errors.email || "If this email isn't already active on FitPortal, we'll email them some login details to get started."}
                onChange={(e) => setEmail(e.target.value)} />

            <Button
                class="primary"
                spinner={findingClient}
                onClick={() => checkForUser()}
                disabled={!validEmail || findingClient} >
                Setup New Client
            </Button>
        </div>
    );
}

export default AddClient;