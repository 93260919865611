import { useEffect, useState } from 'react';
import { db, auth } from '../../../utils/firebase';
import './clients.scss';

import Client from '../../clients/client';

/**
 * Fetches and displays a list of clients listed under a trainer
 */
function ClientsList() {
    const [clients, setClients] = useState([]);

    /**
     * Component mount
     */
    useEffect(() => {
        const unsubscribe = db.collection(`trainers/${auth.currentUser.uid}/clients`)
            .onSnapshot((clientsSnap) => {
                clientsSnap.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        setClients((clients) => [...clients,
                        {
                            id: change.doc.id,
                            ...change.doc.data()
                        }]);
                    }

                    if (change.type === "removed") {
                        setClients((clients) => clients.filter((clientElement) => clientElement.id !== change.doc.id));
                    }
                });
            });

        return () => unsubscribe();
    }, []);

    return (
        <div className="client-cards">
            {clients.map((client) =>
                <Client key={client.id} id={client.id} />
            )}
        </div>
    );
}

export default ClientsList;