import React, { useState, useContext } from "react";
import { AlertsContext } from "../../utils/providers/alerts";
import "./clients.scss";

import ClientsList from '../../components/lists/clients/clients';
import AddClient from '../../components/forms/add-client/add-client';

import Button from '../../components/ui/button/button';
import Modal from "../../components/modal/modal";

export default function Clients() {
    const [addClientModal, setAddClientModal] = useState(false);

    /**
     * Pull the alerts function from the context
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * When the add client flow has completed
     */
    const addNewClientComplete = () => {
        /**
         * Hide the modal used to add them
         */
        setAddClientModal(false);
        /**
         * Then push an alert into the DOM to notify the trainer they've added the client
         */
        pushAlert({
            type: "SUCCESS",
            title: "Connected client",
        });
    }

    return (
        <>
            <div className="page-title">
                <div className="page-title-flex with-margin">
                    <h1>My Clients</h1>
                    <Button class="primary" onClick={() => setAddClientModal(true)}>Setup New Client</Button>
                </div>
                <p>View and manage clients you are currently engaged with.</p>
            </div>

            <ClientsList />

            {addClientModal &&
                <Modal title="Setup New Client" hide={() => setAddClientModal(false)} className="slim">
                    <AddClient complete={() => addNewClientComplete()} />
                </Modal>
            }
        </>
    );
}