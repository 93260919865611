import React, { useEffect, useState } from "react";
import { db, auth } from "../../../utils/firebase";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { EventProvider } from "../../../utils/providers/event";
import moment from "moment";

/**
 * UI components
 */
import Button from "../../../components/ui/button/button";
import Modal from '../../../components/modal/modal';
import AddEventClient from "../../../components/events/add/client/add";
import View from "../../../components/events/view/view";

/**
 * Initialise the localizer for the calendar
 */
const localizer = momentLocalizer(moment);

/**
 * Functional component to return the calendar for the client 
 */
function ClientCalendar() {
    const [events, setEvents] = useState([]);
    const [eventModal, setEventModal] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [viewingEvent, setViewingEvent] = useState(null);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup the listener on the database
         */
        const unsubscribe = db.collection("events")
            .where("users", "array-contains", auth.currentUser.uid)
            .onSnapshot((eventsSnap) => {
                /**
                 * Loop over each of the events and parse them into an array to load into state
                 */
                eventsSnap.docChanges().forEach((change) => {
                    /**
                     * Deconstruct the data from the update call
                     */
                    const event_data = change.doc.data();
                    /**
                     * Event added
                     */
                    if (change.type === "added") {
                        setEvents((event) => [
                            ...event,
                            {
                                id: change.doc.id,
                                start: moment(event_data.starts.seconds, "X").toDate(),
                                end: moment(event_data.ends.seconds, "X").toDate(),
                                ...change.doc.data()
                            }
                        ]);
                    }
                    /**
                     * Event updated
                     */
                    if (change.type === "modified") {
                        setEvents((event) => {
                            let updatedEvents = [...event];
                            for (let i in event) {
                                if (event[i].id === change.doc.id) {
                                    updatedEvents[i] = {
                                        id: change.doc.id,
                                        start: moment(event_data.starts.seconds, "X").toDate(),
                                        end: moment(event_data.ends.seconds, "X").toDate(),
                                        ...change.doc.data()
                                    };
                                    break;
                                }
                            }
                            return updatedEvents;
                        });
                    }
                    /**
                     * Event removed
                     */
                    if (change.type === "removed") {
                        setEvents((event) => event.filter((e) => e.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on component unmount
         */
        return () => unsubscribe();
    }, []);

    /**
     * When a day is selected from the calendar
     */
    const onSlotSelect = (event) => {
        /**
         * Push the starting date for the selected day to the local state
         */
        setStartingDate(event.start);
        /**
         * Show the event modal
         */
        setEventModal(true);
    }

    /**
     * Load the chosen event into the modal
     */
    const viewEvent = (event) => {
        /**
         * Set the event into the local state
         */
        setViewingEvent(event);
    }

    /**
     * Hide the event being viewed from the DOM
     */
    const hideViewingEvent = () => {
        /**
         * Remove the event from the state
         */
        setViewingEvent(null);
    }

    return (
        <>
            <div className="page-title">
                <div className="page-title-flex">
                    <h1>Calendar</h1>
                    <Button class="primary" onClick={() => setEventModal(true)}>Add Event</Button>
                </div>
            </div>

            <Calendar
                selectable={true}
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ flex: "1 1 400px", height: "auto" }}
                onSelectEvent={(event) => viewEvent(event)}
                onSelectSlot={(event) => onSlotSelect(event)}
            />

            {/* Are we adding a new event here */}
            {eventModal &&
                <Modal
                    title="Create a new event"
                    hide={() => setEventModal(false)}
                    className="with-nav no-pad">
                    <EventProvider>
                        <AddEventClient
                            startingDate={startingDate}
                            close={() => setEventModal(false)} />
                    </EventProvider>
                </Modal>
            }

            {/* Are we viewing an event */}
            {viewingEvent &&
                <Modal
                    title={viewingEvent.title}
                    hide={() => hideViewingEvent()}>
                    <View
                        eventID={viewingEvent.id}
                        hide={() => hideViewingEvent()} />
                </Modal>
            }
        </>
    );
}

export default ClientCalendar;
