import React from "react";
import "./card.scss";

/**
 * Functional component to return a card for showing ont he front-end
 */
function Card(props) {

    return (
        <div className={["ui-card", props.className].join(" ")}>
            {props.actions?.length > 0 &&
                <div className="card-actions">

                </div>
            }

            {props.children}
        </div>
    )
}

export default Card;