import React, { useState } from "react";
import Button from "../../components/ui/button/button-new";
import Checkbox from "../../components/ui/inputs/checkbox";
import { auth, db } from "../../utils/firebase";
import firebase from "firebase";
import "./parq.scss";
import Textarea from "../../components/ui/inputs/textarea";

export default function ParQ() {
    const [saving, setSaving] = useState(false);

    const [checked, setChecked] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [checked3, setChecked3] = useState(false);
    const [checked4, setChecked4] = useState(false);
    const [checked5, setChecked5] = useState(false);
    const [checked6, setChecked6] = useState(false);
    const [checked7, setChecked7] = useState(false);

    const [moreDetails, setMoreDetails] = useState("");

    const savePARQForm = async () => {
        setSaving(true);

        const parq = [{
            question: "Has your doctor ever said you have a heart condition and that you should only do physical activity recommended by a doctor?",
            answer: checked,
        }, {
            question: "Do you feel pain in your chest when you do physical activity?",
            answer: checked2,
        }, {
            question: "In the past month, have you had a chest pain when you were not doing physical activity?",
            answer: checked3,
        }, {
            question: "Do you lose balance because of dizziness or do you ever lose consciousness?",
            answer: checked4,
        }, {
            question: "Do you have a bone or joint problem (for example back, knee or hip) that could be made worse by a change in your physical activity?",
            answer: checked5,
        }, {
            question: "Is your doctor currently prescribing medication for your blood pressure or heart condition?",
            answer: checked6,
        }, {
            question: "Do you know of any other reason why you should not take part in physical activity?",
            answer: checked7,
        }];

        await db.doc(`clients/${auth.currentUser.uid}`).set({
            parq: {
                questions: parq,
                completed: firebase.firestore.FieldValue.serverTimestamp(),
                comments: moreDetails || "",
            },
        }, { merge: true });
    }

    return (
        <>
            <div className="page-title">
                <h1>Physical Activity Readiness Questionnaire</h1>
                <p>We need to make sure you're in good health before engaging with a FitPortal trainer to ensure physical saftey for everyone in our community, so please answer the questions below accurately before continuing to the app.</p>
            </div>

            <div className="parq-form">
                <div className="parq-question headings">
                    <p></p>
                    <p>Yes</p>
                    <p>No</p>
                </div>

                <div className="parq-question">
                    <p>Has your doctor ever said you have a heart condition and that you should only do physical activity recommended by a doctor?</p>
                    <Checkbox checked={checked} onClick={() => setChecked(!checked)} />
                    <Checkbox checked={!checked} onClick={() => setChecked(!checked)} />
                </div>

                <div className="parq-question">
                    <p>Do you feel pain in your chest when you do physical activity?</p>
                    <Checkbox checked={checked2} onClick={() => setChecked2(!checked2)} />
                    <Checkbox checked={!checked2} onClick={() => setChecked2(!checked2)} />
                </div>

                <div className="parq-question">
                    <p>In the past month, have you had a chest pain when you were not doing physical activity?</p>
                    <Checkbox checked={checked3} onClick={() => setChecked3(!checked3)} />
                    <Checkbox checked={!checked3} onClick={() => setChecked3(!checked3)} />
                </div>

                <div className="parq-question">
                    <p>Do you lose balance because of dizziness or do you ever lose consciousness?</p>
                    <Checkbox checked={checked4} onClick={() => setChecked4(!checked4)} />
                    <Checkbox checked={!checked4} onClick={() => setChecked4(!checked4)} />
                </div>

                <div className="parq-question">
                    <p>Do you have a bone or joint problem (for example back, knee or hip) that could be made worse by a change in your physical activity?</p>
                    <Checkbox checked={checked5} onClick={() => setChecked5(!checked5)} />
                    <Checkbox checked={!checked5} onClick={() => setChecked5(!checked5)} />
                </div>

                <div className="parq-question">
                    <p>Is your doctor currently prescribing medication for your blood pressure or heart condition?</p>
                    <Checkbox checked={checked6} onClick={() => setChecked6(!checked6)} />
                    <Checkbox checked={!checked6} onClick={() => setChecked6(!checked6)} />
                </div>

                <div className="parq-question">
                    <p>Do you know of any other reason why you should not take part in physical activity?</p>
                    <Checkbox checked={checked7} onClick={() => setChecked7(!checked7)} />
                    <Checkbox checked={!checked7} onClick={() => setChecked7(!checked7)} />

                    {checked7 &&
                        <div className="more-parq-details">
                            <Textarea
                                rows={4}
                                value={moreDetails}
                                onChange={(e) => setMoreDetails(e.target.value)}
                                placeholder="Please provide some more details..." />
                        </div>
                    }
                </div>

                <Button
                    label="Save answers"
                    loading={saving}
                    loadingText="Saving your answers..."
                    disabled={checked7 && (moreDetails.length === 0)}
                    onClick={() => savePARQForm()} />
            </div>
        </>
    );
}