import React from "react";

function CardTitle(props) {
    return (
        <div className="ui-card-title">
            {props.children}
        </div>
    );
}

export default CardTitle;