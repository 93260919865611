import React, { useContext, useState, useEffect } from "react";
import { EventContext } from "../../../../../utils/providers/event";

/**
 * UI components
 */
import Button from "../../../../ui/button/button";
import Participant from "./participant/participant";

/**
 * Functional component to return the goals for each participant assigned
 */
function Goals() {
    const [goals, setGoals] = useState([]);

    /**
     * Deconstruct variables from the add event context
     */
    const {
        setStage,
        participants,
        clientGoals, setClientGoals,
    } = useContext(EventContext);

    /**
     * Push the selected goal to the local state with the clientID as the key
     */
    const pushUserGoal = (clientGoal) => {
        setGoals((goals) => ({
            ...goals,
            [clientGoal.client]: {
                client: clientGoal.client,
                goal: clientGoal.goal,
                contribution: clientGoal.contribution,
            }
        }));
    }

    /**
     * Push the contribution value to the local state
     */
    const pushUserGoalValue = (goalValue) => {
        setGoals((goals) => ({
            ...goals,
            [goalValue.client]: {
                client: goalValue.client,
                goal: goalValue.goal,
                contribution: goalValue.contribution,
            }
        }));
    }

    /**
     * When the local goals array is updated
     */
    useEffect(() => {
        /**
         * Push them into the context
         */
        setClientGoals(goals);
    }, [goals]);

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                {(participants.length === 0) &&
                    <p className="no-client-for-goals">No clients to add goals for</p>
                }

                {participants.map((participant) =>
                    <Participant
                        key={participant.option}
                        id={participant.option}
                        clientGoals={clientGoals}
                        pushGoal={(clientGoal) => pushUserGoal(clientGoal)}
                        pushGoalValue={(goalValue) => pushUserGoalValue(goalValue)} />
                )}
            </div>

            <Button
                class="primary"
                onClick={() => setStage(5)}>
                Continue to Save
            </Button>
        </div>
    );
}

export default Goals;