import React from "react";

import { UserIcon, UsersIcon } from "../../utils/svgs";

function AccountType(props) {
    return (
        <>
            <div className="account-type-card" onClick={() => props.setAccountType("TRAINER")}>
                <div className="type-logo">
                    <UsersIcon />
                </div>
                <div className="type-text">
                    <h5>Trainer</h5>
                    <h6>I'm signing up to service my clients</h6>
                </div>
                <div className="type-arrow">

                </div>
            </div>

            <div className="account-type-card" onClick={() => props.setAccountType("CLIENT")}>
                <div className="type-logo">
                    <UserIcon />
                </div>
                <div className="type-text">
                    <h5>Client</h5>
                    <h6>I'm signing up to find a personal trainer</h6>
                </div>
                <div className="type-arrow">

                </div>
            </div>
        </>
    );
}

export default AccountType;