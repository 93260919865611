import React, { useState } from "react";
import { ProgramProvider } from "../../utils/providers/program";
import "./programs.scss";

/**
 * UI components
 */
import Button from "../../components/ui/button/button";
import Modal from "../../components/modal/modal";
import AddProgram from "../../components/program/add/add";

/**
 * Functional component to return the programs page for the trainers
 */
function Programs() {
    const [addingProgram, setAddingProgram] = useState(false);

    return (
        <>
            <div className="page-title">
                <div className="page-title-flex with-margin">
                    <h1>My Programs</h1>
                    <Button
                        class="primary"
                        onClick={() => setAddingProgram(true)}>
                        Setup New Program
                    </Button>
                </div>
                <p>Setup programs using the meals and workouts you've previously setup, and sell them directly to FitPortal clients for them to add to their calendars.</p>
            </div>

            {/* Are we adding a new program */}
            {addingProgram &&
                <Modal
                    title="Setup New Program"
                    hide={() => setAddingProgram(false)}
                    className="with-nav no-pad">
                    <ProgramProvider>
                        <AddProgram close={() => setAddingProgram(false)} />
                    </ProgramProvider>
                </Modal>
            }
        </>
    );
}

export default Programs;