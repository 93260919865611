import React, { useState, createContext } from "react";

/**
 * Setup a context for storing the "add goal" modal objects
 */
const GoalContext = createContext();

/**
 * Creating the provider to wrap the add goal modal in for displaying
 */
function GoalProvider({ children }) {
    const [stage, setStage] = useState(1);
    const [title, setTitle] = useState("");
    const [target, setTarget] = useState("");
    const [unit, setUnit] = useState({});
    const [period, setPeriod] = useState({});

    return (
        <GoalContext.Provider value={{
            stage, setStage,
            title, setTitle,
            target, setTarget,
            unit, setUnit,
            period, setPeriod,
        }}>
            {children}
        </GoalContext.Provider>
    );
};

export { GoalContext, GoalProvider };