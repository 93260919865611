import React, { useEffect, useState } from 'react';
import { WorkoutProvider } from '../../../utils/providers/workout';
import { EditWorkoutProvider } from '../../../utils/providers/workout-edit';
import { db, auth } from "../../../utils/firebase";

/**
 * UI components
 */
import Button from "../../../components/ui/button/button";
import Modal from "../../../components/modal/modal";
import AddWorkout from '../../../components/workouts/add/add';
import Workout from '../../../components/workouts/workout';
import EditWorkout from '../../../components/workouts/edit/edit';

/**
 * 
 */
function TrainerWorkouts() {
    const [workoutModal, setWorkoutModal] = useState(false);
    const [workouts, setWorkouts] = useState([]);

    const [editingWorkout, setEditingWorkout] = useState("");

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup a listener on the workouts collection for this user
         */
        const unsubscribe = db.collection("workouts")
            .where("created_by", "==", auth.currentUser.uid)
            .where("created_for", "==", null)
            .onSnapshot((workoutsSnap) => {
                /**
                 * Loop through the workouts found
                 */
                workoutsSnap.docChanges().forEach((change) => {
                    /**
                     * Added
                     */
                    if (change.type === "added") {
                        if (!change.doc.data().archived) {
                            setWorkouts((workouts) => [...workouts, {
                                id: change.doc.id,
                                ...change.doc.data(),
                            }]);
                        }
                    }
                    /**
                     * Modified
                     */
                    if (change.type === "modified") {
                        setWorkouts((workouts) => {
                            let updatedWorkouts = [...workouts];
                            for (let i in workouts) {
                                console.log(workouts[i].id === change.doc.id)
                                if (workouts[i].id === change.doc.id) {
                                    updatedWorkouts[i] = {
                                        id: change.doc.id,
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedWorkouts;
                        });
                    }
                    /**
                     * Removed
                     */
                    if (change.type === "removed") {
                        setWorkouts((workouts) => workouts.filter((workout) => workout.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);

    return (
        <>
            <div className="page-title">
                <div className="page-title-flex with-margin">
                    <h1>Workouts</h1>
                    <Button class="primary" onClick={() => setWorkoutModal(true)}>Create Workout</Button>
                </div>
                <p>Create workouts and use them to assign to clients.</p>
            </div>

            <div className="workout-cards">
                {workouts.map((workout) => (
                    <Workout
                        key={workout.id}
                        id={workout.id}
                        editWorkout={(workoutID) => setEditingWorkout(workoutID)} />
                ))}
            </div>

            {editingWorkout &&
                <Modal
                    title="Edit workout"
                    hide={() => setEditingWorkout("")}
                    className="with-nav large no-pad">
                    <EditWorkoutProvider>
                        <EditWorkout
                            workoutID={editingWorkout}
                            hide={() => setEditingWorkout("")} />
                    </EditWorkoutProvider>
                </Modal>
            }

            {workoutModal &&
                <Modal
                    title="Create workout"
                    hide={() => setWorkoutModal(false)}
                    className="with-nav large no-pad">
                    <WorkoutProvider>
                        <AddWorkout close={() => setWorkoutModal(false)} />
                    </WorkoutProvider>
                </Modal>
            }
        </>
    );
}

export default TrainerWorkouts;