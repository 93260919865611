import React, { useEffect, useState } from "react";
import { auth, db } from "../../../../utils/firebase";

/**
 * Functional component to return the participant 
 */
function Participant(props) {
    const [user, setUser] = useState({});

    /**
     * Deconstruct the userID from the props
     */
    const { userID } = props;

    /**
     * On component load
     */
    useEffect(() => {
        db.doc(`clients/${userID}`)
            .get().then((clientDoc) => {
                if (clientDoc.exists) {
                    setUser(clientDoc.data());
                }
            });

        db.doc(`trainers/${userID}`)
            .get().then((trainerDoc) => {
                if (trainerDoc.exists) {
                    setUser(trainerDoc.data());
                }
            });
    }, []);

    if (user.first_name && user.last_name) {
        return (
            <li>{user.first_name} {user.last_name} {auth.currentUser.uid === userID ? "(you)" : ""}</li>
        );
    } else {
        return (
            <li>{user.email} {auth.currentUser.uid === userID ? "(you)" : ""}</li>
        );
    }
}

export default Participant;