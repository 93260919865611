import React, { useState, createContext } from "react";

/**
 * Setup a context for storing the messages modal object
 */
const MessagesContext = createContext();

/**
 * Creating the provider to wrap the messages modal in for displaying
 * 
 * @param {Object} params
 * @returns Messages provider to wrap the modal in
 */
const MessagesProvider = ({ children }) => {
    const [messagesShowing, setMessagesShowing] = useState(false);
    const [activeChat, setActiveChat] = useState("");
    const [hasUnread, setHasUnread] = useState(false);

    return <MessagesContext.Provider value={{ messagesShowing, setMessagesShowing, activeChat, setActiveChat, hasUnread, setHasUnread }}>{children}</MessagesContext.Provider>;
};

export { MessagesContext, MessagesProvider };