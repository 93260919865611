import React, { useState } from "react";
import firebase from "firebase";
import { auth, db } from "../../../utils/firebase";
import moment from "moment";
import "./record.scss";

/**
 * UI components
 */
import Input from "../../ui/inputs/input";
import DatePicker from "../../ui/inputs/datetime";
import Button from "../../ui/button/button";

/**
 * 
 */
function Record(props) {
    const [value, setValue] = useState("");
    const [date, setDate] = useState({})

    /**
     * Deconstruct the metric data from the props
     */
    const { metricID, unit } = props;

    /**
     * Save the record for the given metric into the "records" subcollection
     */
    const saveMetricRecord = async () => {
        /**
         * Get the record date as milliseconds
         */
        const recordAsMillis = moment(date).format("x");
        /**
         * Add the new metric record into the database
         */
        await db.collection(`clients/${auth.currentUser.uid}/metrics/${metricID}/records`).add({
            value,
            recorded_on: firebase.firestore.FieldValue.serverTimestamp(),
            recorded_for: firebase.firestore.Timestamp.fromMillis(recordAsMillis),
            recorded_by: auth.currentUser.uid,
        });
        /**
         * Hide the modal
         */
        props.hide();
        /**
         * Reset the fields
         */
        setValue("");
        setDate({});
    }

    return (
        <div className="metric-record-inputs">
            <DatePicker
                label="Recorded Date:"
                value={date}
                onChange={setDate}
                wrapperClass="fw"
                placeholder="When is this record for?" />

            <Input
                type="number"
                label="Value:"
                value={value}
                placeholder="e.g. 30"
                wrapperClass="fw"
                onChange={(e) => setValue(e.target.value)} />

            {(value) &&
                <p className="recording-for">You are recording a metric value of {value}{unit} for {moment(date).format("DD/MM/YYYY HH:mma")}</p>
            }

            <Button
                class="primary"
                disabled={!value || !date}
                onClick={() => saveMetricRecord()}>
                Save Record
            </Button>
        </div>
    );
}

export default Record;