import React, { useContext, useEffect, useState } from "react";
import { MealContext } from "../../../../utils/providers/meal";

/**
 * UI components
 */
import Input from "../../../ui/inputs/input";
import Button from "../../../ui/button/button";

function Tags(props) {
    const [localTags, setLocalTags] = useState([]);
    const [tag, setTag] = useState("");

    /**
     * Deconstruct variables from the add meal context
     */
    const {
        setStage,
        tags, setTags,
    } = useContext(MealContext);

    /**
    * When this page first loads in, pull the current ingredients from the context
    */
    useEffect(() => {
        setLocalTags(tags);
    }, []);

    /**
     * Save the new tag into the local state
     */
    const saveInstruction = () => {
        /**
         * Do we have an tag present in the field
         */
        if (tag) {
            /**
             * Push the tag into the local state
             */
            setLocalTags((localTags) => [...localTags, tag]);
            /**
             * Reset the fields
             */
            setTag("");
        }
    }

    /**
     * Remove an tag from the local array via the name passed in
     */
    const removeTag = (name) => {
        /**
         * Remove the tag from the array
         */
        setLocalTags(localTags.filter(tag => tag !== name));
    }

    /**
     * Listen for the local instruction to be updated
     */
    useEffect(() => {
        /**
         * Push them into the context when there is an update
         */
        setTags(localTags);
    }, [localTags]);

    /**
     * Key down event for the message chat inputs, checks for an "Enter" key to add the tag
     */
    const checkForEnterKey = (key) => {
        /**
         * If the key pressed was enter
         */
        if (key === "Enter") {
            /**
             * Add the new tag to the array
             */
            saveInstruction();
        }
    };

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <Input
                    type="text"
                    label="Meal Tags:"
                    placeholder="e.g. Vegan"
                    onChange={(e) => setTag(e.target.value)}
                    onKeyDown={(e) => checkForEnterKey(e.key)}
                    value={tag}
                    note="Start typing and press enter to add to the tags" />

                <div className="tags-list">
                    {tags.map((tag, index) => (
                        <p className="list-item-tag" key={index}>
                            {tag}
                            <span onClick={() => removeTag(tag)}>
                                x
                            </span>
                        </p>
                    ))}
                </div>
            </div>

            <Button
                class="primary"
                onClick={() => setStage(5)} >
                Continue to Save
            </Button>
        </div>
    );
}

export default Tags;