import React, { useContext } from "react";
import { AuthContext } from "../../utils/providers/auth";
import { auth } from "../../utils/firebase";

/**
 * Message types
 */
import MessagePriceOffer from "./types/price-offer";
import MessagePriceAccepted from "./types/price-accepted";
import MessagePaymentComplete from "./types/payment-successful";
import MessageSubscriptionSetup from "./types/subscription-setup";
import MessageSubscriptionAccepted from "./types/subscription-accepted";

/**
 * Message element HTML
 */
function Message(props) {
    /**
     * Deconstruct the user object from the auth context
     */
    const { user } = useContext(AuthContext);

    /**
     * Deconstruct the message data from the props
     */
    const message_data = props.data;

    /**
     * Convert the firestore timestamp into the date
     */
    const date = message_data.timestamp?.toDate() || false;

    /**
     * Print the appropriate message element
     */
    if (message_data.type === "PRICE_OFFER") {
        /**
         * Price offer messages
         */
        return <MessagePriceOffer message_data={message_data} chat={props.chat} />;
    } else if ((user?.is_trainer && message_data.type === "PRICE_ACCEPTED")) {
        /**
         * Is the current user a trainer account and has the price been accepted
         */
        return <MessagePriceAccepted message_data={message_data} chat={props.chat} />;
    } else if ((user?.is_trainer && message_data.type === "PAYMENT_SUCCESSFUL")) {
        /**
         * Is the current user a trainer account and is the message a successful payment notification
         */
        return <MessagePaymentComplete message_data={message_data} chat={props.chat} />;
    } else if ((user?.is_trainer && message_data.type === "SUBSCRIPTION_ACCEPTED")) {
        /**
         * Is the current user a trainer account and is the message a successful payment notification
         */
        return <MessageSubscriptionAccepted message_data={message_data} chat={props.chat} />;
    } else if ((user?.is_trainer && message_data.type === "SUBSCRIPTION_SETUP")) {
        /**
         * Is the current user a trainer account and is the message a successful payment notification
         */
        return <MessageSubscriptionSetup message_data={message_data} chat={props.chat} />;
    } else if (message_data.type === "MESSAGE") {
        /**
         * Normal messages
         */
        return (
            <div className={["message-element", auth.currentUser.uid === message_data.user ? "this-user" : ""].join(" ")}>
                <p>{message_data.message}</p>
                {date &&
                    <small>
                        {date.getHours()}:{date.getMinutes()}:{date.getSeconds()}
                    </small>
                }
            </div>
        );
    } else {
        /**
         * If none of the above conditions match, return a null to print nothing
         */
        return null;
    }
}

export default Message;
