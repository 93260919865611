import React, { useRef, useState, useContext } from "react";
import { AuthContext } from "../../utils/providers/auth";
import { auth, db } from "../../utils/firebase";
import { UploadIcon } from "../../utils/svgs";

/**
 * Styles
 */
import "./profilePicture.scss";

/**
 * Hooks
 */
import useStorage from "../../hooks/useStorage";

/**
 *
 */
function ProfilePicture(props) {
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);

    const { user } = useContext(AuthContext);

    const fileUploadRef = useRef(null);

    /**
     * Listen for the upload button to be clicked
     */
    const uploadButtonClicked = () => {
        /**
         * Run a click event on the file input
         */
        fileUploadRef.current.click();
    };

    /**
     * Check the file to ensure it matches the required parameters before the upload starts
     */
    const checkFile = () => {
        const types = ["image/png", "image/jpeg", "image/jpg"];
        return true;
    };

    /**
     * Handle the change event on the file inputfield
     */
    const handleInputChange = async (e) => {
        /**
         * Get the first file from the input array
         */
        const selectedFile = e.target.files[0];
        /**
         * Make sure there was a file chosen
         */
        if (selectedFile) {
            /**
             * Run the checks on the file
             */
            const allowed = await checkFile();
            /**
             * Is the file allowed? If so, set it to state and start the upload
             */
            if (allowed) {
                /**
                 * Set the file
                 */
                setFile(selectedFile);
            }
        }
    };

    /**
     * Use the storage hook to upload the file
     */
    let { progress, url } = useStorage(file, "PROFILE", ["users", auth.currentUser.uid], { userID: auth.currentUser.uid });

    /**
     * If there is then a download URL available for the file
     */
    if (url) {
        if (user?.is_client) {
            /**
             * Set the downloadURL for the original to the users database document
             */
            db.doc(`clients/${auth.currentUser.uid}`).set({
                picture: { original: url },
            }, { merge: true });
        } else if (user?.is_trainer) {
            /**
             * Set the downloadURL for the original to the users database document
             */
            db.doc(`trainers/${auth.currentUser.uid}`).set({
                picture: { original: url },
            }, { merge: true });
        }
        /**
         * Reset the variables to avoid re-renders
         */
        url = "";
        progress = 0;
    }

    return (
        <div className="profile-picture-holder">
            {/* Display the users current profile image or a placeholder */}
            <div className="current-profile-picture">
                {/* Does the user have a profile image saved */}
                {user?.picture ? (
                    <>
                        {/* Load the thumbnail version of the users profile picture */}
                        <img src={user.picture?._256 ?? user.picture.original} alt={`${user?.firstName}'s FitPortal Profile`} />
                    </>
                ) : (
                    <>
                        {/* Show the initials of the user of the coloured background */}
                        <div className="navigation-profile-initials">
                            {user?.firstName?.substring(0, 1)}
                            {user?.lastName?.substring(0, 1)}
                        </div>
                    </>
                )}
                {progress && <div className="profile-image-upload-progress">{progress}</div>}
            </div>
            {/* Upload toggle button to show file browser */}
            <div className="upload-picture-toggle" onClick={() => uploadButtonClicked()}>
                <UploadIcon />
            </div>
            {/* Input field, hidden by default */}
            <input
                type="file"
                ref={fileUploadRef}
                onChange={handleInputChange}
                style={{ display: "none" }}
                accept="image/png, image/jpeg, image/jpg" />
        </div>
    );
}

export default ProfilePicture;
