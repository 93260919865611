import React, { useEffect, useState } from "react";
import { db } from "../../../utils/firebase";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { EventProvider } from "../../../utils/providers/event";
import moment from "moment";

/**
 * UI components
 */
import Modal from "../../modal/modal";
import AddEventTrainer from "../../events/add/trainer/add";

/**
 * Setup the localiser for the calendar
 */
const localizer = momentLocalizer(moment);

/**
 * Functional component to return the calendar for the given client
 */
function ClientCalendar(props) {
    const [events, setEvents] = useState([]);
    const [eventModal, setEventModal] = useState(false);
    const [startingDate, setStartingDate] = useState({});

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup a listener on the events collection to stream the events for this client
         */
        const unsubscribe = db.collection("events")
            .where("users", "array-contains", props.userID)
            .onSnapshot((eventsSnap) => {
                /**
                 * Loop over each of the events and parse them into an array to load into state
                 */
                eventsSnap.docChanges().forEach((change) => {
                    /**
                     * Deconstruct the data from the update call
                     */
                    const event_data = change.doc.data();
                    /**
                     * Event added
                     */
                    if (change.type === "added") {
                        setEvents((event) => [
                            ...event,
                            {
                                id: change.doc.id,
                                start: moment(event_data.starts.seconds, "X").toDate(),
                                end: moment(event_data.ends.seconds, "X").toDate(),
                                ...change.doc.data(),
                            }
                        ]);
                    }
                    /**
                     * Event updated
                     */
                    if (change.type === "modified") {
                        setEvents((event) => {
                            let updatedEvents = [...event];
                            for (let i in event) {
                                if (event[i].id === change.doc.id) {
                                    updatedEvents[i] = {
                                        id: change.doc.id,
                                        start: moment(event_data.starts.seconds, "X").toDate(),
                                        end: moment(event_data.ends.seconds, "X").toDate(),
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedEvents;
                        });
                    }
                    /**
                     * Event removed
                     */
                    if (change.type === "removed") {
                        setEvents((event) => event.filter((eventElement) => eventElement.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);

    /**
     * Load the view modal for the given event
     */
    const viewEvent = (event) => {
        /**
         * Load the event into the view modal
         */
        props.viewEvent(event);
    }

    /**
    * When a day is selected from the calendar
    */
    const onSlotSelect = (event) => {
        /**
         * Push the starting date for the selected day to the local state
         */
        setStartingDate(event.start);
        /**
         * Show the event modal
         */
        setEventModal(true);
    }

    return (
        <>
            <Calendar
                selectable={true}
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ flex: "1 1" }}
                onSelectEvent={(event) => viewEvent(event)}
                onSelectSlot={(event) => onSlotSelect(event)} />

            {/* Are we adding a new event */}
            {eventModal &&
                <Modal
                    title="Create a new event"
                    hide={() => setEventModal(false)}
                    className="with-nav no-pad no-bg">
                    <EventProvider>
                        <AddEventTrainer
                            startingDate={startingDate}
                            client={props.userID}
                            close={() => setEventModal(false)} />
                    </EventProvider>
                </Modal>
            }
        </>
    );
}

export default ClientCalendar;
