import React, { useContext } from "react";
import { ProgramContext } from "../../../../utils/providers/program";

/**
 * UI components
 */
import Input from "../../../ui/inputs/input";
import Textarea from "../../../ui/inputs/textarea";
import Button from "../../../ui/button/button";

/**
 * Functional component to return the details panel for adding a new program
 */
function Scheduling() {
    /**
     * Deconstruct variables from the add meal context
     */
    const {
        setStage,
    } = useContext(ProgramContext);

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">

            </div>

            <Button
                class="primary"
                onClick={() => setStage(5)}>
                Continue to Save
            </Button>
        </div>
    );
}

export default Scheduling;