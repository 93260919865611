import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { db, auth, arrayUnion } from "../../../utils/firebase";

/**
 * UI components
 */
import WorkoutParticipants from "../../../components/workouts/user";
import Modal from "../../../components/modal/modal";
import Button from "../../../components/ui/button/button";
import Input from '../../../components/ui/inputs/input';
import Select from '../../../components/ui/inputs/select/new-select';
import Textarea from '../../../components/ui/inputs/textarea';
import Checkbox from '../../../components/ui/inputs/checkbox';
import DatePicker from "../../../components/ui/inputs/datetime";

const moment = require("moment");

/**
 *
 */
function ClientWorkouts() {
    const [showingWorkout, setShowingWorkout] = useState(false);
    const [upcomingWorkouts, setUpcomingWorkouts] = useState([]);
    const [previousWorkouts, setPreviousWorkouts] = useState([]);
    const [activeWorkout, setActiveWorkout] = useState({});
    const [activeParticipants, setActiveParticipants] = useState([]);
    const [activeSetupBy, setActiveSetupBy] = useState("");
    const [duplicatingEvent, setDuplicatingEvent] = useState(false);
    const [eventTitle, setEventTitle] = useState("");
    const [eventDate, setEventDate] = useState(null);
    const [eventDateEnds, setEventDateEnds] = useState(null);
    const [eventComments, setEventComments] = useState("");
    const [eventAllDay, setEventAllDay] = useState(false);
    const [eventContributesGoal, setEventContributesGoal] = useState(false);
    const [eventGoalContribution, setEventGoalContribution] = useState(0);
    const [contributesToWhatGoal, setContributesToWhatGoal] = useState(null);
    const [errors, setErrors] = useState({});
    const [eventSaving, setEventSaving] = useState(false);
    const [editingEvent, setEditingEvent] = useState(false);
    const [activeEvent, setActiveEvent] = useState("");
    const [userGoals, setUserGoals] = useState({});
    const [eventParticipants, setEventParticipants] = useState([]);
    const [eventParticipantIDs, setEventParticipantIDs] = useState([]);

    /**
     * On component load
     */
    useEffect(() => {
        setupUpcomingEventsListener();
        setupPreviousEventsListener();
    }, []);

    useEffect(() => {
        db.collection(`clients/${auth.currentUser.uid}/goals`).get().then((userGoals) => {
            userGoals.forEach((userGoal) => {
                setUserGoals((goals) => {
                    goals[userGoal.id] = userGoal.data().title;
                    return goals;
                })
            });
        });
    }, []);

    /**
     * Get the current time as a unix timestamp
     */
    const current_timestamp = firebase.firestore.Timestamp.fromMillis(moment().format("x"));

    /**
     * Seutp a listener on the database to pull upcoming events
     */
    const setupUpcomingEventsListener = () => {
        db.collection("events")
            .where("users", "array-contains", auth.currentUser.uid)
            .where("starts", ">=", current_timestamp)
            .orderBy("starts", "asc")
            .onSnapshot((events_snap) => {
                /**
                 * Loop over each of the events and parse them into an array to load into state
                 */
                events_snap.docChanges().forEach((change) => {
                    /**
                     * Deconstruct the data from the update call
                     */
                    const event_data = change.doc.data();
                    /**
                     * Event added
                     */
                    if (change.type === "added") {
                        setUpcomingWorkouts((event) => [
                            ...event,
                            {
                                id: change.doc.id,
                                start: moment(event_data.starts.seconds, "X").format("DD/MM/YYYY"),
                                end: moment(event_data.ends.seconds, "X").format("DD/MM/YYYY"),
                                ...change.doc.data(),
                            },
                        ]);
                    }
                    /**
                     * Event updated
                     */
                    if (change.type === "modified") {
                        setUpcomingWorkouts((event) => {
                            let updatedEvents = [...event];
                            for (let i in event) {
                                if (event[i].id === change.doc.id) {
                                    updatedEvents[i] = {
                                        id: change.doc.id,
                                        start: moment(event_data.starts.seconds, "X").format("DD/MM/YYYY"),
                                        end: moment(event_data.ends.seconds, "X").format("DD/MM/YYYY"),
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedEvents;
                        });
                    }
                    /**
                     * Event removed
                     */
                    if (change.type === "removed") {
                        setUpcomingWorkouts((event) => event.filter((eventElement) => eventElement.id !== change.doc.id));
                    }
                });
            });
    };

    /**
     * Seutp a listener on the database to pull previous events
     */
    const setupPreviousEventsListener = () => {
        db.collection("events")
            .where("users", "array-contains", auth.currentUser.uid)
            .where("starts", "<", current_timestamp)
            .orderBy("starts", "desc")
            .onSnapshot((events_snap) => {
                /**
                 * Loop over each of the events and parse them into an array to load into state
                 */
                events_snap.docChanges().forEach((change) => {
                    /**
                     * Deconstruct the data from the update call
                     */
                    const event_data = change.doc.data();
                    /**
                     * Event added
                     */
                    if (change.type === "added") {
                        setPreviousWorkouts((event) => [
                            ...event,
                            {
                                id: change.doc.id,
                                start: moment(event_data.starts.seconds, "X").format("DD/MM/YYYY"),
                                end: moment(event_data.ends.seconds, "X").format("DD/MM/YYYY"),
                                ...change.doc.data(),
                            },
                        ]);
                    }
                    /**
                     * Event updated
                     */
                    if (change.type === "modified") {
                        setPreviousWorkouts((event) => {
                            let updatedEvents = [...event];
                            for (let i in event) {
                                if (event[i].id === change.doc.id) {
                                    updatedEvents[i] = {
                                        id: change.doc.id,
                                        start: moment(event_data.starts.seconds, "X").format("DD/MM/YYYY"),
                                        end: moment(event_data.ends.seconds, "X").format("DD/MM/YYYY"),
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedEvents;
                        });
                    }
                    /**
                     * Event removed
                     */
                    if (change.type === "removed") {
                        setPreviousWorkouts((event) => event.filter((eventElement) => eventElement.id !== change.doc.id));
                    }
                });
            });
    };

    const showWorkoutDetails = (workoutID, type) => {
        if (type === "PREVIOUS") {
            for (let i in previousWorkouts) {
                if (previousWorkouts[i].id === workoutID) {
                    setActiveWorkout(previousWorkouts[i]);
                }
            }
        } else if (type === "UPCOMING") {
            for (let i in upcomingWorkouts) {
                if (upcomingWorkouts[i].id === workoutID) {
                    setActiveWorkout(upcomingWorkouts[i]);
                }
            }
        }

        setShowingWorkout(true);
    }

    useEffect(() => {
        if (activeWorkout) {
            activeWorkout.users?.forEach((user) => {
                db.doc(`clients/${user}`).get().then((userDoc) => {
                    if (userDoc.exists) {
                        const { first_name, last_name } = userDoc.data();
                        setActiveParticipants((activeParticipants) => [...activeParticipants, `${first_name} ${last_name}`]);
                    }
                });
            });

            db.doc(`trainers/${activeWorkout.setup_by}`).get().then((userDoc) => {
                if (userDoc.exists) {
                    const { first_name, last_name } = userDoc.data();
                    setActiveSetupBy(`${first_name} ${last_name}`);
                }
            });

            setActiveEvent(activeWorkout.id);
            setEventTitle(activeWorkout.title);
            setEventComments(activeWorkout.comments);
            setEventAllDay(activeWorkout.all_day);
            setEventContributesGoal(activeWorkout.contributes_goal);
            setEventGoalContribution(activeWorkout.goal_contribution);
            setContributesToWhatGoal({ option: activeWorkout.contributes_to_goal });
            setEventDate(moment(activeWorkout.starts?.seconds, "X").toDate());
            setEventDateEnds(moment(activeWorkout.ends?.seconds, "X").toDate());
            setEventParticipants(activeWorkout.participants);
            setEventParticipantIDs(activeWorkout.users);
        }
    }, [activeWorkout]);

    /**
     * Save the  event to users calendar
     *
     * @type {const}
     */
    const saveEvent = async () => {
        /**
         * Reset the errors
         */
        setErrors({});
        /**
         * Work out if we need an end date or not
         */
        const requires_end = (eventAllDay === false);
        const has_end = (eventDateEnds ? true : false);
        const conditions_met = (requires_end ? has_end ? true : false : true);
        /**
         * Make sure there is a title and a date/time
         */
        if (eventTitle && eventDate && conditions_met) {
            /**
             * Set the state to saving
             */
            setEventSaving(true);
            /**
             * Get the starts at and ends at timestamps as milliseconds
             */
            const starts_at = moment(eventDate).format("x");
            /**
             * We may need to adjust the ends at value here if it's been marked as all day
             */
            let ends_at = "";
            /**
             * Was the event marked as all day?
             */
            if (eventAllDay) {
                /**
                 * Set the end date to the same as teh start to show as all day on teh calendar
                 */
                ends_at = moment(eventDate).format("x");
            } else {
                /**
                 * Otherwise use the ends at datetime
                 */
                ends_at = moment(eventDateEnds).format("x");
            }

            await db.collection("events/").add({
                title: eventTitle,
                comments: eventComments,
                all_day: eventAllDay,
                contributes_goal: eventContributesGoal,
                goal_contribution: eventGoalContribution,
                contributes_to_goal: contributesToWhatGoal?.option || false,
                users: arrayUnion(auth.currentUser.uid),
                starts: firebase.firestore.Timestamp.fromMillis(starts_at),
                ends: firebase.firestore.Timestamp.fromMillis(ends_at),
                created: firebase.firestore.FieldValue.serverTimestamp(),
                setup_by: auth.currentUser.uid,
            });
            /**
             * Reset the state and hide the adding modal
             */
            setDuplicatingEvent(false);
            setShowingWorkout(false)
            setEventSaving(false);
            setEventTitle("");
            setEventComments("");
            setEventAllDay(false);
            setEventContributesGoal(false);
            setEventGoalContribution(0);
            setContributesToWhatGoal("");
            setEventDate(null);
            setEventDateEnds(null);
        } else {
            /**
             * Show an error
             */
            if (!eventTitle) {
                setErrors((errors) => ({
                    ...errors,
                    eventTitle: "Please enter a title for the event"
                }));
            }
            if (!eventDate) {
                setErrors((errors) => ({
                    ...errors,
                    eventDate: "Please choose a date and time for the event"
                }));
            }
            if (!eventAllDay && !eventDateEnds) {
                setErrors((errors) => ({
                    ...errors,
                    eventDateEnds: "Please choose an end date and time for the event"
                }));
            }
        }
    }

    return (
        <>
            <div className="page-title">
                <h1>My Workouts</h1>
                <p>Track previous workouts setup by you or a trainer you are working with.</p>
            </div>
            <div className="workout-columns">
                <div className="upcoming-workouts">
                    <h2>Upcoming Workouts</h2>
                    <div className="workouts-grid">
                        {upcomingWorkouts.length > 0 &&
                            upcomingWorkouts.map((workout) => (
                                <div key={workout.id} className="workout-card" onClick={() => showWorkoutDetails(workout.id, "UPCOMING")}>
                                    <div className="workout-card-title">
                                        <p>{workout.title}</p>
                                        <small>{moment.unix(workout.starts.seconds).format("DD/MM/YYYY [at] HH:mm")}</small>
                                    </div>
                                    {workout.users?.length > 1 && (
                                        <p className="workout-with">
                                            With <WorkoutParticipants users={workout.users} />
                                        </p>
                                    )}
                                </div>
                            ))}
                        {/* No upcoming workouts */}
                        {upcomingWorkouts.length === 0 && <p className="no-data">No upcoming workouts</p>}
                    </div>
                </div>
                <div className="workouts-divider"></div>
                <div className="previous-workouts">
                    <h2>Previous Workouts</h2>
                    <div className="workouts-grid">
                        {previousWorkouts.length > 0 &&
                            previousWorkouts.map((workout) => (
                                <div key={workout.id} className="workout-card previous" onClick={() => showWorkoutDetails(workout.id, "PREVIOUS")}>
                                    <div className="workout-card-title">
                                        <p>{workout.title}</p>
                                        <small>{moment.unix(workout.starts.seconds).format("DD/MM/YYYY [at] HH:mm")}</small>
                                    </div>
                                </div>
                            ))}
                        {/* No previous workouts */}
                        {previousWorkouts.length === 0 && <p className="no-data">No previous workouts</p>}
                    </div>
                </div>
            </div>

            {showingWorkout &&
                <Modal title={duplicatingEvent ? "Duplicate event" : activeWorkout.title} className="slim" hide={() => setShowingWorkout(false)}>
                    {!duplicatingEvent &&
                        <table>
                            <tbody>
                                <tr>
                                    <td>Title</td>
                                    <td>{activeWorkout.title}</td>
                                </tr>
                                <tr>
                                    <td>Comments</td>
                                    <td>{activeWorkout.comments || "No comments"}</td>
                                </tr>
                                <tr>
                                    <td>Starting</td>
                                    <td>{moment.unix(activeWorkout.starts?.seconds).format("DD/MM/YYYY [at] HH:mm")}</td>
                                </tr>
                                <tr>
                                    <td>Ending</td>
                                    <td>{moment.unix(activeWorkout.ends?.seconds).format("DD/MM/YYYY [at] HH:mm")}</td>
                                </tr>
                                <tr>
                                    <td>Contributes to goal?</td>
                                    <td>{activeWorkout.contributes_to_goal || "No"}</td>
                                </tr>
                                <tr>
                                    <td>Goal contribution</td>
                                    <td>{activeWorkout.goal_contribution}</td>
                                </tr>
                                <tr>
                                    <td>Participants</td>
                                    <td>{activeParticipants.join(", ")}</td>
                                </tr>
                                <tr>
                                    <td>Setup by</td>
                                    <td>{activeSetupBy}</td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <Button onClick={() => setDuplicatingEvent(true)} class="primary">Duplicate event</Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    }

                    {duplicatingEvent &&
                        <>
                            <Input
                                type="text"
                                label="Event title:"
                                wrapperClass="fw"
                                error={errors?.eventTitle}
                                placeholder=" "
                                value={eventTitle}
                                onChange={(e) => setEventTitle(e.target.value)} />

                            <DatePicker
                                type="text"
                                label="Date &amp; time:"
                                wrapperClass="fw"
                                error={errors?.eventDate}
                                placeholder="Choose a date/time"
                                value={eventDate}
                                onChange={(date) => setEventDate(date)} />

                            <Textarea
                                type="text"
                                label="Additional comments:"
                                wrapperClass="fw"
                                placeholder=" "
                                rows={3}
                                value={eventComments}
                                onChange={(e) => setEventComments(e.target.value)} />

                            <Checkbox
                                label="Event is all day"
                                wrapperClass="fw"
                                checked={eventAllDay}
                                onClick={() => setEventAllDay((eventAllDay) => !eventAllDay)} />

                            {!eventAllDay &&
                                <DatePicker
                                    type="text"
                                    label="Ends at:"
                                    wrapperClass="fw"
                                    error={errors?.eventDateEnds}
                                    placeholder="Choose a date/time"
                                    value={eventDateEnds}
                                    onChange={(date) => setEventDateEnds(date)} />
                            }

                            <Checkbox
                                label="Assign to goal"
                                wrapperClass="fw"
                                checked={eventContributesGoal}
                                onClick={() => setEventContributesGoal((eventContributesGoal) => !eventContributesGoal)} />

                            {eventContributesGoal &&
                                <>
                                    <Select
                                        label="Contributes to which goal?"
                                        placeholder="Choose goal"
                                        wrapperClass="fw"
                                        value={contributesToWhatGoal.value}
                                        onSelect={((option) => setContributesToWhatGoal(option))}
                                        options={userGoals} />

                                    <Input
                                        type="number"
                                        label="Contributing value:"
                                        note="e.g. Enter 5 to contribute 5km to a 10km goal"
                                        wrapperClass="fw"
                                        error={errors?.eventGoalContribution}
                                        placeholder=" "
                                        value={eventGoalContribution}
                                        onChange={(e) => setEventGoalContribution(e.target.value)} />
                                </>
                            }

                            <Button class="primary" onClick={() => saveEvent()} disabled={eventSaving}>
                                {eventSaving ? "Saving..." : "Save Event"}
                            </Button>
                        </>
                    }
                </Modal>
            }
        </>
    );
}

export default ClientWorkouts;
