import React, { useState, createContext } from "react";

/**
 * Setup a context for storing the "add meal" modal objects
 */
const MealContext = createContext();

/**
 * Creating the provider to wrap the add meal modal in for displaying
 */
function MealProvider({ children }) {
    const [stage, setStage] = useState(1);
    const [name, setName] = useState("");
    const [prepTime, setPrepTime] = useState(null);
    const [calories, setCalories] = useState("");
    const [carbs, setCarbs] = useState("");
    const [fat, setFat] = useState("");
    const [protein, setProtein] = useState("");
    const [cookingTime, setCookingTime] = useState(null);
    const [ingredients, setIngredients] = useState([]);
    const [instructionsURL, setInstructionsURL] = useState("");
    const [instructions, setInstructions] = useState([]);
    const [tags, setTags] = useState([]);

    return (
        <MealContext.Provider value={{
            stage, setStage,
            name, setName,
            prepTime, setPrepTime,
            cookingTime, setCookingTime,
            ingredients, setIngredients,
            instructionsURL, setInstructionsURL,
            instructions, setInstructions,
            tags, setTags,
            calories, setCalories,
            carbs, setCarbs,
            fat, setFat,
            protein, setProtein,
        }}>
            {children}
        </MealContext.Provider>
    );
};

export { MealContext, MealProvider };