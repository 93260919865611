import React, { useEffect, useState } from "react";
import { db } from "../../utils/firebase";
import "./meal.scss";

import Button from "../ui/button/button";
import Modal from "../modal/modal";

function Meal(props) {
    const [meal, setMeal] = useState({});
    const [viewingMeal, setViewingMeal] = useState(false);

    useEffect(() => {
        db.doc(`meals/${props.id}`).get().then((mealDoc) => {
            setMeal(mealDoc.data());
        });
    }, []);

    const deleteMeal = () => {
        db.doc(`meals/${props.id}`).delete();
    }

    return (
        <>
            <div className="meal-card">
                <div className="meal-card-inner">
                    <div className="meal-details">
                        <p className="meal-title">{meal.name}</p>
                        <p className="meal-time">{parseInt(meal.preperation_time) + parseInt(meal.cooking_time)} mins</p>
                    </div>

                    <div className="meal-tags">
                        <div className="meal-tag">{meal.protein}g Protein</div>
                        <div className="meal-tag">{meal.fat}g Fat</div>
                        <div className="meal-tag">{meal.carbs}g Carbs</div>
                    </div>

                    <Button class="primary" onClick={() => setViewingMeal((viewingMeal) => !viewingMeal)}>
                        View Meal
                    </Button>
                </div>
            </div>

            {viewingMeal &&
                <Modal
                    title={meal.name}
                    className="center"
                    hide={() => setViewingMeal((viewingMeal) => !viewingMeal)} >

                    <div className="meal-modal-column">
                        <div className="tags-list">
                            {meal.tags.map((tag, index) => (
                                <p className="list-item-tag" key={index}>
                                    {tag}
                                </p>
                            ))}
                        </div>
                    </div>

                    <div className="meal-modal-column">
                        <h2>Recipe</h2>
                        {meal.ingredients.map((ingredient, index) => (
                            <p className="list-item" key={index}>
                                {ingredient.measurement}{ingredient.unit} {ingredient.name}
                            </p>
                        ))}
                    </div>

                    <div className="meal-modal-column">
                        <h2>Directions</h2>
                        {meal.instructions.map((instruction, index) => (
                            <p className="list-item instructions" key={index}>
                                {index + 1}. {instruction.instruction}
                            </p>
                        ))}
                    </div>

                    <Button class="red" onClick={() => deleteMeal()}>
                        Delete Meal
                    </Button>
                </Modal>
            }
        </>
    );
}

export default Meal;