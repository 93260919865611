import React from "react";
import moment from "moment";

export default function Documents({ userID, parQ }) {
    const parqCompleted = moment(parQ.completed.seconds, "X").format("DD/MM/YYYY HH:mma");

    return (
        <>
            <p className="parq-completed">ParQ was completed {parqCompleted}</p>

            <table className="client-parq-responses">
                {parQ.questions.map((question) => (
                    <tr>
                        <td>{question.question}</td>
                        <td>{question.answer ? "Yes" : "No"}</td>
                    </tr>
                ))}
            </table>

            <p className="parq-comments-title">Additional comments:</p>

            <p className="parq-comments">{parQ.comments || "No additional comments left"}</p>
        </>
    );
}