import React, { useState, useContext, useEffect } from "react";
import { db, auth, arrayRemove } from "../../utils/firebase";
import { AuthContext } from "../../utils/providers/auth";
import { MessagesContext } from "../../utils/providers/messages";
import { DotsIcon, RightArrowIcon, TrashIcon, MuteIcon } from "../../utils/svgs";

/**
 * Functional component for the chat element to appear along the left of the messages modal
 *
 * @returns HTML markup and functionality for updating the Messages context
 */
function Chat(props) {
    const [editing, setEditing] = useState(false);
    const [muted, setMuted] = useState(false);
    const [mostRecent, setMostRecent] = useState("");
    const [participants, setParticipants] = useState("");

    /**
     * Get the function to update the active chat from the context
     */
    const { setActiveChat } = useContext(MessagesContext);

    /**
     * Pull the current user object from the auth context
     */
    const { user } = useContext(AuthContext);

    /**
     * Deconstruct the conversation data from props
     */
    const { conversation_data } = props;

    /**
     * Component load
     */
    useEffect(() => {
        listenForMostRecent();
        buildUsersString();
    }, []);

    /**
     * When the muted property is updated
     */
    useEffect(() => {
        setMuted(conversation_data.muted);
    }, [conversation_data.muted]);

    /**
     * Get the most recent message from the chat
     * 
     * @type {const}
     */
    const listenForMostRecent = () => {
        /**
         * Setup the database listener
         */
        db.collection(`conversations/${props.id}/messages`)
            .orderBy("timestamp", "asc")
            .limit(1)
            .onSnapshot((messages_snap) => {
                if (messages_snap.size > 0) {
                    /**
                     * Update the state with the most recent
                     */
                    setMostRecent(messages_snap.docs[0].data().message);
                }
            });
    }

    /**
     * Build a string for the users that are in the chat
     */
    const buildUsersString = () => {
        /**
         * Loop over the users and add them to the array
         */
        const users = [];
        conversation_data.users.map(async (convoUser) => {
            /**
             * Fetch only users other than ourselves
             */
            if (convoUser !== auth.currentUser.uid) {
                /**
                 * Get the users name from the database
                 */
                const user_name = await db.doc(`${user?.is_trainer ? "clients" : "trainers"}/${convoUser}`)
                    .get().then((userDoc) => {
                        if (userDoc.exists) {
                            /**
                             * Deconstruct the client details from the document
                             */
                            const { first_name, last_name, email } = userDoc.data();
                            /**
                             * Is there a first name and last name?
                             */
                            if (first_name && last_name) {
                                /**
                                 * Return their full name
                                 */
                                return `${first_name} ${last_name}`;
                            } else {
                                /**
                                 * Otherwise return their email
                                 */
                                return email;
                            }
                        } else {
                            return "Previous FitPortal user"
                        }
                    });
                /**
                 * Push them to the users array
                 */
                users.push(user_name);
                /**
                 * Update the state
                 */
                setParticipants(users.join(", "));
            }
        });
    }

    /**
     * Toggles the new clicked chat into the window and hides the new conversation window if it is showing
     *
     * @type {const}
     */
    const toggleActiveMessage = () => {
        /**
         * Hide the new conversation window
         */
        props.hideNewMessage();
        /**
         * Set the active chat into the context
         */
        setActiveChat(props.id);
    };

    /**
     * Update the state to reflect if this message is being edited, needs to be ran through here to stop
     * the propagation of the event also setting the context to the active ID
     *
     * @type {const}
     * @param {object} e Component click event data
     */
    const setEditActive = (e) => {
        e.stopPropagation();
        /**
         * Update the state
         */
        setEditing((active) => !active);
    };

    /**
     * Delete that chat from the users account
     */
    const deleteChat = (e) => {
        e.stopPropagation();

        db.doc(`conversations/${props.id}`).set({
            active_users: arrayRemove(auth.currentUser.uid),
        }, { merge: true });
        /**
         * Update the state
         */
        setEditing(false);
    };

    return (
        <div className="chat-element" onClick={() => toggleActiveMessage()}>
            <div className={["chat-preview", editing ? "active" : ""].join(" ")}>
                <div className="chat-text">
                    <p className="message-name">{participants}</p>
                    {mostRecent &&
                        <p className="message-preview">{mostRecent}</p>
                    }
                </div>
                {/* <div className="chat-edit" onClick={(e) => setEditActive(e)}>
                    {editing ? <RightArrowIcon /> : <DotsIcon />}
                </div> */}
            </div>
            <div className="chat-actions">
                <div className="chat-action-delete" onClick={(e) => deleteChat(e)}>
                    <TrashIcon />
                </div>
            </div>
        </div>
    );
}

export default Chat;
