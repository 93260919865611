import React, { useEffect, useState } from "react";
import { db } from "../../../../../../utils/firebase";

/**
 * UI components
 */
import Input from "../../../../../ui/inputs/input";
import Select from "../../../../../ui/inputs/select/new-select";

/**
 * Functional component to return the participant goals
 */
function EventParticipant(props) {
    const [goals, setGoals] = useState({});
    const [goal, setGoal] = useState({});
    const [goalValue, setGoalValue] = useState("");
    const [client, setClient] = useState({});

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Are there some details for this clients goals in the state at the moment
         */
        if (props.clientGoals[props.id]) {
            /**
             * Push them into the local state
             */
            setGoal(props.clientGoals[props.id].goal);
            setGoalValue(props.clientGoals[props.id].contribution);
        }
    }, []);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Fetch the users details from the database
         */
        db.doc(`clients/${props.id}`).get().then((userDoc) => {
            /**
             * Push the user data into the local state
             */
            setClient(userDoc.data());
        });
    }, []);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the goals for this user
         */
        db.collection(`clients/${props.id}/goals`)
            .get().then((userGoals) => {
                /**
                 * Loop through each of the goals
                 */
                userGoals.forEach((goalDoc) => {
                    setGoals((goals) => ({
                        ...goals,
                        [goalDoc.id]: goalDoc.data().title,
                    }))
                });
            });
    }, []);

    /**
     * On update of the goal
     */
    useEffect(() => {
        /**
         * Bubble the goal option up the props
         */
        props.pushGoal({
            client: props.id,
            goal: goal,
            contribution: goalValue,
        })
    }, [goal]);

    /**
     * On update of the goalValue
     */
    useEffect(() => {
        /**
         * Bubble the value up the props
         */
        props.pushGoalValue({
            client: props.id,
            goal: goal,
            contribution: goalValue,
        })
    }, [goalValue]);

    return (
        <div className="client-event-goal">
            <h3>{client.first_name} {client.last_name}</h3>

            <div className="input-group-column cols-2">
                <Select
                    label="Assign to goal:"
                    placeholder="Choose a goal"
                    value={goal.value}
                    note={`Choose one of ${client.first_name ? client.first_name : client.email}'s goals`}
                    onSelect={(option) => setGoal(option)}
                    options={goals} />

                <Input
                    type="number"
                    value={goalValue}
                    label="Contribution value:"
                    placeholder="e.g 10"
                    note="How much should this event contribute?"
                    onChange={(e) => setGoalValue(e.target.value)} />
            </div>
        </div>
    );
}

export default EventParticipant;