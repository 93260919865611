/**
 * Styles
 */
import "./input.scss";

/**
 * Input functional component
 *
 * @type {const}
 * @param {object} props Component props passed down
 */
function Textarea(props) {
    return (
        <div className={["input-block", props.wrapperClass].join(" ")}>
            {/* Label */}
            {props.label && <label>{props.label}</label>}
            {/* Inputfield */}
            <textarea
                id={props.id}
                type={props.type}
                className={["inputfield", props.class, props.value && "has-value"].join(" ")}
                onChange={props.onChange}
                required={props.required}
                placeholder={props.placeholder}
                readOnly={props.readonly}
                disabled={props.disabled}
                value={props.value}
                rows={props.rows}
            ></textarea>
            {/* Input note / description */}
            {props.note && <p>{props.note}</p>}
        </div>
    );
}

export default Textarea;
