import React, { useContext, useEffect, useState } from "react";
import { SignupContext } from "../../utils/providers/signup";

import Input from "../ui/inputs/input";
import Button from "../ui/button/button";

/**
 * 
 */
function Billing() {
    const [localSortCode, setLocalSortCode] = useState("");

    const {
        setSignupStage,
        errors, setErrors,
        accountNumber, setAccountNumber,
        sortCode, setSortCode,
    } = useContext(SignupContext);

    const updateInput = (input) => {
        const currentCode = localSortCode;
        const newCode = input;

        let updatedCode = newCode;

        if (newCode.length === 2 && (newCode.length > currentCode.length)) {
            updatedCode = updatedCode + "-";
        }

        if (newCode.length === 3 && (newCode.length < currentCode.length)) {
            updatedCode = updatedCode.slice(0, -1);
        }

        if (newCode.length === 5 && (newCode.length > currentCode.length)) {
            updatedCode = updatedCode + "-";
        }

        if (newCode.length === 6 && (newCode.length < currentCode.length)) {
            updatedCode = updatedCode.slice(0, -1);
        }

        setLocalSortCode(updatedCode);
        setSortCode(updatedCode.replace(/[^0-9]/g, ""));
    }

    const checkInputs = () => {
        let proceed = true;
        let inputErrors = {};

        setErrors({});

        if (!accountNumber || accountNumber.length !== 8) {
            inputErrors["accountNumber"] = "Please enter a valid account number";
            proceed = false;
        }

        if (!sortCode || sortCode.length !== 6) {
            inputErrors["sortCode"] = "Please enter a valid sort code";
            proceed = false;
        }

        if (proceed) {
            setSignupStage((signupStage) => signupStage + 1);
        } else {
            setErrors(inputErrors);
        }
    }

    return (
        <>
            <div className="input-block-section">
                <h2>Bank Details</h2>
                <p>When a client purchases your services through FitPortal we pay you out on the same day, where should we send the funds?</p>
                <div className="input-elements-grid cols-2">
                    <Input type="text"
                        value={accountNumber}
                        placeholder="Account Number:"
                        label="Account Number:"
                        wrapperClass={errors.accountNumber && 'invalid'}
                        note={errors.accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)} />
                    <Input type="text"
                        value={localSortCode}
                        placeholder="Sort Code:"
                        label="Sort Code:"
                        wrapperClass={errors.sortCode && 'invalid'}
                        note={errors.sortCode}
                        onChange={(e) => updateInput(e.target.value)} />
                </div>
            </div>

            <div className="signup-next-stage">
                <Button class="primary" onClick={() => checkInputs()}>Continue</Button>
            </div>
        </>
    );
}

export default Billing;