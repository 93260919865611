/**
 * Import firebase libary
 */
import firebase from "firebase";

/**
 * Setup an object to hold the config
 */
let config = {};
/**
 * Are we in the live environemnt
 */
if (window.location.hostname === "fitportal.uk") {
    /**
     * Set the live firebase config
     */
    config = {
        apiKey: "AIzaSyAcOs6J3gk-ubzoF1kK4q3BlAkNAupbGjQ",
        authDomain: "fit-portal-app.firebaseapp.com",
        projectId: "fit-portal-app",
        storageBucket: "fit-portal-app.appspot.com",
        messagingSenderId: "507591284622",
        appId: "1:507591284622:web:2193affe1a7c4f24d636df",
        measurementId: "G-6XNSS12682",
    };
} else {
    /**
     * Otherwise set the development config
     */
    config = {
        apiKey: "AIzaSyB5AjoYTSdOjQ1DrC8QsIATxe47urWGmKA",
        authDomain: "fit-portal-dev.firebaseapp.com",
        projectId: "fit-portal-dev",
        storageBucket: "fit-portal-dev.appspot.com",
        messagingSenderId: "1011692207299",
        appId: "1:1011692207299:web:90aeca70f1225036143676",
        measurementId: "G-S0N797QW58"
    };
}

/**
 * Initialse the app
 */
firebase.initializeApp(config);
firebase.analytics();

/**
 * Export performance monitoring
 */
const performance = firebase.performance();

/**
 * Export authentication reference
 */
const auth = firebase.auth();

/**
 * Export database reference
 */
const db = firebase.firestore();

/**
 * Export functions reference
 */
const fun = firebase.functions();

/**
 * Export storage reference
 */
const storage = firebase.storage();

/**
 * Check to see if we are running on localhost
 */
if (window.location.hostname === "localhost") {
    /**
     * If we are, connect the services to the local emulators
     */
    // auth.useEmulator("http://localhost:9099");
    // db.useEmulator("localhost", 8080);
    // fun.useEmulator("localhost", 5001);
    // storage.useEmulator("localhost", 9199)
}

/**
 * Array union function from firestore
 */
const arrayUnion = firebase.firestore.FieldValue.arrayUnion;

/**
 * Array remove function from firestore
 */
const arrayRemove = firebase.firestore.FieldValue.arrayRemove;

/**
 * Grabs a timestamp value from firestore
 */
const getTimestamp = async () => {
    return firebase.firestore.Timestamp.fromDate(new Date());
};

/**
 * Increments a document by 1
 */
const increment = firebase.firestore.FieldValue.increment(1);

/**
 * Increments a document by a given amount
 */
const incrementBy = (by) => {
    firebase.firestore.FieldValue.increment(by);
};

/**
 * Decrements a document by 1
 */
const decrement = firebase.firestore.FieldValue.increment(-1);

/**
 * Decrements a document by a given amount
 */
const decrementBy = (by) => {
    firebase.firestore.FieldValue.increment(-Math.abs(parseInt(by)));
};

/**
 * Export firebase referecnes
 */
export {
    firebase,
    auth,
    db,
    fun,
    storage,
    performance,
};

/**
 * Export data manipulation methods
 */
export {
    arrayUnion,
    arrayRemove,
    increment,
    getTimestamp,
    incrementBy,
    decrement,
    decrementBy,
};
