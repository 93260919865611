import React, { useEffect, useState, useContext } from 'react';
import { db } from '../../utils/firebase';
import { AuthContext } from '../../utils/providers/auth';
import { TimesIcon, CheckIcon } from '../../utils/svgs';

/**
 * Styles
 */
import './equipment.scss';

/**
 * UI component
 */
import Button from '../../components/ui/button/button';
import Input from '../../components/ui/inputs/input';
import Modal from '../../components/modal/modal';
import Checkbox from "../../components/ui/inputs/checkbox";
import Textarea from "../../components/ui/inputs/textarea";

/**
 * Functional component to return the client equipment view
 *
 * @returns HTML markup for equipment list
 */
function Equipment() {
    const [addingEquipment, setAddingEquipment] = useState(false);
    const [equipmentList, setEquipmentList] = useState([]);
    const [newEquipmentTitle, setNewEquipmentTitle] = useState(" ");
    const [newEquipmentAtHome, setNewEquipmentAtHome] = useState(false);
    const [newEquipmentAtGym, setNewEquipmentAtGym] = useState(false);
    const [newEquipmentComments, setNewEquipmentComments] = useState(" ");
    const [homeToggled, setHomeToggled] = useState(true);
    const [gymToggled, setGymToggled] = useState(true);

    /**
     * Get the user from context
     */
    const { user } = useContext(AuthContext);

    /**
     * When the user context is loaded
     */
    useEffect(() => {
        setupEquipmentListener();
    }, [user.uid]);

    /**
     * Setup a listener on the database to stream changes to the equipment lists
     *
     * @type {const}
     */
    const setupEquipmentListener = () => {
        /**
         * Setup the database listener
         */
        db.collection(`clients/${user.uid}/equipment`).onSnapshot((equipmentSnapshot) => {
            /**
             * Loop over the changes passed down
             */
            equipmentSnapshot.docChanges().forEach((change) => {
                /**
                 * Equipment item added
                 */
                if (change.type === "added") {
                    setEquipmentList((equipment) => [
                        ...equipment,
                        { id: change.doc.id, data: change.doc.data() }
                    ]);
                }
                /**
                 * Equipment item updated
                 */
                if (change.type === "modified") {
                    setEquipmentList((equipment) => {
                        let updatedEquipment = [...equipment];
                        for (let i in equipment) {
                            if (equipment[i].id === change.doc.id) {
                                updatedEquipment[i] = { id: change.doc.id, data: change.doc.data() };
                                break;
                            }
                        }
                        return updatedEquipment;
                    });
                }
                /**
                 * Equipment item removed
                 */
                if (change.type === "removed") {
                    setEquipmentList((equipment) =>
                        equipment.filter((equipmentElement) => equipmentElement.id !== change.doc.id));
                }
            });
        });
    }

    /**
     * Save the new equipment to the users document
     *
     * @type {const}
     */
    const saveNewEquipment = () => {
        /**
         * Check to make sure the
         */

        /**
         * If there are no errors
         */
        if (true) {
            /**
             * Add it to the users equipment collection
             */
            db.collection(`clients/${user.uid}/equipment`).add({
                title: newEquipmentTitle,
                at_home: newEquipmentAtHome,
                at_gym: newEquipmentAtGym,
                comments: newEquipmentComments,
            });
            /**
             * Reset the fields and hide the modal
             */
            setNewEquipmentTitle("");
            setAddingEquipment(false);
            setNewEquipmentAtHome(false);
            setNewEquipmentAtGym(false);
            setNewEquipmentComments("");
        }
    }

    /**
     * Remove the equipment item from the users equipment collection
     *
     * @type {const}
     */
    const removeEquipment = (equipmentID) => {
        /**
         * Remove it from the collection
         */
        db.doc(`clients/${user.uid}/equipment/${equipmentID}`).delete();
    }

    return (
        <>
            <div className="page-title">
                <h1>My Equipment</h1>
                <p>Add the equipment you have access to for your trainers to better allocate workouts and programmes for you. It helps to describe the equipment you have access to, for example, listing the various dumbbell weights you might have at home.</p>
            </div>
            <div className="equipment-list-wrapper">
                {/* Add equipment button */}
                <Button class="primary" onClick={() => setAddingEquipment(true)}>
                    Add Equipment
                </Button>

                <div className="toggle-type-views">
                    <Checkbox
                        label="Show home"
                        checked={homeToggled}
                        onClick={() => setHomeToggled((homeToggled) => !homeToggled)} />

                    <Checkbox
                        label="Show gym"
                        checked={gymToggled}
                        onClick={() => setGymToggled((gymToggled) => !gymToggled)} />
                </div>

                {/* List of equipment */}
                <div className="equipment-list">
                    {/* Is the equipment list empty */}
                    {equipmentList.length === 0 &&
                        <p className="no-equipment-yet">You havn't added any equipment yet</p>}
                    {/* Are there any equipment to display */}
                    {equipmentList.length > 0 &&
                        equipmentList.map((equipment) =>
                            <div key={equipment.id} className={[
                                "equipment-list-item",
                                equipment.data.at_home ? homeToggled ? "show" : "hide" : "",
                                equipment.data.at_gym ? gymToggled ? "show" : "hide" : "",
                            ].join(" ")}>
                                <p>{equipment.data.title}</p>
                                <p>
                                    {equipment.data.comments ?
                                        <span>{equipment.data.comments}</span>
                                        :
                                        <span className="placeholder">No additional comments.</span>
                                    }
                                </p>
                                <div className="ea-locations">
                                    {/* Available at home */}
                                    <div className={[
                                        "ea-location",
                                        equipment.data.at_home ? "available" : ""
                                    ].join(" ")}>
                                        {equipment.data.at_home ? <CheckIcon /> : <TimesIcon />}
                                        <p>Home</p>
                                    </div>
                                    {/* Available at the gym */}
                                    <div className={[
                                        "ea-location",
                                        equipment.data.at_gym ? "available" : ""
                                    ].join(" ")}>
                                        {equipment.data.at_gym ? <CheckIcon /> : <TimesIcon />}
                                        <p>Gym</p>
                                    </div>
                                </div>
                                <div className="remove-equipment">
                                    <p onClick={() => removeEquipment(equipment.id)}>remove</p>
                                </div>
                            </div>
                        )}
                </div>
            </div>

            {/* Are we adding a new equipment item */}
            {addingEquipment &&
                <Modal title="Add Equipment" className="slim" hide={() => setAddingEquipment(false)}>
                    <Input
                        type="text"
                        label="Equipment:"
                        wrapperClass="fw"
                        placeholder=" "
                        value={newEquipmentTitle}
                        onChange={(e) => setNewEquipmentTitle(e.target.value)} />

                    <Textarea
                        label="Additional comments:"
                        wrapperClass="fw"
                        rows={3}
                        placeholder=" "
                        value={newEquipmentComments}
                        onChange={(e) => setNewEquipmentComments(e.target.value)} />

                    <Checkbox
                        label="Available at home"
                        checked={newEquipmentAtHome}
                        onClick={() => setNewEquipmentAtHome((equipmentAtHome) => !equipmentAtHome)} />

                    <Checkbox
                        label="Available at the gym"
                        checked={newEquipmentAtGym}
                        onClick={() => setNewEquipmentAtGym((equipmentAtGym) => !equipmentAtGym)} />

                    <Button class="primary" onClick={() => saveNewEquipment()}>Add Equipment</Button>
                </Modal>
            }
        </>
    );
}

export default Equipment;