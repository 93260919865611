import React, { useContext } from "react";
import { ProgramContext } from "../../../utils/providers/program";
import "./add.scss";

/**
 * Add meal stages
 */
import Details from "./stages/details";
import Meals from "./stages/meals";
import Save from "./stages/save";
import Scheduling from "./stages/scheduling";
import Workouts from "./stages/workouts";

/**
 * Functional component to return the form for adding new programs
 */
function AddProgram(props) {
    /**
     * Deconstruct the stage vars from the context
     */
    const { stage, setStage } = useContext(ProgramContext);

    /**
     * Check the stage the user is trying to navigate towards before continuing, only 
     * allowing them to move back to a stage that has previoulsy been filled out
     */
    const processNavClick = (newStage) => {
        /**
         * If the nav item clicked is in the past
         */
        if (stage >= newStage) {
            /**
             * Move back in the stage
             */
            setStage(newStage);
        }
    }

    return (
        <div className="add-meal-modal">
            <div className="add-meal-modal-nav">
                <p
                    onClick={() => processNavClick(1)}
                    className={[
                        (stage === 1) && "is-active",
                        (stage > 1) && "is-past"
                    ].join(" ")}>
                    Details
                </p>
                <p
                    onClick={() => processNavClick(2)}
                    className={[
                        (stage === 2) && "is-active",
                        (stage > 2) && "is-past"
                    ].join(" ")}>
                    Workouts
                </p>
                <p
                    onClick={() => processNavClick(3)}
                    className={[
                        (stage === 3) && "is-active",
                        (stage > 3) && "is-past"
                    ].join(" ")}>
                    Meals
                </p>
                <p
                    onClick={() => processNavClick(4)}
                    className={[
                        (stage === 4) && "is-active",
                        (stage > 4) && "is-past"
                    ].join(" ")}>
                    Schedules
                </p>
                <p
                    onClick={() => processNavClick(5)}
                    className={[
                        (stage === 5) && "is-active",
                        (stage > 5) && "is-past"
                    ].join(" ")}>
                    Save
                </p>
            </div>

            <div className="add-meal-modal-inputs">
                {(stage === 1) && <Details />}
                {(stage === 2) && <Workouts />}
                {(stage === 3) && <Meals />}
                {(stage === 4) && <Scheduling />}
                {(stage === 5) && <Save close={() => props.close()} />}
            </div>
        </div>
    );
}

export default AddProgram;