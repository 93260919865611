import React, { useContext } from "react";
import { WorkoutContext } from "../../../utils/providers/workout";
import "./add.scss";

/**
 * Add workout stages
 */
import Details from "./stages/details";
import Exercises from "./stages/exercises";
import Save from "./stages/save";
import Tags from "./stages/tags";

/**
 * Functional component to return the form for adding new meals
 */
function AddWorkout(props) {
    /**
     * Deconstruct the stage vars from the context
     */
    const { stage, setStage, setForClient } = useContext(WorkoutContext);

    /**
     * 
     */
    const { forClient } = props;

    /**
     * 
     */
    if (forClient) {
        setForClient(forClient);
    }

    /**
     * Check the stage the user is trying to navigate towards before continuing, only 
     * allowing them to move back to a stage that has previoulsy been filled out
     */
    const processNavClick = (newStage) => {
        /**
         * If the nav item clicked is in the past
         */
        if (stage >= newStage) {
            /**
             * Move back in the stage
             */
            setStage(newStage);
        }
    }

    return (
        <div className="add-meal-modal">
            <div className="add-meal-modal-nav">
                <p
                    onClick={() => processNavClick(1)}
                    className={[
                        (stage === 1) && "is-active",
                        (stage > 1) && "is-past"
                    ].join(" ")}>
                    Details
                </p>
                <p
                    onClick={() => processNavClick(2)}
                    className={[
                        (stage === 2) && "is-active",
                        (stage > 2) && "is-past"
                    ].join(" ")}>
                    Exercises
                </p>
                <p
                    onClick={() => processNavClick(3)}
                    className={[
                        (stage === 3) && "is-active",
                        (stage > 3) && "is-past"
                    ].join(" ")}>
                    Tags
                </p>
                <p
                    onClick={() => processNavClick(4)}
                    className={[
                        (stage === 4) && "is-active",
                        (stage > 4) && "is-past"
                    ].join(" ")}>
                    Save
                </p>
            </div>

            <div className="add-meal-modal-inputs">
                {(stage === 1) && <Details />}
                {(stage === 2) && <Exercises />}
                {(stage === 3) && <Tags />}
                {(stage === 4) && <Save close={() => props.close()} />}
            </div>
        </div>
    );
}

export default AddWorkout;