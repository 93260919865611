import React, { useEffect, useState } from "react";
import { db, auth } from "../../../utils/firebase";
import moment from "moment";
import "./view.scss";

/**
 * UI components
 */
import Modal from "../../modal/modal";
import View from "../../events/view/view";

/**
 * Tabs
 */
import Workouts from "../tabs/workouts";
import Calendar from "../tabs/calendar";
import Payments from "../tabs/payments";
import Nutrition from "../tabs/nutrition";
import Equipment from "../tabs/equipment";
import Notes from "../tabs/notes";
import Documents from "../tabs/documents";
import Messages from "../tabs/messages";

/**
 * Functional component for returning the client card
 */
function ViewClient(props) {
    const [viewingClient, setViewingClient] = useState(false);
    const [client, setClient] = useState({});
    const [modalTab, setModalTab] = useState("CALENDAR");
    const [clientSince, setClientSince] = useState("");
    const [viewingEvent, setViewingEvent] = useState(null);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the client details from firestore
         */
        db.doc(`clients/${props.client}`).get().then((clientDoc) => {
            /**
             * Set them into the local state
             */
            setClient({
                id: props.id,
                ...clientDoc.data(),
            });
        });
    }, []);

    /**
     * Get the client document on thei trainer so we can get the added date
     */
    useEffect(() => {
        /**
         * Get the client document
         */
        db.doc(`trainers/${auth.currentUser.uid}/clients/${props.client}`).get().then((trainerClientDoc) => {
            /**
             * Make sure the document exists
             */
            if (trainerClientDoc.exists) {
                /**
                 * Set the since local state to a reable format
                 */
                setClientSince(moment.unix(trainerClientDoc.data().connected.seconds).format("DD/MM/YYYY HH:mm"));
            }
        });
    }, []);

    /**
     * Remove the client from the trainers account
     */
    const removeClient = async () => {
        /**
         * Remove the client document from the trainers collection
         */
        await db.doc(`trainers/${auth.currentUser.uid}/clients/${props.client}`).delete();
        /**
         * Then remove the trainers document from the clients collection
         */
        await db.doc(`clients/${props.client}/trainers/${auth.currentUser.uid}`).delete();
        /**
         * @todo Need to cancel off any subscriptions between the users at this point too
         */
    }

    return (
        <>
            <Modal
                className="large tall"
                title={client.first_name ? `${client.first_name} ${client.last_name}` : client.email}
                subtitle={`Edit ${client.first_name || client.email}'s account with you`}
                hide={() => props.hide()}>
                <div className="modal-tab-navigations">
                    <p className={["modal-tab-nav-link", (modalTab === "CALENDAR") && "active"].join(" ")}
                        onClick={() => setModalTab("CALENDAR")}>
                        Calendar
                    </p>
                    <p className={["modal-tab-nav-link", (modalTab === "WORKOUTS") && "active"].join(" ")}
                        onClick={() => setModalTab("WORKOUTS")}>
                        Workouts
                    </p>
                    <p className={["modal-tab-nav-link", (modalTab === "EQUIPMENT") && "active"].join(" ")}
                        onClick={() => setModalTab("EQUIPMENT")}>
                        Equipment
                    </p>
                    <p className={["modal-tab-nav-link", (modalTab === "NUTRITION") && "active"].join(" ")}
                        onClick={() => setModalTab("NUTRITION")}>
                        Nutrition
                    </p>
                    <p className={["modal-tab-nav-link", (modalTab === "MESSAGING") && "active"].join(" ")}
                        onClick={() => setModalTab("MESSAGING")}>
                        Messaging
                    </p>
                    <p className={["modal-tab-nav-link", (modalTab === "NOTES") && "active"].join(" ")}
                        onClick={() => setModalTab("NOTES")}>
                        Notes
                    </p>
                    <p className={["modal-tab-nav-link", (modalTab === "DOCUMENTS") && "active"].join(" ")}
                        onClick={() => setModalTab("DOCUMENTS")}>
                        ParQ Declaration
                    </p>
                </div>

                {modalTab === "SUMMARY" &&
                    <div className="client-modal-tab summary">
                        <div className="remove-client">
                            <p>Active client since {clientSince}</p>
                            <p onClick={() => removeClient()}>
                                Remove Client
                            </p>
                        </div>
                    </div>
                }

                {modalTab === "PAYMENTS" &&
                    <div className="client-modal-tab payments">
                        <Payments userID={props.client} />
                    </div>
                }

                {modalTab === "CALENDAR" &&
                    <div className="client-modal-tab calendar">
                        <Calendar
                            userID={props.client}
                            viewEvent={(event) => setViewingEvent(event)} />
                    </div>
                }

                {modalTab === "WORKOUTS" &&
                    <div className="client-modal-tab">
                        <Workouts
                            userID={props.client}
                            clientName={`${client.first_name} ${client.last_name}`} />
                    </div>
                }

                {modalTab === "EQUIPMENT" &&
                    <div className="client-modal-tab">
                        <Equipment userID={props.client} />
                    </div>
                }

                {modalTab === "NUTRITION" &&
                    <div className="client-modal-tab">
                        <Nutrition userID={props.client} />
                    </div>
                }

                {modalTab === "MESSAGING" &&
                    <div className="client-modal-tab">
                        <Messages userID={props.client} />
                    </div>
                }

                {modalTab === "NOTES" &&
                    <div className="client-modal-tab">
                        <Notes userID={props.client} />
                    </div>
                }

                {modalTab === "DOCUMENTS" &&
                    <div className="client-modal-tab">
                        <Documents userID={props.client} parQ={client.parq} />
                    </div>
                }
            </Modal>

            {/* If we are viewing an event */}
            {viewingEvent &&
                <Modal
                    title={viewingEvent.title}
                    hide={() => setViewingEvent(null)}>
                    <View
                        eventID={viewingEvent.id}
                        hide={() => setViewingEvent(null)} />
                </Modal>
            }
        </>
    );
}

export default ViewClient;
