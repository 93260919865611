import React, { useContext, useEffect, useState } from "react";
import { db, auth } from "../../utils/firebase";
import { MessageIcon } from "../../utils/svgs";
import { MessagesContext } from "../../utils/providers/messages";
import "./messages.scss";

/**
 * UI components
 */
import Modal from "../../components/modal/modal";
import Chat from "../../components/messages/preview";
import ChatWindow from "../../components/messages/window";
import StartConversation from "../../components/messages/start-conversation";

/**
 * Functional component to return the HTML makrup and functionality for the messages modal
 */
function Messages() {
    const [newMessage, setNewMessage] = useState(false);
    const [conversations, setConversations] = useState([]);

    /**
     * Deconstruct the messages context
     */
    const {
        messagesShowing,
        setMessagesShowing,
        setHasUnread,
    } = useContext(MessagesContext);

    /**
     * Component load
     */
    useEffect(() => {
        /**
         * If the auth object has a UID present
         */
        if (auth?.currentUser?.uid) {
            /**
             * Setup the database listener
             */
            const unsubscribe = db.collection("conversations")
                .where("unread_by", "array-contains", auth.currentUser.uid)
                .onSnapshot((conversationDocs) => {
                    if (conversationDocs.size > 0) {
                        setHasUnread(true);
                    }
                });
            /**
             * On component unload
             */
            return () => unsubscribe();
        }
    }, [auth.currentUser]);

    /**
     * Component load
     */
    useEffect(() => {
        /**
         * If the auth object has a UID present
         */
        if (auth.currentUser && auth.currentUser.uid) {
            /**
             * Setup the database listener
             */
            const unsubscribe = db.collection("conversations")
                .where("active_users", "array-contains", auth.currentUser.uid)
                .orderBy("latest_message", "asc")
                .onSnapshot((conversationDocs) => {
                    /**
                     * Loop over the changes passed down
                     */
                    conversationDocs.docChanges().forEach((change) => {
                        /**
                         * Conversation added
                         */
                        if (change.type === "added") {
                            setConversations((conversations) => [
                                ...conversations,
                                { id: change.doc.id, ...change.doc.data() }
                            ]);
                        }
                        /**
                         * Conversation updated
                         */
                        if (change.type === "modified") {
                            setConversations((conversations) => {
                                let updatedConversations = [...conversations];
                                for (let i in conversations) {
                                    if (conversations[i].id === change.doc.id) {
                                        updatedConversations[i] = { id: change.doc.id, ...change.doc.data() };
                                        break;
                                    }
                                }
                                return updatedConversations;
                            });
                        }
                        /**
                         * Conversation removed
                         */
                        if (change.type === "removed") {
                            setConversations((conversations) =>
                                conversations.filter((conversation) => conversation.id !== change.doc.id));
                        }
                    });
                });
            /**
             * On component unload
             */
            return () => unsubscribe();
        }
    }, [auth.currentUser]);

    /**
     * Is the modal active
     */
    if (messagesShowing) {
        return (
            <Modal className="wide flex no-pad min-height" title="Messages" hide={() => setMessagesShowing(false)}>
                <div className="messages-modal">
                    <div className="messages-list">
                        <div className="chats">
                            {conversations && conversations.map((conversation) => (
                                <Chat
                                    key={conversation.id}
                                    id={conversation.id}
                                    conversation_data={conversation}
                                    hideNewMessage={() => setNewMessage(false)} />
                            ))}
                        </div>
                        {/* <div className="new-message" onClick={() => setNewMessage(true)}>
                            <MessageIcon />
                            <p>New message</p>
                        </div> */}
                    </div>
                    <div className="message-window">
                        {newMessage ? <StartConversation /> : <ChatWindow />}
                    </div>
                </div>
            </Modal>
        );
    } else {
        return null;
    }
}

export default Messages;