import firebase from "firebase";
import { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { db, auth } from "../../../utils/firebase";
import moment from "moment";

/**
 * UI componenets
 */
import Button from "../../ui/button/button";
import Select from "../../ui/inputs/select/new-select";
import Input from "../../ui/inputs/input";
import DatePicker from "../../ui/inputs/datetime";

/**
 * Start the moment week on a monday
 */
moment.locale("gb", {
    week: {
        dow: 1,
        doy: 1,
    },
});
const localizer = momentLocalizer(moment);

/**
 * 
 */
function Nutrition(props) {
    const [meals, setMeals] = useState([]);
    const [assignMealID, setAssignMealID] = useState({});
    const [assignMealDate, setAssignMealDate] = useState(null);
    const [trainerMeals, setTrainerMeals] = useState({});
    const [calorieTarget, setCalorieTarget] = useState("");
    const [carbTarget, setCarbTarget] = useState("");
    const [fatTarget, setFatTarget] = useState("");
    const [proteinTarget, setProteinTarget] = useState("");

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the trainers meals from the database
         */
        db.collection("meals").where("created_by", "==", auth.currentUser.uid)
            .get().then((mealDocs) => {
                /**
                 * Loop over each of the meals found
                 */
                mealDocs.forEach((meal) => {
                    /**
                     * Push the meal into the local state for adding to the clients planner
                     */
                    setTrainerMeals((trainerMeals) => ({ ...trainerMeals, [meal.id]: meal.data().name }));
                });
            });
    }, []);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the start of the period in milliseconds
         */
        const periodStartMillis = moment().startOf("week").format("x");
        /**
         * Generare a firebase timestamp for the start of the period
         */
        const fromTimestamp = firebase.firestore.Timestamp.fromMillis(periodStartMillis);
        /**
         * Setup a listener on the users meals subcollection
         */
        const unsubscribe = db.collection(`clients/${props.userID}/meals`)
            .where("for_when", ">=", fromTimestamp)
            .onSnapshot((mealsSnap) => {
                /**
                 * Loop through the meals found in the database
                 */
                mealsSnap.docChanges().map(async (change) => {
                    /**
                     * Get the meal data from the document
                     */
                    const meal_data = change.doc.data();
                    /**
                     * Pull the meal details from the meals colleciton in the database
                     */
                    const meal_doc_data = await db.doc(`meals/${meal_data.meal}`)
                        .get().then((mealDoc) => {
                            /**
                             * If this meal document exists
                             */
                            if (mealDoc.exists) {
                                /**
                                 * Return the meal data
                                 */
                                return mealDoc.data();
                            } else {
                                return false;
                            }
                        });
                    /**
                     * If the meal document exists in the database
                     */
                    if (meal_doc_data) {
                        /**
                         * If the meal was added
                         */
                        if (change.type === "added") {
                            setMeals((meals) => [
                                ...meals,
                                {
                                    id: change.doc.id,
                                    title: meal_doc_data.name,
                                    start: moment(meal_data.for_when.seconds, "X").toDate(),
                                    end: moment(meal_data.for_when.seconds, "X").add(1, "hour").toDate(),
                                    ...change.doc.data(),
                                    ...meal_doc_data,
                                },
                            ]);
                        }
                        /**
                         * Meal updated
                         */
                        if (change.type === "modified") {
                            setMeals((meals) => {
                                let updatedMeals = [...meals];
                                for (let i in updatedMeals) {
                                    if (updatedMeals[i].id === change.doc.id) {
                                        updatedMeals[i] = {
                                            title: meal_doc_data.name,
                                            start: moment(meal_data.for_when.seconds, "X").toDate(),
                                            end: moment(meal_data.for_when.seconds, "X").add(1, "hour").toDate(),
                                            ...change.doc.data(),
                                            ...meal_doc_data,
                                        };
                                        break;
                                    }
                                }
                                return updatedMeals;
                            });
                        }
                    }
                    /**
                     * Meal removed
                     */
                    if (change.type === "removed") {
                        setMeals((meals) => meals.filter((mealElement) => mealElement.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);
    console.log(meals)

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the clients document from the database
         */
        db.doc(`clients/${props.userID}`).get().then((clientDoc) => {
            /**
             * Deconstruct the targets from the users document
             */
            const { nutrition_targets } = clientDoc.data();
            /**
             * If there are some nutrition values found
             */
            if (nutrition_targets) {
                /**
                 * Update the local state 
                 */
                setCalorieTarget(nutrition_targets.calories);
                setCarbTarget(nutrition_targets.carbs);
                setFatTarget(nutrition_targets.fat);
                setProteinTarget(nutrition_targets.protein);
            }
        });
    }, []);

    /**
     * Save the new meal to the clients planner
     */
    const saveMealToClient = async () => {
        /**
         * If there is a meal chosenw ith an accepted datetime
         */
        if (assignMealID.option && assignMealDate) {
            /**
             * Get the meal data from the database
             */
            const mealData = await db.doc(`meals/${assignMealID.option}`)
                .get().then((mealDoc) => {
                    /**
                     * Return all the data for this meal object
                     */
                    return mealDoc.data();
                });
            /**
             * Get the assigned date as milliseconds
             */
            const dateTime = moment(assignMealDate).valueOf();
            /**
             * Add the meal to the clients calendar
             */
            db.collection(`clients/${props.userID}/meals`).add({
                meal: assignMealID.option,
                nutrition: {
                    calories: mealData.calories,
                    carbs: mealData.carbs,
                    fat: mealData.fat,
                    protein: mealData.protein,
                },
                for_when: firebase.firestore.Timestamp.fromMillis(dateTime),
                added_by: auth.currentUser.uid,
            });
            /**
             * Reset the state
             */
            setAssignMealID({});
            setAssignMealDate({});
        }
    }

    /**
     * Check the inputs and add the targets to the clients document
     */
    const saveClientTargets = () => {
        db.doc(`clients/${props.userID}`).set({
            nutrition_targets: {
                calories: calorieTarget,
                carbs: carbTarget,
                fat: fatTarget,
                protein: proteinTarget,
            },
        }, { merge: true });
    }

    return (
        <>
            <div className="calorie-targets">
                <Input
                    type="number"
                    label="Calories:"
                    placeholder="e.g. 2000"
                    wrapperClass="fw"
                    value={calorieTarget}
                    onChange={(e) => setCalorieTarget(e.target.value)} />

                <Input
                    type="number"
                    label="Carbs (g):"
                    placeholder="e.g. 250"
                    wrapperClass="fw"
                    value={carbTarget}
                    onChange={(e) => setCarbTarget(e.target.value)} />

                <Input
                    type="number"
                    label="Fat (g):"
                    placeholder="e.g. 100"
                    wrapperClass="fw"
                    value={fatTarget}
                    onChange={(e) => setFatTarget(e.target.value)} />

                <Input
                    type="number"
                    label="Protein (g):"
                    placeholder="e.g. 175"
                    wrapperClass="fw"
                    value={proteinTarget}
                    onChange={(e) => setProteinTarget(e.target.value)} />

                <Button
                    class="primary"
                    disabled={!calorieTarget || !carbTarget || !fatTarget || !proteinTarget}
                    onClick={() => saveClientTargets()}>
                    Save
                </Button>
            </div>

            <div className="assign-meal-inputs">
                <Select
                    options={trainerMeals}
                    value={assignMealID.value}
                    placeholder="Choose a meal"
                    onSelect={(option) => setAssignMealID(option)} />

                <DatePicker
                    type="text"
                    wrapperClass="fw"
                    placeholder="For when?"
                    value={assignMealDate}
                    onChange={(date) => setAssignMealDate(date)} />

                <Button
                    class="primary"
                    disabled={!assignMealID.option || !assignMealDate}
                    onClick={() => saveMealToClient()}>
                    Add
                </Button>
            </div>

            <Calendar
                view="week"
                step={60 * 4}
                timeslots={1}
                toolbar={false}
                localizer={localizer}
                formats={{
                    eventTimeRangeFormat: () => {
                        return "";
                    }
                }}
                events={meals}
                startAccessor="start"
                endAccessor="end"
                style={{ height: "280px" }}
            />
        </>
    );
}

export default Nutrition;