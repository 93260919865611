import React, { useState, useEffect, createContext } from "react";
import { arrayRemove, arrayUnion, auth, db } from "../firebase";
import firebase from "firebase";

/**
 * Setup a context for storing the "add workout" modal objects
 */
const EditWorkoutContext = createContext();

/**
 * Creating the provider to wrap the add workout modal in for displaying
 */
function EditWorkoutProvider({ children }) {
    const [stage, setStage] = useState(1);

    const [title, setTitle] = useState("");
    const [summary, setSummary] = useState("");

    const [workoutID, setWorkoutID] = useState("");

    const [tags, setTags] = useState([]);
    const [exercisesOrder, setExercisesOrder] = useState([]);

    const [saving, setSaving] = useState(false);
    const [addingSet, setAddingSet] = useState(false);

    useEffect(() => {
        saveWorkoutDraft();
    }, [stage]);

    /**
     * When the workout ID is updated in the state
     */
    useEffect(() => {
        /**
         * If there is a workout ID present in the state
         */
        if (workoutID && (workoutID.length > 0)) {
            /**
             * Setup a listener on the database document for the workout to stream the updates into the 
             * local state for use throughout the context
             */
            const unsubscribe = db.doc(`workouts/${workoutID}`)
                .onSnapshot((workoutSnap) => {
                    /**
                     * Pull some data from the workout snapshot
                     */
                    const { title, summary, exercises_order, tags } = workoutSnap.data();
                    /**
                     * Set the data into the state
                     */
                    setTitle(title);
                    setSummary(summary);
                    setExercisesOrder(exercises_order || []);
                    setTags(tags || []);
                });
            /**
             * Return the unsubscribe method to unload the snapshot listener
             */
            return () => unsubscribe();
        }
    }, [workoutID]);

    const saveWorkoutDraft = async () => {
        /**
         * Update the saving flag
         */
        setSaving(true);
        /**
         * Save a new workout into the collection and mark it as a "draft". This will later be updated 
         * to "false" when all requirements have been met for exercises
         */
        workoutID && await db.doc(`workouts/${workoutID}`).set({
            title,
            summary,
            updated: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true });
        /**
         * Hide the saving flag
         */
        setSaving(false);
    }

    const addNewExercise = async (title) => {
        /**
         * Add the new exercise as a document in the workouts "exercises" collection
         */
        const exerciseID = await db.collection(`workouts/${workoutID}/exercises`).add({
            title,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        }).then((exerciseDoc) => { return exerciseDoc.id });
        /**
         * Push this new exercise ID into the order array for the workout
         */
        await db.doc(`workouts/${workoutID}`).set({
            exercises_order: arrayUnion(exerciseID),
        }, { merge: true });
    }

    const removeExercise = async (exerciseID) => {
        /**
         * Remove the exercise ID from the order array on the workout document
         */
        await db.doc(`workouts/${workoutID}`).set({
            exercises_order: arrayRemove(exerciseID),
        }, { merge: true });
        /**
         * And then remove the exercise document from the database
         */
        await db.doc(`workouts/${workoutID}/exercises/${exerciseID}`).delete();
    }

    const addNewSet = async (exerciseID) => {
        /**
         * Add the new set as a document in the exericses "sets" collection
         */
        const setID = await db.collection(`workouts/${workoutID}/exercises/${exerciseID}/sets`).add({
            created: firebase.firestore.FieldValue.serverTimestamp(),
        }).then((setDoc) => { return setDoc.id });
        /**
         * Push this new set ID into the order array for the exercise
         */
        await db.doc(`workouts/${workoutID}/exercises/${exerciseID}`).set({
            sets_order: arrayUnion(setID),
        }, { merge: true });
    }

    return (
        <EditWorkoutContext.Provider value={{
            workoutID, setWorkoutID,
            stage, setStage,
            title, setTitle,
            summary, setSummary,
            exercisesOrder, setExercisesOrder,
            tags, setTags,
            saving, setSaving,
            saveWorkoutDraft,
            addNewExercise,
            removeExercise,
            addNewSet,
        }}>
            {children}
        </EditWorkoutContext.Provider>
    );
};

export { EditWorkoutContext, EditWorkoutProvider };