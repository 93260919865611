import React, { useContext, useState } from "react";
import { ExerciseContext } from "../../../../utils/providers/exercise";

/**
 * UI components
 */
import Input from "../../../ui/inputs/input";
import Button from "../../../ui/button/button";
import Checkbox from "../../../ui/checkbox/checkbox";

function Details() {
    const [hasSets, setHasSets] = useState(false);

    /**
     * Deconstruct variables from the add meal context
     */
    const {
        setStage,
        title, setTitle,
        sets, setSets,
        reps, setReps,
        rest, setRest,
    } = useContext(ExerciseContext);

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <Input
                    type="text"
                    label="Name:"
                    placeholder="e.g. Chest press"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title} />

                <Checkbox
                    checked={hasSets}
                    onClick={() => setHasSets(!hasSets)}
                    label="Include sets, reps and rest?" />

                <br />

                {hasSets &&
                    <div className="input-group-column cols-3">
                        <Input
                            type="number"
                            label="Sets:"
                            placeholder="e.g. 3"
                            onChange={(e) => setSets(e.target.value)}
                            value={sets} />

                        <Input
                            type="number"
                            label="Reps:"
                            placeholder="e.g. 12"
                            onChange={(e) => setReps(e.target.value)}
                            value={reps} />

                        <Input
                            type="number"
                            label="Rest (seconds):"
                            placeholder="e.g. 60"
                            onChange={(e) => setRest(e.target.value)}
                            value={rest} />
                    </div>
                }
            </div>

            <Button
                class="primary"
                onClick={() => setStage(2)}
                disabled={!title}>
                Continue to Equipment
            </Button>
        </div>
    );
}

export default Details;