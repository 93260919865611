import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { db, auth, arrayUnion } from "../../../utils/firebase";
import useProfilePicture from "../../../hooks/useProfileImage";
import "./trainer.scss";

import { CheckIcon } from "../../../utils/svgs";

/**
 * UI components
 */
import Modal from "../../modal/modal";
import Button from "../../ui/button/button";
import Textarea from "../../ui/inputs/textarea";

/**
 * 
 */
function Trainer(props) {
    const [trainer, setTrainer] = useState({});
    const [viewingTrainer, setViewingTrainer] = useState(false);
    const [messageTrainerBody, setMessageTrainerBody] = useState("");
    const [messageSent, setMessageSent] = useState(false);

    /**
     * Deconstruct the trainer data from the props
     */
    const trainer_data = props.trainer_data;

    /**
     * On component load, fetch the data for the trainer
     */
    useEffect(() => {
        /**
         * Get the trainer document from the database
         */
        db.doc(`trainers/${props.id}`).get().then((trainerDoc) => {
            /**
             * If they exists
             */
            if (trainerDoc.exists) {
                /**
                 * Set their data into the state
                 */
                setTrainer(trainerDoc.data());
            }
        });
    }, []);

    /**
     * Get the profile picture or initals of the trainer
     */
    const profilePicture = useProfilePicture(props.id, "TRAINER");

    /**
     * Try find a conversation with just these two users in it before making a new one
     */
    const findOrStartConversation = async () => {
        /**
         * Perform a query on the database to try and find a conversation that already 
         * exists with both the current users ID and the trainers ID
         */
        const alreadyExists = await db.collection("conversations")
            .where("users", "array-contains", auth.currentUser.uid, props.id)
            .get().then((conversationDocs) => {
                /**
                 * Are there any conversations for this user?
                 */
                if (conversationDocs.size > 0) {
                    /**
                     * Set the initial result to false
                     */
                    let existingConversation = false;
                    /**
                     * Loop through the converstaion documents found
                     */
                    conversationDocs.forEach((conversationDoc) => {
                        /**
                         * Deconstruct the users from the document
                         */
                        const { users } = conversationDoc.data();
                        /**
                         * Is the size of the users array only 2?
                         */
                        if (users.length === 2) {
                            /**
                             * Does the current trainer ID exist in this array? This would 
                             * mean this conversation is private between the current user 
                             * and the trainer
                             */
                            if (users.includes(props.id)) {
                                /**
                                 * If it is, then there is already a conversation between 
                                 * these two users setup
                                 */
                                existingConversation = conversationDoc.id;
                            }
                        }
                    });
                    /**
                     * Return the result
                     */
                    return existingConversation;
                } else {
                    return false;
                }
            });
        /**
         * If the conversation between these two users does not already exist
         */
        if (!alreadyExists) {
            /**
             * Add the conversation to the database collection
             */
            const conversation = await db.collection("conversations").add({
                createdBy: auth.currentUser.uid,
                created: firebase.firestore.Timestamp.now(),
                users: [auth.currentUser.uid, props.id],
                active_users: [auth.currentUser.uid, props.id],
                latest_message: firebase.firestore.FieldValue.serverTimestamp(),
            });
            /**
             * Push the conversation ID onto the two users
             */
            await db.doc(`clients/${auth.currentUser.uid}/conversations/${conversation.id}`).set({
                joined: firebase.firestore.FieldValue.serverTimestamp(),
            });
            await db.doc(`trainers/${props.id}/conversations/${conversation.id}`).set({
                joined: firebase.firestore.FieldValue.serverTimestamp(),
            });
            /**
             * Then add the message into the conversation
             */
            await db.collection(`conversations/${conversation.id}/messages`).add({
                user: auth.currentUser.uid,
                message: messageTrainerBody,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                type: "MESSAGE",
            });
        } else {
            /**
             * Add the user into the "active_users" array
             */
            await db.doc(`conversations/${alreadyExists}`).set({
                active_users: arrayUnion(auth.currentUser.uid),
            }, { merge: true });
            /**
             * Then add the message into the conversation
             */
            await db.collection(`conversations/${alreadyExists}/messages`).add({
                user: auth.currentUser.uid,
                message: messageTrainerBody,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                type: "MESSAGE",
            });
        }
        /**
         * Update the state
         */
        setMessageTrainerBody("");
        setMessageSent(true);
    }

    return (
        <>
            <div className="marketplace-trainer">
                <div className="trainer-inner">
                    <div className="trainer-price">
                        <p>
                            <span>from</span> £{trainer.from_price}
                        </p>
                    </div>
                    <div className="trainer-profile-picture">
                        {profilePicture}
                    </div>
                    <div className="trainer-details">
                        <p className="trainer-name">{trainer.first_name} {trainer.last_name}</p>
                        <p className="trainer-location">{trainer.address?.city}, {trainer.address?.county}</p>
                        <p className="trainer-availability available">
                            <CheckIcon /> Available
                        </p>
                    </div>
                    <div className="trainer-tags">
                        {/* Does the trainer have any tags */}
                        {trainer.tags?.length > 0 &&
                            <>
                                {/* If the trainer has tags, print the first 2 */}
                                {trainer.tags?.length > 0 && trainer.tags.slice(0, 2).map((tag) =>
                                    <div className="trainer-tag">{tag}</div>
                                )}

                                {/* If there are some trainer tags left over to show */}
                                {(trainer.tags?.length - 2) > 0 &&
                                    <div className="trainer-tag-more">+{trainer.tags?.length - 2}</div>
                                }
                            </>
                        }

                        {/* Does the trainer not have any tags */}
                        {trainer.tags?.length === 0 &&
                            <>
                                <small className="no-tags">No tags present</small>
                            </>
                        }
                    </div>
                    <div className="trainer-bio">
                        {trainer.bio &&
                            <p>
                                {trainer.bio.length > 80 ? trainer.bio.substr(0, 80) + "..." : trainer.bio}
                            </p>
                        }
                    </div>
                    <Button class="primary" onClick={() => setViewingTrainer((viewingTrainer) => !viewingTrainer)}>
                        More Info
                    </Button>
                </div>
            </div>

            {/* Are we viewing this trainer */}
            {viewingTrainer && (
                <Modal
                    title={`${trainer.first_name} ${trainer.last_name}`}
                    subtitle={`${trainer.address?.city}, ${trainer.address?.county}`}
                    hide={() => setViewingTrainer((viewingTrainer) => !viewingTrainer)}
                    className="center slim no-pad">
                    <div className="view-trainer-details">
                        <div className="grid-col">
                            <div className="vt-profile-picture">
                                {profilePicture}
                            </div>
                            <div className="vt-classes">
                                <p className="block-heading">Methods:</p>
                                <p>{trainer.session_types?.face_to_face && "Face to face"}</p>
                                <p>{trainer.session_types?.remote && "Online/remote"}</p>
                            </div>
                        </div>
                        <div className="grid-col">
                            <div className="vt-bio">
                                <p>{trainer.bio}</p>
                            </div>
                            <div className="vt-tags">
                                {trainer.tags?.length > 0 && trainer.tags.map((tag) =>
                                    <div className="trainer-tag">{tag}</div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="message-trainer">
                        {messageSent &&
                            <p className="message-sent">Message sent! {trainer.first_name} will be in touch shortly.</p>
                        }

                        {!messageSent &&
                            <>
                                <Textarea
                                    type="text"
                                    label="Message:"
                                    wrapperClass="fw"
                                    placeholder={`e.g. Hey ${trainer.first_name}! I'd like to learn more about your....`}
                                    value={messageTrainerBody}
                                    onChange={(e) => setMessageTrainerBody(e.target.value)}
                                    rows={4}
                                />
                                <Button class="primary" onClick={() => findOrStartConversation()} disabled={!messageTrainerBody}>
                                    Message {trainer.first_name}
                                </Button>
                            </>
                        }
                    </div>
                </Modal>
            )}
        </>
    );
}

export default Trainer;
