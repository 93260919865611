import React, { useEffect, useContext } from "react";
import { EventContext } from "../../../../utils/providers/event";
import moment from "moment";
import "./add.scss";

/**
 * Add event stages
 */
import Details from "./stages/details";
import Goals from "./stages/goals";
import Save from "./stages/save";

/**
 * Functional component to return the form for adding new events
 */
function AddEventClient(props) {
    /**
     * Deconstruct starting date from the props
     */
    const { startingDate } = props;

    /**
     * Deconstruct the stage vars from the context
     */
    const { stage, setStage, setStartTime } = useContext(EventContext);

    /**
     * On the update of a starting date from the calendar day click event
     */
    useEffect(() => {
        /**
         * Is there a startingDate present
         */
        if (startingDate) {
            /**
             * Push it into the new event context
             */
            setStartTime(moment(startingDate).add(9, "hours"));
        }
    }, [startingDate]);

    /**
     * Check the stage the user is trying to navigate towards before continuing, only 
     * allowing them to move back to a stage that has previoulsy been filled out
     */
    const processNavClick = (newStage) => {
        /**
         * If the nav item clicked is in the past
         */
        if (stage >= newStage) {
            /**
             * Move back in the stage
             */
            setStage(newStage);
        }
    }

    return (
        <div className="add-meal-modal">
            <div className="add-meal-modal-nav">
                <p
                    onClick={() => processNavClick(1)}
                    className={[
                        (stage === 1) && "is-active",
                        (stage > 1) && "is-past"
                    ].join(" ")}>
                    Details
                </p>
                <p
                    onClick={() => processNavClick(2)}
                    className={[
                        (stage === 2) && "is-active",
                        (stage > 2) && "is-past"
                    ].join(" ")}>
                    Goals
                </p>
                <p
                    onClick={() => processNavClick(3)}
                    className={[
                        (stage === 3) && "is-active",
                        (stage > 3) && "is-past"
                    ].join(" ")}>
                    Save
                </p>
            </div>

            <div className="add-meal-modal-inputs">
                {(stage === 1) && <Details />}
                {(stage === 2) && <Goals />}
                {(stage === 3) && <Save close={() => props.close()} />}
            </div>
        </div>
    );
}

export default AddEventClient;