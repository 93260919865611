import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { db, auth } from "../../../utils/firebase";
import { CalendarIcon, WorkoutsIcon, ClientsIcon, PoundIcon } from '../../../utils/svgs';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "../dashboard.scss";

import ClientsList from '../../../components/lists/clients/clients';

const localizer = momentLocalizer(moment);

function TrainerDashboard() {
    const [events, setEvents] = useState([]);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup the listener on the database
         */
        const unsubscribe = db.collection("events")
            .where("users", "array-contains", auth.currentUser.uid).onSnapshot((events_snap) => {
                /**
                 * Loop over each of the events and parse them into an array to load into state
                 */
                events_snap.docChanges().forEach((change) => {
                    /**
                     * Deconstruct the data from the update call
                     */
                    const event_data = change.doc.data();

                    let participants = [];

                    change.doc.data().users?.forEach((user) => {
                        if (user !== auth.currentUser.uid) {
                            db.doc(`clients/${user}`).get().then((userDoc) => {
                                if (userDoc.exists) {
                                    const { first_name, last_name } = userDoc.data();
                                    participants.push(`${first_name} ${last_name}`);
                                }
                            });
                        }
                    });

                    /**
                     * Event added
                     */
                    if (change.type === "added") {
                        setEvents((event) => [
                            ...event,
                            {
                                id: change.doc.id,
                                participants: participants,
                                start: moment(event_data.starts.seconds, "X").toDate(),
                                end: moment(event_data.ends.seconds, "X").toDate(),
                                ...change.doc.data()
                            }
                        ]);
                    }
                    /**
                     * Event updated
                     */
                    if (change.type === "modified") {
                        setEvents((event) => {
                            let updatedEvents = [...event];
                            for (let i in event) {
                                if (event[i].id === change.doc.id) {
                                    updatedEvents[i] = {
                                        id: change.doc.id,
                                        participants: participants,
                                        start: moment(event_data.starts.seconds, "X").toDate(),
                                        end: moment(event_data.ends.seconds, "X").toDate(),
                                        ...change.doc.data()
                                    };
                                    break;
                                }
                            }
                            return updatedEvents;
                        });
                    }
                    /**
                     * Event removed
                     */
                    if (change.type === "removed") {
                        setEvents((event) => event.filter((eventElement) => eventElement.id !== change.doc.id));
                    }
                });
            });

        return () => unsubscribe();
    }, []);

    return (
        <>
            <div className="page-title">
                <h1>Dashboard</h1>
            </div>

            <div className="dashboard-cards">
                <h1 className="section-title no-top-pad">Jump back in</h1>
                <div className="dashboard-cards-grid">
                    <NavLink to="/app/calendar" className="dashboard-card">
                        <div className="dashboard-card-inner">
                            <CalendarIcon />
                            <h1>Calendar</h1>
                        </div>
                    </NavLink>
                    <NavLink to="/app/clients" className="dashboard-card">
                        <div className="dashboard-card-inner">
                            <ClientsIcon />
                            <h1>Clients</h1>
                        </div>
                    </NavLink>
                    <NavLink to="/app/workouts" className="dashboard-card">
                        <div className="dashboard-card-inner">
                            <WorkoutsIcon />
                            <h1>Workouts</h1>
                        </div>
                    </NavLink>
                    {/* <NavLink to="/app/payments" className="dashboard-card disabled">
                        <div className="dashboard-card-inner">
                            <PoundIcon />
                            <h1>Payments</h1>
                        </div>
                    </NavLink> */}
                </div>
            </div>

            <div className="dashboard-calendar">
                <h1 className="section-title">This weeks agenda</h1>
                <Calendar
                    localizer={localizer}
                    events={events}
                    view="week"
                    startAccessor="start"
                    endAccessor="end"
                    style={{ flex: "1 1 400px", height: "450px" }} />
            </div>

            <div className="dashboard-clients">
                <h1 className="section-title">Your active clients</h1>
                <ClientsList />
            </div>
        </>
    );
}

export default TrainerDashboard;