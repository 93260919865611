import { TimesIcon } from "../../utils/svgs";
import "./modal.scss";

/**
 * Component for the Modals
 */
function Modal(props) {
    return (
        <div className="modal-page">
            <div className={["modal-box", props.className].join(" ")}>
                <div className="modal-header">
                    {/* Title section */}
                    <div className="modal-title">
                        {props.title && <h1>{props.title}</h1>}
                        {props.subtitle && <h2>{props.subtitle}</h2>}
                    </div>
                    {/* Modal close button */}
                    <div className="modal-close" onClick={() => props.hide()}>
                        <TimesIcon />
                    </div>
                </div>
                <div className="modal-body">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default Modal;
