import React from "react";

/**
 * UI components
 */
import Navigation from "../navigation/navigation";
import Footer from '../footer/footer';

/**
 * Images
 */
import bannerImage from "../../../assets/images/website/homepage-banner.png";

/**
 * Functional component to return the terms and conditions page
 */
function TermsAndConditions() {
    return (
        <>
            <section className="general-banner" style={{ backgroundImage: `url(${bannerImage})` }}>
                <Navigation />

                <div className="banner-text">
                    <h1>Terms and Conditions</h1>
                </div>
            </section>

            <section className="document-wrapper padding-block">
                <div className="max-width">
                    <div className="document">
                        <p>FitPortal Terms &amp; conditions - Last updated: Sep 29th, 2021</p>

                        <h2>1. Definitions</h2>

                        <p>1.1 The following definitions shall apply:&nbsp;</p>

                        <p><strong>Authorised Users</strong>: those employees, agents and independent contractors of the Customer who are authorised by the Customer to use the Platform, the Services and the Documentation.&nbsp;&nbsp;</p>

                        <p><strong>Business Day</strong>: a day other than a Saturday, Sunday or public holiday in England, when banks in London are open for business.</p>

                        <p><strong>Business Hours</strong>: 9.00am to 5pm GMT on each Business Day.</p>

                        <p><strong>Confidential Information</strong>: information that is proprietary or confidential and is either clearly labelled as such or identified as Confidential Information in clause 14.</p>

                        <p><strong>Charges</strong>: the amounts payable by the Customer to the Supplier under this Contract as specified in the relevant Pricing Plan;</p>

                        <p><strong>Contract</strong>: the contract between the Supplier and the Customer for the provision of the Platform as a service, incorporating these terms and conditions.</p>

                        <p><strong>Customer</strong>: the person, company or firm who wishes to use the Supplier's service in its business operations.</p>

                        <p><strong>Customer Data</strong>: the data inputted by the Customer, Authorised Users, or the Supplier on the Customer's behalf for the purpose of using the Services or facilitating the Customer's use of the Services.</p>

                        <p><strong>Customer Materials:</strong> all works and materials:</p>

                        <p>(a) uploaded to, stored on, processed using or transmitted via the Platform by or on behalf of the Customer or by any person or application or automated system using the Customer's account; and</p>

                        <p>(b) otherwise provided by the Customer to the Supplier in connection with the Agreement.</p>

                        <p><strong>Data Protection Legislation</strong>: the UK Data Protection Legislation and any other European Union legislation relating to personal data and all other legislation and regulatory requirements in force from time to time which apply to a party relating to the use of personal data (including, without limitation, the privacy of electronic communications).&nbsp;</p>

                        <p>Defect(s): means a defect, error or bug having a materially adverse effect on the appearance, operation or functionality of the Platform, including any defect, error or bug caused by or arising as a result of:</p>

                        <p>(a) an act or omission of the Customer, or an act or omission of one of the Customer's Authorised Users, employees, officers, agents, suppliers or sub-contractors; or</p>

                        <p>(b) an incompatibility between the Platform and any other system, application, program or software not specified as compatible in the Documentation.</p>

                        <p>Documentation: the document made available to the Customer by the Supplier online via https://www.fitportal.uk or such other web address notified by the Supplier to the Customer from time to time which sets out a description of the Services and the user instructions for the Services.</p>

                        <p>Effective Date: the date the Contract comes into effect between the Supplier and the Customer.&nbsp;</p>

                        <p>Force Majeure Event: means the events listed in clause 15.</p>

                        <p>Initial Subscription Term: means 30 calendar days from the date the Contract is formed between the Customer and the Supplier.</p>

                        <p>Intellectual Property Rights: means all patents, utility models, rights to inventions, copyright and neighbouring and related rights, trademarks and service marks, business names and domain names, rights in get-up and trade dress, goodwill and the right to sue for passing off or unfair competition, rights in designs, database rights, rights in computer software and source code, rights to use, and protect the confidentiality of, confidential information (including know-how and trade secrets) and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, renewals or extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection that subsist or will subsist now or in the future in any part of the world.</p>

                        <p>Platform: means the online software platform known as FitPortal, that is owned and operated by the Supplier and that will be made available to the Customer as a service via the internet under the Agreement.</p>

                        <p>Pricing Plan: means the pricing plan chosen by the Customer as advertised on the Supplier’s website from time to time, which entitle the Customer to a certain number of user accounts and active projects at any given time.</p>

                        <p>Services: the subscription services applicable to the use of the Platform as provided by the Supplier to the Customer under this agreement, as more particularly described in the Documentation.</p>

                        <p>Signing Page: means the online signature page each Customer is obliged to complete and sign in order to subscribe to the Platform and receive the Services in accordance with these terms.&nbsp;</p>

                        <p>Subscription Fees: the subscription fees payable by the Customer to the Supplier for the use of the Platform in accordance with the Pricing Plan as set out from time to time on the Supplier’s website https://www.fitportal.uk.</p>

                        <p>Subscription Term: has the meaning given in clause &nbsp; (being the Initial Subscription Term together with any subsequent Renewal Periods as defined in clause 10).</p>

                        <p>Supplier: FitPortal Ltd, registered in England and Wales with company number [TODO_COMPANY_NUMBER], whose registered office address is at [TODO_COMPANY_aDDRESS].</p>

                        <p>Terms: the terms and conditions set out in this document.&nbsp;</p>

                        <p>Upgrades: means new versions of, and updates to, the Platform, whether for the purpose of fixing an error, bug or other issue in the Platform or enhancing the functionality of the Platform.</p>

                        <p>Virus/Viruses: any thing or device (including any software, code, file or programme) which may prevent, impair or otherwise adversely affect the operation of any computer software relevant to the Platform, or any hardware or network, any telecommunications service, equipment or network or any other service or device; prevent, impair or otherwise adversely affect access to or the operation of any programme or data, including the reliability of any programme or data (whether by re-arranging, altering or erasing the programme or data in whole or part or otherwise), or adversely affect the user experience, including worms, trojan horses, viruses and other similar things or devices.</p>

                        <h2>2. Basis Of Contract</h2>

                        <p>2.1 These Conditions apply to each Contract placed pursuant to these Conditions to the exclusion of any other terms that the Customer seeks to impose or incorporate, or which are implied by law, trade custom, practice or course of dealing;</p>

                        <p>2.2 The completion of the Signing Page by the Customer via the Supplier’s website https://www.fitportal.uk constitutes an offer by the Customer to subscribe to the Platform and purchase the Services in accordance with the chosen Pricing Plan and these Terms. The Customer is responsible for ensuring that the information set out on the Signing Page are complete and accurate in all respects;</p>

                        <p>2.3 The Customer’s offer shall only be deemed to be accepted and the Contract shall only come into legal effect on completion of the sequence of events detailed in clause 2.4.</p>

                        <p>2.4 In order to conclude the Contract, the Customer must: (a) visit the sign up page on the Supplier’s website at https://www.fitportal.uk/signup; (b) the Customer must select which account type they are opening; (c) the Customer must then complete the Signup Page, provide the required information and confirm its acceptance of these Terms; (d) the Supplier will then create an account for the Customer and will contact its nominated payment account provider (Stripe API) to successfully integrate the Customer’s payment information with the subscription;&nbsp; (e) once payment has been received by the Supplier the Customer will be given access to the Platform and this Contract will come into effect.&nbsp;</p>

                        <p>2.5 No amendment or variation of these Terms shall apply to the Contract unless expressly agreed to in writing by the Supplier;&nbsp;</p>

                        <p>2.6 Any descriptive matter or advertising produced by the Supplier and any descriptions or illustrations contained in the Supplier’s catalogues, brochures or on the Supplier’s website are produced for the sole purpose of giving an approximate idea of the Platform and the Services offered and they shall not form part of the Contract or have any contractual force.&nbsp;</p>

                        <h2>3. The Platform</h2>

                        <p>3.1 The Supplier will make available the Platform to the Customer by setting up an account for the Customer on the Platform, and providing to the Customer login details for that account as soon as practicable following the Effective Date.</p>

                        <p>3.2 Subject to clauses 3.3 and 10, the Supplier hereby grants to the Customer and its Authorised Users a non-exclusive, non-transferable right, without the right to grant sub-licences, to permit the Authorised Users to use the Platform, the Services and the Documentation during the Subscription Term.&nbsp;</p>

                        <p>3.3 In relation to the Authorised Users, the Customer warrants and undertakes to the Supplier that:</p>

                        <p>3.3.1 the maximum number of Authorised Users that it authorises to access and use the Platform, the Services and the Documentation shall not exceed the number of&nbsp; agency subscriptions it has purchased from time to time on the relevant Pricing Plan;</p>

                        <p>3.3.2 the Customer acknowledges that it may change, add or remove an Authorised User provided that it notifies the Supplier in advance in writing and follows the change procedure outlined by the Supplier from time to time;&nbsp;</p>

                        <p>3.3.3 the Platform must not be used at any point in time by more than the number of concurrent Authorised Users specified in the Customer’s chosen Pricing Plan, providing that the Customer may add or remove concurrent user licences in accordance with the procedure outlined by the Supplier from time to time;</p>

                        <p>3.3.4 the Platform may only be used by the employees, agents and subcontractors of the Customer and:</p>

                        <p>3.3.4.1 where the Customer is a company, the Customer's officers;</p>

                        <p>3.3.4.2 where the Customer is a partnership, the Customer's partners; and</p>

                        <p>3.3.4.3 where the Customer is a limited liability partnership, the Customer's members;</p>

                        <p>3.3.5 the Customer shall, and shall ensure that its Authorised Users shall, comply with the Supplier’s acceptable use policy from time to time in force, as shown on the Supplier’s website.&nbsp;</p>

                        <h2>4. Customer Obligations&nbsp;</h2>

                        <p>4.1 The Customer shall not, and shall ensure that any Authorised User, employee, subcontractor, agent or client shall not access, store, distribute or transmit any Viruses, or any material during the course of its use of the Platform or the Services that:</p>

                        <p>4.1.1 is unlawful harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically offensive;</p>

                        <p>4.1.2 facilitates illegal activity;</p>

                        <p>4.1.3 depicts sexually explicit images;</p>

                        <p>4.1.4 promotes unlawful violence;</p>

                        <p>4.1.5 is discriminatory based on race, gender, colour, religious belief, sexual orientation, disability; or</p>

                        <p>4.1.6 is otherwise illegal or causes damage or injury to any person or property;&nbsp;</p>

                        <p>and the Supplier reserves the right, without liability or prejudice to its other rights to the Customer, to disable the Customer's access to any material that breaches the provisions of this clause.</p>

                        <p>4.2 The Customer shall not, and shall ensure that any Authorised User, employee, subcontractor, agent or client shall not:</p>

                        <p>4.2.1 copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of the Platform or the underlying software and/or Documentation (as applicable) in any form or media or by any means; or</p>

                        <p>4.2.2 de-compile, reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Platform or underlying software; or</p>

                        <p>4.2.3 access all or any part of the Platform in order to build a product or service which competes with the Platform or the Services or the Documentation; or</p>

                        <p>4.2.4 not alter or adapt or edit the Platform save as expressly permitted by the Documentation.</p>

                        <p>4.3 For the avoidance of doubt neither the Customer not its Authorised Users, employees, officers, sub-contractors or agents have the right to access the object code or source code of the Platform, either during or after the Subscription Term.</p>

                        <p>4.4 The Customer shall use all reasonable endeavours to prevent any unauthorised access to, or use of, the Platform, the Services and/or the Documentation and, in the event of any such unauthorised access or use, promptly notify the Supplier.</p>

                        <h2>5. Services &amp; Upgrades</h2>

                        <p>5.1 The Supplier shall, during the Subscription Term, provide the Platform, the Services and make available the Documentation to the Customer and its Authorised Users on and subject to these Terms.&nbsp;</p>

                        <p>5.2 The Supplier shall use commercially reasonable endeavours to make the Platform and the Services available 24 hours a day, seven days a week, except for:</p>

                        <p>5.2.1 any planned maintenance performed outside Business Hours during the Subscription Term which shall be notified by the Supplier to the Customer in advance; and</p>

                        <p>5.2.2 any unscheduled maintenance performed during Business Hours, provided that the Supplier has used reasonable endeavours to give the Customer at least 2 Business Hours' notice in advance.</p>

                        <p>5.3 The Supplier will, as part of the Services and at no additional cost to the Customer, provide the Customer with the Supplier's standard customer support services during Business Hours in accordance with the Supplier's Support Services Policy in effect at the time that the Services are provided.&nbsp; The Supplier may amend the Support Services Policy in its sole and absolute discretion from time to time.&nbsp;&nbsp;</p>

                        <p>5.4 The Supplier may sub-contract the provision of any of the Services without obtaining the consent of the Customer.</p>

                        <p>5.5 The Customer acknowledges that from time to time during the Subscription Term the Supplier may apply Upgrades to the Platform, and that such Upgrades may result in changes the appearance and/or functionality of the Platform.&nbsp;</p>

                        <p>5.6 The Supplier will give to the Customer reasonable prior written notice of the application of any significant Upgrade to the Platform. Such notice shall include details of the specific changes to the functionality of the Platform resulting from the application of the Upgrade.</p>

                        <p>5.7 The Customer shall not be subject to any additional Charges arising out of the application of the Upgrade, save where the Upgrade introduces materially new functionality to the Platform.&nbsp;</p>

                        <h2>6. Warranties</h2>

                        <p>6.1 The Supplier warrants that that the Platform and the Services will be provided substantially in accordance with the Documentation and as stated on the Supplier’s website and other marketing literature and shall be provided with reasonable skill and care.</p>

                        <p>6.2 The Supplier does not warrant that:&nbsp;</p>

                        <p>6.2.1 the Customer's use of the Platform or the Services will be uninterrupted or error-free;&nbsp;</p>

                        <p>6.2.2 the Platform, the Services, Documentation and/or the information obtained by the Customer through the Platform will meet the Customer's requirements;&nbsp;</p>

                        <p>6.2.3 the Platform will be free from Defects or from Viruses; or&nbsp;</p>

                        <p>6.2.4 the Platform will be compatible with any application, program or software not specifically identified as compatible in the Documentation.&nbsp;</p>

                        <p>6.3 The Supplier shall have no liability under the warranty set out in clause 5.1 if:&nbsp;</p>

                        <p>6.3.1 the Customer fails to pay any of the Charges when due for payment in accordance with these Terms;</p>

                        <p>6.3.2 the Customer is in breach of any of these Terms.&nbsp;</p>

                        <h2>7. Customer Materials</h2>

                        <p>7.1 The Customer grants to the Supplier during the Subscription Term a non-exclusive licence to store, copy and otherwise use the Customer Materials on the Platform for the purposes of operating the Platform, providing the Services, fulfilling its other obligations under the Contract, and exercising its rights under the Contract.</p>

                        <p>7.2 The Customer warrants and represents to the Supplier that the Customer Materials, and their use by the Supplier in accordance with the terms of the Contract, will not:</p>

                        <p>7.2.1 breach any laws, statutes, regulations or legally-binding codes;</p>

                        <p>7.2.2 infringe any person's Intellectual Property Rights or other legal rights; or</p>

                        <p>7.2.3 give rise to any cause of action against the Supplier or the Customer or any third party,</p>

                        <p>in each case in England and Wales and under English law.</p>

                        <p>7.3 Where the Supplier reasonably suspects that there has been a breach by the Customer of the provisions of this clause 6, the Supplier may:&nbsp;</p>

                        <p>7.3.1 delete or amend the relevant Customer Materials; and/or</p>

                        <p>7.3.2 suspend any or all of the Services and/or the Customer’s access to the Platform while it investigates the matter.&nbsp;</p>

                        <p>7.4 Any breach by the Customer of this clause 5 will be deemed to be a material breach of the Contract for the purposes of clause 10.</p>

                        <p>7.5 The Supplier shall ensure that the Customer Materials stored and processed by the Platform are stored separately from, and are not co-mingled with, the materials of other customers of the Supplier.</p>

                        <h2>8. Charges</h2>

                        <p>A charge setup through FitPortal is a transaction between two users. Any payment made by the end user is deposited into the other parties connected account, hosted with stripe after FitPortal take our fees.</p>

                        <h2>9. Limitation Of Liability</h2>

                        <p>9.1 The restrictions on liability in this clause 9 apply to every liability arising under or in connection with the Contract including liability for breach of contract, tort (including negligence), misrepresentation, restitution or otherwise.</p>

                        <p>9.2 Nothing in the Contract limits or excludes any liability which cannot legally be limited or excluded, including liability for:</p>

                        <p>9.2.1 death or personal injury caused by negligence; or</p>

                        <p>9.2.2 fraud or fraudulent misrepresentation;</p>

                        <p>9.3 Subject to clause 9.2, the Supplier's total liability to the Customer shall not exceed the total of the Charges paid by the Customer to the Supplier during the Subscription Term.&nbsp;</p>

                        <p>9.4 Subject to clause [CHARGE_CLAUSE], the following types of loss are wholly and expressly excluded:</p>

                        <p>9.4.1 loss of profits;</p>

                        <p>9.4.2 loss of sales or business;</p>

                        <p>9.4.3 loss of agreements or contracts;</p>

                        <p>9.4.4 loss of anticipated savings;</p>

                        <p>9.4.5 loss of use or corruption of software, data or information;</p>

                        <p>9.4.6 loss of or damage to goodwill; and</p>

                        <p>9.4.7 any indirect or consequential loss not already covered in clauses 9.4.1 to 9.4.6.&nbsp;</p>

                        <p>9.5 This clause 9 shall survive termination of the Contract.</p>

                        <h2>10. Termination</h2>

                        <p>10.1 Without limiting its other rights or remedies, the Supplier may terminate this Contract with immediate effect by giving written notice to the Customer if:</p>

                        <p>10.1.1 the Customer commits a material breach of any term of the Contract and (if such a breach is remediable) fails to remedy that breach within 14 days of the Customer being notified by the Supplier to do so;</p>

                        <p>10.1.2 the Customer takes any step or action in connection with its entering administration, provisional liquidation or any composition or arrangement with its creditors (other than in relation to a solvent restructuring), being wound up (whether voluntarily or by order of the court, unless for the purpose of a solvent restructuring), having a receiver appointed to any of its assets or ceasing to carry on business;</p>

                        <p>10.1.3 the Customer suspends, threatens to suspend, ceases or threatens to cease to carry on all or a substantial part of its business; or</p>

                        <p>10.1.4 the Customer's financial position deteriorates to such an extent that in the Supplier's opinion the Customer's capability to adequately fulfil its obligations under the Contract has been placed in jeopardy;</p>

                        <p>10.2 Without limiting its other rights or remedies, the Supplier may suspend the Customer’s access to Platform if the Customer becomes subject to any of the events listed in clause 10.1.1 to clause 10.1.4, or the Supplier reasonably believes that the Customer is about to become subject to any of them, or if the Customer fails to pay any amount due under this Contract on the due date for payment;</p>

                        <p>10.3 On termination of the Contract for any reason the licence granted by the Supplier to the Customer under clause 3.2 shall be immediately terminated and the Customer shall pay to the Supplier all of the Supplier's outstanding unpaid invoices together with any applicable interest due.&nbsp;</p>

                        <p>10.4 Any provision of the Contract that expressly or by implication is intended to come into or continue in force on or after termination or expiry of the Contract shall remain in full force and effect.</p>

                        <h2>11. Intellectual Property Rights</h2>

                        <p>11.1 The Customer acknowledges and agrees that the Supplier owns all Intellectual Property Rights in the Platform, any and all Upgrades to the Platform, the Services and the Documentation. Except as expressly stated herein, this agreement does not grant the Customer any Intellectual Property Rights in and to the in the Platform, the Services and the Documentation.&nbsp;</p>

                        <p>11.2 Subject at all times to clause 7.1, all Intellectual Property Rights in the Customer Materials will remain, as between the parties, the sole property of the Customer.</p>

                        <h2>12. Data Protection</h2>

                        <p>12.1 The Supplier and the Customer shall comply with all applicable requirements of the Data Protection Legislation.</p>

                        <p>12.2 The Customer warrants that it has the legal right to disclose all Personal Data that it does in fact disclose to the Provider under or in connection with the Contract.&nbsp;</p>

                        <p>12.3 The Provider shall:&nbsp;</p>

                        <p>12.3.1 only act on instructions from the Customer in relation to the processing of any Personal Data performed by the Provider on behalf of the Customer; and</p>

                        <p>12.3.2 at all times ensure that it has in place appropriate security measures (both technical and organisational) against unlawful or unauthorised processing of Personal Data and against loss or corruption of Personal Data processed by the Provider on behalf of the Customer.</p>

                        <h2>14. Confidentiality</h2>

                        <p>13.1 Each party may be given access to Confidential Information from the other party in order to perform its obligations under this Contract. A party's Confidential Information shall not be deemed to include information that:</p>

                        <p>13.1.1 is or becomes publicly known other than through any act or omission of the receiving party;</p>

                        <p>13.1.2 was in the other party's lawful possession before the disclosure;</p>

                        <p>13.1.3 is lawfully disclosed to the receiving party by a third party without restriction on disclosure; or</p>

                        <p>13.1.4 independently developed by the receiving party, which independent development can be shown by written evidence.</p>

                        <p>13.2 Subject to clause 14.4, each party shall hold the other's Confidential Information in confidence and not make the other's Confidential Information available to any third party, or use the other's Confidential Information for any purpose other than the implementation of this Contract.&nbsp;</p>

                        <p>13.3 Each party shall take all reasonable steps to ensure that the other's Confidential Information to which it has access is not disclosed or distributed by its employees or agents in violation of the terms of this Contract.&nbsp;</p>

                        <p>13.4 A party may disclose Confidential Information to the extent such Confidential Information is required to be disclosed by law, by any governmental or other regulatory authority or by a court or other authority of competent jurisdiction, provided that, to the extent it is legally permitted to do so, it gives the other party as much notice of such disclosure as possible and, where notice of disclosure is not prohibited and is given in accordance with this clause 11.4, it takes into account the reasonable requests of the other party in relation to the content of such disclosure.</p>

                        <p>13.5 No party shall make, or permit any person to make, any public announcement concerning this Contract without the prior written consent of the other parties (such consent not to be unreasonably withheld or delayed), except as required by law, any governmental or regulatory authority (including, without limitation, any relevant securities exchange), any court or other authority of competent jurisdiction.</p>

                        <p>13.6 The provisions of this clause 14 shall survive termination of this Contract, however arising.</p>

                        <h2>14. Force Majeure&nbsp;</h2>

                        <p>14.1 In this Contract a “Force Majeure Event” shall mean any circumstances beyond the reasonable control of Supplier, including but not limited to acts or restraints of government or public authorities, pandemics or epidemics; strikes, lockouts or other industrial disputes; failure of supplies of power, fuel, transport, equipment or other goods or services or utilities; terrorist attack, civil war, civil commotion or riots, war, threat of or preparation for war, armed conflict, imposition of sanctions, blockage, embargo, or breaking off of diplomatic relations; acts of God, flood, drought, earthquake or other natural disaster; nuclear chemical or biological contamination; collapse of buildings, fire, explosion or accident; or non-performance by suppliers or subcontractors;</p>

                        <p>14.2 Neither party shall be in breach of the Contract nor liable for delay in performing, or failure to perform, any of its obligations under the Contract if such delay or failure result from a Force Majeure Event. In such circumstances, subject to clause 14.3, the affected party shall be entitled to a reasonable extension of the time for performing such obligations;</p>

                        <p>14.3 If the period of delay or non-performance continues for 12 weeks, the party not affected may terminate this agreement immediately by serving written notice to the affected party.</p>

                        <h2>15. General</h2>

                        <p>15.1 The Supplier may at any time assign, transfer, mortgage, charge, subcontract, delegate, declare a trust over or deal in any other manner with all or any of its rights or obligations under the Contract;</p>

                        <p>15.2 The Customer may not assign, transfer, mortgage, charge, subcontract, delegate, declare a trust over or deal in any other manner with any or all of its rights or obligations under the Contract without the prior written consent of the Supplier.</p>

                        <p>15.3 No variation of this Contract shall be effective unless it is in writing and signed by the parties (or their authorised representatives);</p>

                        <p>15.4 No failure or delay by a party to exercise any right or remedy provided under the Contract or by law shall constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any other right or remedy. No single or partial exercise of such right or remedy shall prevent or restrict the further exercise of that or any other right or remedy;</p>

                        <p>15.5 If any provision or part-provision of the Contract is or becomes invalid, illegal or unenforceable, it shall be deemed deleted, but that shall not affect the validity and enforceability of the rest of this agreement. If any provision of the Contract is deemed deleted under this clause 11.5 the parties shall negotiate in good faith to agree a replacement provision that, to the greatest extent possible, achieves the intended commercial result of the original provision.</p>

                        <p>15.6 Any notice given to a party under or in connection with the Contract shall be in writing and shall be delivered by hand or by pre-paid first-class post or other next working day delivery service at its registered office (if a company) or its principal place of business (in any other case).&nbsp;</p>

                        <p>15.7 Each party undertakes that it shall not at any time disclose to any person any confidential information concerning the business, affairs, customers, clients or suppliers of the other party;&nbsp;</p>

                        <p>15.8 This Contract does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of the Contract.</p>

                        <p>15.9 This Contract constitutes the entire agreement between the parties and supersedes and extinguishes all previous agreements, promises, assurances, warranties, representations and understandings between them, whether written or oral, relating to its subject matter.</p>

                        <p>15.10 This Contract, and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with it or its subject matter or formation, shall be governed by and construed in accordance with the law of England and Wales.</p>

                        <p>15.11 Each party irrevocably agrees that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with this Contract or its subject matter or formation.</p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TermsAndConditions;