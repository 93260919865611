import React, { useEffect, useState } from "react";
import { db, auth } from "../../utils/firebase";
import { MealProvider } from "../../utils/providers/meal";
import "./meals.scss";

/**
 * UI components
 */
import Button from "../../components/ui/button/button";
import Meal from "../../components/meals/meal";
import AddMeal from "../../components/meals/add/add";
import Modal from "../../components/modal/modal";

function Meals() {
    const [meals, setMeals] = useState([]);
    const [mealModal, setMealModal] = useState(false);

    useEffect(() => {
        const unsubscribe = db.collection("meals")
            .where("created_by", "==", auth.currentUser.uid)
            .onSnapshot((mealsSnap) => {
                mealsSnap.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        setMeals((meals) => [
                            ...meals,
                            { id: change.doc.id, ...change.doc.data() }
                        ]);
                    }

                    if (change.type === "modified") {
                        setMeals((meals) => {
                            let updatedMeals = [...meals];
                            for (let i in meals) {
                                if (meals[i].id === change.doc.id) {
                                    updatedMeals[i] = { ...change.doc.data() };
                                    break;
                                }
                            }
                            return updatedMeals;
                        });
                    }

                    if (change.type === "removed") {
                        setMeals((meals) => meals.filter((mealElement) => mealElement.id !== change.doc.id));
                    }
                });
            });

        return () => unsubscribe();
    }, []);

    return (
        <>
            <div className="page-title">
                <div className="page-title-flex with-margin">
                    <h1>Meal Catalog</h1>
                    <Button class="primary" onClick={() => setMealModal(true)}>Create Meal</Button>
                </div>
                <p>Create meals here to assign to your clients for their meal plans.</p>
            </div>

            <div className="meals-container">
                {meals.map((meal) => <Meal key={meal.id} id={meal.id} />)}
            </div>

            {mealModal &&
                <Modal title="Create new meal" hide={() => setMealModal(false)} className="with-nav no-pad">
                    <MealProvider>
                        <AddMeal close={() => setMealModal(false)} />
                    </MealProvider>
                </Modal>
            }
        </>
    );
}

export default Meals;