import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../utils/providers/auth";
import { AlertsContext } from "../../../utils/providers/alerts";
import parsePhoneNumber from "libphonenumber-js";
import { db } from "../../../utils/firebase";

/**
 * UI Components
 */
import Input from "../../../components/ui/inputs/input";
import Button from "../../../components/ui/button/button";
import Textarea from "../../../components/ui/inputs/textarea";
import ProfilePicture from "../../../components/account/profilePicture";
import PhoneNumber from "../../../components/ui/inputs/phone-number";
import Checkbox from "../../../components/ui/checkbox/checkbox";
import Select from "../../../components/ui/inputs/select/new-select";

/**
 * HTML structure for the trainer account page
 */
function TrainerAccount() {
    /**
     * Set some state up for the user details
     */
    const [panel, setPanel] = useState("BIO");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [profileImage, setProfileImage] = useState([]);
    const [mobilePhone, setMobilePhone] = useState("");
    const [formattedNumber, setFormattedNumber] = useState("");
    const [addressLineOne, setAddressLineOne] = useState("");
    const [addressLineTwo, setAddressLineTwo] = useState("");
    const [addressCity, setAddressCity] = useState("");
    const [addressCounty, setAddressCounty] = useState("");
    const [addressPostcode, setAddressPostcode] = useState("");
    const [bio, setBio] = useState("");
    const [gender, setGender] = useState({});
    const [faceToFace, setFaceToFace] = useState(false);
    const [remote, setRemote] = useState(false);
    const [tag, setTag] = useState("");
    const [tags, setTags] = useState([]);
    const [fromPrice, setFromPrice] = useState("");

    /**
     * Get the user from context
     */
    const { user } = useContext(AuthContext);

    const { pushAlert } = useContext(AlertsContext);

    /**
     * Component load
     */
    useEffect(() => {
        /**
         * Set the state with the users details
         */
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setEmail(user.email);
        setProfileImage(user.profilePicture);
        if (user.phone) {
            setMobilePhone(user.phone);
        }
        if (user.address) {
            setAddressLineOne(user.address?.first_line);
            setAddressLineTwo(user.address?.second_line);
            setAddressCity(user.address?.city);
            setAddressCounty(user.address?.county);
            setAddressPostcode(user.address?.postcode);
        }
        setBio(user.bio);
        setRemote(user.session_types?.remote);
        setFaceToFace(user.session_types?.face_to_face);
        setTags(user.tags || []);
        setFromPrice(user.from_price);

        if (user.gender === "male") {
            setGender({ option: "male", value: "Male" })
        } else if (user.gender === "female") {
            setGender({ option: "female", value: "Female" })
        } else {
            setGender({ option: "none", value: "Rather not say" })
        }
    }, []);

    /**
     * When the mobile phone number is updated in the state
     */
    useEffect(() => {
        /**
         * Parse the number with the GB country code of 44 at the start
         */
        const formattedNumber = parsePhoneNumber(mobilePhone, "GB");
        /**
         * Set the now formatted number into the state for saving onto the trainers document
         */
        setFormattedNumber(formattedNumber?.number);
    }, [mobilePhone]);

    /**
     * Saves the users details from state into the database record for them
     *
     * @type {const}
     */
    const saveUserDetails = () => {
        /**
         * Save the details to the database
         */
        db.doc(`trainers/${user.uid}`).set({
            first_name: firstName,
            last_name: lastName,
            phone: formattedNumber,
            address: {
                first_line: addressLineOne,
                second_line: addressLineTwo,
                city: addressCity,
                county: addressCounty,
                postcode: addressPostcode,
            },
            bio,
            gender: gender.option,
            session_types: {
                remote,
                face_to_face: faceToFace,
            },
            tags,
            from_price: Number(parseFloat(fromPrice).toFixed(2)),
        }, { merge: true });
        /**
         * Push an alert into the dom
         */
        pushAlert({
            type: "SUCCESS",
            title: "Profile saved",
        });
    };

    /**
     * Remove an tag from the local array via the name passed in
     */
    const removeTag = (name) => {
        /**
         * Remove the tag from the array
         */
        setTags(tags.filter(tag => tag !== name));
    }

    /**
     * Key down event for the message chat inputs, checks for an "Enter" key to add the tag
     */
    const checkForEnterKey = (key) => {
        /**
         * If the key pressed was enter
         */
        if (key === "Enter") {
            /**
         * Do we have an tag present in the field
         */
            if (tag) {
                /**
                 * Push the tag into the local state
                 */
                setTags((tags) => [...tags, tag]);
                /**
                 * Reset the fields
                 */
                setTag("");
            }
        }
    };

    return (
        <>
            <div className="account-edit-title">
                <h1>{firstName} {lastName}</h1>
            </div>

            <div className="account-navigation">
                <p onClick={() => setPanel("BIO")} className={[
                    (panel === "BIO") && "is-active"
                ].join(" ")}>FitPortal Bio</p>

                <p onClick={() => setPanel("DETAILS")} className={[
                    (panel === "DETAILS") && "is-active"
                ].join(" ")}>Details</p>

                <p onClick={() => setPanel("ADDRESS")} className={[
                    (panel === "ADDRESS") && "is-active"
                ].join(" ")}>Address</p>
            </div>

            {panel === "BIO" &&
                <>
                    {/* Profile details */}
                    <div className="edit-account-column account-column-profile">
                        <h5 className="column-title">FitPortal Bio</h5>
                        <div className="column-field-group cols-1">
                            <ProfilePicture current={profileImage} />
                        </div>
                        <div className="column-field-group cols-1">
                            <Textarea
                                type="text"
                                label="FitPortal Bio"
                                value={bio}
                                wrapperClass="fw"
                                onChange={(e) => setBio(e.target.value)}
                                placeholder="We'll show this to potential clients on FitPortal, so sell yourself! Include things like your qualifications." />

                            <Select
                                type="text"
                                label="Gender:"
                                placeholder="Choose a gender"
                                value={gender.value}
                                wrapperClass="fw"
                                note="Some clients may prefer a certain gender of trainer"
                                onSelect={(option) => setGender(option)}
                                options={{
                                    male: "Male",
                                    female: "Female",
                                    none: "Rather not say",
                                }} />
                        </div>
                        <div className="column-field-group cols-1">
                            <Checkbox
                                checked={remote}
                                onClick={() => setRemote(!remote)}
                                label="Offering remote sessions" />
                            <Checkbox
                                checked={faceToFace}
                                onClick={() => setFaceToFace(!faceToFace)}
                                label="Offering face-to-face sessions" />
                        </div>
                        <div className="column-field-group cols-1">
                            <Select
                                type="text"
                                label="Tags:"
                                placeholder="e.g. Weight Loss/Muscle Gain"
                                onChange={(e) => setTag(e.target.value)}
                                onKeyDown={(e) => checkForEnterKey(e.key)}
                                value={tag}
                                wrapperClass="fw"
                                note="Choose some areas that match your expertise"
                                options={{
                                    weight_loss: "Weight Loss",
                                    strength: "Strength Training",
                                    muscle_gain: "Muscle Gain",
                                    flexibility: "Flexibility Training",
                                    balance: "Balance Training",
                                }}
                                onSelect={(option) => setTags((tags) => [...tags, option.value])}
                                alreadyChosen={tags || []} />

                            <div className="trainer-tags-list">
                                {tags.map((tag, index) => (
                                    <p className="list-item-tag" key={index}>
                                        {tag}
                                        <span onClick={() => removeTag(tag)}>
                                            x
                                        </span>
                                    </p>
                                ))}
                            </div>
                        </div>
                        <div className="column-field-group cols-1">
                            <Input
                                type="number"
                                label="From price (£):"
                                wrapperClass="fw"
                                placeholder="e.g 30"
                                value={fromPrice}
                                onChange={(e) => setFromPrice(e.target.value)}
                                note="What is your minimum rate?" />
                        </div>
                    </div>
                </>
            }

            {panel === "DETAILS" &&
                <>
                    {/* Profile details */}
                    <div className="edit-account-column account-column-profile">
                        <h5 className="column-title">Your Details</h5>
                        <div className="column-field-group cols-2">
                            <Input type="text" label="First name:" wrapperClass="fw" placeholder=" " value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            <Input type="text" label="Last name:" wrapperClass="fw" placeholder=" " value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                        <div className="column-field-group cols-1">
                            <Input type="email" label="Email:" wrapperClass="fw" placeholder=" " value={email} readonly={true} disabled={true} />
                        </div>
                        <div className="column-field-group cols-1">
                            <Input
                                type="tel"
                                label="Phone:"
                                placeholder="e.g. 07123 456 789"
                                value={mobilePhone}
                                onChange={(e) => setMobilePhone(e.target.value)} />


                            {/* <PhoneNumber
                                label="Phone:"
                                placeholder="e.g. +447123 456 789"
                                wrapperClass="fw"
                                value={mobilePhone}
                                onChange={setMobilePhone} /> */}
                        </div>
                        <p className="change-bank-details">If you need to ammend your bank details, please contact us directly.</p>
                    </div>
                </>
            }

            {panel === "ADDRESS" &&
                <>
                    {/* Address details */}
                    <div className="edit-account-column account-column-address">
                        <h5 className="column-title">Address</h5>
                        <div className="column-field-group cols-1">
                            <Input type="text" label="First line of address:" wrapperClass="fw" placeholder=" " value={addressLineOne} onChange={(e) => setAddressLineOne(e.target.value)} />
                        </div>
                        <div className="column-field-group cols-1">
                            <Input type="text" label="Second line of address:" wrapperClass="fw" placeholder=" " value={addressLineTwo} onChange={(e) => setAddressLineTwo(e.target.value)} />
                        </div>
                        <div className="column-field-group cols-1">
                            <Input type="text" label="City/town:" wrapperClass="fw" placeholder=" " value={addressCity} onChange={(e) => setAddressCity(e.target.value)} />
                        </div>
                        <div className="column-field-group cols-2">
                            <Input type="text" label="County:" wrapperClass="fw" placeholder=" " value={addressCounty} onChange={(e) => setAddressCounty(e.target.value)} />
                            <Input type="text" label="Postcode:" wrapperClass="fw" placeholder=" " value={addressPostcode} onChange={(e) => setAddressPostcode(e.target.value)} />
                        </div>

                    </div>
                </>
            }

            <div className="save-button">
                <Button class="primary" onClick={() => saveUserDetails()}>
                    Save changes
                </Button>
            </div>
        </>
    );
}

export default TrainerAccount;
