import React, { useEffect, useState } from "react";
import { db } from "../../../utils/firebase";
import moment from "moment";

/**
 *
 */
function MessageSubscriptionSetup(props) {
    const [name, setName] = useState("");

    /**
     * Get the message data from the props
     */
    const { message_data } = props;

    /**
     * Get the starting from date if it's an on-going plan
     */
    const setup_on = moment.unix(message_data.timestamp.seconds).format("Do [of] MMMM [at] HH:mm");

    /**
     * When the message component loads in, get the client details
     */
    useEffect(() => {
        /**
         * Get the first name of the client who accepted the price
         */
        db.doc(`clients/${message_data.user}`).get().then((clientDoc) => {
            setName(clientDoc.data().first_name);
        });
    }, []);

    return (
        <div className={["message-element global-message payment-successful"].join(" ")}>
            <p>{name}'s subscription was successfully setup on {setup_on}, they have been charged</p>
        </div>
    );
}

export default MessageSubscriptionSetup;
