import React, { useContext, useEffect, useState, useRef } from "react";
import { MessagesContext } from "../../utils/providers/messages";
import { AuthContext } from "../../utils/providers/auth";
import { db, auth, } from "../../utils/firebase";
import firebase from "firebase";

/**
 * UIcomponents
 */
import Input from "../ui/inputs/input";
import Message from "./message";
import SetupPrice from "./setup-price/setup-price";
import ButtonExtendable from "../ui/button/button-extendable";
import Button from "../ui/button/button";

/**
 * Chat window to display the messages from the chat
 */
function ChatWindow({ hideRemove }) {
    const [messages, setMessages] = useState([]);
    const [messageInput, setMessageInput] = useState("");
    const [setupPrice, setSetupPrice] = useState(false);
    const [clientID, setClientID] = useState("");
    const [isAlreadyClient, setIsAlreadyClient] = useState(false);
    const [conversationUsers, setConversationUsers] = useState([]);

    /**
     * Deconstruct the user and active chat from their context APIs
     */
    const { user } = useContext(AuthContext);
    const { activeChat, setActiveChat } = useContext(MessagesContext);

    /**
     * Setup a ref for the chat window
     */
    const chatWindowRef = useRef();

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the conversation from the database
         */
        activeChat && db.doc(`conversations/${activeChat}`).get().then((conversationDoc) => {
            /**
             * Deconstruct the users from the document
             */
            const { users } = conversationDoc.data();
            /**
             * Are there two users in this array? would suggest it's a private chat
             */
            if (users.length === 2) {
                /**
                 * Remove the current users ID from the array, just leaving the clients
                 */
                const cleanUsers = users.filter((user) => user !== auth.currentUser.uid);
                /**
                 * Then push the first (and only) element into the local state as the client ID
                 */
                setClientID(cleanUsers[0]);
            }
            /**
             * 
             */
            const withThisUser = users.filter((user) => user !== auth.currentUser.uid);
            /**
             * 
             */
            setConversationUsers(withThisUser);
        });
    }, [activeChat]);

    /**
     * If there is a client ID passed into the local state, check to see if they are already
     * marked as a client for this trainer
     */
    useEffect(() => {
        /**
         * Attempt to find the client document on the trainers collection
         */
        clientID && db.doc(`trainers/${auth.currentUser.uid}/clients/${clientID}`)
            .get().then((clientDoc) => {
                /**
                 * Does the client document exist
                 */
                if (clientDoc.exists) {
                    /**
                     * Update the local state to change the button
                     */
                    setIsAlreadyClient(true);
                }
            })
    }, [clientID]);

    /**
     * When a activeChat ID is present
     */
    useEffect(() => {
        /**
         * Clear the chat window
         */
        activeChat && setMessages([]);
        /**
         * Hide the "price card" window
         */
        setSetupPrice(false);
    }, [activeChat]);

    /**
     * On component load
     */
    useEffect(() => {
        if (activeChat) {
            /**
             * Setup a listener on the messages sub-collection
             */
            const unsubscribe = db.collection(`conversations/${activeChat}/messages`)
                .orderBy("timestamp", "asc")
                .onSnapshot((messageDocs) => {
                    /**
                     * Loop over the changes passed down
                     */
                    messageDocs.docChanges().forEach((change) => {
                        /**
                         * Message added
                         */
                        if (change.type === "added") {
                            setMessages((messages) => [...messages, {
                                id: change.doc.id,
                                ...change.doc.data()
                            }]);
                        }
                        /**
                         * Message edited
                         */
                        if (change.type === "modified") {
                            setMessages((messages) => {
                                let updatedMessages = [...messages];
                                for (let i in messages) {
                                    if (messages[i].id === change.doc.id) {
                                        updatedMessages[i] = {
                                            id: change.doc.id,
                                            ...change.doc.data()
                                        };
                                        break;
                                    }
                                }
                                return updatedMessages;
                            });
                        }
                        /**
                         * Message removed
                         */
                        if (change.type === "removed") {
                            setMessages((messages) => messages.filter((message) => message.id !== change.doc.id));
                        }
                    });
                    /**
                     * Scroll to the bottom of the chat window again
                     */
                    scrollToChatBottom();
                });
            /**
             * Remove the listener on component unload
             */
            return () => unsubscribe();
        }
    }, [activeChat]);

    /**
     * Scroll to the bottom of the chat window
     */
    const scrollToChatBottom = () => {
        /**
         * Make sure there is an active chat window
         */
        if (chatWindowRef.current) {
            /**
             * Scroll to the bottom of the chat window to show the most recent
             */
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    };

    /**
     * Key down event for the message chat inputs, checks for an "Enter" key to send the message
     *
     * @type {const}
     * @param {string} key Key code passed in from the input key down event
     */
    const checkForEnterKey = (key) => {
        /**
         * If the key pressed was enter
         */
        if (key === "Enter") {
            /**
             * Send the new message into the collection
             */
            sendNewMessage();
        }
    };

    /**
     * Send the new message into the conversation subcollection
     *
     * @type {const}
     */
    const sendNewMessage = async () => {
        /**
         * Make sure the input is longer than 1 charchter
         */
        if (!setupPrice && messageInput.length > 0) {
            /**
             * Add the message into the collection
             */
            await db.collection(`conversations/${activeChat}/messages`).add({
                user: auth.currentUser.uid,
                message: messageInput,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                type: "MESSAGE",
            });
            /**
             * Update the latest message timestamp for the conversation
             */
            await db.doc(`conversations/${activeChat}`).set({
                latest_message: firebase.firestore.FieldValue.serverTimestamp(),
                unread_by: conversationUsers,
            }, { merge: true });
            /**
             * Clear the input
             */
            setMessageInput("");
        }
    };

    /**
     * Add the user as a client for the current user (trainer)
     */
    const addAsClient = async () => {
        /**
         * Add the trainer ID to the clients "trainers" collection
         */
        await db.doc(`clients/${clientID}/trainers/${auth.currentUser.uid}`).set({
            connected: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true });
        /**
         * then add the client ID to the trainer "clients" collection
         */
        await db.doc(`trainers/${auth.currentUser.uid}/clients/${clientID}`).set({
            connected: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true });
    }

    /**
     * Remove the user as a client from the current trainer
     */
    const removeAsClient = async () => {
        /**
         * Add the trainer ID to the clients "trainers" collection
         */
        await db.doc(`clients/${clientID}/trainers/${auth.currentUser.uid}`).delete();
        /**
         * then add the client ID to the trainer "clients" collection
         */
        await db.doc(`trainers/${auth.currentUser.uid}/clients/${clientID}`).delete();
        /**
         * @todo Need to cancel off subscriptions between the two here too
         */
    }

    /**
     * Is this the active chat in the context?
     */
    if (activeChat) {
        return (
            <div className="chat-window-wrapper">
                {/* Messages */}
                <div className="chat-messages" ref={chatWindowRef}>
                    {/* Are we setting up a price? */}
                    {setupPrice &&
                        <SetupPrice
                            activeChat={activeChat}
                            hideSetupPrice={() => setSetupPrice(false)} />
                    }

                    {/* Are we not sending a price? */}
                    {!setupPrice &&
                        <>
                            {/* Are there any messages to show */}
                            {(messages.length > 0) &&
                                messages.map((message) =>
                                    <Message
                                        key={message.id}
                                        id={message.id}
                                        data={message}
                                        chat={activeChat} />)
                            }

                            {/* If there are not any messages to show */}
                            {(messages.length === 0) &&
                                <div className="start-of-conversation">
                                    This is the start of your conversation
                                </div>
                            }
                        </>
                    }
                </div>

                {/* Input field */}
                <div className="chat-input">
                    <Input
                        wrapperClass="fw"
                        placeholder="Start typing..."
                        value={messageInput}
                        onChange={(e) => setMessageInput(e.target.value)}
                        onKeyDown={(e) => checkForEnterKey(e.key)} />

                    {/* Is this user a trainer? */}
                    {(user?.is_trainer) &&
                        <>
                            {!hideRemove &&
                                <>
                                    {!isAlreadyClient &&
                                        <ButtonExtendable
                                            label="Send"
                                            onClick={sendNewMessage}
                                            actions={[
                                                {
                                                    label: "Add as client",
                                                    onClick: () => addAsClient(),
                                                },
                                                {
                                                    label: "Send price card",
                                                    onClick: () => setSetupPrice(true),
                                                },
                                            ]} />
                                    }

                                    {isAlreadyClient &&
                                        <ButtonExtendable
                                            label="Send"
                                            onClick={sendNewMessage}
                                            actions={[
                                                {
                                                    label: "Remove as client",
                                                    onClick: () => removeAsClient(),
                                                },
                                                {
                                                    label: "Send price card",
                                                    onClick: () => setSetupPrice(true),
                                                },
                                            ]} />
                                    }
                                </>
                            }

                            {hideRemove &&
                                <>
                                    {!isAlreadyClient &&
                                        <ButtonExtendable
                                            label="Send"
                                            onClick={sendNewMessage}
                                            actions={[
                                                {
                                                    label: "Send price card",
                                                    onClick: () => setSetupPrice(true),
                                                },
                                            ]} />
                                    }

                                    {isAlreadyClient &&
                                        <ButtonExtendable
                                            label="Send"
                                            onClick={sendNewMessage}
                                            actions={[
                                                {
                                                    label: "Send price card",
                                                    onClick: () => setSetupPrice(true),
                                                },
                                            ]} />
                                    }
                                </>
                            }
                        </>
                    }

                    {/* Is the user a client type? */}
                    {(user?.is_client) &&
                        <Button
                            class="primary"
                            onClick={() => sendNewMessage()}>
                            Send
                        </Button>
                    }
                </div>
            </div>
        );
    } else {
        /**
                * Don't return anything if it isn't
                */
        return null;
    }
}

export default ChatWindow;
