import React from "react";

function CardImage(props) {
    return (
        <div className="ui-card-image">
            {props.image}
        </div>
    );
}

export default CardImage;