import React, { useContext } from "react";
import { MealContext } from "../../../../utils/providers/meal";
import { db, auth } from "../../../../utils/firebase";
/**
 * UI components
 */
import Button from "../../../ui/button/button";

function Save(props) {
    /**
     * Deconstruct variables from the add meal context
     */
    const {
        name,
        prepTime,
        cookingTime,
        instructionsURL,
        tags,
        ingredients,
        instructions,
        calories,
        carbs,
        fat,
        protein,
    } = useContext(MealContext);

    /**
     * Save the meal with the variables from the context
     */
    const saveMeal = () => {
        /**
         * Add the meal into the database
         */
        db.collection("meals").add({
            name,
            calories,
            protein,
            fat,
            carbs,
            preperation_time: prepTime,
            cooking_time: cookingTime,
            created_by: auth.currentUser.uid,
            tags,
            instructions,
            instructionsURL,
            ingredients,
        });
        /**
         * Hide the add meal modal through the props
         */
        props.close();
    }

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <h1>{name}</h1>
                <div className="tags-list summary">
                    {tags.map((tag, index) => (
                        <p className="list-item-tag" key={index}>
                            {tag}
                        </p>
                    ))}
                </div>

                <div className="input-group-column cols-2 weighted">
                    <div className="column-group">
                        <h2>Recipe</h2>
                        {ingredients.map((ingredient, index) => (
                            <p className="list-item" key={index}>
                                {ingredient.measurement}{ingredient.unit} {ingredient.name}
                            </p>
                        ))}
                    </div>

                    <div className="column-group">
                        <h2>Directions</h2>
                        {instructions.map((instruction, index) => (
                            <p className="list-item instructions" key={index}>
                                {index + 1}. {instruction.instruction}
                            </p>
                        ))}
                    </div>
                </div>
            </div>

            <Button
                class="primary"
                onClick={() => saveMeal()}>
                Save Meal
            </Button>
        </div >
    );
}

export default Save;