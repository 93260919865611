import React, { useContext } from "react";
import { SignupContext } from "../../utils/providers/signup";
import { NavLink } from "react-router-dom";

import Input from "../ui/inputs/input";
import Button from "../ui/button/button";

function Terms(props) {
    const {
        setSignupStage,
        errors, setErrors,
        password, setPassword,
        confirmPassword, setConfirmPassword,
    } = useContext(SignupContext);

    const checkInputs = () => {
        let proceed = true;
        let inputErrors = {};

        setErrors({});

        if (!password || password.length <= 6) {
            inputErrors["password"] = "Please enter a valid password";
            proceed = false;
        }

        if (!confirmPassword || confirmPassword.length <= 6) {
            inputErrors["confirmPassword"] = "Please enter a valid password";
            proceed = false;
        }

        if (password !== confirmPassword) {
            inputErrors["password"] = "Passwords must match";
            inputErrors["confirmPassword"] = "Passwords must match";
            proceed = false;
        }

        if (proceed) {
            setSignupStage((signupStage) => signupStage + 1);
        } else {
            setErrors(inputErrors);
        }
    }

    return (
        <>
            <div className="input-block-section">
                <h2>Terms &amp; Conditions</h2>
                <p>Payment processing services for "Trainers" on "FitPortal" are provided by Stripe and are subject to the Stripe Connected Account Agreement, which includes the Stripe Terms of Service (collectively, the “Stripe Services Agreement”). By signing up to FitPortal, you agree to be bound by the Stripe Services Agreement, as the same may be modified by Stripe from time to time. As a condition of FitPortal enabling payment processing services through Stripe, you agree to provide FitPortal accurate and complete information about you and your business, and you authorize FitPortal to share it and transaction information related to your use of the payment processing services provided by Stripe.
                    <br />
                    By signing up to FitPortal I accept the above terms in relation to Stripe along with the FitPortal <NavLink to="/terms-and-conditions" target="_blank">terms and conditions</NavLink> and <NavLink to="/privacy-policy" target="_blank">privacy policy</NavLink> statements.
                </p>
                <div className="input-elements-grid cols-2">
                    <Input type="password"
                        value={password}
                        placeholder="Password:"
                        label="Password:"
                        wrapperClass={errors.password && 'invalid'}
                        note={errors.password}
                        onChange={(e) => setPassword(e.target.value)} />
                    <Input type="password"
                        value={confirmPassword}
                        placeholder="Confirm Password:"
                        label="Confirm Password:"
                        wrapperClass={errors.confirmPassword && 'invalid'}
                        note={errors.confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)} />
                </div>
            </div>

            <div className="signup-next-stage">
                <Button class="primary" onClick={() => checkInputs()}>Continue</Button>
            </div>
        </>
    );
}

export default Terms;