import React, { useContext, useEffect, useState } from "react";
import firebase from "firebase";
import { EventContext } from "../../../../../utils/providers/event";
import { db, auth } from "../../../../../utils/firebase";
import moment from "moment";

/**
 * UI components
 */
import Button from "../../../../ui/button/button";

/**
 * 
 */
function Save(props) {
    const [participantIDs, setParticipantIDs] = useState([auth.currentUser.uid]);

    /**
     * Deconstruct variables from the add event context
     */
    const {
        title,
        comments,
        startTime,
        endTime,
        isAllDay,
        participants,
        contributionGoal,
        contributionValue,
        clientGoals,
        workout,
    } = useContext(EventContext);

    /**
     * Generate some readable dates with moment
     */
    const allDayReadable = moment(startTime).format("DD/MM/YYYY");
    const startReadable = moment(startTime).format("DD/MM/YYYY HH:mma");
    const endReadable = moment(endTime).format("DD/MM/YYYY HH:mma");

    /**
     * Load all the participant IDs into the local state
     */
    useEffect(() => {
        /**
         * Loop over the participants
         */
        participants.map((participant) => {
            /**
             * Psuh the ID onto the state
             */
            return setParticipantIDs([...participantIDs, participant.option]);
        });
    }, [participants]);

    /**
     * Save the event with the variables from the context
     */
    const saveEvent = async () => {
        /**
         * Add the event into the database and return the new document
         */
        const newEvent = await db.collection("events").add({
            title,
            comments,
            all_day: isAllDay,
            users: participantIDs,
            workout: workout.option,
            starts: firebase.firestore.Timestamp.fromMillis(moment(startTime).format("x")),
            ends: firebase.firestore.Timestamp.fromMillis(moment(endTime).format("x")),
            created: firebase.firestore.FieldValue.serverTimestamp(),
            setup_by: auth.currentUser.uid,
        });
        /**
         * Loop through the clients that have goals assigned 
         */
        Object.entries(clientGoals).map((clientGoal) => {
            /**
             * Get the second array element as the goal data
             */
            const goal_data = clientGoal[1];
            /**
             * Add the goal record document to the subcollection
             */
            db.doc(`clients/${goal_data.client}/goals/${goal_data.goal.option}/records/${newEvent.id}`)
                .set({
                    starts: firebase.firestore.Timestamp.fromMillis(moment(startTime).format("x")),
                    ends: firebase.firestore.Timestamp.fromMillis(moment(endTime).format("x")),
                    goal_contribution: goal_data.contribution || 0,
                }, { merge: true });
        });
        /**
         * Hide the add event modal through the props
         */
        props.close();
    }

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <h1>{title}</h1>

                <small className="event-timespan">
                    {isAllDay &&
                        `Scheduled for all day on ${allDayReadable}`
                    }

                    {!isAllDay &&
                        `Scheduled between ${startReadable} and ${endReadable}`
                    }
                </small>

                {contributionGoal?.option &&
                    <p className="workout-sets">
                        When this event has passed, it'll contribute {contributionValue} to your {contributionGoal.value} goal
                    </p>
                }

                <div className="input-group-column">
                    <div className="column-group">
                        <h2>Event Participants</h2>
                        <p className="list-item">
                            You
                        </p>
                        {participants.map((participant, index) => (
                            <p className="list-item" key={index}>
                                {participant.value}
                            </p>
                        ))}
                    </div>
                </div>
            </div>

            <Button
                class="primary"
                onClick={() => saveEvent()}>
                Save Event
            </Button>
        </div >
    );
}

export default Save;