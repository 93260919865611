import { useEffect, useState } from "react";
import { auth, db } from "../../utils/firebase";

function WorkoutParticipants(props) {
    const [participants, setParticipants] = useState([]);

    /**
     * Component load
     */
    useEffect(() => {
        fetchUserDetails();
    }, []);

    /**
     * Pull the users details from the database
     */
    const fetchUserDetails = () => {
        /**
         * Loop over each of the event participants
         */
        props.users.map((user) => {
            /**
             * Make sure we don't add the current user
             */
            if (user !== auth.currentUser.uid) {
                return db
                    .doc(`clients/${user}`)
                    .get()
                    .then((user_doc) => {
                        if (user_doc.exists) {
                            setParticipants((participants) => [...participants, `${user_doc.data().first_name} ${user_doc.data().last_name}`]);
                        } else {
                            setParticipants((participants) => [...participants, "FitPortal Member"]);
                        }
                    });
            } else {
                return false;
            }
        });
    };

    /**
     * Return the users
     */
    return participants.join(", ");
}

export default WorkoutParticipants;
