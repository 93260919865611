import React, { useContext, useState, useEffect } from "react";
import { MealContext } from "../../../../utils/providers/meal";
import { PlusIcon, SaveIcon, TrashAltIcon } from "../../../../utils/svgs";

/**
 * UI components
 */
import Input from "../../../ui/inputs/input";
import Button from "../../../ui/button/button";
import Textarea from "../../../ui/inputs/textarea";

function Instructions(props) {
    const [addingStep, setAddingStep] = useState(false);
    const [instruction, setInstruction] = useState("");
    const [localInstructions, setLocalInstructions] = useState([]);

    /**
     * Deconstruct variables from the add meal context
     */
    const {
        setStage,
        instructionsURL, setInstructionsURL,
        instructions, setInstructions,
    } = useContext(MealContext);

    /**
     * When this page first loads in, pull the current ingredients from the context
     */
    useEffect(() => {
        setLocalInstructions(instructions);
    }, []);

    /**
     * Save the new instruction into the local state
     */
    const saveInstruction = (addingAnother) => {
        /**
         * Do we have an instruction present in the field
         */
        if (instruction) {
            /**
             * Push the instruction into the local state
             */
            setLocalInstructions((localInstructions) => [...localInstructions, {
                instruction,
            }]);
            /**
             * Reset the fields
             */
            setInstruction("");
            /**
             * If we are not adding another one
             */
            if (!addingAnother) {
                /**
                 * Hide the adding form
                 */
                setAddingStep(false);
            }
        }
    }

    /**
     * Remove an instruction from the local array via the name passed in
     */
    const removeInstruction = (instruction) => {
        /**
         * Remove the instruction from the array
         */
        setLocalInstructions(localInstructions.filter(instruct => instruct.instruction !== instruction));
    }

    /**
     * Listen for the local instruction to be updated
     */
    useEffect(() => {
        /**
         * Push them into the context when there is an update
         */
        setInstructions(localInstructions);
    }, [localInstructions]);

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <Input
                    type="text"
                    label="URL with instructions (optional):"
                    placeholder="e.g. http://www.fitportal.uk/meal/slim-steak"
                    onChange={(e) => setInstructionsURL(e.target.value)}
                    value={instructionsURL}
                    note="Provide a link to a website with instructions for how to cook" />

                <div className="instructions-seperator"></div>

                {!addingStep &&
                    <div className="add-list-item">
                        <p onClick={() => setAddingStep(true)}><PlusIcon /> Add Step</p>
                    </div>
                }

                {addingStep &&
                    <div className="add-ingredient">
                        <Textarea
                            type="text"
                            label="Instruction:"
                            placeholder="e.g. Peel the potatoes"
                            onChange={(e) => setInstruction(e.target.value)}
                            value={instruction} />

                        <div className="save-list-item">
                            <p onClick={() => saveInstruction(false)}><SaveIcon /> Save</p>
                            <p onClick={() => saveInstruction(true)}><SaveIcon /> Save &amp; Add Another</p>
                            <p onClick={() => setAddingStep(false)} className="red">Cancel</p>
                        </div>
                    </div>
                }

                {!addingStep &&
                    <div className="instructions-list">
                        {instructions.map((instruction, index) => (
                            <p className="list-item instructions" key={index}>
                                {index + 1}. {instruction.instruction}
                                <span onClick={() => removeInstruction(instruction.instruction)}>
                                    <TrashAltIcon />
                                </span>
                            </p>
                        ))}
                    </div>
                }
            </div>

            <Button
                class="primary"
                onClick={() => setStage(4)}
                disabled={(!instructionsURL && (instructions.length === 0))}>
                Continue to Tags
            </Button>
        </div>
    );
}

export default Instructions;