import React, { useContext } from "react";
import { EditWorkoutContext } from "../../../../utils/providers/workout-edit";

/**
 * UI components
 */
import Input from "../../../ui/inputs/input";
import Textarea from "../../../ui/inputs/textarea";
import Button from "../../../ui/button/button";

function Details() {
    /**
     * Deconstruct variables from the add meal context
     */
    const {
        title, setTitle,
        summary, setSummary,
        saving,
        setStage,
    } = useContext(EditWorkoutContext);

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <Input
                    type="text"
                    label="Name:"
                    placeholder="e.g. Upper body"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title} />

                <Textarea
                    type="text"
                    label="Workout summary"
                    placeholder="e.g. This workout includes..."
                    value={summary}
                    onChange={(e) => setSummary(e.target.value)} />
            </div>

            <Button
                class="primary"
                loading={saving}
                loadingText="Saving..."
                onClick={() => setStage(2)}
                disabled={!title}>
                Continue to Exercises
            </Button>
        </div>
    );
}

export default Details;