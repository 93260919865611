import React, { useEffect, useState } from "react";
import { db } from "../../../utils/firebase";

/**
 * Functional component to return the equipment list for a given client
 */
function Equipment(props) {
    const [equipment, setEquipment] = useState({});

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the clients equipment list from the database
         */
        db.collection(`clients/${props.userID}/equipment`).get().then((equipmentDocs) => {
            /**
             * Loop over each of the equipment documents found
             */
            equipmentDocs.forEach((equipmentDoc) => {
                /**
                 * Deconstruct the equipment data from the document
                 */
                const { at_home, at_gym } = equipmentDoc.data();
                /**
                 * Build a string to show where the client has access to it
                 */
                let available = [];

                if (at_home) {
                    available.push("at home");
                }

                if (at_gym) {
                    available.push("at gym");
                }

                const availableWhere = available.join(" & ");
                /**
                 * Push it onto the equipments local state
                 */
                setEquipment((equipment) => ({ ...equipment, [equipmentDoc.id]: { ...equipmentDoc.data(), availableWhere } }));
            });
        });
    }, []);

    return (
        <div className="client-equipment-list">
            {/* If there is no equipment to show */}
            {(Object.entries(equipment).length === 0) &&
                <p>They have not yet added any equipment</p>
            }

            {/* If there is equipment to show */}
            {Object.entries(equipment).map((item) => (
                <p key={item[0]}>{item[1].title} ({item[1].availableWhere})</p>
            ))}
        </div>
    );
}

export default Equipment;
