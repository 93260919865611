import React, { useEffect, useState, useContext } from "react";
import { MessagesContext } from "../../../utils/providers/messages";
import { db, auth } from "../../../utils/firebase";
import firebase from "firebase";
import ChatWindow from "../../messages/window";

export default function Messages({ userID }) {
    const [conversationID, setConversationID] = useState("");

    const { setActiveChat } = useContext(MessagesContext);

    useEffect(() => {
        db.collection("conversations")
            .where("users", "array-contains", auth.currentUser.uid, userID)
            .get().then((conversationDocs) => {
                /**
                 * Are there any conversations for this user?
                 */
                if (conversationDocs.size > 0) {
                    /**
                     * Loop through the converstaion documents found
                     */
                    conversationDocs.forEach((conversationDoc) => {
                        /**
                         * Deconstruct the users from the document
                         */
                        const { users } = conversationDoc.data();
                        /**
                         * Is the size of the users array only 2?
                         */
                        if ((users.length === 2) && users.includes(userID) && users.includes(auth.currentUser.uid)) {
                            /**
                             * Does the current trainer ID exist in this array? This would 
                             * mean this conversation is private between the current user 
                             * and the trainer
                             */
                            if (users.includes(userID)) {
                                /**
                                 * If it is, then there is already a conversation between 
                                 * these two users setup
                                 */
                                setConversationID(conversationDoc.id);
                            } else {
                                createNewConversation();
                            }
                        }
                    });
                } else {
                    createNewConversation();
                }
            });
    }, []);

    const createNewConversation = async () => {
        /**
         * Add the conversation to the database collection
         */
        const conversation = await db.collection("conversations").add({
            createdBy: auth.currentUser.uid,
            created: firebase.firestore.Timestamp.now(),
            users: [auth.currentUser.uid, userID],
            active_users: [auth.currentUser.uid, userID],
            latest_message: firebase.firestore.FieldValue.serverTimestamp(),
        });
        /**
         * Push the conversation ID onto the two users
         */
        await db.doc(`clients/${auth.currentUser.uid}/conversations/${conversation.id}`).set({
            joined: firebase.firestore.FieldValue.serverTimestamp(),
        });
        await db.doc(`trainers/${userID}/conversations/${conversation.id}`).set({
            joined: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }

    useEffect(() => {
        (conversationID.length > 0) && setActiveChat(conversationID);
    }, [conversationID]);

    return (
        <div className="client-messages">
            <div className="message-window">
                <ChatWindow hideRemove={true} />
            </div>
        </div>
    );
}