import React, { useEffect, useState, useContext } from "react";
import { EventContext } from "../../../../utils/providers/event";
import moment from "moment";
import "./add.scss";

/**
 * Add event stages
 */
import Details from "./stages/details";
import Workouts from "./stages/workouts";
import Participants from "./stages/participants";
import Goals from "./stages/goals";
import Save from "./stages/save";
import { db } from "../../../../utils/firebase";

/**
 * Functional component to return the form for adding new events
 */
function AddEventTrainer(props) {
    /**
     * Deconstruct starting date from the props
     */
    const { startingDate } = props;

    /**
     * Deconstruct the stage vars from the context
     */
    const { stage, setStage, setStartTime, setParticipants } = useContext(EventContext);

    /**
     * On the update of a starting date from the calendar day click event
     */
    useEffect(() => {
        /**
         * Is there a startingDate present
         */
        if (startingDate) {
            /**
             * Push it into the new event context
             */
            setStartTime(moment(startingDate).add(9, "hours"));
        }
    }, [startingDate]);

    /**
     * Check the stage the user is trying to navigate towards before continuing, only 
     * allowing them to move back to a stage that has previoulsy been filled out
     */
    const processNavClick = (newStage) => {
        /**
         * If the nav item clicked is in the past
         */
        if (stage >= newStage) {
            /**
             * Move back in the stage
             */
            setStage(newStage);
        }
    }

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * If there is client ID that has been passed in
         */
        if (props.client) {
            /**
             * Get the client document from the database
             */
            db.doc(`client/${props.client}`).get().then((clientDoc) => {
                /**
                 * Make sure the user exists
                 */
                if (clientDoc.exists) {
                    /**
                     * Deconstruct the clients details
                     */
                    const { first_name, last_name, email } = clientDoc.data();
                    /**
                     * Push the client to the event participants array
                     */
                    setParticipants([{
                        option: props.client,
                        value: (first_name && last_name) ? `${first_name} ${last_name}` : email
                    }]);
                }
            });
        }
    }, [])

    return (
        <div className="add-meal-modal">
            <div className="add-meal-modal-nav">
                <p
                    onClick={() => processNavClick(1)}
                    className={[
                        (stage === 1) && "is-active",
                        (stage > 1) && "is-past"
                    ].join(" ")}>
                    Details
                </p>
                <p
                    onClick={() => processNavClick(2)}
                    className={[
                        (stage === 2) && "is-active",
                        (stage > 2) && "is-past"
                    ].join(" ")}>
                    Workouts
                </p>
                <p
                    onClick={() => processNavClick(3)}
                    className={[
                        (stage === 3) && "is-active",
                        (stage > 3) && "is-past"
                    ].join(" ")}>
                    Participants
                </p>
                <p
                    onClick={() => processNavClick(4)}
                    className={[
                        (stage === 4) && "is-active",
                        (stage > 4) && "is-past"
                    ].join(" ")}>
                    Goals
                </p>
                <p
                    onClick={() => processNavClick(5)}
                    className={[
                        (stage === 5) && "is-active",
                        (stage > 5) && "is-past"
                    ].join(" ")}>
                    Save
                </p>
            </div>

            <div className="add-meal-modal-inputs">
                {(stage === 1) && <Details />}
                {(stage === 2) && <Workouts />}
                {(stage === 3) && <Participants />}
                {(stage === 4) && <Goals />}
                {(stage === 5) && <Save close={() => props.close()} />}
            </div>
        </div>
    );
}

export default AddEventTrainer;