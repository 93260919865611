import React, { useState, useContext } from "react";
import { EditWorkoutContext } from "../../../../utils/providers/workout-edit";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { db } from "../../../../utils/firebase";

import Input from "../../../ui/inputs/input";
import Button from "../../../ui/button/button-new";

import Exercise from "./exercise/exercise";

export default function Exercises() {
    const [savingExercise, setSavingExercise] = useState(false);
    const [updatingOrder, setUpdatingOrder] = useState(false);
    const [newExerciseTitle, setNewExerciseTitle] = useState("");
    const [titleInputError, setTitleInputError] = useState("");

    /**
     * Deconstruct the exercises order from the workout context
     */
    const {
        setStage,
        workoutID,
        exercisesOrder,
        addNewExercise,
    } = useContext(EditWorkoutContext);

    /**
     * Check for an enter key to be pressed on the new exercise input
     */
    const checkForEnterKey = (key) => {
        /**
         * If the key pressed was enter
         */
        (key === "Enter") && checkForInputs();
    };

    /**
     * Check the inputs for adding a new exercise before we write it to the database
     */
    const checkForInputs = async () => {
        /**
         * Show a loading spinner
         */
        setSavingExercise(true);
        /**
         * First check the title for it is greater than or equal to 3 characters 
         */
        if (newExerciseTitle.length >= 3) {
            /**
             * If it is, we can then add the new exercise to the workout
             */
            await addNewExercise(newExerciseTitle);
            /**
             * Clear the input
             */
            setNewExerciseTitle("");
        } else {
            /**
             * Show an error under the input
             */
            setTitleInputError("Please enter a title with 3 or more characters")
        }
        /**
         * Hide the loading spinner
         */
        setSavingExercise(false);
    }

    /**
     * On the drag end event of the exercises list by the user, get the current and new positions for 
     * the exercises to push into the database
     */
    const handleOnDragEnd = async (result) => {
        /**
         * Show a flag in the state to show it's being saved
         */
        setUpdatingOrder(true);
        /**
         * Create a new array using the exercises from the state
         */
        let currentOrder = [...exercisesOrder];
        /**
         * Find the element to move in the array
         */
        const [itemToMove] = currentOrder.splice(result.source.index, 1);
        /**
         * Splice the new array together with the chosen element in the new position
         */
        currentOrder.splice(result.destination.index, 0, itemToMove);
        /**
         * Then we need to push this new order into the array on the exercise document
         */
        await db.doc(`workouts/${workoutID}`).set({
            exercises_order: currentOrder,
        }, { merge: true });
        /**
         * Hide teh saving flag
         */
        setSavingExercise(false);
    };

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <div className="excerise-inputs">
                    <Input
                        label="Exercise name:"
                        placeholder="e.g. Chest Press"
                        value={newExerciseTitle}
                        onChange={(e) => setNewExerciseTitle(e.target.value)}
                        onKeyDown={(e) => checkForEnterKey(e.key)}
                        note="Type the exercise name, and then press enter to build sets" />

                    <Button
                        label="Add"
                        loading={savingExercise}
                        loadingText="Saving..."
                        error={titleInputError}
                        onClick={() => checkForInputs()} />
                </div>

                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="e-dnd">
                        {(provided) => (
                            <div className="e-dnd" {...provided.droppableProps} ref={provided.innerRef}>
                                {exercisesOrder.map((exercise, index) => (
                                    <Exercise
                                        index={index}
                                        key={exercise}
                                        id={exercise}
                                        workoutID={workoutID} />
                                ))}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>

            <Button
                class="primary"
                onClick={() => setStage(3)}
                label="Continue to tags" />
        </div>
    );
}