import React, { useContext, useEffect, useState } from "react";
import { db, auth } from "../../utils/firebase";
import { NotificationsContext } from "../../utils/providers/notifications";

/**
 * Styles
 */
import "./notifications.scss";

/**
 * UI components
 */
import Modal from "../../components/modal/modal";
import Notification from "../../components/notifications/notification";

/**
 * Functional component to return the HTML makrup and functionality for the notifications modal
 */
function Notifications() {
    const [notifications, setNotifications] = useState([]);
    const { notificationsShowing, setNotificationsShowing } = useContext(NotificationsContext);

    /**
     * Component load
     */
    useEffect(() => {
        auth.currentUser && setupNotificationsListener();
    }, [auth.currentUser]);

    /**
     * Setup a listener on the database for notifications
     *
     * @type {const}
     */
    const setupNotificationsListener = () => {
        /**
         * Setup the database listener
         */
        db.collection(`users/${auth.currentUser.uid}/notifications`).onSnapshot((notifications_snap) => {
            /**
             * Loop over the changes passed down
             */
            notifications_snap.docChanges().forEach((change) => {
                /**
                 * Notification added
                 */
                if (change.type === "added") {
                    setNotifications((notification) => [
                        ...notification,
                        { id: change.doc.id, data: change.doc.data() }
                    ]);
                }
                /**
                 * Notification updated
                 */
                if (change.type === "modified") {
                    setNotifications((notification) => {
                        let updatedNotifications = [...notification];
                        for (let i in notification) {
                            if (notification[i].id === change.doc.id) {
                                updatedNotifications[i] = { id: change.doc.id, data: change.doc.data() };
                                break;
                            }
                        }
                        return updatedNotifications;
                    });
                }
                /**
                 * Notification removed
                 */
                if (change.type === "removed") {
                    setNotifications((notification) => notification.filter((notificationElement) => notificationElement.id !== change.doc.id));
                }
            });
        });
    }

    /**
     * Dismiss the notification
     *
     * @type {const}
     */
    const dismissNotification = (notificationID) => {
        /**
         * Update the database records
         */
        db.doc(`users/${auth.currentUser.uid}/notifications/${notificationID}`).set({
            unread: false,
        }, { merge: true });
    }

    /**
     * Is the modal active
     */
    if (notificationsShowing) {
        return (
            <Modal className="no-pad" title="Notifications" hide={() => setNotificationsShowing(false)}>
                <div className="notifications-max-height">
                    {notifications.length > 0 && notifications.map((notification) =>
                        <Notification key={notification.id} id={notification.id} data={notification.data} onClick={() => dismissNotification(notification.id)} />
                    )}

                    {notifications.length === 0 && <p className="no-notifications">You don't have any notifications yet.</p>}
                </div>
            </Modal>
        );
    } else {
        return null;
    }
}

export default Notifications;
