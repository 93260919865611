import React, { useEffect, useState } from "react";
import { db } from "../../../../../../utils/firebase";

/**
 * 
 */
function Exercise(props) {
    const [exercise, setExercise] = useState({});

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the exercise details from the database
         */
        db.doc(`exercises/${props.id}`).get().then((exerciseDoc) => {
            /**
             * Set the exercise data into the local state
             */
            setExercise(exerciseDoc.data());
        });
    }, [])

    if (exercise?.title) {
        return (
            <div className="">
                <p>{exercise.title}</p>

                {/* If there are any reps to show */}
                {(exercise?.sets && exercise?.reps && exercise?.rest) &&
                    <p className="workout-sets">
                        {exercise?.sets} sets of {exercise?.reps} reps, with a {exercise?.rest} second rest
                    </p>
                }
            </div>
        );
    } else {
        return null;
    }
}

export default Exercise;