import React, { useEffect, useContext } from "react";
import { SignupContext } from "../../utils/providers/signup";
import { db } from "../../utils/firebase";
import firebase from "firebase";
import moment from "moment";

export default function SignupTrainer() {
    /**
     * Pull all the data needed for the signup from the context
     */
    const {
        firstName,
        lastName,
        email,
        password,
        firstLine,
        secondLine,
        city,
        county,
        postcode,
        dob,
        accountNumber,
        sortCode,
    } = useContext(SignupContext);

    /**
     * When this final component of the signup is loaded into the DOM
     */
    useEffect(() => {
        /**
         * Attempt to create a new account for the user
         */
        firebase.auth()
            .createUserWithEmailAndPassword(email, password)
            .then((userAuth) => {
                /**
                 * Upon success, get the new trainers ID
                 */
                const { uid } = userAuth.user;
                /**
                 * Split the date of birth to save it into the document as individual numbers. We need 
                 * to do this becuase the Stripe signup is going to require the date of birth so it's 
                 * easier for us to sort it here so the cloud funciton can pick it up
                 */
                const dobParts = moment(dob).format("DD/MM/YYYY").split("/");
                /**
                 * Set a new document for the trainer in the "trainers" collection
                 */
                db.doc(`trainers/${uid}`).set({
                    first_name: firstName,
                    last_name: lastName,
                    email,
                    stripe: {
                        verification: {
                            status: "unverified",
                        }
                    },
                    address: {
                        first_line: firstLine,
                        second_line: secondLine || "",
                        city,
                        county,
                        postcode,
                    },
                    dob: {
                        day: dobParts[0],
                        month: dobParts[1],
                        year: dobParts[2],
                    },
                    billing: {
                        account_number: accountNumber,
                        sort_code: sortCode,
                    },
                    signed_up: firebase.firestore.FieldValue.serverTimestamp(),
                }).then(() => {
                    /**
                     * Then redirect the user to their dashboard
                     */
                    window.location.href = "/app/dashboard";
                });
            }).catch((error) => {
                /**
                 * If there was an error, pull the message
                 */
                const { message } = error;
                /**
                 * And just print it to the console for now
                 * 
                 * @todo Need to show the message on the fornt-end
                 */
                console.log(message);
            });
    }, []);

    return (
        <>
            Signing you up...
        </>
    );
}