import React, { useContext, useEffect, useState } from "react";
import { EditWorkoutContext } from "../../../../utils/providers/workout-edit";
import { db } from "../../../../utils/firebase";

import Input from "../../../ui/inputs/input";
import Button from "../../../ui/button/button";

export default function Tags() {
    const [localTags, setLocalTags] = useState([]);
    const [newTagTitle, setNewTagTitle] = useState("");

    /**
     * Deconstruct the workoutID and setStage function from the context
     */
    const { workoutID, setStage, tags } = useContext(EditWorkoutContext);

    /**
     * 
     */
    useEffect(() => {
        setLocalTags(tags);
    }, []);

    /**
     * When the local localTags are updated
     */
    useEffect(() => {
        /**
         * Push the local localTags array into the workout document
         */
        db.doc(`workouts/${workoutID}`).set({
            tags: localTags,
        }, { merge: true });
    }, [localTags]);

    const checkForEnterKey = (key) => {
        /**
         * If the key pressed was enter
         */
        (key === "Enter") && addNewTag();
    };

    const addNewTag = async () => {
        /**
         * If the new tag length is greater than or equal to 3
         */
        if (newTagTitle.length >= 3) {
            /**
             * Push the tag into the local state
             */
            setLocalTags((localTags) => [...localTags, newTagTitle]);
            /**
             * Reset the fields
             */
            setNewTagTitle("");
        }
    }

    const removeTag = async (tagToRemove) => {
        /**
         * Remove the tag from the array
         */
        setLocalTags(localTags.filter((tag) => tag !== tagToRemove));
    }

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <Input
                    type="text"
                    label="Workout Tags:"
                    placeholder="e.g. Strength Building"
                    value={newTagTitle}
                    onChange={(e) => setNewTagTitle(e.target.value)}
                    onKeyDown={(e) => checkForEnterKey(e.key)}
                    note="Start typing and press enter to add to the localTags" />

                <div className="tags-list">
                    {localTags.map((tag, index) => (
                        <p className="list-item-tag" key={index}>
                            {tag}
                            <span onClick={() => removeTag(tag)}>
                                x
                            </span>
                        </p>
                    ))}
                </div>
            </div>

            <Button
                class="primary"
                onClick={() => setStage(4)} >
                Continue to Save
            </Button>
        </div>
    );
}