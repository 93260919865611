import React, { useState, useEffect } from "react";
import moment from "moment";
import "./nutrition.scss";

/**
 * ChartJS
 */
import Calories from "../../components/nutrition/calories/calories";
import Select from "../../components/ui/inputs/select/new-select";
import Meals from "../../components/nutrition/meals/meals";

/**
 * Functional component to return the nutrition page for the current user
 */
function Nutrition() {
    const [meals, setMeals] = useState(0);
    const [period, setPeriod] = useState({
        option: "this_week",
        value: "This Week",
    });
    const [periodStart, setPeriodStart] = useState({});
    const [periodEnd, setPeriodEnd] = useState({});

    /**
     * When the period is updated
     */
    useEffect(() => {
        /**
         * Update the start and end dates accordingly
         */
        switch (period.option) {
            case "this_week":
                setPeriodStart(moment().startOf("week"));
                setPeriodEnd(moment().endOf("week"));
                break;
            case "this_month":
                setPeriodStart(moment().startOf("month"));
                setPeriodEnd(moment().endOf("month"));
                break;
            case "this_year":
                setPeriodStart(moment().startOf("year"));
                setPeriodEnd(moment().endOf("year"));
                break;
            default:
                return null;
        }
    }, [period]);

    return (
        <>
            <div className="page-title">
                <h1>My Nutrition</h1>
                <p>Track your nutrition and view recommended meal plans from your trainers. Data shown is based on {meals} tracked meal{meals > 1 && "s"} this week.</p>
            </div>
            <div className="nutrition-page">
                <div className="nutrition-charts">
                    <Calories />
                </div>
                <div className="nutrition-additional">
                    <Meals
                        start={periodStart}
                        end={periodEnd}
                        setMeals={(meals) => setMeals(meals)} />
                </div>
            </div>
        </>
    );
}

export default Nutrition;
