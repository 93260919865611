import { CheckIcon } from "../../../utils/svgs";

/**
 * Styles
 */
import "./input.scss";

/**
 * Input functional component
 *
 * @type {const}
 * @param {object} props Component props passed down
 */
function Checkbox(props) {
    return (
        <div className={["input-block", props.wrapperClass, props.error && "invalid"].join(" ")}>
            <div className="checkbox-wrapper" onClick={() => props.onClick()}>
                {/* Checkbox object */}
                <div className={["checkbox-box", props.checked && "checked"].join(" ")}>
                    <CheckIcon />
                </div>
                {/* Label */}
                {props.label && <label className="checkbox-label">{props.label}</label>}
            </div>
            {/* Input note / description */}
            {props.note && <p>{props.note}</p>}
            {/* Input error */}
            {props.error && <p className="input-error">{props.error}</p>}
        </div>
    );
}

export default Checkbox;
