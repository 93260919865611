import React, { useEffect, useState } from "react";
import { db, auth } from "../../utils/firebase";
import firebase from "firebase";
import moment from "moment";

/**
 * Circular progress bar
 */
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

/**
 * Functional component to return the goal disc
 */
function Goal(props) {
    const [goalCurrent, setGoalCurrent] = useState(0);
    const [goalProgress, setGoalProgress] = useState(0);

    /**
     * Deconstruct the goal data from the props
     */
    const { id, title, target, period } = props.data;

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Init some variables to hold the firsbase timestamps
         */
        let fromWhen;
        /**
         * Construct timestamps from the period property on the goal
         */
        if (period === "weekly") {
            fromWhen = firebase.firestore.Timestamp.fromMillis(moment().startOf("week"));
        } else if (period === "monthly") {
            fromWhen = firebase.firestore.Timestamp.fromMillis(moment().startOf("month"));
        } else {
            fromWhen = firebase.firestore.Timestamp.fromMillis(moment().startOf("week"));
        }
        /**
         * The toWhen value will just be this current time
         */
        const toWhen = firebase.firestore.Timestamp.fromMillis(moment().valueOf());
        /**
         * Setup a listener on the events collection to pull the details for this goal
         */
        const unsubscribe = db.collection(`clients/${auth.currentUser.uid}/goals/${id}/records`)
            .where("starts", ">=", fromWhen)
            .onSnapshot((eventsSnap) => {
                /**
                 * Loop over each of the records
                 */
                eventsSnap.docChanges().forEach((change) => {
                    /**
                     * Get the document value for the record
                     */
                    const { goal_contribution } = change.doc.data();
                    /**
                     * Record added
                     */
                    if (change.type === "added") {
                        setGoalCurrent((current) => parseInt(current) + parseInt(goal_contribution));
                    }
                    /**
                     * Record removed
                     */
                    if (change.type === "removed") {
                        setGoalCurrent((current) => parseInt(current) - parseInt(goal_contribution));
                    }
                });
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);

    /**
     * On update of the goal current value
     */
    useEffect(() => {
        /**
         * Work out the current progress as a percentage
         */
        setGoalProgress(((goalCurrent / target) * 100).toFixed(0));
    }, [goalCurrent]);

    return (
        <div className="goal-circle personal">
            {/* Goal title */}
            <small>{title}</small>
            {/* Progress to show inside circle */}
            <p>{goalProgress}<span>%</span></p>
            {/* Outer progress circle */}
            <CircularProgressbar
                value={goalProgress}
                strokeWidth={goalProgress >= 100 ? 16 : 6}
                styles={buildStyles({
                    pathColor: props.trainer ? "#50bac8" : "#cd85de",
                    trailColor: "#ffffff",
                    strokeLinecap: "flat",
                })} />
        </div>
    );
}

export default Goal;