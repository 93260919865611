import React, { useContext } from "react";
import firebase from "firebase";
import { ProgramContext } from "../../../../utils/providers/program";
import { db, auth } from "../../../../utils/firebase";
/**
 * UI components
 */
import Button from "../../../ui/button/button";

function Save(props) {
    /**
     * Deconstruct variables from the add meal context
     */
    const {
        name,
        details,
    } = useContext(ProgramContext);

    /**
     * Save the meal with the variables from the context
     */
    const saveProgram = () => {
        /**
         * Add the meal into the database
         */
        db.collection("programs").add({
            name,
            details,
            trainer: auth.currentUser.uid,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        });
        /**
         * Hide the add meal modal through the props
         */
        props.close();
    }

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <h1>{name}</h1>
            </div>

            <Button
                class="primary"
                onClick={() => saveProgram()}>
                Save Program
            </Button>
        </div >
    );
}

export default Save;