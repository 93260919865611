import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../utils/providers/auth";
import { MessagesContext } from "../../../utils/providers/messages";
import firebase from "firebase";
import { db, auth } from "../../../utils/firebase";
import moment from "moment";

/**
 * UI components
 */
import Button from "../../ui/button/button";
import { NavLink } from "react-router-dom";


/**
 * Functional component for the price card setup window
 */
function MessagePriceOffer(props) {
    const [accepted, setAccepted] = useState(false);
    const [interval, setInterval] = useState("");

    /**
     * Deconstruct the user object from the context
     */
    const { user } = useContext(AuthContext);

    /**
     * Deconstruct the message modal show/hide function
     */
    const { setMessagesShowing } = useContext(MessagesContext);

    /**
     * Get the message data from the props
     */
    const message_data = props.message_data;

    /**
     * Get the starting from date if it's an on-going plan
     */
    const interval_start_date = moment.unix(message_data.starting_on?.seconds).format("DD/MM/YYYY");
    const interval_starts = message_data.interval ? `, starting ${interval_start_date}` : "";

    /**
     * Print a readable version of the interval for the user
     */
    useEffect(() => {
        switch (message_data.interval) {
            case "one_off":
                return setInterval("One off");
            case "week":
                return setInterval("Weekly");
            case "month":
                return setInterval("Monthly");
            default:
                return null;
        }
    }, [message_data.interval]);

    /**
     * Accept the price
     */
    const acceptPrice = () => {
        /**
         * Create a new document in the charges collection for the cloud function to pick
         * up to process a charge
         */
        db.collection("charges").add({
            customer: auth.currentUser.uid,
            trainer: message_data.user,
            value: (message_data.price * 100),
            fee: (message_data.fee * 100),
            interval: message_data.interval,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            conversation_id: props.chat,
            message_id: message_data.id,
        }).then((chargeDoc) => {
            /**
             * Mark the price as accepted in the local state
             */
            setAccepted(true);
            /**
             * Update the message document to show the accepted notice
             */
            db.doc(`conversations/${props.chat}/messages/${message_data.id}`).set({
                status: {
                    is_accepted: true,
                    charge_id: chargeDoc.id,
                    accepted_on: firebase.firestore.FieldValue.serverTimestamp(),
                },
            }, { merge: true });
            /**
             * Add a new message to the chat to show the price being accepted
             */
            db.collection(`conversations/${props.chat}/messages`).add({
                user: auth.currentUser.uid,
                value: message_data.price,
                interval: message_data.interval,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                type: message_data.interval === "one_off" ? "PRICE_ACCEPTED" : "SUBSCRIPTION_ACCEPTED",
            });
        });
    };

    return (
        <div className={["message-element price-card", auth.currentUser.uid === props.message_data.user ? "this-user" : ""].join(" ")}>
            <div className="offer-summary">
                <p className="price">£{props.message_data.price}</p>
                <p className="price-terms">
                    {/* Readable version of the interval */}
                    {interval}
                    {/* If it's a subscription, show the starting date for it */}
                    {props.message_data.interval !== "one_off" && interval_starts}
                </p>
            </div>
            <div className="offer-details">
                <p>{props.message_data.message}</p>

                {(auth.currentUser.uid !== props.message_data.user) &&
                    <>
                        {/* If this user has a paymentSource setup */}
                        {user?.stripe?.paymentSource &&
                            <>
                                {(!message_data.status?.is_accepted && !accepted) &&
                                    <Button class="primary fw" onClick={() => acceptPrice()}>
                                        Confirm Price
                                    </Button>
                                }

                                {(message_data.status?.is_accepted || accepted) &&
                                    <>
                                        {props.message_data.interval === "one_off" &&
                                            <p className="price-accepted">
                                                You accepted this payment on the {moment.unix(message_data.status?.accepted_on?.seconds).format("Do [of] MMMM [at] HH:mm")}, we'll charge your card shortly.
                                            </p>
                                        }

                                        {props.message_data.interval !== "one_off" &&
                                            <p className="price-accepted">
                                                You accepted this subscription on the {moment.unix(message_data.status?.accepted_on?.seconds).format("Do [of] MMMM [at] HH:mm")}, we'll charge your card shortly to get the subscription started.
                                            </p>
                                        }
                                    </>
                                }
                            </>
                        }

                        {/* Does this user not have a paymentSource setup */}
                        {!user?.stripe?.paymentSource &&
                            <p className="setup-billing-method" onClick={() => setMessagesShowing(false)}>You need to setup a <NavLink to="/app/account">billing method</NavLink> before you can accept this price.</p>
                        }
                    </>
                }
            </div>
        </div>
    );
}

export default MessagePriceOffer;
