import React, { useState, useContext, useEffect } from "react";
import { EditWorkoutContext } from "../../../../utils/providers/workout-edit";
import { db } from "../../../../utils/firebase";

import Button from "../../../ui/button/button-new";
import Exercise from "../../view/exercise/exercise";

export default function Save(props) {
    const [saving, setSaving] = useState(false);
    const [exercises, setExercises] = useState([]);

    /**
     * Deconstruct the workoutID from the context
     */
    const {
        workoutID,
        title,
        summary,
        tags,
    } = useContext(EditWorkoutContext);

    useEffect(() => {
        db.collection(`workouts/${workoutID}/exercises`)
            .orderBy("created")
            .get().then(async (exerciseDocs) => {
                exerciseDocs.forEach((exercise) => {
                    setExercises((exercises) => [...exercises, { id: exercise.id, ...exercise.data() }])
                })
            });
    }, [workoutID]);

    /**
     * Save the workout in the databse by flagging the "is_draft" field to false
     */
    const saveWorkout = async () => {
        /**
         * Show a saving spinner
         */
        setSaving(true);
        /**
         * Mark the workout as no longer a draft
         */
        await db.doc(`workouts/${workoutID}`).set({
            is_draft: false,
        }, { merge: true });
        /**
         * Close the modal
         */
        props.hide();
    }

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <h1>{title}</h1>
                <p>{summary}</p>

                <div className="tags-list summary">
                    {tags.map((tag, index) => (
                        <p className="list-item-tag" key={index}>
                            {tag}
                        </p>
                    ))}
                </div>

                {(exercises?.length > 0) &&
                    <div className="meal-modal-column">
                        <h2>Exercises Involved</h2>
                        {exercises.map((exercise, index) => (
                            <table className="exercise-table-layout">
                                <Exercise
                                    key={exercise.id}
                                    id={exercise.id}
                                    title={exercise.title}
                                    workoutID={workoutID} />
                            </table>
                        ))}
                    </div>
                }
            </div>

            <Button
                label="Save workout"
                loading={saving}
                loadingText="Saving..."
                disabled={saving}
                onClick={() => saveWorkout()} />
        </div >
    );
}