import React, { useState, createContext } from "react";

/**
 * Setup a context for storing the notifications modal object
 */
const NotificationsContext = createContext();

/**
 * Creating the provider to wrap the notifications modal in for displaying
 * 
 * @param {Object} params
 * @returns Notifications provider to wrap the modal in
 */
const NotificationsProvider = ({ children }) => {
    const [notificationsShowing, setNotificationsShowing] = useState(false);

    return <NotificationsContext.Provider value={{ notificationsShowing, setNotificationsShowing }}>{children}</NotificationsContext.Provider>;
};

export { NotificationsContext, NotificationsProvider };