import React, { useEffect, useState, useContext } from "react";
import { db } from "../../../../../utils/firebase";
import { WorkoutContext } from "../../../../../utils/providers/workout";
import { Draggable } from "react-beautiful-dnd";
import { DragIcon, PlusIcon } from "../../../../../utils/svgs";
import "./exercise.scss";

import Set from "./set/set";

export default function Exercise({ index, id, workoutID }) {
    const [title, setTitle] = useState("");
    const [setsOrder, setSetsOrder] = useState([]);
    const [addingSets, setAddingSets] = useState(false);

    /**
     * Pull both the workout ID and the removeExercise funciton from the context
     */
    const {
        addNewSet,
        removeExercise,
    } = useContext(WorkoutContext);

    /**
     * When the ID for this exercise is updated
     */
    useEffect(() => {
        /**
         * Setup a listener on the exercise document to stream changes of it's title and the order of sets 
         * into the local state
         */
        const unsubscribe = db.doc(`workouts/${workoutID}/exercises/${id}`)
            .onSnapshot((exerciseSnap) => {
                /**
                 * Pull both the title and the sets order from the document snap
                 */
                const { title, sets_order } = exerciseSnap.data();
                /**
                 * And then set them into the local state
                 */
                setTitle(title);
                setSetsOrder(sets_order || []);
            });
        /**
         * Then remove the snapshot listener when the component unmounts
         */
        return () => unsubscribe();
    }, [id]);

    /**
     * Remove the exercise from the workout document
     */
    const pushExerciseRemoval = async (e) => {
        /**
         * Stop the bubbling of the click event up through the DOM
         */
        e.stopPropagation();
        /**
         * Call the removal function from the context
         */
        removeExercise(id);
    }

    return (
        <Draggable key={id} draggableId={id} index={index}>
            {(provided) => (
                <div
                    className="exercise-item"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>

                    {/* Upper part of the exercise, for showing the title and remove option */}
                    <div className="exercise-item-upper" onClick={() => setAddingSets(!addingSets)}>
                        <div className="ex-drag">
                            <DragIcon />
                        </div>
                        <div className="ex-title">
                            <p>{(index + 1)}) {title}</p>
                        </div>
                        <div className="ex-remove" onClick={(e) => pushExerciseRemoval(e)}>
                            <p>remove</p>
                        </div>
                    </div>

                    {/* Lower section of the exercise, for adding the sets */}
                    <div className={["exercise-item-lower", addingSets && "is-showing"].join(" ")}>
                        <div className="add-list-item">
                            <p onClick={() => addNewSet(id)}><PlusIcon /> Add Set</p>
                        </div>

                        {setsOrder.map((set, index) => (
                            <Set
                                index={index}
                                key={set}
                                id={set}
                                workoutID={workoutID}
                                exerciseID={id} />
                        ))}
                    </div>
                </div>
            )}
        </Draggable>
    );
}