import React, { useEffect, useState, createContext } from "react";
import { auth, db } from "../firebase";
import firebase from "firebase";

/**
 * Log the user in with the provided credentials
 *
 * @param {string} username
 * @param {string} password
 */
const loginRequest = async (username, password) => {
    /**
     * Make a call to the firebase auth service with the given email and password
     */
    return firebase
        .auth()
        .signInWithEmailAndPassword(username, password)
        .then(() => {
            /**
             * If the sign in was successful, return a truthy statement on the authentication
             */
            return { authenticated: true };
        })
        .catch((error) => {
            /**
             * If there was an error signing them in, return the error object
             */
            return error;
        });
};

/**
 * Log the user out of the session
 */
const logoutRequest = async () => {
    /**
     * Make a signout request on the auth object
     */
    return firebase
        .auth()
        .signOut()
        .then(() => {
            /**
             * Redirect back to the root on signout
             */
            window.location.href = "/";
        });
};

/**
 * Setup a context for storing the user object
 */
const AuthContext = createContext();

/**
 * Functional component to act as a context wrapper for the application.
 * 
 * This carries a "state" where teh user details for the current session are stored.
 */
const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        /**
         * Set a loading boolean
         */
        setUser({ logged_in: false });
        /**
         * Listen for changes on the auth
         */
        auth.onAuthStateChanged(async (user) => {
            /**
             * If there is a user loaded into the state
             */
            if (user) {
                /**
                 * Pull the UID for the user from the auth object
                 */
                const { uid } = user;
                /**
                 * We need to check both the clients and trainers collections to find the correct 
                 * user document for this user. With it being 2 seperate collections, there is a possibility 
                 * of a collision meaning if it's a trainer that has just signed in, there could be a client 
                 * document witht eh same ID and os they'd be picked up first.
                 * 
                 * However with that being said, we'd need to generate 163 quadrillion seperate records to 
                 * have a 1% chance of collision, so it's extreamly unlikely.
                 * 
                 * First check to see if the ID can be found in the "clients" collection
                 */
                const isClient = await db.doc(`clients/${uid}`)
                    .get().then((clientDoc) => {
                        /**
                         * Return whether or not the document was found
                         */
                        return clientDoc.exists;
                    });
                /**
                 * Do the same for the "trainers" collection
                 */
                const isTrainer = await db.doc(`trainers/${uid}`)
                    .get().then((trainerDoc) => {
                        /**
                         * Return whether or not the document was found
                         */
                        return trainerDoc.exists;
                    });
                /**
                 * We then want to setup a snapshot listener on the users document to stream changes to 
                 * their document into the auth context.
                 * 
                 * Let's check first if we need to set the snapshot up on a client document
                 */
                if (isClient) {
                    /**
                     * If it was a client document found, set the snapshot up on the that document
                     */
                    db.doc(`clients/${uid}`).onSnapshot((userSnap) => {
                        /**
                         * Push the contents of the document into the state for the context
                         */
                        setUser({
                            uid: user.uid,
                            logged_in: true,
                            is_client: true,
                            ...userSnap.data(),
                        });
                    });
                } else if (isTrainer) {
                    /**
                     * Otherwise if it was a trainer document that was found, set the snapshot up on them
                     */
                    db.doc(`trainers/${uid}`).onSnapshot((userSnap) => {
                        /**
                         * Push the contents of the document into the state for the context
                         */
                        setUser({
                            uid: user.uid,
                            logged_in: true,
                            is_trainer: true,
                            ...userSnap.data(),
                        });
                    });
                }
            } else {
                /**
                 * If no user object was returned from the auth state update, mark the context as "logged out"
                 */
                setUser({ logged_in: false });
            }
        });
    }, []);

    return <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>;
};

export { loginRequest, logoutRequest, AuthContext, AuthProvider };
