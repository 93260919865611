import React, { useContext } from "react";
import { SignupContext } from "../../utils/providers/signup";

import Input from "../ui/inputs/input";
import Button from "../ui/button/button";

function SlimPersonalDetails() {
    const {
        setSignupStage,
        errors, setErrors,
        firstName, setFirstName,
        lastName, setLastName,
        email, setEmail,
    } = useContext(SignupContext);

    const checkInputs = () => {
        let proceed = true;
        let inputErrors = {};

        setErrors({});

        if (!firstName || firstName.length <= 2) {
            inputErrors["firstName"] = "Please enter a valid first name";
            proceed = false;
        }

        if (!lastName || lastName.length <= 3) {
            inputErrors["lastName"] = "Please enter a valid last name";
            proceed = false;
        }

        const checkEmail = /\S+@\S+\.\S+/;
        if (!email || !checkEmail.test(email)) {
            inputErrors["email"] = "Please enter a valid email address";
            proceed = false;
        }

        if (proceed) {
            setSignupStage((signupStage) => signupStage + 1);
        } else {
            setErrors(inputErrors);
        }
    }

    return (
        <>
            <div className="input-block-section">
                <h2>Your Details</h2>
                <p>Your name, email and phone number. Don't worry, you'll be able to choose which information is visible on FitPortal.</p>
                <div className="input-elements-grid cols-2">
                    <Input type="text"
                        value={firstName}
                        placeholder="First Name:"
                        label="First Name:"
                        wrapperClass={errors.firstName && 'invalid'}
                        note={errors.firstName}
                        onChange={(e) => setFirstName(e.target.value)} />
                    <Input type="text"
                        value={lastName}
                        placeholder="Last Name:"
                        label="Last Name:"
                        wrapperClass={errors.lastName && 'invalid'}
                        note={errors.lastName}
                        onChange={(e) => setLastName(e.target.value)} />
                </div>

                <div className="input-elements-grid cols-1">
                    <Input type="email"
                        value={email}
                        placeholder="Email:"
                        label="Email:"
                        wrapperClass={errors.email && 'invalid'}
                        note={errors.email}
                        onChange={(e) => setEmail(e.target.value)} />
                </div>
            </div>

            <div className="signup-next-stage">
                <Button class="primary" onClick={() => checkInputs()}>Continue</Button>
            </div>
        </>
    );
}

export default SlimPersonalDetails;