import React, { useEffect, useState } from "react";
import { db } from "../../utils/firebase";
import "./marketplace.scss";

/**
 * Algolia
 */
import algoliasearch from "algoliasearch";
import { InstantSearch, Hits, connectSearchBox, ToggleRefinement, Configure } from "react-instantsearch-dom";

/**
 * UI components
 */
import Trainer from "../../components/marketplace/trainer/trainer";
import Input from "../../components/ui/inputs/input";
import Select from "../../components/ui/inputs/select/new-select";

/**
 * 
 */
export default function Marketplace({ navigation }) {
    const [trainers, setTrainers] = useState([]);
    const [filteredTrainers, setFilteredTrainers] = useState([]);
    const [trainerInput, setTrainerInput] = useState("");
    const [filterGender, setFilterGender] = useState("");
    const [filterPrice, setFilterPrice] = useState("");
    const [filterMinPrice, setFilterMinPrice] = useState(0);
    const [filterMaxPrice, setFilterMaxPrice] = useState(0);

    /**
     * Component load
     */
    useEffect(() => {
        pullTrainers();
    }, []);

    /**
     * On change of the trainerInput input
     */
    useEffect(() => {
        /**
         * Make sure the input is longer than 3 characters to avcoid flooding the database
         */
        if (trainerInput.length > 3) {
            /**
             * Filter the trainers by their name
             */
            const filtered = trainers.filter((trainer) => {
                return trainer.name.includes(trainerInput);
            });
            /**
             * Update the state
             */
            setFilteredTrainers(filtered);
        } else {
            /**
             * Clean the filtered users out
             */
            setFilteredTrainers([]);
        }
    }, [trainerInput]);

    /**
     * When the filter price is updated
     */
    useEffect(() => {
        /**
         * Make sure there is a filter price chosen in the state
         */
        if (filterPrice.option) {
            /**
             * Split the key for the chosen value to get the min and max
             */
            const minMax = filterPrice.option.split("_");
            /**
             * Then set these values as integers into the state
             */
            console.log(parseInt(minMax[0]))
            console.log(parseInt(minMax[1]))
            setFilterMinPrice(parseInt(minMax[0]));
            setFilterMaxPrice(parseInt(minMax[1]));
        }
    }, [filterPrice]);

    /**
     * Pull down a collection of trainers from the database
     * 
     * @type {const}
     */
    const pullTrainers = () => {
        /**
         * Query the database
         */
        db.collection(`users`).where("type", "==", "Trainer").get().then((trainerDocs) => {
            /**
             * Loop over the trainers found
             */
            trainerDocs.forEach((trainer) => {
                /**
                 * Push each onto the array
                 */
                const { first_name, last_name, } = trainer.data();
                setTrainers((currentTrainers) => [...currentTrainers, {
                    id: trainer.id,
                    ...trainer.data(),
                    name: `${first_name} ${last_name}`,
                }]);
            });
        });
    }

    /**
     * Algolia search API keys 
     */
    const searchClient = algoliasearch(
        "V4YODL3E8X",
        "61b071b4cb73478379dc1c4f4ea74149",
    );

    /**
     * Custom inputfield for the algolia search
     */
    const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
        <form noValidate action="" role="search">
            <Input
                wrapperClass="fw"
                type="search"
                placeholder="Search by name, email or location:"
                value={currentRefinement}
                onChange={(e) => refine(e.currentTarget.value)} />
        </form>
    );

    /**
     * Connect the custom search box
     */
    const CustomSearchBox = connectSearchBox(SearchBox);

    /**
     * Use the correct index for the algolia search depending on hostname of the session
     */
    const search_index = (window.location.hostname === "fitportal.uk" ? "trainers" : "development_trainers");

    return (
        <>
            <div className="page-title">
                <h1>Trainer Marketplace</h1>
                <p>Search our database of personal trainers and find the match to kick-start your fitness journey. Once you have found a trainer, you can message them directly and see the style of workouts and fitness plans they have to offer.</p>
            </div>

            <InstantSearch searchClient={searchClient} indexName={search_index}>
                <div className="hide-algolia-refinements">
                    {(filterGender.option && (filterGender.option !== "both")) &&
                        <ToggleRefinement
                            attribute="gender"
                            label="Trainer gender"
                            value={filterGender.option}
                            defaultRefinement={true} />
                    }

                    {(filterPrice.option) &&
                        <Configure filters={`from_price:${filterMinPrice} TO ${filterMaxPrice}`} />
                    }
                </div>

                <div className="marketplace-filters">
                    <CustomSearchBox />

                    <Select
                        wrapperClass="fw"
                        options={{
                            male: "Male",
                            female: "Female",
                            both: "All",
                        }}
                        onSelect={(option) => setFilterGender(option)}
                        value={filterGender.value}
                        placeholder="Gender:" />

                    <Select
                        wrapperClass="fw"
                        options={{
                            "0_29": "< £30ph",
                            "30_40": "£30 - £40",
                            "40_50": "£40 - £50",
                            "50_999": "> £50",
                        }}
                        onSelect={(option) => setFilterPrice(option)}
                        value={filterPrice.value}
                        placeholder="Price range:" />
                </div>

                <div className="marketplace-results">
                    <Hits hitComponent={(user) =>
                        <Trainer
                            key={user.hit.objectID}
                            id={user.hit.objectID}
                            trainer_data={user.hit} />
                    } />
                </div>
            </InstantSearch>
        </>
    );
};