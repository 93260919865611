import React, { useContext } from "react";
import { MealContext } from "../../../../utils/providers/meal";

/**
 * UI components
 */
import Input from "../../../ui/inputs/input";
import Button from "../../../ui/button/button";

function Details() {
    /**
     * Deconstruct variables from the add meal context
     */
    const {
        setStage,
        name, setName,
        prepTime, setPrepTime,
        cookingTime, setCookingTime,
        calories, setCalories,
        carbs, setCarbs,
        fat, setFat,
        protein, setProtein,
    } = useContext(MealContext);

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <Input
                    type="text"
                    label="Name:"
                    placeholder="e.g. Slim steak with beans"
                    onChange={(e) => setName(e.target.value)}
                    value={name} />

                <div className="input-group-column cols-2">
                    <Input
                        type="number"
                        label="Prep time (mins):"
                        placeholder="e.g. 15"
                        onChange={(e) => setPrepTime(e.target.value)}
                        value={prepTime} />

                    <Input
                        type="number"
                        label="Cooking time (mins):"
                        placeholder="e.g. 45"
                        onChange={(e) => setCookingTime(e.target.value)}
                        value={cookingTime} />
                </div>

                <div className="input-group-column cols-4">
                    <Input
                        type="number"
                        label="Calories:"
                        placeholder="e.g. 750"
                        onChange={(e) => setCalories(e.target.value)}
                        value={calories} />

                    <Input
                        type="number"
                        label="Carbs (g):"
                        placeholder="e.g. 27"
                        onChange={(e) => setCarbs(e.target.value)}
                        value={carbs} />

                    <Input
                        type="number"
                        label="Fat (g):"
                        placeholder="e.g. 45"
                        onChange={(e) => setFat(e.target.value)}
                        value={fat} />

                    <Input
                        type="number"
                        label="Protein (g):"
                        placeholder="e.g. 35"
                        onChange={(e) => setProtein(e.target.value)}
                        value={protein} />
                </div>
            </div>

            <Button
                class="primary"
                onClick={() => setStage(2)}
                disabled={!name || !prepTime || !cookingTime || !calories || !carbs || !fat || !protein}>
                Continue to Ingredients
            </Button>
        </div>
    );
}

export default Details;