import React, { useEffect, useState } from "react";
import { db, auth } from "../../utils/firebase";
import useProfileImage from "../../hooks/useProfileImage";
import moment from "moment";
import "./client.scss";

/**
 * UI components
 */
import Button from "../ui/button/button-new";

/**
 * Tabs
 */
import Card from "../ui/card/card";
import CardImage from "../ui/card/partials/image";
import CardTitle from "../ui/card/partials/title";
import ViewClient from "./view/view";

/**
 * Functional component for returning the client card
 */
function Client(props) {
    const [viewingClient, setViewingClient] = useState(false);
    const [client, setClient] = useState({});
    const [clientSince, setClientSince] = useState("");

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the client details from firestore
         */
        db.doc(`clients/${props.id}`).get().then((clientDoc) => {
            /**
             * Set them into the local state
             */
            setClient({
                id: props.id,
                ...clientDoc.data(),
            });
        });
    }, []);

    /**
     * Get the client document on thei trainer so we can get the added date
     */
    useEffect(() => {
        /**
         * Get the client document
         */
        db.doc(`trainers/${auth.currentUser.uid}/clients/${props.id}`)
            .get().then((trainerClientDoc) => {
                /**
                 * Make sure the document exists
                 */
                if (trainerClientDoc.exists) {
                    /**
                     * Set the since local state to a reable format
                     */
                    setClientSince(moment.unix(trainerClientDoc.data().connected?.seconds).format("DD/MM/YYYY"));
                }
            });
    }, []);

    /**
     * Get the profile picture for the user from the hook
     */
    const profilePicture = useProfileImage(props.id);

    return (
        <>
            <Card>
                <CardImage image={profilePicture} />
                <CardTitle>
                    {client.first_name &&
                        <p>{client.first_name} {client.last_name}</p>
                    }

                    {!client.first_name &&
                        <p>{client.email?.split("@")[0]}</p>
                    }

                    <small>Client since {clientSince}</small>
                </CardTitle>

                <Button
                    label={`View ${client.first_name ? client.first_name + "'s" : ""} Profile`}
                    onClick={() => setViewingClient((viewingClient) => !viewingClient)} />
            </Card>

            {viewingClient &&
                <ViewClient
                    client={props.id}
                    hide={() => setViewingClient(false)} />
            }
        </>
    );
}

export default Client;
