import React, { useState, useRef, useEffect } from "react";
import { ChevronUpIcon } from "../../../utils/svgs";
import "./button-extendable.scss";

/**
 * Functional component for an "extendable button", one with a main funciton and 
 * a menu to show on toggle
 */
function ButtonExtendable(props) {
    const [menuToggled, setMenuToggled] = useState(false);

    /**
     * Listen for a click outside the button ref
     * 
     * @type {const}
     */
    const ClickedOutside = (buttonRef) => {
        useEffect(() => {
            /**
             * Check for the click to be outside the select field
             */
            const checkForOutsideClick = (event) => {
                /**
                 * Is the click outside?
                 */
                if (buttonRef.current && !buttonRef.current.contains(event.target)) {
                    /**
                     * Hide the button menu
                     */
                    setMenuToggled(false);
                }
            }
            /**
             * Bind on the component load
             */
            document.addEventListener("mousedown", checkForOutsideClick)
            return () => {
                /**
                 * Unbind on component unload
                 */
                document.removeEventListener("mousedown", checkForOutsideClick);
            }
        }, [buttonRef]);
    }

    /**
     * Button ref
     */
    const buttonRef = useRef(null);

    /**
     * Bind onto the click event
     */
    ClickedOutside(buttonRef);

    /**
     * Bubble the onClick passed in for the action through here to hide the menu first
     */
    const bubbleAction = (action) => {
        /**
         * Hide the menu
         */
        setMenuToggled(false);
        /**
         * Then run the action
         */
        action();
    }

    return (
        <div className="button-extendable" ref={buttonRef}>
            <div className="be-main" onClick={() => props.onClick()}>
                {props.label}
            </div>

            <div className="be-toggle" onClick={() => setMenuToggled(!menuToggled)}>
                <ChevronUpIcon />
            </div>

            {/* Is the extendable menu toggled? */}
            {(props.actions.length > 0) &&
                <div className={["be-menu", menuToggled && "is-active"].join(" ")}>
                    {props.actions.map((action) => (
                        <div
                            key={action.label}
                            className="be-menu-action"
                            onClick={() => bubbleAction(action.onClick)}>
                            <p>{action.label}</p>
                        </div>
                    ))}
                </div>
            }
        </div>
    );
}

export default ButtonExtendable;