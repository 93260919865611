import React, { useContext, useEffect } from "react";
import { EventContext } from "../../../../../utils/providers/event";
import moment from "moment";

/**
 * UI components
 */
import Input from "../../../../ui/inputs/input";
import Button from "../../../../ui/button/button";
import DatePicker from "../../../../ui/inputs/datetime";
import Checkbox from "../../../../ui/inputs/checkbox";
import Textarea from "../../../../ui/inputs/textarea";

/**
 * 
 */
function Details() {
    /**
     * Deconstruct variables from the add event context
     */
    const {
        setStage,
        title, setTitle,
        comments, setComments,
        startTime, setStartTime,
        endTime, setEndTime,
        isAllDay, setIsAllDay,
    } = useContext(EventContext);

    /**
     * When the start date is updated for the event
     */
    useEffect(() => {
        /**
         * Get the datetime one hour in the future
         */
        const oneHourAhead = moment(startTime).add(1, 'hours');
        /**
         * Set that as the end date for the event
         */
        startTime && setEndTime(oneHourAhead);
    }, [startTime]);

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <Input
                    type="text"
                    label="Name:"
                    placeholder="e.g. HIT Workout Session"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title} />

                <Checkbox
                    checked={isAllDay}
                    onClick={() => setIsAllDay(!isAllDay)}
                    label="This event is all day" />

                <div className={["input-group-column", !isAllDay && "cols-2"].join(" ")}>
                    <DatePicker
                        type="text"
                        label="Event starts:"
                        wrapperClass="fw"
                        placeholder="Choose a date/time"
                        value={startTime}
                        onChange={(date) => setStartTime(date)} />

                    {!isAllDay &&
                        <DatePicker
                            type="text"
                            label="Event ends:"
                            wrapperClass="fw"
                            placeholder="Choose a date/time"
                            value={endTime}
                            onChange={(date) => setEndTime(date)} />
                    }
                </div>

                <div className="input-group-column">
                    <Textarea
                        type="text"
                        value={comments}
                        label="Additional comments:"
                        placeholder="e.g. 1st event of the week for the new goal"
                        onChange={(e) => setComments(e.target.value)} />
                </div>
            </div>

            <Button
                class="primary"
                onClick={() => setStage(2)}
                disabled={!title || !startTime || (!isAllDay && !endTime)}>
                Continue to Workouts
            </Button>
        </div>
    );
}

export default Details;