import React, { useContext, useEffect, useState } from "react";
import { ExerciseContext } from "../../../../utils/providers/exercise";
import { PlusIcon, SaveIcon, TrashAltIcon } from "../../../../utils/svgs";

/**
 * UI components
 */
import Input from "../../../ui/inputs/input";
import Button from "../../../ui/button/button";

/**
 * 
 */
function Equipment() {
    const [addingEquipment, setAddingEquipment] = useState(false);
    const [equipmentTitle, setEquipmentTitle] = useState("");
    const [localEquipment, setLocalEquipment] = useState([]);

    /**
     * Deconstruct variables from the add meal context
     */
    const {
        setStage,
        equipment, setEquipment,
    } = useContext(ExerciseContext);

    /**
     * When this page first loads in, pull the current equipment from the context
     */
    useEffect(() => {
        setLocalEquipment(equipment);
    }, []);

    /**
     * Save the new equipment into the local state
     */
    const saveEquipment = (addingAnother) => {
        /**
         * Do we have a title present
         */
        if (equipmentTitle) {
            /**
             * Push the equipment into the local state
             */
            setLocalEquipment((localEquipment) => [...localEquipment, equipmentTitle]);
            /**
             * Reset the fields
             */
            setEquipmentTitle("");
            /**
             * If we are not adding another one
             */
            if (!addingAnother) {
                /**
                 * Hide the adding form
                 */
                setAddingEquipment(false);
            }
        }
    }

    /**
     * Remove an equipment from the local array via the title passed in
     */
    const removeEquipment = (title) => {
        /**
         * Remove the equipment from the array
         */
        setLocalEquipment(localEquipment.filter((item) => item !== title));
    }

    /**
     * Listen for the local equipment list to be updated
     */
    useEffect(() => {
        /**
         * Push them into the context when there is an update
         */
        setEquipment(localEquipment);
    }, [localEquipment]);

    console.log(equipment)

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                {!addingEquipment &&
                    <div className="add-list-item">
                        <p onClick={() => setAddingEquipment(true)}><PlusIcon /> Add Equipment</p>
                    </div>
                }

                {addingEquipment &&
                    <div className="add-ingredient">
                        <Input
                            type="text"
                            label="Equipment:"
                            placeholder="e.g. 10kg Dumbells"
                            onChange={(e) => setEquipmentTitle(e.target.value)}
                            value={equipmentTitle} />

                        <div className="save-list-item">
                            <p onClick={() => saveEquipment(false)}><SaveIcon /> Save</p>
                            <p onClick={() => saveEquipment(true)}><SaveIcon /> Save &amp; Add Another</p>
                            <p onClick={() => setAddingEquipment(false)} className="red">Cancel</p>
                        </div>
                    </div>
                }

                {!addingEquipment &&
                    <>
                        {equipment.map((equipment, index) => (
                            <p className="list-item" key={index}>
                                {equipment}
                                <span onClick={() => removeEquipment(equipment)}>
                                    <TrashAltIcon />
                                </span>
                            </p>
                        ))}
                    </>
                }
            </div>

            <Button
                class="primary"
                onClick={() => setStage(3)}>
                Continue to Tags
            </Button>
        </div>
    );
}

export default Equipment;