import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../utils/providers/auth";
import { AlertsContext } from "../../../utils/providers/alerts";
import firebase from "firebase";
import { db, auth, fun } from "../../../utils/firebase";
import { CardIcon } from "../../../utils/svgs";
import visaLogo from "../../../assets/images/visa.png";

/**
 * UI Components
 */
import Input from "../../../components/ui/inputs/input";
import Button from "../../../components/ui/button/button";
import ProfilePicture from "../../../components/account/profilePicture";
import Modal from "../../../components/modal/modal";
import Billing from "../../../components/account/billing/billing";
import PhoneNumber from "../../../components/ui/inputs/phone-number";
import Textarea from "../../../components/ui/inputs/textarea";

/**
 * 
 */
function ClientAccount() {
    /**
     * Set some state up for the user details
     */
    const [panel, setPanel] = useState("BIO");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [profileImage, setProfileImage] = useState([]);
    const [billingLast4, setBillingLast4] = useState("");
    const [stripeID, setStripeID] = useState("");
    const [billingExpiry, setBillingExpiry] = useState("");
    const [billingType, setBillingType] = useState("");
    const [paymentSource, setPaymentSource] = useState("");
    const [addingCard, setAddingCard] = useState(false);
    const [bio, setBio] = useState("");

    /**
     * Get the user from context
     */
    const { user } = useContext(AuthContext);

    /**
     * Deconstruct the alerts functions from the context
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * Component load
     */
    useEffect(() => {
        /**
         * Set the state with the users details
         */
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setEmail(user.email);
        setProfileImage(user.picture);
        setBio(user.bio);
        setStripeID(user.stripe?.accountID);
        /**
         * Setup a listener to stream payment details
         */
        listenForBillingUpdates();
    }, []);

    /**
     * Setup a listener on the users database document to stream updates to the billing back down
     *
     * @type {const}
     */
    const listenForBillingUpdates = () => {
        /**
         * Setup the listener
         */
        db.doc(`clients/${auth.currentUser.uid}`)
            .onSnapshot((userSnap) => {
                /**
                 * Get the billing fields
                 */
                const billing = userSnap.data().billing || false;
                const stripe = userSnap.data().stripe || false;
                /**
                 * Are there billing records to display
                 */
                if (billing) {
                    setBillingLast4(billing?.last4);
                    setBillingExpiry(billing?.expiry);
                    setBillingType(billing?.brand);
                    setPaymentSource(stripe?.paymentSource);
                } else {
                    setBillingLast4("");
                }
            });
    };

    /**
     * Saves the users details from state into the database record for them
     *
     * @type {const}
     */
    const saveUserDetails = () => {
        /**
         * Save the details to the database
         */
        db.doc(`clients/${user.uid}`).set(
            {
                first_name: firstName,
                last_name: lastName,
                bio,
            },
            { merge: true }
        );
        /**
         * Show an alert to show is being saved
         */
        pushAlert({
            type: "SUCCESS",
            title: "Profile saved!"
        });
    };

    /**
     * Remove the payment method from the users account
     */
    const removePaymentMethod = async () => {
        /**
         * Remove the billing details from firestore
         */
        await db.doc(`clients/${auth.currentUser.uid}`).update({
            "stripe.paymentSource": firebase.firestore.FieldValue.delete(),
            billing: firebase.firestore.FieldValue.delete(),
        });
        /**
         * Then make a call to the cloud functions to remove it from the stripe customer account
         */
        const updatePaymentMethod = fun.httpsCallable("paymentMethodRemoved");
        await updatePaymentMethod({
            customer: stripeID,
            source: paymentSource,
        });
    };

    return (
        <>
            <div className="account-edit-title">
                <h1>{firstName} {lastName}</h1>
            </div>

            <div className="account-navigation">
                <p onClick={() => setPanel("BIO")} className={[
                    (panel === "BIO") && "is-active"
                ].join(" ")}>FitPortal Bio</p>

                <p onClick={() => setPanel("DETAILS")} className={[
                    (panel === "DETAILS") && "is-active"
                ].join(" ")}>Details</p>

                <p onClick={() => setPanel("BILLING")} className={[
                    (panel === "BILLING") && "is-active"
                ].join(" ")}>Billing</p>
            </div>

            {panel === "BIO" &&
                <>
                    {/* Profile details */}
                    <div className="edit-account-column account-column-profile">
                        <h5 className="column-title">FitPortal Bio</h5>
                        <div className="column-field-group cols-1">
                            <ProfilePicture current={profileImage} />
                        </div>
                        <div className="column-field-group cols-1">
                            <Textarea
                                type="text"
                                label="FitPortal Bio"
                                value={bio}
                                wrapperClass="fw"
                                onChange={(e) => setBio(e.target.value)}
                                placeholder="We'll show this to trainers on FitPortal, include things like any injuries you might have." />
                        </div>
                    </div>
                </>
            }

            {panel === "DETAILS" &&
                <>
                    {/* Profile details */}
                    <div className="edit-account-column account-column-profile">
                        <h5 className="column-title">Your Details</h5>
                        <div className="column-field-group cols-2">
                            <Input type="text" label="First name:" wrapperClass="fw" placeholder=" " value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            <Input type="text" label="Last name:" wrapperClass="fw" placeholder=" " value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                        <div className="column-field-group cols-1">
                            <Input type="email" label="Email:" wrapperClass="fw" placeholder=" " value={email} readonly={true} disbaled={true} />
                        </div>
                    </div>
                </>
            }

            {panel === "BILLING" &&
                <>
                    {/* Profile details */}
                    <div className="edit-account-column account-column-profile">
                        {/* Billing details */}
                        <h5 className="column-title">Billing Details</h5>
                        <label className="billing-label">Payment Methods</label>
                        {/* If there is no card on the account */}
                        {!billingLast4 && (
                            <>
                                <p className="no-billing-details">No payment methods setup, yet</p>
                                <p className="add-payment-method" onClick={() => setAddingCard((addingCard) => !addingCard)}>
                                    <CardIcon /> Add payment method
                                </p>
                            </>
                        )}
                        {/* If there is a card on the account */}
                        {billingLast4 && (
                            <div className="account-card">
                                <div className="card-logo">{billingType === "Visa" && <img src={visaLogo} alt="" />}</div>
                                <div className="card-details">
                                    <p className="card-number">
                                        {billingType} .... {billingLast4}
                                    </p>
                                    <p className="card-expiry">Expires {billingExpiry}</p>
                                </div>
                                <div className="card-remove" onClick={() => removePaymentMethod()}>
                                    remove
                                </div>
                            </div>
                        )}
                    </div>
                </>
            }

            <div className="save-button">
                <Button class="primary" onClick={() => saveUserDetails()}>
                    Save changes
                </Button>
            </div>

            {/* Add card modal */}
            {addingCard && (
                <Modal title="Add New Card" hide={() => setAddingCard((addingCard) => !addingCard)} className="slim">
                    <div className="add-card-modal">
                        <div className="add-card-terms">
                            <p>
                                Your card will be stored with our secure payment service provider and used to process payments throughout the platform. <span>You can remove it at any time.</span>
                            </p>
                        </div>
                        <label className="billing-label">Card details:</label>
                        <Billing customer={stripeID} close={() => setAddingCard((addingCard) => !addingCard)} />
                    </div>
                </Modal>
            )}
        </>
    );
}

export default ClientAccount;
