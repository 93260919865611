import React, { useContext, useState, useEffect } from "react";
import { ProgramContext } from "../../../../utils/providers/program";
import { db, auth } from "../../../../utils/firebase";
import { TrashAltIcon } from "../../../../utils/svgs";

/**
 * UI components
 */
import Button from "../../../ui/button/button";
import Select from "../../../ui/inputs/select/new-select";

/**
 * Functional component to return the meals panel for adding a new program
 */
function Meals() {
    const [userMeals, setUserMeals] = useState({});
    const [localMeals, setLocalMeals] = useState([]);

    /**
     * Deconstruct variables from the add meal context
     */
    const {
        setStage,
        meals, setMeals,
    } = useContext(ProgramContext);

    /**
     * On component load, get the meals from the context
     */
    useEffect(() => {
        /**
         * Pull the current meals and add them into the local state
         */
        setLocalMeals(meals);
    }, []);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Pull the meals for this trainer from the database
         */
        db.collection("meals")
            .where("createdBy", "==", auth.currentUser.uid)
            .get().then((mealDocs) => {
                /**
                 * Loop through the meal docs
                 */
                mealDocs.forEach((mealDoc) => {
                    /**
                     * Push it into the local state
                     */
                    setUserMeals((userMeals) => ({
                        ...userMeals,
                        [mealDoc.id]: mealDoc.data().name
                    }));
                });
            });
    }, []);

    /**
     * Listen for the local meals list to be updated
     */
    useEffect(() => {
        /**
         * Push them into the context when there is an update
         */
        setMeals(localMeals);
    }, [localMeals]);

    /**
     * Add the meal to the state
     */
    const addMeal = (option) => {
        /**
         * Push the ingredient into the local state
         */
        setLocalMeals((localMeals) => [...localMeals, option]);
    }

    /**
     * Remove the meal from the state
     */
    const removeMeal = (remove) => {
        /**
         * Remove the ingredient from the array
         */
        setLocalMeals(localMeals.filter((meal) => meal.value !== remove));
    }

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <Select
                    label="Meals involved:"
                    placeholder="Choose your meals"
                    note="You'll assign these to schedules later on"
                    onSelect={(option) => addMeal(option)}
                    options={userMeals} />

                {meals.map((meal, index) => (
                    <p className="list-item" key={index}>
                        {meal.value}
                        <span onClick={() => removeMeal(meal.value)}>
                            <TrashAltIcon />
                        </span>
                    </p>
                ))}
            </div>

            <Button
                class="primary"
                onClick={() => setStage(4)}>
                Continue to Scheduling
            </Button>
        </div>
    );
}

export default Meals;