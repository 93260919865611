import React, { useState, createContext } from "react";

/**
 * Setup a context for storing the "add workout" modal objects
 */
const ExerciseContext = createContext();

/**
 * Creating the provider to wrap the add workout modal in for displaying
 */
function ExerciseProvider({ children }) {
    const [stage, setStage] = useState(1);
    const [title, setTitle] = useState("");
    const [sets, setSets] = useState("");
    const [reps, setReps] = useState("");
    const [rest, setRest] = useState("");
    const [equipment, setEquipment] = useState([]);
    const [tags, setTags] = useState([]);

    return (
        <ExerciseContext.Provider value={{
            stage, setStage,
            title, setTitle,
            sets, setSets,
            reps, setReps,
            rest, setRest,
            equipment, setEquipment,
            tags, setTags,
        }}>
            {children}
        </ExerciseContext.Provider>
    );
};

export { ExerciseContext, ExerciseProvider };