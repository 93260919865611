import React, { useState } from "react";
import { fun } from "../../../utils/firebase";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

/**
 * Styles
 */
import "./billing.scss";

/**
 * UI components
 */
import Button from "../../ui/button/button";

/**
 * Functional component for the stripe checkout flow
 */
function BillingDetails(props) {
    const [addingCard, setAddingCard] = useState(false);

    /**
     * Use the stripe react hooks
     */
    const stripe = useStripe();
    const elements = useElements();
    /**
     * Handle the submission of the card elements form
     *
     * @type {const}
     */
    const addPaymentMethod = async () => {
        /**
         * Update the UI
         */
        setAddingCard(true);
        /**
         * Make sure we have loaded the stripe libaries correctly
         */
        if (!stripe || !elements) {
            return;
        }
        /**
         * Build the card element object form the form field
         */
        const cardElement = elements.getElement(CardElement);
        /**
         * Create the source with stripe
         */
        const { error, source } = await stripe.createSource(cardElement);
        /**
         * If there was an error
         */
        if (error) {
            console.log(error);
        } else {
            /**
             * Payment method updated
             */
            const updatePaymentMethod = fun.httpsCallable("paymentMethodUpdated");
            await updatePaymentMethod({
                customer: props.customer,
                source,
            });
        }
        /**
         * Update the UI
         */
        setAddingCard(false);
        /**
         * Hide the parent modal
         */
        props.close();
    };

    /**
     * Print the card element form to the page
     */
    return (
        <div className="">
            <CardElement
                options={{
                    style: {
                        base: {
                            color: "#4E5C70",
                            fontFamily: '"Poppins", sans-serif',
                            fontSmoothing: "antialiased",
                            fontSize: "15px",
                            fontWeight: "400",
                            "::placeholder": {
                                fontSize: "14px",
                                opacity: "0.5",
                                color: "#4E5C70",
                            },
                        },
                        invalid: {
                            color: "#fa755a",
                            iconColor: "#fa755a",
                        },
                    },
                }}
            />
            <Button class="primary" disabled={!stripe || addingCard} onClick={() => addPaymentMethod()}>
                {addingCard ? "Adding..." : "Add Payment Method"}
            </Button>
        </div>
    );
}

/**
 * Load the Stripe APIs with the correct public keys
 */
let stripePubKey;
if (window.location.hostname === "fitportal.uk") {
    /**
     * Get the live stripe key
     */
    stripePubKey = "pk_live_51IgyYpFTZLi5gJKtREV4FGKuNcqqfdNdWvxEz6mJ5EaqTRxo8w8XuB5YxpnP93DofhzF91t8tyT5LPqOwsomfvm900aaRchlXq";
} else {
    /**
     * Get the development stripe key
     */
    stripePubKey = "pk_test_51IgyYpFTZLi5gJKthnT5T5erJAILBK4UJB4QEdPUwhsut5PAuPbynpmgAak1q6NtiKZ3LTmJuY1zZgMysceUc1O800KwdmJSu1";
}

/**
 * Create the stripe promise
 */
const stripePromise = loadStripe(stripePubKey);

/**
 * Return method for the BillingDetails object wrapped in the stripe promise
 *
 * @type {const}
 */
const Billing = (props) => {
    return (
        <Elements stripe={stripePromise}>
            <BillingDetails customer={props.customer} close={() => props.close()} />
        </Elements>
    );
};

export default Billing;
