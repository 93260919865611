import React, { useContext } from "react";
import { ExerciseContext } from "../../../../utils/providers/exercise";
import { db, auth } from "../../../../utils/firebase";
/**
 * UI components
 */
import Button from "../../../ui/button/button";

/**
 * 
 */
function Save(props) {
    /**
     * Deconstruct variables from the add workout context
     */
    const {
        title,
        sets,
        reps,
        rest,
        equipment,
        tags,
    } = useContext(ExerciseContext);

    /**
     * Save the workout with the variables from the context
     */
    const saveExercise = () => {
        /**
         * Add the workout into the database
         */
        db.collection("exercises").add({
            title,
            sets,
            reps,
            rest,
            equipment,
            tags,
            createdBy: auth.currentUser.uid,
        });
        /**
         * Hide the add meal modal through the props
         */
        props.close();
    }

    return (
        <div className="add-meal-field-group">
            <div className="add-meal-inputs">
                <h1>{title}</h1>

                {(sets && reps && rest) &&
                    <p className="workout-sets">
                        {sets} sets of {reps} reps, with a {rest} second rest
                    </p>
                }

                <div className="tags-list summary">
                    {tags.map((tag, index) => (
                        <p className="list-item-tag" key={index}>
                            {tag}
                        </p>
                    ))}
                </div>

                <div className="input-group-column">
                    <div className="column-group">
                        <h2>Equipment Needed</h2>
                        {equipment.map((equipItem, index) => (
                            <p className="list-item" key={index}>
                                {equipItem}
                            </p>
                        ))}
                    </div>
                </div>
            </div>

            <Button
                class="primary"
                onClick={() => saveExercise()}>
                Save Exercise
            </Button>
        </div >
    );
}

export default Save;