import React, { useEffect, useState } from "react";
import { db } from "../../../utils/firebase";
import moment from "moment";
import "./view.scss";

/**
 * UI components
 */
import Button from "../../ui/button/button";
import Participant from "./participant/participant";

/**
 * Functional component to return the event in question being viewed
 */
function View(props) {
    const [event, setEvent] = useState({});
    const [workout, setWorkout] = useState({});

    /**
     * Deconstruct the eventID from the props
     */
    const { eventID } = props;

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Pull the event details from the database
         */
        db.doc(`events/${eventID}`).get().then((eventDoc) => {
            /**
             * If the event still exists
             */
            if (eventDoc.exists) {
                /**
                 * Deconstruct the data from the document
                 */
                const {
                    title,
                    starts,
                    ends,
                    all_day,
                    comments,
                    users,
                    workout,
                } = eventDoc.data();
                /**
                 * And then set it into the local state
                 */
                setEvent({
                    title,
                    comments,
                    isAllDay: all_day,
                    allDay: moment(starts.seconds, "X").format("DD/MM/YYYY"),
                    start: moment(starts.seconds, "X").format("DD/MM/YYYY HH:mma"),
                    end: moment(ends.seconds, "X").format("DD/MM/YYYY HH:mma"),
                    users,
                    workout,
                });
            }
        });
    }, [eventID]);

    /**
     * Cancel the event for all the participants involved
     */
    const cancelEvent = () => {
        /**
         * Remove the event from the database
         */
        db.doc(`events/${eventID}`).delete();
        /**
         * Then hide the viewingModal
         */
        props.hide();
    }

    /**
     * When the workout is updated
     */
    useEffect(() => {
        /**
         * Get the workout details from the database
         */
        db.doc(`workouts/${event.workout}`).get().then((workoutDoc) => {
            /**
             * If the workout document was found
             */
            if (workoutDoc.exists) {
                /**
                 * Push the workout data to the local state
                 */
                setWorkout(workoutDoc.data());
            }
        });
    }, [event.workout]);

    return (
        <div className="viewing-event-modal">
            <div className="viewing-event-details">
                <h1>{event.title}</h1>

                {event.comments &&
                    <h2>{event.comments}</h2>
                }

                <small className="event-timespan">
                    {event.isAllDay &&
                        `Scheduled for all day on ${event.allDay}`
                    }

                    {!event.isAllDay &&
                        `Scheduled between ${event.start} and ${event.end}`
                    }
                </small>

                {(event.users?.length > 0) &&
                    <div className="">
                        <h3>Participants</h3>
                        <ul>
                            {event.users.map((eventUser) => (
                                <Participant userID={eventUser} />
                            ))}
                        </ul>
                    </div>
                }

                {(workout.exercises && (workout.exercises.length > 0)) &&
                    <>
                        <br />
                        <div className="meal-modal-column chosen-workout-exercises">
                            <h3>Exercises Involved</h3>
                            {workout.exercises.map((exercise, index) => (
                                <>
                                    <p className="list-item" key={index}>
                                        {exercise.title}
                                    </p>
                                    {(exercise.sets.length > 0) &&
                                        <div className="exercise-set-list">
                                            <small>Sets:</small>
                                            <ol>
                                                {exercise.sets.map((set, index) => (
                                                    <li>{index + 1}) {set.reps.value} {set.reps.unit} at {set.load.value}{set.load.unit} with {set.rest.value} {set.rest.unit} {set.rest.label}</li>
                                                ))}
                                            </ol>
                                        </div>
                                    }
                                </>
                            ))}
                        </div>
                    </>
                }
            </div>

            <Button
                class="red"
                onClick={() => cancelEvent()}>
                Cancel Event
            </Button>
        </div >
    );
}

export default View;