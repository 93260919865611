import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

/**
 * Functional component to return a phone input field
 */
function PhoneNumber(props) {
    return (
        <div className={["input-block", props.wrapperClass, props.error && "invalid"].join(" ")}>
            {/* Label */}
            {props.label && <label>{props.label}</label>}
            {/* Phone inputfield */}
            <PhoneInput
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e) => props.onChange(e)}
                defaultCountry="GB"
                className={[props.value && "has-value"]} />
            {/* Input note / description */}
            {props.note && <p>{props.note}</p>}
            {/* Input error */}
            {props.error && <p className="input-error">{props.error}</p>}
        </div>
    )
}

export default PhoneNumber;