import React, { useEffect, useState } from "react";
import { db } from "../../utils/firebase";
import "./exercise.scss";

import Button from "../ui/button/button";
import Modal from "../modal/modal";

function Exercise(props) {
    const [exercise, setExercise] = useState({});
    const [viewingexercise, setViewingexercise] = useState(false);

    useEffect(() => {
        db.doc(`exercises/${props.id}`).get().then((exerciseDoc) => {
            setExercise(exerciseDoc.data());
        });
    }, []);

    const deleteExercise = () => {
        db.doc(`exercises/${props.id}`).delete();
    }

    return (
        <>
            <div className="meal-card">
                <div className="meal-card-inner">
                    <div className="meal-picture"></div>
                    <div className="meal-details">
                        <p className="meal-title">{exercise.title}</p>
                    </div>
                    {exercise.sets &&
                        <div className="meal-tags">
                            <div className="meal-tag">{exercise.sets} Sets</div>
                            <div className="meal-tag">{exercise.reps} Reps</div>
                            <div className="meal-tag">{exercise.rest} Rest</div>
                        </div>
                    }
                    {!exercise.sets &&
                        <div className="meal-tags">
                            <div className="meal-tag">No sets required</div>
                        </div>
                    }
                    <Button
                        class="primary"
                        onClick={() => setViewingexercise((viewingexercise) => !viewingexercise)}>
                        View Exercise
                    </Button>
                </div>
            </div>

            {viewingexercise &&
                <Modal
                    title={exercise.title}
                    className="center"
                    hide={() => setViewingexercise((viewingexercise) => !viewingexercise)} >

                    {exercise.sets &&
                        <p className="exercise-sets">
                            {exercise.sets} sets of {exercise.reps} reps, with a {exercise.rest} second rest
                        </p>
                    }

                    {(exercise.tags.length > 0) &&
                        <div className="meal-modal-column">
                            <div className="tags-list">
                                {exercise.tags.map((tag, index) => (
                                    <p className="list-item-tag" key={index}>
                                        {tag}
                                    </p>
                                ))}
                            </div>
                        </div>
                    }

                    {(exercise.equipment.length > 0) &&
                        <div className="meal-modal-column">
                            <h2>Equipment Needed</h2>
                            {exercise.equipment.map((equipment, index) => (
                                <p className="list-item" key={index}>
                                    {equipment}
                                </p>
                            ))}
                        </div>
                    }

                    {(exercise.equipment.length === 0) &&
                        <div className="meal-modal-column">
                            <h2>No Equipment Needed</h2>
                        </div>
                    }

                    <Button class="red" onClick={() => deleteExercise()}>
                        Delete Exercise
                    </Button>
                </Modal>
            }
        </>
    );
}

export default Exercise;