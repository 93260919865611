import React, { useEffect, useState } from "react";
import { arrayUnion, db } from "../../../../../../utils/firebase";
import { CaretDownIcon, CaretUpIcon } from "../../../../../../utils/svgs";
import firebase from "firebase";

import Button from "../../../../../ui/button/button-new";
import Input from "../../../../../ui/inputs/input";

export default function Set({ index, id, workoutID, exerciseID }) {
    const [reps, setReps] = useState({ value: 0, measurement: "reps", label: "Reps" });
    const [load, setLoad] = useState({ value: 0, measurement: "kg", label: "Weight" });
    const [rest, setRest] = useState({ value: 0, measurement: "secs", label: "Rest time" });

    const [stateLoaded, setStateLoaded] = useState(false);
    const [editingValue, setEditingValue] = useState("");
    const [copyingSet, setCopyingSet] = useState(false);

    const repsVariables = { "Reps": "mins", "Reps": "secs", "Reps": "reps" };
    const loadVariables = { "Weight": "kg", "Speed": "m/h", "Speed": "km/h" };
    const restVariables = { "Rest time": "secs", "Duration": "secs" };

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Fetch the data for this set from the database
         */
        db.doc(`workouts/${workoutID}/exercises/${exerciseID}/sets/${id}`)
            .get().then((setDoc) => {
                /**
                 * Pull the set data from the document on the exercise
                 */
                const { target } = setDoc.data();
                /**
                 * Set the values into the state
                 */
                setReps({ ...reps, ...target?.reps });
                setLoad({ ...load, ...target?.load });
                setRest({ ...rest, ...target?.rest });
            }).then(() => { setStateLoaded(true); });
    }, []);

    /**
     * When the reps, load or rest values are changed
     */
    useEffect(() => {
        /**
         * Fetch the data for this set from the database
         */
        stateLoaded && db.doc(`workouts/${workoutID}/exercises/${exerciseID}/sets/${id}`).set({
            target: {
                reps: {
                    value: Number(reps.value),
                    measurement: reps.measurement,
                    label: reps.label,
                },
                load: {
                    value: Number(load.value),
                    measurement: load.measurement,
                    label: load.label,
                },
                rest: {
                    value: Number(rest.value),
                    measurement: rest.measurement,
                    label: rest.label,
                },
            },
        }, { merge: true });
    }, [reps, load, rest]);

    const changeSetUnit = async (type, label, value) => {
        /**
         * Hide the unit selector
         */
        setEditingValue("");
        /**
         * Perform a switch case to update the appropriate set units
         */
        switch (type) {
            case "reps":
                return setReps({ ...reps, label, measurement: value });
            case "load":
                return setLoad({ ...load, label, measurement: value });
            case "rest":
                return setRest({ ...rest, label, measurement: value });
            default:
                return false;
        }
    }

    const updateSetDetails = async (type, value) => {
        /**
         * Force the value inputted to a number
         */
        const toNumber = Number(value);
        /**
         * Perform a switch case to update the appropriate set value
         */
        switch (type) {
            case "reps":
                return setReps({ ...reps, value: toNumber });
            case "load":
                return setLoad({ ...load, value: toNumber });
            case "rest":
                return setRest({ ...rest, value: toNumber });
            default:
                return false;
        }
    }

    const copySet = async () => {
        /**
         * Show a loading spinner
         */
        setCopyingSet(true);
        /**
         * Add a copy of this new set into the "sets" collection on the exercise
         */
        const newSetID = await db.collection(`workouts/${workoutID}/exercises/${exerciseID}/sets`).add({
            created: firebase.firestore.FieldValue.serverTimestamp(),
            target: {
                reps: {
                    value: Number(reps.value),
                    measurement: reps.measurement,
                    label: reps.label,
                },
                load: {
                    value: Number(load.value),
                    measurement: load.measurement,
                    label: load.label,
                },
                rest: {
                    value: Number(rest.value),
                    measurement: rest.measurement,
                    label: rest.label,
                },
            },
        }).then((newSetDoc) => { return newSetDoc.id });
        /**
         * And then push the new ID into the orders array to have it show in the DOM
         */
        await db.doc(`workouts/${workoutID}/exercises/${exerciseID}`).set({
            sets_order: arrayUnion(newSetID),
        }, { merge: true });
        /**
         * hide the loading spinner
         */
        setCopyingSet(false);
    }

    return (
        <div className="set-row add-set-row">
            <h6>Set {index}</h6>

            <div className="add-set-inputs">
                <div className="add-set-input">
                    <div className={[
                        "set-unit-options",
                        (editingValue === "reps") && "is-active"
                    ].join(" ")}>
                        {Object.entries(repsVariables).map((variable) => (
                            <div
                                className="unit-option"
                                onClick={() => changeSetUnit(
                                    "reps", variable[0], variable[1]
                                )}>
                                <p>{variable[0]} ({variable[1]})</p>
                            </div>
                        ))}
                    </div>

                    <p onClick={() => setEditingValue("reps")}>
                        {reps.label} ({reps.measurement})
                        {(editingValue === "reps") ? <CaretDownIcon /> : <CaretUpIcon />}
                    </p>

                    <Input
                        type="number"
                        value={reps.value}
                        onChange={(e) => updateSetDetails("reps", e.target.value)} />
                </div>

                <div className="add-set-input">
                    <div className={[
                        "set-unit-options",
                        (editingValue === "load") && "is-active"
                    ].join(" ")}>
                        {Object.entries(loadVariables).map((variable) => (
                            <div
                                className="unit-option"
                                onClick={() => changeSetUnit(
                                    "load", variable[0], variable[1]
                                )}>
                                <p>{variable[0]} ({variable[1]})</p>
                            </div>
                        ))}
                    </div>

                    <p onClick={() => setEditingValue("load")}>
                        {load.label} ({load.measurement})
                        {(editingValue === "load") ? <CaretDownIcon /> : <CaretUpIcon />}
                    </p>

                    <Input
                        type="number"
                        value={load.value}
                        onChange={(e) => updateSetDetails("load", e.target.value)} />
                </div>

                <div className="add-set-input">
                    <div className={[
                        "set-unit-options",
                        (editingValue === "rest") && "is-active"
                    ].join(" ")}>
                        {Object.entries(restVariables).map((variable) => (
                            <div
                                className="unit-option"
                                onClick={() => changeSetUnit(
                                    "rest", variable[0], variable[1]
                                )}>
                                <p>{variable[0]} ({variable[1]})</p>
                            </div>
                        ))}
                    </div>

                    <p onClick={() => setEditingValue("rest")}>
                        {rest.label} ({rest.measurement})
                        {(editingValue === "rest") ? <CaretDownIcon /> : <CaretUpIcon />}
                    </p>

                    <Input
                        type="number"
                        value={rest.value}
                        onChange={(e) => updateSetDetails("rest", e.target.value)} />
                </div>

                <Button
                    label="Copy"
                    loading={copyingSet}
                    loadingText="Copying..."
                    small={true}
                    onClick={() => copySet()} />
            </div>
        </div>
    );
}