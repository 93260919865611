import React, { useEffect, useState } from "react";
import { db } from "../../utils/firebase";
import "./workout.scss";

/**
 * UI components
 */
import ButtonExtendable from "../ui/button/button-extendable";
import Card from "../ui/card/card";
import CardTitle from "../ui/card/partials/title";
import ViewWorkout from "./view/view";
import EditWorkout from "./edit/edit";
import AssignWorkout from "./assign/assign";

/**
 * Functional component to return the workout, with viewing modal included
 */
function Workout(props) {
    const [workout, setWorkout] = useState({
        title: "",
        exercises: [],
        tags: [],
    });
    const [viewingWorkout, setViewingWorkout] = useState(false);
    const [editingWorkout, setEditingWorkout] = useState(false);
    const [assigningWorkout, setAssigningWorkout] = useState(false);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the workout document from the database
         */
        const unsubscribe = db.doc(`workouts/${props.id}`)
            .onSnapshot((workoutDoc) => {
                if (workoutDoc.exists) {
                    /**
                     * Deconstruct the workout data from the document
                     */
                    const { title, summary, exercises_order, tags } = workoutDoc.data();
                    /**
                     * Push the data we need into the state
                     */
                    setWorkout({
                        title,
                        summary,
                        exercises: exercises_order?.length || 0,
                        tags: tags || [],
                    });
                }
            });
        return () => unsubscribe();
    }, [props.id]);

    /**
     * Delete the workout
     */
    const deleteWorkout = () => {
        /**
         * Delete the workout document in the database
         */
        db.doc(`workouts/${props.id}`).delete();
    }

    /**
     * 
     */
    const toggleWorkoutView = () => {
        setViewingWorkout(!viewingWorkout);
    }

    /**
     * 
     */
    const editWorkout = () => {
        props.editWorkout(props.id);
    }

    return (
        <>
            <Card>
                <CardTitle>
                    <p>{workout.title}</p>
                    <small>Includes {workout.exercises} excerise{workout.exercises === 1 ? "" : "s"}</small>
                    <p className="workout-summary-para">{workout.summary}</p>
                </CardTitle>

                <ButtonExtendable
                    class="primary"
                    label="View Workout"
                    onClick={toggleWorkoutView}
                    actions={[
                        {
                            label: "Assign to client",
                            onClick: () => setAssigningWorkout(true),
                        },
                        {
                            label: "Edit",
                            onClick: () => editWorkout(),
                        },
                        {
                            label: "Delete",
                            onClick: () => deleteWorkout(),
                        },
                    ]} />
            </Card>

            {assigningWorkout &&
                <AssignWorkout workoutID={props.id} title={workout.title} hide={() => setAssigningWorkout(false)} />
            }

            {editingWorkout &&
                <EditWorkout workoutID={props.id} title={workout.title} hide={() => props.editWorkout("")} />
            }

            {viewingWorkout &&
                <ViewWorkout workoutID={props.id} hide={() => setViewingWorkout(false)} />
            }
        </>
    );
}

export default Workout;