import React, { useEffect, useState } from "react";
import { db, auth } from "../../../utils/firebase";
import { WorkoutProvider } from "../../../utils/providers/workout";
import { EditWorkoutProvider } from "../../../utils/providers/workout-edit";
import firebase from "firebase";
import moment from "moment";

/**
 * UI components
 */
import WorkoutParticipants from "../../../components/workouts/user";
import Button from "../../../components/ui/button/button-new";
import Modal from "../../../components/modal/modal";
import DatePicker from "../../ui/inputs/datetime";
import Input from "../../ui/inputs/input";
import AddWorkout from "../../workouts/add/add";
import EditWorkout from "../../workouts/edit/edit";
import { CalendarIcon, CopyIcon, PencilIcon, TrashIcon } from "../../../utils/svgs";

/**
 *
 */
function Workouts(props) {
    const [trainerWorkouts, setTrainerWorkouts] = useState([]);
    const [clientWorkouts, setClientWorkouts] = useState([]);

    const [upcomingWorkouts, setUpcomingWorkouts] = useState([]);
    const [assigning, setAssigning] = useState(false);
    const [creatingInstance, setCreatingInstance] = useState(false);

    const [assignTitle, setAssignTitle] = useState("");
    const [assignForDate, setAssignForDate] = useState({});
    const [assignWorkoutID, setAssignWorkoutID] = useState("");
    const [assignSaving, setAssignSaving] = useState(false);

    const [trainerWorkoutIDs, setTrainerWorkoutIDs] = useState([]);

    const [addingForClient, setAddingForClient] = useState(false);

    const [copying, setCopying] = useState("");

    const [editingWorkout, setEditingWorkout] = useState("");

    /**
     * On component load
     */
    useEffect(() => {
        setupUpcomingEventsListener();
    }, []);

    /**
     * 
     */
    useEffect(() => {
        setTrainerWorkouts([]);

        db.collection("workouts")
            .where("created_by", "==", auth.currentUser.uid)
            .onSnapshot((workoutDocs) => {
                workoutDocs.docChanges().forEach((workoutDoc) => {
                    if (!workoutDoc.doc.data().created_for) {
                        if (workoutDoc.type === "added") {
                            setTrainerWorkouts((trainerWorkouts) => [...trainerWorkouts, {
                                id: workoutDoc.doc.id,
                                ...workoutDoc.doc.data(),
                            }]);
                        }

                        if (workoutDoc.type === "modified") {
                            if (workoutDoc.doc.data().archived) {
                                setTrainerWorkouts((trainerWorkouts) => trainerWorkouts.filter((workoutElement) => workoutElement.id !== workoutDoc.doc.id));
                            }
                        }
                    }
                });
            });
    }, []);

    useEffect(() => {
        let trainerIDs = [];

        setClientWorkouts([]);

        db.collection("workouts")
            .where("created_by", "==", auth.currentUser.uid)
            .where("created_for", "==", props.userID)
            .onSnapshot((workoutDocs) => {
                workoutDocs.docChanges().forEach((workoutDoc) => {
                    if (workoutDoc.type === "added") {
                        setClientWorkouts((clientWorkouts) => [...clientWorkouts, {
                            id: workoutDoc.doc.id,
                            ...workoutDoc.doc.data(),
                        }]);
                    }

                    if (workoutDoc.type === "modified") {
                        if (workoutDoc.doc.data().archived) {
                            setClientWorkouts((clientWorkouts) => clientWorkouts.filter((workoutElement) => workoutElement.id !== workoutDoc.doc.id));
                        }
                    }
                });
            });

        setTrainerWorkoutIDs(trainerIDs);
    }, []);

    /**
     * Get the current time as a unix timestamp
     */
    const current_timestamp = firebase.firestore.Timestamp.fromMillis(moment().format("x"));

    /**
     * Seutp a listener on the database to pull upcoming events
     */
    const setupUpcomingEventsListener = () => {
        db.collection("events")
            .where("users", "array-contains", props.userID)
            .where("starts", ">=", current_timestamp)
            .orderBy("starts", "asc")
            .onSnapshot((events_snap) => {
                /**
                 * Loop over each of the events and parse them into an array to load into state
                 */
                events_snap.docChanges().forEach((change) => {
                    /**
                     * Deconstruct the data from the update call
                     */
                    const event_data = change.doc.data();
                    /**
                     * Event added
                     */
                    if (change.type === "added") {
                        setUpcomingWorkouts((event) => [
                            ...event,
                            {
                                id: change.doc.id,
                                start: moment(event_data.starts.seconds, "X").format("DD/MM/YYYY"),
                                end: moment(event_data.ends.seconds, "X").format("DD/MM/YYYY"),
                                ...change.doc.data(),
                            },
                        ]);
                    }
                    /**
                     * Event updated
                     */
                    if (change.type === "modified") {
                        setUpcomingWorkouts((event) => {
                            let updatedEvents = [...event];
                            for (let i in event) {
                                if (event[i].id === change.doc.id) {
                                    updatedEvents[i] = {
                                        id: change.doc.id,
                                        start: moment(event_data.starts.seconds, "X").format("DD/MM/YYYY"),
                                        end: moment(event_data.ends.seconds, "X").format("DD/MM/YYYY"),
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedEvents;
                        });
                    }
                    /**
                     * Event removed
                     */
                    if (change.type === "removed") {
                        setUpcomingWorkouts((event) => event.filter((eventElement) => eventElement.id !== change.doc.id));
                    }
                });
            });
    };

    const loadAssignWorkout = async (workout) => {
        setAssignTitle(workout.title);
        setAssignWorkoutID(workout.id);

        setAssigning(true);
    }

    const assignWorkoutToClient = async () => {
        setAssignSaving(true);

        const dateAsMillis = moment(assignForDate).valueOf();
        const dateAsEndMillis = moment(assignForDate).add(1, "hours").valueOf();

        const timestampDate = firebase.firestore.Timestamp.fromMillis(dateAsMillis);
        const timestampDateEnds = firebase.firestore.Timestamp.fromMillis(dateAsEndMillis);

        await db.collection("events").add({
            all_day: false,
            comments: "",
            created: firebase.firestore.FieldValue.serverTimestamp(),
            starts: timestampDate,
            ends: timestampDateEnds,
            setup_by: auth.currentUser.uid,
            title: assignTitle,
            users: [
                props.userID,
                auth.currentUser.uid,
            ],
            workout: assignWorkoutID,
        });

        setAssignSaving(false);
        setAssigning(false);
    }

    const createWorkoutCopy = async (workoutID) => {
        setCopying(workoutID);

        let currentWorkout = await db.doc(`workouts/${workoutID}`)
            .get().then((workoutDoc) => {
                return { ...workoutDoc.data() };
            });

        const newWorkoutID = await db.collection("workouts").add({
            copy_of: workoutID,
            ...currentWorkout,
            created_for: props.userID,
        }).then((newWorkoutDoc) => { return newWorkoutDoc.id });

        await db.collection(`workouts/${workoutID}/exercises`)
            .get().then(async (exerciseDocs) => {
                exerciseDocs.forEach((exerciseDoc) => {
                    db.collection(`workouts/${newWorkoutID}/exercises`).add({
                        ...exerciseDoc.data(),
                    }).then(async (newExerciseDoc) => {
                        await db.collection(`workouts/${workoutID}/exercises/${exerciseDoc.id}/sets`)
                            .get().then((setDocs) => {
                                setDocs.forEach((setDoc) => {
                                    db.collection(`workouts/${newWorkoutID}/exercises/${newExerciseDoc.id}/sets`).add({
                                        ...setDoc.data(),
                                    });
                                });
                            });
                    });
                });
            });

        setCopying("");
    }

    const archiveWorkout = async (workout) => {
        await db.doc(`workouts/${workout.id}`).set({
            archived: true,
        }, { merge: true });
    }

    const createWorkoutForClient = () => {
        setAddingForClient(true);
    }

    const loadEditWorkout = (workoutID) => {
        setEditingWorkout(workoutID);
    }

    return (
        <>
            <div className="tab-workout-columns">
                <div className="upcoming-workouts">
                    <h2>Upcoming workouts</h2>
                    <div className="workouts-grid">
                        {upcomingWorkouts.length > 0 &&
                            upcomingWorkouts.map((workout) => (
                                <div key={workout.id} className="workout-card">
                                    <div className="workout-card-title">
                                        <p>{workout.title}</p>
                                        <small>{moment.unix(workout.starts.seconds).format("DD/MM/YYYY [at] HH:mm")}</small>
                                    </div>
                                    {workout.users?.length > 1 && (
                                        <p className="workout-with">
                                            With <WorkoutParticipants users={workout.users} />
                                        </p>
                                    )}
                                </div>
                            ))}
                        {/* No upcoming workouts */}
                        {upcomingWorkouts.length === 0 && <p className="no-data">No upcoming workouts</p>}
                    </div>
                </div>
                <div className="workouts-divider"></div>
                <div className="workouts-libary">
                    <h2>Client workout library</h2>
                    <div className="trainer-workouts">
                        {(clientWorkouts.length === 0) &&
                            <p className="no-data">Copy a workout across from your libary to customise for this client</p>
                        }

                        {clientWorkouts.length > 0 &&
                            <div className="trainer-workouts-grid">
                                {clientWorkouts.map((workout) => (
                                    <>
                                        {!workout?.archived &&
                                            <div className="trainer-workout-in-client">
                                                <p>{workout.title}</p>
                                                <Button
                                                    small={true}
                                                    icon={<PencilIcon />}
                                                    onClick={() => loadEditWorkout(workout.id)} />
                                                <Button
                                                    small={true}
                                                    icon={<CalendarIcon />}
                                                    onClick={() => loadAssignWorkout(workout)} />
                                                <Button
                                                    small={true}
                                                    icon={<TrashIcon />}
                                                    onClick={() => archiveWorkout(workout)} />
                                            </div>
                                        }
                                    </>
                                ))}
                            </div>
                        }
                    </div>

                    <div className="create-workout-for-client">
                        <Button
                            label="Create workout"
                            onClick={() => createWorkoutForClient()} />
                    </div>
                </div>
                <div className="workouts-divider"></div>
                <div className="workouts-libary">
                    <h2>Your workout library</h2>
                    <div className="trainer-workouts">
                        {trainerWorkouts.length > 0 &&
                            <div className="trainer-workouts-grid">
                                {trainerWorkouts.map((workout) => (
                                    <>
                                        {!trainerWorkoutIDs.includes(workout.id) && !workout.archived &&
                                            <div className="trainer-workout-in-client">
                                                <p>{workout.title}</p>
                                                <Button
                                                    small={true}
                                                    icon={<CopyIcon />}
                                                    loading={copying === workout.id}
                                                    loadingText="Copying..."
                                                    onClick={() => createWorkoutCopy(workout.id)} />
                                                <Button
                                                    small={true}
                                                    icon={<CalendarIcon />}
                                                    onClick={() => loadAssignWorkout(workout)} />
                                                <Button
                                                    small={true}
                                                    icon={<TrashIcon />}
                                                    onClick={() => archiveWorkout(workout)} />
                                            </div>
                                        }
                                    </>
                                ))}
                            </div>
                        }
                    </div>
                </div>
            </div>

            {/* Are we assigning a workout to the client */}
            {assigning &&
                <Modal
                    title="Assign workout"
                    className="slim"
                    hide={() => setAssigning(false)}>
                    <div className="assign-workout-column">
                        <Input
                            placeholder="Workout title:"
                            value={assignTitle}
                            onChange={(e) => setAssignTitle(e.target.value)} />

                        <DatePicker
                            placeholder="Choose date/time:"
                            onChange={(date) => setAssignForDate(date)} />

                        <Button
                            label="Assign workout"
                            loading={assignSaving}
                            loadingText="Saving..."
                            onClick={() => assignWorkoutToClient()} />
                    </div>
                </Modal>
            }

            {addingForClient &&
                <Modal
                    title="Create workout"
                    hide={() => setAddingForClient(false)}
                    className="with-nav large no-pad">
                    <WorkoutProvider>
                        <AddWorkout close={() => setAddingForClient(false)} forClient={props.userID} />
                    </WorkoutProvider>
                </Modal>
            }

            {editingWorkout &&
                <Modal
                    title="Edit workout"
                    hide={() => setEditingWorkout("")}
                    className="with-nav large no-pad">
                    <EditWorkoutProvider>
                        <EditWorkout
                            workoutID={editingWorkout}
                            hide={() => setEditingWorkout("")} />
                    </EditWorkoutProvider>
                </Modal>
            }
        </>
    );
}

export default Workouts;
