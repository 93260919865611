import React, { useEffect, useState } from "react";

/**
 * Functional component to return the payments between a trainer and client
 */
function Payments() {
    return ("payments");
}

export default Payments;
